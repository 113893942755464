import React, { useMemo } from 'react';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import * as S from './SearchResults.styles';
import { BaseList } from '@app/components/common/BaseList/BaseList';
import { Component } from '@app/constants/config/components';

interface SearchResultsProps {
  results: Component[];
}

export const SearchResults: React.FC<SearchResultsProps> = ({ results }) => {

  const { t } = useTranslation();

  const resultsList = useMemo(
    () =>
      results.map((result) => (
        <BaseList
          key={result.id}
          rowKey={(record) => record.id}
          split={false}
          header={
            <BaseList.Item>
              <BaseList.Item.Meta
                title={<HashLink to={'/customer/360/view/' + result.id}>{result.custName + ' ' + '(' + result.mobileNo + ')'}</HashLink>}
              />
            </BaseList.Item>
          }
          dataSource={results}
          /* renderItem={(item) => (
            <HashLink to={'/customer/list/'}>
              <BaseList.Item>
                <S.Text>{item.CustName}</S.Text>
              </BaseList.Item>
            </HashLink>
          )} */
        />
      )),
    [results, t],
  );

  return <S.SearchResultsWrapper>{resultsList}</S.SearchResultsWrapper>;
 
};

import React from 'react';
import * as S from './MainSider/MainSider.styles';
import { RightOutlined } from '@ant-design/icons';
import { useResponsive } from 'hooks/useResponsive';
//import logo from 'assets/logo.png';
//import logoDark from 'assets/logo-dark.png';
import AppLogo from 'assets/hajj_link_logo.png';
import { useAppSelector } from '@app/hooks/reduxHooks';

interface SiderLogoProps {
  isSiderCollapsed: boolean;
  toggleSider: () => void;
}
export const SiderLogo: React.FC<SiderLogoProps> = ({ isSiderCollapsed, toggleSider }) => {
  const { desktopOnly  } = useResponsive();

  const theme = useAppSelector((state) => state.theme.theme);

  const img = theme === 'dark' ? AppLogo : AppLogo;

  return (
    <S.SiderLogoDiv>
      <S.SiderLogoLink to="/dashboard">
        <img src={img} alt="HAJLINK" width={48} height={48} />
        <S.BrandSpan>HAJLINK</S.BrandSpan>
      </S.SiderLogoLink>
      {desktopOnly &&(
        <S.CollapseButton
          shape="circle"
          size="small"
          $isCollapsed={isSiderCollapsed}
          icon={<RightOutlined rotate={isSiderCollapsed ? 180 : 0} />}
          onClick={toggleSider}
        />
      )}
    </S.SiderLogoDiv>
  );
};

import React, { Component, useState } from 'react';
import { getRecordById, updateRecord, getRecords } from '@app/utils/APIUtils';
import { Descriptions, Input, Select } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { handleEnter } from '@app/helper/EnterIndexHelper';

const FormItem = Form.Item;

const onSearch = (value) => {
  console.log('search:', value);
};

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  return (
    <EditPackagePreferences
      {...props}
      navigation={navigate}
      isLoading={isLoading}
      setLoading={setLoading}
      params={params}
    />
  );
}

class EditPackagePreferences extends Component {
  render() {
    const AntWrappedOfficeForm = Form.create()(EditPackagePreferencesForm);
    return (
      <div>
        <AntWrappedOfficeForm
          dataId={this.props.params}
          navigate={this.props.navigation}
          isLoading={this.props.isLoading}
          setLoading={this.props.setLoading}
        />
      </div>
    );
  }
}

class EditPackagePreferencesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      pkgPrefId: this.props.dataId.id,
      durationList: [],
      makkahHotelList: [],
      madinaHotelList: [],
      roomPreferenceList: [
        { value: 1, label: 'Private Room' },
        { value: 2, label: 'Shared Room' },
        { value: 3, label: 'Family Room' },
      ],
      isRequired: false,
      isReadonly: false,
      totalPersons: {
        value: '',
      },
      customerInfo: {},
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateTotalPersons = this.validateTotalPersons.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  componentDidMount() {
    let promise, promise1, promise2, promise3;

    promise = getRecords('/domain/data/durations/v1/all');
    promise1 = getRecords('/setup/v1/hotels/makkah/list');
    promise2 = getRecords('/setup/v1/hotels/medina/list');

    this.setState({
      isLoading: true,
    });

    promise
      .then((response) => {
        this.setState({
          durationList: response.map((item) => ({ value: item.id, label: item.noOfDays })),
        });
      })
      .catch((error) => {
        if (error)
          this.setState({
            isLoading: false,
          });
      });

    promise1
      .then((response) => {
        this.setState({
          makkahHotelList: response.map((item) => ({ value: item.hotelId, label: item.hotelType })),
        });
      })
      .catch((error) => {
        if (error)
          this.setState({
            isLoading: false,
          });
      });

    promise2
      .then((response) => {
        this.setState({
          madinaHotelList: response.map((item) => ({ value: item.hotelId, label: item.hotelType })),
        });
      })
      .catch((error) => {
        if (error)
          this.setState({
            isLoading: false,
          });
      });

    promise3 = getRecordById('/customer/package/v1/preferences/', this.state.pkgPrefId);
    promise3.then((res) => {
      this.props.form.setFieldsValue({
        customerId: res.CustomerBean.id,
        totalPersons: res.TotalPax,
        pkgDurationId: res.Duration.id,
        hotelMakkahId: res.MakkahHotel.id,
        hotelMedinaId: res.MadinaHotel.id,
        roomPreference: res.RoomPrefID,
        remarks: res.Remarks,
      });

      this.setState({
        customerInfo: res.CustomerBean,
      });
    });

    Promise.all([promise, promise1, promise2, promise3]);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const updateRequest = Object.assign({}, values);
        //updateRequest.referenceStatusId  = 111;
        console.log('updateRequest => ' + JSON.stringify(updateRequest));

        updateRecord(this.state.pkgPrefId, updateRequest, '/customer/package/v1/preferences/')
          .then((response) => {
            if (response)
              notificationController.success({
                message: 'Package preferences updated.',
              });
            this.props.navigate('/package_preferences/list');
          })
          .catch((error) => {
            notificationController.error({
              message: 'Failed! ' + error.message,
            });
          });
      }
    });
  }

  onCancelForm(event) {
    event.preventDefault();
    this.props.navigate('/package_preferences/list');
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const { customerInfo } = this.state;

    return (
      <BaseCard id="validation form" title="Edit Package Preferences" padding="1.25rem">
        <BaseRow gutter={[30, 30]}>
          <BaseCol xs={24} sm={20} xl={12}>
            <Form
              onSubmit={this.handleSubmit}
              className="preferences-form"
              name="basic"
              labelCol={{ span: 7 }}
              style={{ maxWidth: 500 }}
              initialValues={{ remember: true }}
              autoComplete="off"
            >
              <FormItem label="Cutomer Id">
                {getFieldDecorator('customerId', {
                  rules: [{ required: true, message: 'Customer id is required!' }],
                })(<Input name="customerId" size="small" placeholder="Enter customer id" disabled={true} />)}
              </FormItem>

              <FormItem
                label="Total Person"
                validateStatus={this.state.totalPersons.validateStatus}
                help={this.state.totalPersons.errorMsg}
              >
                {getFieldDecorator('totalPersons', {
                  rules: [{ required: true, message: 'Total person is required!' }],
                })(
                  <Input
                    name="totalPersons"
                    size="small"
                    placeholder="Enter total persons"
                    onChange={(event) => this.handleInputChange(event, this.validateTotalPerson)}
                    onKeyDown={(e) => handleEnter(e)}
                  />,
                )}
              </FormItem>

              <FormItem label="Package Duration">
                {getFieldDecorator('pkgDurationId', {
                  rules: [{ required: true, message: 'Package duration is required!' }],
                })(
                  <Select
                    showSearch
                    placeholder="Select package"
                    size="small"
                    name="pkgDurationId"
                    optionFilterProp="children"
                    onSearch={onSearch}
                    onKeyDown={(e) => handleEnter(e)}
                  >
                    {this.state.durationList.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </FormItem>

              <FormItem label="Hotel In Makkah">
                {getFieldDecorator('hotelMakkahId', {
                  rules: [{ required: true, message: 'Hotel is required!' }],
                })(
                  <Select
                    showSearch
                    placeholder="Select"
                    size="small"
                    name="hotelMakkahId"
                    optionFilterProp="children"
                    onSearch={onSearch}
                    onKeyDown={(e) => handleEnter(e)}
                  >
                    {this.state.makkahHotelList.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </FormItem>

              <FormItem label="Hotel In Madinah">
                {getFieldDecorator('hotelMedinaId', {
                  rules: [{ required: true, message: 'Hotel is required!' }],
                })(
                  <Select
                    showSearch
                    placeholder="Select"
                    size="small"
                    name="hotelMedinaId"
                    optionFilterProp="children"
                    onSearch={onSearch}
                    onKeyDown={(e) => handleEnter(e)}
                  >
                    {this.state.madinaHotelList.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </FormItem>

              <FormItem label="Room Preference">
                {getFieldDecorator('roomPreference', {
                  rules: [{ required: true, message: 'Please select room preference!' }],
                })(
                  <Select
                    showSearch
                    placeholder="Select room preference"
                    size="small"
                    name="roomPreference"
                    optionFilterProp="children"
                    onSearch={onSearch}
                    onKeyDown={(e) => handleEnter(e)}
                  >
                    {this.state.roomPreferenceList.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </FormItem>

              <FormItem label="Remarks">
                {getFieldDecorator('remarks', {
                  rules: [{ required: false, message: 'Remarks is required!' }],
                })(<Input name="remarks" size="small" placeholder="Enter remarks" onKeyDown={(e) => handleEnter(e)} />)}
              </FormItem>

              <FormItem
                wrapperCol={{
                  offset: 7,
                  span: 9,
                }}
              >
                <BaseButtonsGroup onCancel={this.onCancelForm} />
              </FormItem>
            </Form>
          </BaseCol>

          <BaseCol xs={24} sm={12} xl={12}>
            <Descriptions title="Customer Info" bordered size="middle">
              <Descriptions.Item label="Customer Name" span={3}>
                {customerInfo.CustName}
              </Descriptions.Item>
              <Descriptions.Item label="Profession" span={3}>
                {customerInfo.ProfName}
              </Descriptions.Item>
              <Descriptions.Item label="Customer Email" span={3}>
                {customerInfo.Email}
              </Descriptions.Item>
              <Descriptions.Item label="Mobile" span={3}>
                {customerInfo.MobileNo}
              </Descriptions.Item>
              <Descriptions.Item label="WhatsApp" span={3}>
                {customerInfo.WhatsApp}
              </Descriptions.Item>
              <Descriptions.Item label="Address" span={3}>
                {customerInfo.AddressLine1}
              </Descriptions.Item>
              <Descriptions.Item label="Reference" span={3}>
                {customerInfo.Reference}
              </Descriptions.Item>
            </Descriptions>
          </BaseCol>
        </BaseRow>
      </BaseCard>
    );
  }

  validateTotalPerson = (totalPersons) => {
    if (!totalPersons) {
      return {
        validateStatus: 'error',
        errorMsg: 'Total person may not be empty',
      };
    }

    const NUMBER_REGEX = RegExp('^[1-9]');
    if (!NUMBER_REGEX.test(totalPersons)) {
      return {
        validateStatus: 'error',
        errorMsg: 'Only number is allowed',
      };
    }

    return {
      validateStatus: null,
      errorMsg: null,
    };
  };

  validateTotalPersons() {
    // First check for client side errors in username
    const totalPersonsValue = this.state.totalPersons.value;
    const totalPersonsValidation = this.validateTotalPerson(totalPersonsValue);

    if (totalPersonsValidation.validateStatus === 'error') {
      this.setState({
        totalPersons: {
          value: totalPersonsValue,
          ...totalPersonsValidation,
        },
      });
      return;
    }

    this.setState({
      totalPersons: {
        value: totalPersonsValue,
        validateStatus: 'validating',
        errorMsg: null,
      },
    });
  }
}

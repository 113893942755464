import React, { Component, useState } from 'react';
import { saveRecord, getRecords, checkRecordAvailability } from '@app/utils/APIUtils';
import { CUSTOMERNAME_MIN_LENGTH, CUSTOMERNAME_MAX_LENGTH, EMAIL_MAX_LENGTH, MOBILE_MAX_LENGTH } from '@app/constants';
import { Form, Input, Select } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { MailOutlined, PhoneOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { handleEnter } from '../../helper/EnterIndexHelper';

const FormItem = Form.Item;

const onSearch = (value) => {
  console.log('search:', value);
};

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  return (
    <AddCustomer {...props} 
      navigation={navigate} 
      isLoading={isLoading} 
      setLoading={setLoading} 
      form={form}
      isOptionSelected={isOptionSelected}
      setIsOptionSelected={setIsOptionSelected}
    />
  )
}

class AddCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salesPartnerList: [],
      districtList: [],
      thanaList: [],
      referenceStatusItems: [
        { value: 1, label: 'Prospect' },
        { value: 2, label: 'Pre-registered' },
        { value: 3, label: 'Registered' },
        { value: 4, label: 'Transferred In' },
        { value: 5, label: 'Transferred Out' },
        { value: 6, label: 'Cancelled' },
        { value: 7, label: 'Refunded' },
        { value: 8, label: 'Archived' },
        { value: 9, label: 'Blocked' },
      ],
      isRequired: false,
      isReadonly: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);
    this.handleDistrictChange = this.handleDistrictChange.bind(this);
    /* this.validateCustomerNameAvailability = this.validateCustomerNameAvailability.bind(this);
    this.validateEmailAvailability = this.validateEmailAvailability.bind(this);
    this.validateCustomerWhatsAppNumberAvailability = this.validateCustomerWhatsAppNumberAvailability.bind(this);
    this.validateCustomerMobileNumberAvailability = this.validateCustomerMobileNumberAvailability.bind(this); */
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  componentDidMount() {
    let promise1, promise2;

    promise1 = getRecords('/domain/data/districts/v1/all');
    promise2 = getRecords('/admin/v1/agents/partners');

    this.props.setLoading(true)

    promise1.then((response) => {
      this.setState({
        districtList: response.map((item) => ({
          value: item.id,
          label: item.districtNameEn + ' - ' + item.districtNameBn,
        })),
      });
    })
    .catch((error) => {
      notificationController.error({
        message: error.message,
      });
      this.props.setLoading(false)
    });

    promise2.then((response) => {
      this.setState({
        salesPartnerList: response.map((item) => ({
          value: item.agentId,
          label: item.agentName,
        })),
      });
    })
    .catch((error) => {
      notificationController.error({
        message: error.message,
      });
      this.props.setLoading(false)
    });
  }

  handleSubmit(values) {
    const saveRequest = Object.assign({}, values);
    console.log('saveRequest => ' + JSON.stringify(saveRequest));

    saveRecord(saveRequest, '/public/v1/customers').then((response) => {
      if (response.statusCode === 101) {
        notificationController.error({
          message: 'Mobile or email already exist.',
        });
      } else {
        notificationController.success({
          message: 'Customer created.',
        });
        this.props.navigation('/customer/list');
      }
    })
    .catch((error) => {
      notificationController.error({
        message: 'Failed! ' + error.message,
      });
    });
  }

  onCancelForm(event) {
    event.preventDefault();
    this.props.navigation('/customer/list');
  }

  handleDistrictChange = (value) => {
    try {
      getRecords(`/domain/data/thanas/v1/${value}/district`)
        .then((response) => {
          this.props.form.resetFields(['thana']);
          this.setState({
            thanaList: response.map((item) => ({ value: item.id, label: item.thanaNameEn + ' - ' + item.thanaNameBn })),
          });
        })
        .catch((error) => {
          notificationController.error({
            message: error.message,
          }); 
          this.setState({ thanaList: [] });
          this.props.setLoading(false)
        });
    } catch (error) {
      notificationController.error({
        message: error.message,
      }); 
    }
  };

  handleKeyDown = (event, fieldName) => {
    if (event.key === 'Enter' && this.props.isOptionSelected) {
      event.preventDefault();
      const fields = this.props.form.getFieldsValue();
      const fieldNames = Object.keys(fields);
      const currentIndex = fieldNames.indexOf(fieldName);

      if (currentIndex >= 0 && currentIndex < fieldNames.length - 1) {
        this.props.form.getFieldInstance(fieldNames[currentIndex + 1]).focus();
        this.props.setIsOptionSelected(false);  // Reset the flag for future use
      }
    }
  };

  handleSelect = () => {
    this.props.setIsOptionSelected(true);
  };

  render() {
    return (
      <BaseCol xs={24} sm={24} xl={24}>
        <BaseCard id="customer" title="Add Customer" padding="1.25rem">
          <Form
            form={this.props.form}
            onFinish={this.handleSubmit}
            className="customer-form"
            name="basic"
            labelCol={{ span: 7 }}
            //wrapperCol={{ span: 16 }}
            style={{ maxWidth: 500 }}
            initialvalues={{ remember: true }}
            autoComplete="off"
          >
            <FormItem 
              label="Cutomer Name"
              name='customerName'
              rules={[
                {
                  required: true, message: 'Cutomer name is required!'
                }
              ]}
              className="ant-latest-form-item"
            >
              <Input
                size="small"
                placeholder="Enter customer name"
                onKeyDown={(e) => handleEnter(e)}
              />
            </FormItem>
            
            <FormItem 
              label="Profession"
              name='professionName'
              rules={[
                {
                  required: false, message: 'Profession is required!'
                }
              ]}
              className="ant-latest-form-item"
            >
              <Input
                size="small"
                placeholder="Enter profession"
                onKeyDown={(e) => handleEnter(e)}
              />
            </FormItem> 
            
            <FormItem 
              label="Mobile Number"
              name='mobileNumber'
              rules={[
                {
                  required: true, message: 'Mobile number is required!'
                },
                {
                  pattern: /^(?:\d*)$/,
                  message: 'Only number allowed',
                },
              ]}
              className="ant-latest-form-item"
            >
              <Input
                addonAfter={<PhoneOutlined />}
                maxLength={15}    
                size="small"
                placeholder="Enter mobile number"
                onKeyDown={(e) => handleEnter(e)}
              />
            </FormItem>

            <FormItem 
              label="WhatsApp Number"
              name='whatsAppNumber'
              rules={[
                {
                  required: false, message: 'WhatsApp number is required!'
                },
                {
                  pattern: /^(?:\d*)$/,
                  message: 'Only number allowed',
                },
              ]}
              className="ant-latest-form-item"
            >
              <Input
                addonAfter={<WhatsAppOutlined />}
                maxLength={15}    
                size="small"
                placeholder="Enter whatsApp number"
                onKeyDown={(e) => handleEnter(e)}
              />
            </FormItem>

            <FormItem 
              label="Email"
              name='email'
              rules={[
                {
                  required: false, message: 'Email address is required!'
                },
                {
                  type: 'email',
                  message: 'Input is not a valid email!',
                }
              ]}
              className="ant-latest-form-item"
            >
              <Input
                addonAfter={<MailOutlined />}    
                size="small"
                placeholder="Enter valid email address"
                onKeyDown={(e) => handleEnter(e)}
              />
            </FormItem>

            <FormItem 
              label="District"
              name='district'
              rules={[
                {
                  required: false, message: 'District is required!'
                }
              ]}
              className="ant-latest-form-item"
            >
              <Select
                showSearch
                placeholder="Select district"
                size="small"
                onChange={this.handleDistrictChange}
                optionFilterProp="children"
                onSearch={onSearch}
                onKeyDown={(e) => this.handleKeyDown(e, 'district')}
                onSelect={this.handleSelect}
              >
                <Select.Option value={''}>--- Select ---</Select.Option> 
                {this.state.districtList.map((item, index) => (
                  <Select.Option value={item.value} key={index}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>

            <FormItem 
              label="Thana"
              name='thana'
              rules={[
                {
                  required: false, message: 'Thana is required!'
                }
              ]}
              className="ant-latest-form-item"
            >
              <Select
                showSearch
                placeholder="Select thana"
                size="small"
                optionFilterProp="children"
                onSearch={onSearch}
                onKeyDown={(e) => handleEnter(e)}
              >
                {this.state.thanaList.map((item, index) => (
                  <Select.Option value={item.value} key={index}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>

            <FormItem 
              label="Address Line 1"
              name='addressLineOne'
              rules={[
                {
                  required: true, message: 'Address Line 1 is required!'
                }
              ]}
              className="ant-latest-form-item"
            >
              <Input   
                size="small"
                placeholder="Enter address Line One"
                onKeyDown={(e) => handleEnter(e)}
              />
            </FormItem>

            <FormItem 
              label="Address Line 2"
              name='addressLineTwo'
              rules={[
                {
                  required: false, message: 'Address Line 2 is required!'
                }
              ]}
              className="ant-latest-form-item"
            >
              <Input   
                size="small"
                placeholder="Enter address Line two"
                onKeyDown={(e) => handleEnter(e)}
              />
            </FormItem>

            <FormItem 
              label="Tags"
              name='tags'
              rules={[
                {
                  required: true, message: 'Tags is required!'
                }
              ]}
              className="ant-latest-form-item"
            >
              <Input   
                size="small"
                placeholder="Enter tags like this A,B"
                onKeyDown={(e) => handleEnter(e)}
              />
            </FormItem>

            <FormItem 
              label="Remarks"
              name='remarks'
              rules={[
                {
                  required: false, message: 'Remarks is required!'
                }
              ]}
              className="ant-latest-form-item"
            >
              <Input   
                size="small"
                placeholder="Enter remarks"
                onKeyDown={(e) => handleEnter(e)}
              />
            </FormItem>

            <FormItem 
              label="Status change notes"
              name='statusChangeNotes'
              rules={[
                {
                  required: false, message: 'Status change notes is required!'
                }
              ]}
              className="ant-latest-form-item"
            >
              <Input   
                size="small"
                placeholder="Enter status change notes"
                onKeyDown={(e) => handleEnter(e)}
              />
            </FormItem>

            <FormItem 
              label="Sales Partner"
              name='salesPartnerId'
              rules={[
                {
                  required: false, message: 'Please select sales partner!'
                }
              ]}
              className="ant-latest-form-item"
            >
              <Select
                showSearch
                placeholder="Select sales partner"
                size="small"
                optionFilterProp="children"
                onSearch={onSearch}
                onKeyDown={(e) => handleEnter(e)}
              >
                <Select.Option value="">--- Select ---</Select.Option> 
                {this.state.salesPartnerList.map((item, index) => (
                  <Select.Option value={item.value} key={index}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>

            <FormItem 
              label="Status"
              name='recordStat'
              rules={[
                {
                  required: true, message: 'Please select status!'
                }
              ]}
              className="ant-latest-form-item"
            >
              <Select
                showSearch
                placeholder="Select status"
                size="small"
                optionFilterProp="children"
                onSearch={onSearch}
                onKeyDown={(e) => handleEnter(e)}
              >
                {this.state.referenceStatusItems.map((item, index) => (
                  <Select.Option value={item.value} key={index}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>

            <FormItem 
              label="Reference"
              name='reference'
              rules={[
                {
                  required: false, message: 'Reference is required!'
                }
              ]}
              className="ant-latest-form-item"
            >
              <Input
                size="small"
                placeholder="Enter reference"
                onKeyDown={(e) => handleEnter(e)}
              />
            </FormItem>

            <FormItem
              wrapperCol={{
                offset: 7,
                span: 9,
              }}
            >
              <BaseButtonsGroup onCancel={this.onCancelForm} />
            </FormItem>
          </Form>
        </BaseCard>
      </BaseCol>
    );
  }

  // Validation Functions
  validateCustomerName = (customerName) => {
    if (customerName.length < CUSTOMERNAME_MIN_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `Customer name is too short (Minimum ${CUSTOMERNAME_MIN_LENGTH} characters needed.)`,
      };
    } else if (customerName.length > CUSTOMERNAME_MAX_LENGTH) {
      return {
        validationStatus: 'error',
        errorMsg: `Customer name is too long (Maximum ${CUSTOMERNAME_MAX_LENGTH} characters allowed.)`,
      };
    } else {
      return {
        validateStatus: null,
        errorMsg: null,
      };
    }
  };

  validateEmail = (email) => {
    if (!email) {
      return {
        validateStatus: 'error',
        errorMsg: 'Email may not be empty',
      };
    }

    const EMAIL_REGEX = RegExp('[^@ ]+@[^@ ]+\\.[^@ ]+');
    if (!EMAIL_REGEX.test(email)) {
      return {
        validateStatus: 'error',
        errorMsg: 'Email not valid',
      };
    }

    if (email.length > EMAIL_MAX_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `Email is too long (Maximum ${EMAIL_MAX_LENGTH} characters allowed)`,
      };
    }

    return {
      validateStatus: null,
      errorMsg: null,
    };
  };

  validateWhatsAppNumber = (whatsAppNumber) => {
    if (!whatsAppNumber) {
      return {
        validateStatus: 'error',
        errorMsg: 'Phone number may not be empty',
      };
    }

    const NUMBER_REGEX = RegExp('^[0-9]');
    if (!NUMBER_REGEX.test(whatsAppNumber)) {
      return {
        validateStatus: 'error',
        errorMsg: 'WhatsApp number not valid',
      };
    }

    if (whatsAppNumber.length > MOBILE_MAX_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `whatsApp number is too long (Maximum ${MOBILE_MAX_LENGTH} characters allowed)`,
      };
    }

    return {
      validateStatus: null,
      errorMsg: null,
    };
  };

  validateMobile = (mobileNumber) => {
    if (!mobileNumber) {
      return {
        validateStatus: 'error',
        errorMsg: 'Mobile number may not be empty',
      };
    }

    const NUMBER_REGEX = RegExp('^[0-9]');
    if (!NUMBER_REGEX.test(mobileNumber)) {
      return {
        validateStatus: 'error',
        errorMsg: 'Mobile number not valid',
      };
    }

    if (mobileNumber.length > MOBILE_MAX_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `Mobile number is too long (Maximum ${MOBILE_MAX_LENGTH} characters allowed)`,
      };
    }

    return {
      validateStatus: null,
      errorMsg: null,
    };
  };

  validateCustomerNameAvailability() {
    // First check for client side errors in username
    const customernameValue = this.state.customerName.value;
    const customernameValidation = this.validateCustomerName(customernameValue);

    if (customernameValidation.validateStatus === 'error') {
      this.setState({
        customerName: {
          value: customernameValue,
          ...customernameValidation,
        },
      });
      return;
    }

    this.setState({
      customerName: {
        value: customernameValue,
        validateStatus: 'validating',
        errorMsg: null,
      },
    });

    checkRecordAvailability('/public/v1/customers/checkCustomername?customerName=', customernameValue)
      .then((response) => {
        if (response.available) {
          this.setState({
            customerName: {
              value: customernameValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
        } else {
          this.setState({
            customerName: {
              value: customernameValue,
              validateStatus: 'error',
              errorMsg: 'Customer name already exists',
            },
          });
        }
      })
      .catch((error) => {
        if (error)
          // Marking validateStatus as success, Form will be recchecked at server
          this.setState({
            agentName: {
              value: customernameValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
      });
  }

  validateEmailAvailability() {
    // First check for client side errors in email
    const emailValue = this.state.email.value;
    const emailValidation = this.validateEmail(emailValue);

    if (emailValidation.validateStatus === 'error') {
      this.setState({
        email: {
          value: emailValue,
          ...emailValidation,
        },
      });
      return;
    }

    this.setState({
      email: {
        value: emailValue,
        validateStatus: 'validating',
        errorMsg: null,
      },
    });

    checkRecordAvailability('/public/v1/customers/checkCustomerEmail?email=', emailValue)
      .then((response) => {
        if (response.available) {
          this.setState({
            email: {
              value: emailValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
        } else {
          this.setState({
            email: {
              value: emailValue,
              validateStatus: 'error',
              errorMsg: 'Email is already exists',
            },
          });
        }
      })
      .catch((error) => {
        if (error)
          // Marking validateStatus as success, Form will be recchecked at server
          this.setState({
            email: {
              value: emailValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
      });
  }

  validateCustomerWhatsAppNumberAvailability() {
    // First check for client side errors in username
    const whatsAppNumberValue = this.state.whatsAppNumber.value;
    const whatsAppNumberValidation = this.validateWhatsAppNumber(whatsAppNumberValue);

    if (whatsAppNumberValidation.validateStatus === 'error') {
      this.setState({
        whatsAppNumber: {
          value: whatsAppNumberValue,
          ...whatsAppNumberValidation,
        },
      });
      return;
    }

    this.setState({
      whatsAppNumber: {
        value: whatsAppNumberValue,
        validateStatus: 'validating',
        errorMsg: null,
      },
    });

    checkRecordAvailability('/public/v1/customers/checkCustomerWhatsAppNumber?whatsAppNumber=', whatsAppNumberValue)
      .then((response) => {
        if (response.available) {
          this.setState({
            whatsAppNumber: {
              value: whatsAppNumberValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
        } else {
          this.setState({
            whatsAppNumber: {
              value: whatsAppNumberValue,
              validateStatus: 'error',
              errorMsg: 'WhatsApp number is already exists',
            },
          });
        }
      })
      .catch((error) => {
        if (error)
          // Marking validateStatus as success, Form will be recchecked at server
          this.setState({
            whatsAppNumber: {
              value: whatsAppNumberValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
      });
  }

  validateCustomerMobileNumberAvailability() {
    // First check for client side errors in username
    const customerMobileValue = this.state.mobileNumber.value;
    const customerMobileValidation = this.validateMobile(customerMobileValue);

    if (customerMobileValidation.validateStatus === 'error') {
      this.setState({
        mobileNumber: {
          value: customerMobileValue,
          ...customerMobileValidation,
        },
      });
      return;
    }

    this.setState({
      mobileNumber: {
        value: customerMobileValue,
        validateStatus: 'validating',
        errorMsg: null,
      },
    });

    checkRecordAvailability('/public/v1/customers/checkCustomerMobileNumber?mobileNumber=', customerMobileValue)
      .then((response) => {
        if (response.available === true) {
          this.setState({
            mobileNumber: {
              value: customerMobileValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
        } else {
          this.setState({
            mobileNumber: {
              value: customerMobileValue,
              validateStatus: 'error',
              errorMsg: 'Customer mobile number is already exists',
            },
          });
        }
      })
      .catch((error) => {
        if (error)
          // Marking validateStatus as success, Form will be recchecked at server
          this.setState({
            mobileNumber: {
              value: customerMobileValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
      });
  }
}

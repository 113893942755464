import React, { Component, useState } from 'react';
import { getRecords, saveRecord, checkRecordAvailability } from '@app/utils/APIUtils';
import { BUSLABEL_MAX_LENGTH, BUSNO_MAX_LENGTH } from '@app/constants';
import { Input, Select } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { handleEnter } from '@app/helper/EnterIndexHelper';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';

const FormItem = Form.Item;
const { TextArea } = Input;

const onSearch = (value) => {
  console.log('search:', value);
};

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  return <AddBus {...props} navigation={navigate} isLoading={isLoading} setLoading={setLoading} />;
}

class AddBus extends Component {
  render() {
    const AntWrappedOfficeForm = Form.create()(AddBusForm);
    return (
      <div>
        <AntWrappedOfficeForm
          navigate={this.props.navigation}
          isLoading={this.props.isLoading}
          setLoading={this.props.setLoading}
        />
      </div>
    );
  }
}

class AddBusForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      busId: {
        value: '',
      },
      busNo: {
        value: '',
      },
      busRoute: {
        value: '',
      },
      busLabel: {
        value: '',
      },
      seats: {
        value: '',
      },
      contactInfo: {
        value: '',
      },
      agentId: {
        value: '',
      },
      agentList: [],
      movementTypeList: [],
      statusItems: [
        { value: 0, label: 'Inactive' },
        { value: 1, label: 'Active' },
      ],
      isCascade: true,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isFormInvalid = this.isFormInvalid.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);
    this.validateBusNoAvailability = this.validateBusNoAvailability.bind(this);
    //this.validateBusLabelAvailability = this.validateBusLabelAvailability.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  handleChangeIsCascade = (e) => {
    const { checked } = e.target;
    this.setState({
      isCascade: checked,
    });
  };

  componentDidMount() {
    this.props.setLoading(true);

    let promise, promise1;

    promise = getRecords('/admin/v1/agents/all');
    promise1 = getRecords('/domain/data/movement/v1/list');

    promise
      .then((response) => {
        this.setState({
          agentList: response.map((item) => ({ value: item.id, label: item.AgentName })),
        });
      })
      .catch((error) => {
        if (error) this.props.setLoading(false);
      });

    promise1
      .then((response) => {
        this.setState({
          movementTypeList: response.map((item) => ({ value: item.id, label: item.CategoryName })),
        });
      })
      .catch((error) => {
        if (error) this.props.setLoading(false);
      });

    Promise.all([promise, promise1]);
  }

  //? Handle on submit form
  handleSubmit(event) {
    event.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (this.isFormInvalid()) {
          notificationController.error({
            message: 'Bus number already exist',
          });
        } else {
          const saveRequest = Object.assign({}, values);
          console.log('saveRequest => ' + JSON.stringify(saveRequest));

          saveRecord(saveRequest, '/setup/v1/buses')
            .then((response) => {
              if (response) {
                notificationController.success({
                  message: 'Bus created.',
                });
                this.props.navigate('/bus/list');
              }
            })
            .catch((error) => {
              notificationController.error({
                message: 'Failed! ' + error.message,
              });
            });
        }
      }
    });
  }

  onCancelForm(event) {
    event.preventDefault();
    this.props.navigate('/bus/list');
  }

  isFormInvalid() {
    return !(this.state.busNo.validateStatus === 'success');
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <BaseCol xs={24} sm={24} xl={24}>
        <BaseCard id="validation form" title="Add Bus" padding="1.25rem">
          <Form
            onSubmit={this.handleSubmit}
            className="bus-form"
            name="basic"
            labelCol={{ span: 6 }}
            style={{ maxWidth: 500 }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <FormItem label="Movement Type">
              {getFieldDecorator('movementId', {
                rules: [{ required: true, message: 'Select movement type!' }],
              })(
                <Select
                  showSearch
                  size="small"
                  placeholder="Please select"
                  name="movementId"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  onKeyDown={(e) => handleEnter(e)}
                >
                  {this.state.movementTypeList.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </FormItem>

            <FormItem
              label="Bus No"
              hasFeedback
              validateStatus={this.state.busNo.validateStatus}
              help={this.state.busNo.errorMsg}
            >
              {getFieldDecorator('busNo', {
                rules: [{ required: true, message: 'Please input bus no!' }],
              })(
                <Input
                  size="small"
                  name="busNo"
                  autoComplete="off"
                  placeholder="Enter bus no"
                  value={this.state.busNo.value}
                  onKeyUpCapture={this.validateBusNoAvailability}
                  onKeyDown={(e) => handleEnter(e)}
                  onChange={(event) => this.handleInputChange(event, this.validateBusNo)}
                />,
              )}
            </FormItem>

            <FormItem label="Bus Route">
              {getFieldDecorator('busRoute', {
                rules: [{ required: false, message: 'Please input bus route!' }],
              })(
                <Input
                  size="small"
                  name="busRoute"
                  autoComplete="off"
                  placeholder="Enter bus route"
                  value={this.state.busRoute.value}
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>

            <FormItem label="Bus Label">
              {getFieldDecorator('busLabel', {
                rules: [{ required: false, message: 'Please input bus label!' }],
              })(
                <Input
                  size="small"
                  name="busLabel"
                  autoComplete="off"
                  placeholder="Enter bus label"
                  value={this.state.busLabel.value}
                  //onBlur={this.validateBusLabelAvailability}
                  onKeyDown={(e) => handleEnter(e)}
                  //onChange={(event) => this.handleInputChange(event, this.validateBusLabel)}
                />,
              )}
            </FormItem>

            <FormItem label="Seats">
              {getFieldDecorator('seats', {
                rules: [
                  { required: false, message: 'Please input total seats!' },
                  {
                    pattern: /^(?:\d*)$/,
                    message: 'Only number allowed',
                  },
                ],
              })(
                <Input
                  size="small"
                  name="seats"
                  autoComplete="off"
                  onKeyDown={(e) => handleEnter(e)}
                  placeholder="Enter bus seats"
                  value={this.state.seats.value}
                />,
              )}
            </FormItem>

            <FormItem label="Contact Info">
              {getFieldDecorator('contactInfo', {
                rules: [{ required: false, message: 'Please input contact info!' }],
              })(
                <TextArea
                  size="small"
                  name="contactInfo"
                  autoComplete="off"
                  placeholder="Enter contact info"
                  onKeyDown={(e) => handleEnter(e)}
                  value={this.state.contactInfo.value}
                />,
              )}
            </FormItem>

            <FormItem label="Cascade">
              {getFieldDecorator('isCascade', {
                valuePropName: 'checked',
                initialValue: this.state.isCascade,
                rules: [{ required: false, message: 'Cascade is required!' }],
              })(
                <BaseCheckbox
                  onChange={(e) => this.handleChangeIsCascade(e)}
                  defaultChecked={this.state.isCascade}
                  value={this.state.isCascade}
                  onKeyDown={(e) => handleEnter(e)}
                ></BaseCheckbox>,
              )}
            </FormItem>

            {/* <FormItem label="Agent">
              {getFieldDecorator('agentId', {
                rules: [{ required: true, message: 'Please select agent!' }],
              })(
                <Select
                  showSearch
                  size="small"
                  placeholder="Please select"
                  name="agentId"
                  value={this.state.agentId.value}
                  optionFilterProp="children"
                  onSearch={onSearch}
                  onKeyDown={(e) => handleEnter(e)}
                >
                  {this.state.agentList.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </FormItem> */}

            <FormItem
              wrapperCol={{
                offset: 6,
                span: 9,
              }}
            >
              <BaseButtonsGroup onCancel={this.onCancelForm} />
            </FormItem>
          </Form>
        </BaseCard>
      </BaseCol>
    );
  }

  // Validation Functions
  validateBusNo = (busNo) => {
    if (!busNo) {
      return {
        validateStatus: 'error',
        errorMsg: 'Bus no may not be empty',
      };
    }

    if (busNo.length > BUSNO_MAX_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `Bus no is too long (Maximum ${BUSNO_MAX_LENGTH} characters allowed)`,
      };
    }

    return {
      validateStatus: null,
      errorMsg: null,
    };
  };

  validateBusLabel = (busLabel) => {
    if (!busLabel) {
      return {
        validateStatus: 'error',
        errorMsg: 'Bus label may not be empty',
      };
    }

    if (busLabel.length > BUSLABEL_MAX_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `Bus label is too long (Maximum ${BUSLABEL_MAX_LENGTH} characters allowed)`,
      };
    }

    return {
      validateStatus: null,
      errorMsg: null,
    };
  };

  validateBusNoAvailability() {
    // First check for client side errors in busno
    const busNoValue = this.state.busNo.value;
    const busNoValidation = this.validateBusNo(busNoValue);

    if (busNoValidation.validateStatus === 'error') {
      this.setState({
        busNo: {
          value: busNoValue,
          ...busNoValidation,
        },
      });
      return;
    }

    this.setState({
      busNo: {
        value: busNoValue,
        validateStatus: 'validating',
        errorMsg: null,
      },
    });

    checkRecordAvailability('/setup/v1/buses/check/busno?busNo=', busNoValue)
      .then((response) => {
        if (response.available) {
          this.setState({
            busNo: {
              value: busNoValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
        } else {
          this.setState({
            busNo: {
              value: busNoValue,
              validateStatus: 'error',
              errorMsg: 'Bus no already exists',
            },
          });
        }
      })
      .catch((error) => {
        if (error)
          // Marking validateStatus as success, Form will be recchecked at server
          this.setState({
            busNo: {
              value: busNoValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
      });
  }

  validateBusLabelAvailability() {
    // First check for client side errors in bus label
    const busLabelValue = this.state.busLabel.value;
    const busLabelValidation = this.validateBusLabel(busLabelValue);

    if (busLabelValidation.validateStatus === 'error') {
      this.setState({
        busLabel: {
          value: busLabelValue,
          ...busLabelValidation,
        },
      });
      return;
    }

    this.setState({
      busLabel: {
        value: busLabelValue,
        validateStatus: 'validating',
        errorMsg: null,
      },
    });

    checkRecordAvailability('/setup/v1/buses/check/buslabel?busLabel=', busLabelValue)
      .then((response) => {
        if (response.available) {
          this.setState({
            busLabel: {
              value: busLabelValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
        } else {
          this.setState({
            busLabel: {
              value: busLabelValue,
              validateStatus: 'error',
              errorMsg: 'Bus label already exists',
            },
          });
        }
      })
      .catch((error) => {
        if (error)
          // Marking validateStatus as success, Form will be recchecked at server
          this.setState({
            busLabel: {
              value: busLabelValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
      });
  }
}

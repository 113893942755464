import React, { Component, useState } from 'react';
import { saveRecord, getRecords, checkRecordAvailability, searchRecord } from '@app/utils/APIUtils';
import { AGENTNAME_MIN_LENGTH, AGENTNAME_MAX_LENGTH, EMAIL_MAX_LENGTH, MOBILE_MAX_LENGTH } from '@app/constants';
import { Descriptions, Input, Select } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { handleEnter } from '@app/helper/EnterIndexHelper';

const FormItem = Form.Item;

const onSearch = (value) => {
  console.log('search:', value);
};

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  return <AddPackagePreferences {...props} navigation={navigate} isLoading={isLoading} setLoading={setLoading} />;
}

class AddPackagePreferences extends Component {
  render() {
    const AntWrappedOfficeForm = Form.create()(AddPackagePreferencesForm);
    return (
      <div>
        <AntWrappedOfficeForm
          navigate={this.props.navigation}
          isLoading={this.props.isLoading}
          setLoading={this.props.setLoading}
        />
      </div>
    );
  }
}

class AddPackagePreferencesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: {
        value: '',
      },
      customerName: {
        value: '',
      },
      totalPersons: {
        value: '',
      },
      pkgDurationId: {
        value: '',
      },
      hotelMakkahId: {
        value: '',
      },
      hotelMedinaId: {
        value: '',
      },
      remarks: {
        value: '',
      },
      durationList: [],
      makkahHotelList: [],
      madinaHotelList: [],
      roomPreferenceList: [
        { value: 1, label: 'Private Room' },
        { value: 2, label: 'Shared Room' },
        { value: 3, label: 'Family Room' },
      ],
      isRequired: false,
      isReadonly: false,
      mobileNumber: {
        value: '',
      },
      customerInfo: {},
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isFormInvalid = this.isFormInvalid.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);
    this.validateAgentnameAvailability = this.validateAgentnameAvailability.bind(this);
    this.validateEmailAvailability = this.validateEmailAvailability.bind(this);
    this.validateTotalPersons = this.validateTotalPersons.bind(this);
    this.validateAgentMobileNumberAvailability = this.validateAgentMobileNumberAvailability.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  componentDidMount() {
    let promise, promise1, promise2;

    promise = getRecords('/domain/data/durations/v1/all');
    promise1 = getRecords('/setup/v1/hotels/makkah/list');
    promise2 = getRecords('/setup/v1/hotels/medina/list');

    this.setState({
      isLoading: true,
    });

    promise
      .then((response) => {
        this.setState({
          durationList: response.map((item) => ({ value: item.id, label: item.NoOfDays })),
        });
        console.log(this.state.durationList);
      })
      .catch((error) => {
        if (error)
          this.setState({
            isLoading: false,
          });
      });

    promise1
      .then((response) => {
        this.setState({
          makkahHotelList: response.map((item) => ({ value: item.hotelId, label: item.hotelType })),
        });
        console.log(this.state.makkahHotelList);
      })
      .catch((error) => {
        if (error)
          this.setState({
            isLoading: false,
          });
      });

    promise2
      .then((response) => {
        this.setState({
          madinaHotelList: response.map((item) => ({ value: item.hotelId, label: item.hotelType })),
        });
        console.log(this.state.madinaHotelList);
      })
      .catch((error) => {
        if (error)
          this.setState({
            isLoading: false,
          });
      });

    Promise.all([promise, promise1, promise2]);
  }

  handleKeyDown(event) {
    event.preventDefault();

    this.props.form.validateFields((err, values) => {
      const searchRequest = {
        mobileNumber: values.mobileNumber,
      };

      console.log('searchRequest => ' + JSON.stringify(searchRequest));

      searchRecord(searchRequest, '/public/v1/customers/cif')
        .then((response) => {
          if (response.statusCode === 204) {
            notificationController.info({
              message: 'Customer not found!',
            });
          } else {
            this.setState({
              customerInfo: response,
            });
            this.props.form.setFieldsValue({
              customerId: response.id,
            });
          }

          console.log(this.state.customerInfo);
        })
        .catch((error) => {
          notificationController.error({
            message: 'Failed! ' + error.message,
          });
        });
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const saveRequest = Object.assign({}, values);
        //saveRequest.referenceStatusId  = 111;
        console.log('saveRequest => ' + JSON.stringify(saveRequest));

        saveRecord(saveRequest, '/customer/package/v1/preferences')
          .then((response) => {
            if (response)
              notificationController.success({
                message: 'Package preferences created.',
              });
            this.props.navigate('/package_preferences/list');
          })
          .catch((error) => {
            notificationController.error({
              message: 'Failed! ' + error.message,
            });
          });
      }
    });
  }

  onCancelForm(event) {
    event.preventDefault();
    this.props.navigate('/package_preferences/list');
  }

  isFormInvalid() {
    return !(this.state.agentName.validateStatus === 'success');
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <BaseCard id="validation form" title="Add Package Preferences" padding="1.25rem">
        <BaseRow gutter={[30, 30]}>
          <BaseCol xs={24} sm={20} xl={12}>
            <Form
              onSubmit={this.handleSubmit}
              className="preferences-form"
              name="basic"
              labelCol={{ span: 7 }}
              style={{ maxWidth: 500 }}
              initialValues={{ remember: true }}
              autoComplete="off"
            >
              <BaseCard id="mobileNumber" padding="1.25rem" style={{ marginBottom: 20 }}>
                <BaseRow gutter={[30, 30]}>
                  <BaseCol xs={24} sm={24} xl={24}>
                    <FormItem label="Search by mobile number" labelCol={{ span: 0 }}>
                      {getFieldDecorator('mobileNumber', {
                        rules: [{ required: true, message: 'Mobile number is required!' }],
                      })(
                        <Input
                          name="mobileNumber"
                          placeholder="Enter mobile number"
                          value={this.state.mobileNumber.value}
                          onBlur={this.handleKeyDown}
                          onKeyDown={(e) => handleEnter(e)}
                        />,
                      )}
                    </FormItem>
                  </BaseCol>
                </BaseRow>
              </BaseCard>

              <FormItem label="Cutomer Id">
                {getFieldDecorator('customerId', {
                  rules: [{ required: true, message: 'Customer id is required!' }],
                })(
                  <Input
                    name="customerId"
                    size="small"
                    readOnly={true}
                    onKeyDown={(e) => handleEnter(e)}
                    style={{ backgroundColor: '#c5d3e0' }}
                  />,
                )}
              </FormItem>

              <FormItem
                label="Total Person"
                validateStatus={this.state.totalPersons.validateStatus}
                help={this.state.totalPersons.errorMsg}
              >
                {getFieldDecorator('totalPersons', {
                  rules: [{ required: true, message: 'Total person is required!' }],
                })(
                  <Input
                    name="totalPersons"
                    size="small"
                    placeholder="Enter total persons"
                    onChange={(event) => this.handleInputChange(event, this.validateTotalPerson)}
                    onKeyDown={(e) => handleEnter(e)}
                  />,
                )}
              </FormItem>

              <FormItem label="Package Duration">
                {getFieldDecorator('pkgDurationId', {
                  rules: [{ required: true, message: 'Package duration is required!' }],
                })(
                  <Select
                    showSearch
                    placeholder="Select package"
                    size="small"
                    name="pkgDurationId"
                    optionFilterProp="children"
                    onSearch={onSearch}
                    onKeyDown={(e) => handleEnter(e)}
                  >
                    {this.state.durationList.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </FormItem>

              <FormItem label="Hotel In Makkah">
                {getFieldDecorator('hotelMakkahId', {
                  rules: [{ required: true, message: 'Hotel is required!' }],
                })(
                  <Select
                    showSearch
                    placeholder="Select"
                    size="small"
                    name="hotelMakkahId"
                    optionFilterProp="children"
                    onSearch={onSearch}
                    onKeyDown={(e) => handleEnter(e)}
                  >
                    {this.state.makkahHotelList.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </FormItem>

              <FormItem label="Hotel In Madinah">
                {getFieldDecorator('hotelMedinaId', {
                  rules: [{ required: true, message: 'Hotel is required!' }],
                })(
                  <Select
                    showSearch
                    placeholder="Select"
                    size="small"
                    name="hotelMedinaId"
                    optionFilterProp="children"
                    onSearch={onSearch}
                    onKeyDown={(e) => handleEnter(e)}
                  >
                    {this.state.madinaHotelList.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </FormItem>

              <FormItem label="Room Preference">
                {getFieldDecorator('roomPreference', {
                  rules: [{ required: true, message: 'Please select hotelRoom preference!' }],
                })(
                  <Select
                    showSearch
                    placeholder="Select room preference"
                    size="small"
                    name="roomPreference"
                    optionFilterProp="children"
                    onSearch={onSearch}
                    onKeyDown={(e) => handleEnter(e)}
                  >
                    {this.state.roomPreferenceList.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </FormItem>

              <FormItem label="Remarks">
                {getFieldDecorator('remarks', {
                  rules: [{ required: false, message: 'Remarks is required!' }],
                })(
                  <Input
                    name="remarks"
                    size="small"
                    placeholder="Enter remarks"
                    value={this.state.remarks.value}
                    onKeyDown={(e) => handleEnter(e)}
                  />,
                )}
              </FormItem>

              <FormItem
                wrapperCol={{
                  offset: 7,
                  span: 9,
                }}
              >
                <BaseButtonsGroup onCancel={this.onCancelForm} />
              </FormItem>
            </Form>
          </BaseCol>

          <BaseCol xs={24} sm={12} xl={12}>
            <Descriptions title="Customer Info" bordered size="middle">
              <Descriptions.Item label="Customer Name" span={3}>
                {this.state.customerInfo.CustName}
              </Descriptions.Item>
              <Descriptions.Item label="Profession" span={3}>
                {this.state.customerInfo.ProfName}
              </Descriptions.Item>
              <Descriptions.Item label="Customer Email" span={3}>
                {this.state.customerInfo.Email}
              </Descriptions.Item>
              <Descriptions.Item label="Mobile" span={3}>
                {this.state.customerInfo.MobileNo}
              </Descriptions.Item>
              <Descriptions.Item label="WhatsApp" span={3}>
                {this.state.customerInfo.WhatsApp}
              </Descriptions.Item>
              <Descriptions.Item label="Address" span={3}>
                {this.state.customerInfo.AddressLine1}
              </Descriptions.Item>
              <Descriptions.Item label="Reference" span={3}>
                {this.state.customerInfo.Reference}
              </Descriptions.Item>
            </Descriptions>
          </BaseCol>
        </BaseRow>
      </BaseCard>
    );
  }

  // Validation Functions
  validateAgentname = (agentName) => {
    if (agentName.length < AGENTNAME_MIN_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `Agent name is too short (Minimum ${AGENTNAME_MIN_LENGTH} characters needed.)`,
      };
    } else if (agentName.length > AGENTNAME_MAX_LENGTH) {
      return {
        validationStatus: 'error',
        errorMsg: `Agent name is too long (Maximum ${AGENTNAME_MAX_LENGTH} characters allowed.)`,
      };
    } else {
      return {
        validateStatus: null,
        errorMsg: null,
      };
    }
  };

  validateEmail = (email) => {
    if (!email) {
      return {
        validateStatus: 'error',
        errorMsg: 'Email may not be empty',
      };
    }

    const EMAIL_REGEX = RegExp('[^@ ]+@[^@ ]+\\.[^@ ]+');
    if (!EMAIL_REGEX.test(email)) {
      return {
        validateStatus: 'error',
        errorMsg: 'Email not valid',
      };
    }

    if (email.length > EMAIL_MAX_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `Email is too long (Maximum ${EMAIL_MAX_LENGTH} characters allowed)`,
      };
    }

    return {
      validateStatus: null,
      errorMsg: null,
    };
  };

  validateTotalPerson = (totalPersons) => {
    if (!totalPersons) {
      return {
        validateStatus: 'error',
        errorMsg: 'Total person may not be empty',
      };
    }

    const NUMBER_REGEX = RegExp('^[1-9]');
    if (!NUMBER_REGEX.test(totalPersons)) {
      return {
        validateStatus: 'error',
        errorMsg: 'Only number is allowed',
      };
    }

    return {
      validateStatus: null,
      errorMsg: null,
    };
  };

  validateMobile = (mobileNumber) => {
    if (!mobileNumber) {
      return {
        validateStatus: 'error',
        errorMsg: 'Mobile number may not be empty',
      };
    }

    const NUMBER_REGEX = RegExp('^[0-9]');
    if (!NUMBER_REGEX.test(mobileNumber)) {
      return {
        validateStatus: 'error',
        errorMsg: 'Mobile number not valid',
      };
    }

    if (mobileNumber.length > MOBILE_MAX_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `Mobile number is too long (Maximum ${MOBILE_MAX_LENGTH} characters allowed)`,
      };
    }

    return {
      validateStatus: null,
      errorMsg: null,
    };
  };

  validateAgentnameAvailability() {
    // First check for client side errors in username
    const agentnameValue = this.state.agentName.value;
    const agentnameValidation = this.validateAgentname(agentnameValue);

    if (agentnameValidation.validateStatus === 'error') {
      this.setState({
        agentName: {
          value: agentnameValue,
          ...agentnameValidation,
        },
      });
      return;
    }

    this.setState({
      agentName: {
        value: agentnameValue,
        validateStatus: 'validating',
        errorMsg: null,
      },
    });

    checkRecordAvailability('/setup/agent/checkAgentname?agentName=', agentnameValue)
      .then((response) => {
        if (response.available) {
          this.setState({
            agentName: {
              value: agentnameValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
        } else {
          this.setState({
            agentName: {
              value: agentnameValue,
              validateStatus: 'error',
              errorMsg: 'This Agent name is already exists',
            },
          });
        }
      })
      .catch((error) => {
        if (error)
          // Marking validateStatus as success, Form will be recchecked at server
          this.setState({
            agentName: {
              value: agentnameValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
      });
  }

  validateEmailAvailability() {
    // First check for client side errors in email
    const emailValue = this.state.email.value;
    const emailValidation = this.validateEmail(emailValue);

    if (emailValidation.validateStatus === 'error') {
      this.setState({
        email: {
          value: emailValue,
          ...emailValidation,
        },
      });
      return;
    }

    this.setState({
      email: {
        value: emailValue,
        validateStatus: 'validating',
        errorMsg: null,
      },
    });

    checkRecordAvailability('/setup/agent/checkAgentEmail?email=', emailValue)
      .then((response) => {
        if (response.available) {
          this.setState({
            email: {
              value: emailValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
        } else {
          this.setState({
            email: {
              value: emailValue,
              validateStatus: 'error',
              errorMsg: 'This Email is already exists',
            },
          });
        }
      })
      .catch((error) => {
        if (error)
          // Marking validateStatus as success, Form will be recchecked at server
          this.setState({
            email: {
              value: emailValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
      });
  }

  validateTotalPersons() {
    // First check for client side errors in username
    const totalPersonsValue = this.state.totalPersons.value;
    const totalPersonsValidation = this.validateTotalPerson(totalPersonsValue);

    if (totalPersonsValidation.validateStatus === 'error') {
      this.setState({
        totalPersons: {
          value: totalPersonsValue,
          ...totalPersonsValidation,
        },
      });
      return;
    }

    this.setState({
      totalPersons: {
        value: totalPersonsValue,
        validateStatus: 'validating',
        errorMsg: null,
      },
    });
  }

  validateAgentMobileNumberAvailability() {
    // First check for client side errors in username
    const customermobileValue = this.state.mobileNumber.value;
    const customermobileValidation = this.validateMobile(customermobileValue);

    if (customermobileValidation.validateStatus === 'error') {
      this.setState({
        mobileNumber: {
          value: customermobileValue,
          ...customermobileValidation,
        },
      });
      return;
    }

    this.setState({
      mobileNumber: {
        value: customermobileValue,
        validateStatus: 'validating',
        errorMsg: null,
      },
    });

    checkRecordAvailability('/setup/customer/checkCustomerMobileNumber?mobileNumber=', customermobileValue)
      .then((response) => {
        if (response.available) {
          this.setState({
            mobileNumber: {
              value: customermobileValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
        } else {
          this.setState({
            mobileNumber: {
              value: customermobileValue,
              validateStatus: 'error',
              errorMsg: 'Customer mobile number is already exists',
            },
          });
        }
      })
      .catch((error) => {
        if (error)
          // Marking validateStatus as success, Form will be recchecked at server
          this.setState({
            mobileNumber: {
              value: customermobileValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
      });
  }
}

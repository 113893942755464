import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { notificationController } from '@app/controllers/notificationController';
//import { useAppDispatch } from '@app/hooks/reduxHooks';
//import { doSetNewPassword } from '@app/store/slices/authSlice';
import * as S from './NewPasswordForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { passwordPattern } from '@app/constants/patterns';
import { deleteItemByParam, updateRecord } from '@app/utils/APIUtils';
import { handleEnter } from '@app/helper/EnterIndexHelper';
import { ACCESS_TOKEN } from '@app/constants';

interface NewPasswordFormData {
  password: string;
  confirmPassword: string;
}

const initStates = {
  password: '',
  confirmPassword: '',
};

export const NewPasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  //const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);
  const params = useParams();

  const handleSubmit = (values: NewPasswordFormData) => {
    setLoading(true);
    console.log(values);
    updateRecord(params.username, values, '/sa/users/password-new/')
      .then((response) => {
        if (response.statusCode === 200) {
          //Delete token by username on password change
          deleteItemByParam('/auth/logout?username=' + params.username).then((res) => {
            if (res.statusCode === 200) {
              localStorage.removeItem(ACCESS_TOKEN);
            }
          });
          notificationController.success({
            message: response.message,
          });
          navigate('/auth/login');
        }
      })
      .catch((error) => {
        notificationController.error({
          message: error.message,
        });
        setLoading(false);
      });
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initStates}>
        <Auth.BackWrapper onClick={() => navigate(-1)}>
          <Auth.BackIcon />
          {t('common.back')}
        </Auth.BackWrapper>
        <Auth.FormTitle>{t('newPassword.title')}</Auth.FormTitle>
        <S.Description>{t('newPassword.description')}</S.Description>
        <Auth.FormItem
          name="password"
          label={t('common.password')}
          rules={[
            { required: true, message: t('common.requiredField') },
            {
              pattern: passwordPattern,
              message: t('profile.nav.securitySettings.notValidPassword'),
            },
          ]}
        >
          <Auth.FormInputPassword placeholder={t('common.password')} onKeyDown={(e) => handleEnter(e)} />
        </Auth.FormItem>
        <Auth.FormItem
          name="confirmPassword"
          label={t('common.confirmPassword')}
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: t('common.requiredField'),
            },
            {
              pattern: passwordPattern,
              message: t('profile.nav.securitySettings.notValidPassword'),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('common.confirmPasswordError')));
              },
            }),
          ]}
          hasFeedback
        >
          <Auth.FormInputPassword placeholder={t('common.confirmPassword')} onKeyDown={(e) => handleEnter(e)} />
        </Auth.FormItem>
        <BaseForm.Item noStyle>
          <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            Change Password
          </S.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};

import React, { Component, useState } from 'react';
import { deleteItemByID, getRecordSet, getRecordById } from '@app/utils/APIUtils';
import {
  Modal,
  Row,
  Descriptions,
  // Badge
} from 'antd';
import '@ant-design/compatible/assets/index.css';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import * as S from '@app/components/tables/Tables/Tables.styles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput';
import * as ST from '@app/pages/uiComponentsPages/UIComponentsPage.styles';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import { PlusCircleOutlined } from '@ant-design/icons';
import { LIST_SIZE } from '@app/constants';

export default function (props) {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  return (
    <PackagePreferencesList
      {...props}
      loading={loading}
      setLoading={setLoading}
      page={page}
      setPage={setPage}
      navigate={navigate}
    />
  );
}

class PackagePreferencesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      recordSet: [],
      isLoading: false,
      response: {},
      searchedText: '',
      visible: false,
      viewRecord: [],
      page: 0,
      size: 10,
      totalElements: 0,
      totalPages: 0,
      last: true,
    };
    this.save = this.save.bind(this);
    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);
    this.loadRecords = this.loadRecords.bind(this);
  }

  loadRecords(page, size = LIST_SIZE) {
    this.setState({
      recordSet: [],
      page: 0,
      size: 10,
      totalElements: 0,
      totalPages: 0,
      last: true,
      isLoading: false,
    });

    this.props.setLoading(true);

    let promise = getRecordSet(page, size, '/customer/package/v1/preferences?page=');

    if (!promise) {
      return;
    }

    promise
      .then((response) => {
        const recordSet = this.state.recordSet.slice();

        this.setState({
          recordSet: recordSet.concat(response.content),
          page: response.page,
          size: response.size,
          totalElements: response.totalElements,
          totalPages: response.totalPages,
          last: response.last,
        });

        setTimeout(() => {
          this.props.setLoading(false);
        }, 1500);
      })
      .catch((error) => {
        if (error) this.props.setLoading(false);
      });
    console.log('Component mounted.');
  }

  componentDidMount() {
    this.loadRecords();
  }

  save() {
    this.props.navigate('/package_preferences/new');
  }

  edit(id) {
    this.props.navigate(`/package_preferences/edit/${id}`);
  }

  delete(id) {
    const { confirm } = Modal;
    return new Promise((resolve, reject) => {
      confirm({
        title: 'Are you sure you want to Delete ?',
        onOk: () => {
          resolve(true);

          //onRemoveFunctionality here
          deleteItemByID(id, '/customer/package/v1/preferences/').then((res) => {
            if (res) this.setState({ recordSet: this.state.recordSet.filter((record) => record.pkgPrefId !== id) });
            notificationController.success({
              message: 'Package preferences deleted.',
            });
          });
        },
        onCancel: () => {
          reject(true);
        },
      });
    });
  }

  view(id) {
    this.setState({
      visible: true,
    });

    let promise1 = getRecordById('/customer/package/v1/preferences/', id);
    promise1.then((res) => {
      this.setState({
        viewRecord: res,
      });

      // this.setState({
      //     district: res.DistrictBean.districtNameEn
      // })

      // this.setState({
      //     thana: res.ThanBean.thanaNameEn
      // })
    }, []);

    Promise.all([promise1]);
  }

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  render() {
    const { recordSet, totalElements /* viewRecord */ } = this.state;

    //const vendorViews = [];
    const data = [];
    recordSet.forEach((record, recordIndex) => {
      data.push({
        key: recordIndex,
        pkgPrefId: record.pkgPrefId,
        customerName: record.customerName,
        mobileNo: record.mobileNo,
        packageDurationName: record.pkgDurationName,
        hotelTypeMakkah: record.hotelTypeMakkah,
        hotelTypeMadinah: record.hotelTypeMadinah,
        totalPersons: record.totalPersons,
        remarks: record.remarks,
      });
    });

    const columns = [
      /* {
                    title: 'ID',
                    dataIndex: 'packagePreferencesId',
                    key: 'packagePreferencesId',
                }, */
      {
        title: 'Customer Name',
        dataIndex: 'customerName',
        key: 'customerName;',
        filteredValue: [this.state.searchedText],
        onFilter: (value, record) => {
          return (
            String(record.customerName).toLowerCase().includes(value.toLowerCase()) ||
            String(record.packageDurationName).toLowerCase().includes(value.toLowerCase()) ||
            String(record.hotelTypeMakkah).toLowerCase().includes(value.toLowerCase()) ||
            String(record.hotelTypeMadinah).toLowerCase().includes(value.toLowerCase()) ||
            String(record.totalPersons).toLowerCase().includes(value.toLowerCase())
          );
        },
      },
      {
        title: 'Mobile No',
        dataIndex: 'mobileNo',
        key: 'mobileNo;',
      },
      {
        title: 'Package Duration',
        dataIndex: 'packageDurationName',
        key: 'packageDurationName;',
      },
      {
        title: 'Hotel Type Makkah',
        dataIndex: 'hotelTypeMakkah',
        key: 'hotelTypeMakkah;',
      },
      {
        title: 'Hotel Type Madinah',
        dataIndex: 'hotelTypeMadinah',
        key: 'hotelTypeMadinah;',
      },
      {
        title: 'Total Person',
        dataIndex: 'totalPersons',
        key: 'totalPersons;',
      },
      // {
      //     title: 'Remarks',
      //     dataIndex: 'remarks',
      //     key: 'remarks',
      // },
      {
        title: 'Action',
        key: 'action',
        render: (_, record) => {
          return (
            <BaseSpace>
              <BaseButton size="small" type="primary" onClick={() => this.view(record.pkgPrefId)}>
                {' '}
                View
              </BaseButton>

              <BaseButton severity="info" size="small" onClick={() => this.edit(record.pkgPrefId)}>
                {' '}
                Edit
              </BaseButton>

              <BaseButton type="primary" danger size="small" onClick={() => this.delete(record.pkgPrefId)}>
                {' '}
                Delete
              </BaseButton>
            </BaseSpace>
          );
        },
      },
    ];

    return (
      <div className="page-Container">
        <S.Card
          id="basic-table"
          title="Package Preferences List"
          padding="1.25rem 1.25rem 0"
          extra={
            <BaseButton
              type="info"
              className="btn btn-dark"
              onClick={this.save}
              size="small"
              icon={<PlusCircleOutlined />}
              style={{}}
            >
              Add
            </BaseButton>
          }
        >
          <div className="search-box">
            <ST.InputsWrapper>
              <SearchInput
                placeholder="Search..."
                onSearch={(value) => {
                  this.setState({
                    searchedText: value,
                  });
                }}
                onChange={(e) => {
                  this.setState({
                    searchedText: e.target.value,
                  });
                }}
                style={{
                  width: 230,
                  marginBottom: 10,
                }}
                allowClear
                size="small"
              />
            </ST.InputsWrapper>
          </div>
          <BaseTable
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: 10,
              total: totalElements,
              showTotal: (showTotal) => (
                <span style={{ left: 0, position: 'absolute', fontSize: 15 }}>Total {showTotal} records</span>
              ),
              onChange: (page) => this.loadRecords(page - 1),
            }}
            loading={this.props.loading}
            bordered
            scroll={{ x: true }}
            rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-odd' : 'table-row-even')}
          />
        </S.Card>
        <Modal
          open={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
          width={1000}
          style={{ top: 20 }}
        >
          <Row>
            <Descriptions title="Package preferences Info" layout="vertical" bordered>
              {/* <Descriptions.Item label="P-Preferences Id">
                                    {viewRecord.id}
                                </Descriptions.Item>
                                <Descriptions.Item  label="Service Name">
                                    {viewRecord.serviceName}
                                </Descriptions.Item>
                                <Descriptions.Item label="Descriptions">
                                    {viewRecord.descriptions}
                                </Descriptions.Item>
                                <Descriptions.Item label="Parent Service">
                                    {this.state.parentServiceName.serviceName}
                                </Descriptions.Item>
                                <Descriptions.Item label="Record Status">
                                    {viewRecord.status}
                                </Descriptions.Item>
                                <Descriptions.Item label="Activated">
                                    <Badge status="success" text={viewRecord.isActive} />
                                </Descriptions.Item>
                                <Descriptions.Item label="Created Date" time>
                                    {moment(viewRecord.createdDate).format('YYYY-MM-DD')}
                                </Descriptions.Item>
                                <Descriptions.Item label="Updated Date" span={2}>
                                    {moment(viewRecord.updatedData).format('YYYY-MM-DD')}
                                </Descriptions.Item>
                                <Descriptions.Item label="Created By">
                                    {viewRecord.createdBy}
                                </Descriptions.Item>
                                <Descriptions.Item label="Modified By">
                                    {viewRecord.modifiedBy}
                                </Descriptions.Item>
                                <Descriptions.Item label="Version">
                                    {viewRecord.version}
                                </Descriptions.Item> */}
            </Descriptions>
          </Row>
        </Modal>
      </div>
    );
  }
}

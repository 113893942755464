import { ImportOutlined, UploadOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput.styles';
import { Form, Input } from 'antd';
import React, { useState } from 'react';
import { saveRecord } from '@app/utils/APIUtils';
import * as ST from '@app/pages/uiComponentsPages/UIComponentsPage.styles';
import * as S from '@app/components/tables/Tables/Tables.styles';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { notificationController } from '@app/controllers/notificationController';
import Papa from 'papaparse';
import { useNavigate } from 'react-router-dom';

const ImportCustomerRecord = () => {
  const [file, setFile] = useState();
  const [recordSet, setRecordSet] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [searchedText, setSearchedText] = useState('');
  const navigate = useNavigate();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const data = results.data.map((record, index) => ({
            serial: index + 1,
            customerName: record.customerName,
            mobileNumber: record.mobileNumber,
            addressLineOne: record.addressLineOne,
            tags: record.tags,
            status: record.status,
            recordStat: record.recordStat,
          }));
          setRecordSet(data);
          setDisabled(false);
        },
      });
    } else {
      notificationController.warning({ message: 'Please choose a file' });
      setRecordSet([]);
      setDisabled(true);
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    try {
      const response = await saveRecord(recordSet, '/public/v1/customers/import/csv');
      if (response) {
        notificationController.success({ message: 'Customer uploaded.' });
        navigate('/customer/list');
      }
    } catch (error) {
      notificationController.error({ message: `Failed! ${error.message}` });
    }
  };

  const columns = [
    {
      title: 'Index',
      dataIndex: 'serial',
      key: 'serial',
      width: '10%',
    },
    {
      title: 'Name',
      dataIndex: 'customerName',
      key: 'customerName',
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          String(record.customerName).toLowerCase().includes(value.toLowerCase()) ||
          String(record.mobileNumber).toLowerCase().includes(value.toLowerCase()) ||
          String(record.addressLineOne).toLowerCase().includes(value.toLowerCase()) ||
          String(record.tags).toLowerCase().includes(value.toLowerCase()) 
        );
      },
    },
    {
      title: 'Mobile',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
    },
    {
      title: 'Address',
      dataIndex: 'addressLineOne',
      key: 'addressLineOne',
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  return (
    <div className="page-Container">
      <S.Card id="basic-table" title="Import Customer CSV" padding="1.25rem 1.25rem 0">
        <Form id="fileUploadForm" style={{ position: 'absolute', left: '35%' }}>
          <Form.Item>
            <Input type="file" accept=".csv" onChange={handleOnChange} allowClear />
          </Form.Item>

          <BaseSpace style={{ position: 'absolute', left: '15%' }}>
            <BaseButton type="primary" onClick={handleOnSubmit} size="small" icon={<ImportOutlined />}>
              Import CSV
            </BaseButton>
            <BaseButton type="info" onClick={() => navigate('/customer/list')} size="small">
              Cancel
            </BaseButton>
          </BaseSpace>
        </Form>

        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '7rem' }}>
          <ST.InputsWrapper>
            <SearchInput
              placeholder="Search..."
              onSearch={setSearchedText}
              onChange={(e) => setSearchedText(e.target.value)}
              style={{ width: 230, marginBottom: 10 }}
              allowClear
              size="small"
            />
          </ST.InputsWrapper>
          <div>
            <BaseButton
              type="primary"
              size="small"
              icon={<UploadOutlined />}
              disabled={disabled}
              onClick={handleUpload}
            >
              Upload
            </BaseButton>
          </div>
        </div>

        <BaseTable
          columns={columns}
          dataSource={recordSet}
          bordered
          rowClassName={(_, index) => (index % 2 === 0 ? 'table-row-odd' : 'table-row-even')}
          scroll={{ x: true }}
        />
      </S.Card>
    </div>
  );
};

export default ImportCustomerRecord;

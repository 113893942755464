import React, { Component, useState } from 'react';
import { getRecordById, updateRecord, getRecords } from '@app/utils/APIUtils';
import { Form, Input, Select, Descriptions, Tag } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { handleEnter } from '@app/helper/EnterIndexHelper';

const FormItem = Form.Item;

const onSearch = (value) => {
  console.log('search:', value);
};

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();
  return (
    <EditCollection
      {...props}
      navigation={navigate}
      isLoading={isLoading}
      setLoading={setLoading}
      params={params}
      form={form}
    />
  );
}

class EditCollection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      collectionId: this.props.params.id,
      payCategoryList: [],
      paymentModeList: [],
      id: {
        value: '',
      },
      address: '',
      customerName: '',
      mobileNumber: '',
      collectionAmount: '',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  componentDidMount() {
    let promise1, promise2, promise3;

    promise1 = getRecordById('/customer/v1/collections/', this.state.collectionId);
    promise3 = getRecords('/domain/data/paymentmode/v1/all');

    promise1.then((res) => {
      this.props.form.setFieldsValue({
        customerId: res.CustomerBean.id,
        paymentCategory: res.PaymentCat,
        others: res.others,
        paymentMode: res.PaymentModeBean.id,
        amount: res.Amount,
        remarks: res.Remarks,
        voucherNo: res.VoucherNo,
      });

      this.setState({
        customerName: res.CustomerBean.CustName,
        mobileNumber: res.CustomerBean.MobileNo,
        address: res.CustomerBean.AddressLine1,
      });

      promise2 = getRecordById('/customer/v1/collections/amount/', res.CustomerBean.id);
      promise2
        .then((response) => {
          this.setState({
            collectionAmount: response,
          });
          console.log('price', this.state.collectionAmount);
        })
        .catch((error) => {
          notificationController.error({
            message: error.message,
          });
        });
    });

    promise3
      .then((response) => {
        this.setState({
          paymentModeList: response.map((item) => ({ value: item.id, label: item.paymentModeName })),
        });
      })
      .catch((error) => {
        notificationController.error({
          message: error.message,
        });
      });

    Promise.all([promise1, promise2, promise3]);
  }

  handleSubmit(values) {
    const updateRequest = Object.assign({}, values);
    //updateRequest.referenceStatusId  = 111;
    console.log('updateRequest => ' + JSON.stringify(updateRequest));

    updateRecord(this.state.collectionId, updateRequest, '/customer/v1/collections/')
    .then((response) => {
      if (response.statusCode === 200) {
        notificationController.success({
          message: 'Collection updated.',
        });
        this.props.navigation('/customer/payment/collection/list');
      }
    })
    .catch((error) => {
      notificationController.error({
        message: 'Failed! ' + error.message,
      });
    });
  }

  onCancelForm(event) {
    event.preventDefault();
    this.props.navigation('/customer/payment/collection/list');
  }

  render() {
    const numberFormat = (value) => new Intl.NumberFormat('en-IN').format(value);

    return (
      <BaseCard id="validation form" title="Edit Collection" padding="1.25rem">
        <BaseRow gutter={[30, 30]}>
          <BaseCol xs={24} sm={20} xl={12}>
            <Form
              form={this.props.form}
              onFinish={this.handleSubmit}
              className="collect-form"
              name="basic"
              labelCol={{ span: 7 }}
              style={{ maxWidth: 500 }}
              initialValues={{ remember: true }}
              autoComplete="off"
            >
              <FormItem
                label="Customer Id"
                name="customerId"
                rules={[
                  {
                    required: true,
                    message: 'Customer id is required!',
                  },
                ]}
                className="ant-latest-form-item"
              >
                <Input
                  size="small"
                  readOnly={true}
                  onKeyDown={(e) => handleEnter(e)}
                  style={{ backgroundColor: '#c5d3e0' }}
                />
              </FormItem>

              <FormItem
                label="Voucher No"
                name="voucherNo"
                rules={[
                  {
                    required: true,
                    message: 'Voucher no is required!',
                  },
                ]}
                className="ant-latest-form-item"
              >
                <Input size="small" placeholder="Enter voucher no" onKeyDown={(e) => handleEnter(e)} />
              </FormItem>

              <FormItem
                label="Payment Category"
                name="paymentCategory"
                rules={[
                  {
                    required: true,
                    message: 'Payment category is required!',
                  },
                ]}
                className="ant-latest-form-item"
              >
                <Input size="small" placeholder="Enter payment category" onKeyDown={(e) => handleEnter(e)} />
              </FormItem>

              <FormItem
                label="Mode of Payment"
                name="paymentMode"
                rules={[
                  {
                    required: true,
                    message: 'Mode of payment is required!',
                  },
                ]}
                className="ant-latest-form-item"
              >
                <Select
                  showSearch
                  placeholder="Select payment mode"
                  size="small"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  onKeyDown={(e) => handleEnter(e)}
                >
                  {this.state.paymentModeList.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>

              <FormItem
                label="Amount"
                name="amount"
                rules={[
                  {
                    required: true,
                    message: 'Amount is required!',
                  },
                  {
                    pattern: /^(?:\d*)$/,
                    message: 'Only number allowed',
                  },
                ]}
                className="ant-latest-form-item"
              >
                <Input
                  addonAfter="BDT"
                  size="small"
                  placeholder="Enter collection amount"
                  onKeyDown={(e) => handleEnter(e)}
                />
              </FormItem>

              <FormItem
                label="Others"
                name="others"
                rules={[
                  {
                    required: false,
                    message: 'Others is required!',
                  },
                ]}
                className="ant-latest-form-item"
              >
                <Input name="others" placeholder="Enter others" size="small" onKeyDown={(e) => handleEnter(e)} />
              </FormItem>

              <FormItem
                label="Remarks"
                name="remarks"
                rules={[
                  {
                    required: false,
                    message: 'Remarks is required!',
                  },
                ]}
                className="ant-latest-form-item"
              >
                <Input size="small" placeholder="Enter remarks" onKeyDown={(e) => handleEnter(e)} />
              </FormItem>

              <FormItem
                wrapperCol={{
                  offset: 7,
                  span: 9,
                }}
              >
                <BaseButtonsGroup onCancel={this.onCancelForm} loading={this.props.isLoading} />
              </FormItem>
            </Form>
          </BaseCol>

          <BaseCol xs={24} sm={12} xl={12}>
            <Descriptions title="Customer Collection Info" bordered size="middle">
              <Descriptions.Item label="Customer Name" span={3}>
                {this.state.customerName}
              </Descriptions.Item>
              <Descriptions.Item label="Mobile Number" span={3}>
                {this.state.mobileNumber}
              </Descriptions.Item>
              <Descriptions.Item label="Address" span={3}>
                {this.state.address}
              </Descriptions.Item>
              <Descriptions.Item label="Total Collection Amount" span={3}>
                <Tag color="green" key={this.state.collectionAmount} style={{ fontSize: 15, fontWeight: 600 }}>
                  {'৳'.concat(' ').concat(numberFormat(this.state.collectionAmount))}
                </Tag>
              </Descriptions.Item>
            </Descriptions>
          </BaseCol>
        </BaseRow>
      </BaseCard>
    );
  }
}

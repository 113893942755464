import React, { Component, useState } from 'react';
import { /* deleteItemByID, */ getRecordById, getRecordSet } from '@app/utils/APIUtils';
import { Modal, Row, Descriptions, Badge } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import moment from 'moment';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import * as S from '@app/components/tables/Tables/Tables.styles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput';
import * as ST from '@app/pages/uiComponentsPages//UIComponentsPage.styles';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import { LIST_SIZE } from '@app/constants';

export default function (props) {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  return (
    <AgentList {...props} loading={loading} setLoading={setLoading} page={page} setPage={setPage} navigate={navigate} />
  );
}

class AgentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      recordSet: [],
      isLoading: false,
      response: {},
      searchedText: '',
      visible: false,
      viewRecord: [],
      district: '',
      thana: '',
      parentAgentName: '',
      page: 0,
      size: 10,
      totalElements: 0,
      totalPages: 0,
      last: true,
    };
    this.save = this.save.bind(this);
    this.edit = this.edit.bind(this);
    //this.delete = this.delete.bind(this);
    this.loadRecords = this.loadRecords.bind(this);
  }

  async loadRecords(searchedText, page, size = LIST_SIZE) {
    this.setState({
      recordSet: [],
      page: 0,
      size: 10,
      totalElements: 0,
      totalPages: 0,
      last: true,
      isLoading: false,
    });

    this.props.setLoading(true);

    let promise = getRecordSet(page, size, '/admin/v1/agents?page=', searchedText);

    if (!promise) {
      return;
    }

    await promise
      .then((response) => {
        const recordSet = this.state.recordSet.slice();

        if (!(searchedText === '' || searchedText === undefined)) {
          this.setState({
            recordSet: recordSet.concat(response.content),
            page: response.page,
            size: response.size,
            totalElements: response.totalElements,
            totalPages: response.totalPages,
            last: response.last,
          });

          this.props.setLoading(false);
        } else {
          setTimeout(() => {
            this.setState({
              recordSet: recordSet.concat(response.content),
              page: response.page,
              size: response.size,
              totalElements: response.totalElements,
              totalPages: response.totalPages,
              last: response.last,
            });

            this.props.setLoading(false);
          }, 1000);
        }
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
        this.props.setLoading(false);
      });
  }

  componentDidMount() {
    this.loadRecords();
  }

  save() {
    this.props.navigate('/agent/new');
  }

  edit(id) {
    this.props.navigate(`/agent/edit/${id}`);
  }

  //? Hidden delete option
  /* delete(id) {
    const { confirm } = Modal;
    return new Promise((resolve, reject) => {
      confirm({
        title: 'Are you sure you want to Delete ?',
        onOk: () => {
          resolve(true);
          //onRemoveFunctionality here
          deleteItemByID(id, '/admin/v1/agents/').then((res) => {
            if (res.statusCode === 200) {
              this.setState({ recordSet: this.state.recordSet.filter((record) => record.agentId !== id) });
              notificationController.success({
                message: 'Agent deleted.',
              });
            }
          });
        },
        onCancel: () => {
          reject(true);
        },
      });
    });
  } */

  view(id) {
    this.setState({
      visible: true,
    });

    let promise1 = getRecordById('/admin/v1/agents/', id);
    promise1.then((res) => {
      this.setState({
        viewRecord: res,
      });

      this.setState({
        district: res.DistrictBean.districtNameEn,
      });

      this.setState({
        thana: res.ThanBean.thanaNameEn,
      });

      if (res.ParentID != null) {
        let promise2 = getRecordById('/admin/v1/agents/', res.ParentID);
        promise2
          .then((response) => {
            this.setState({
              parentAgentName: response.AgentName,
            });
          })
          .catch((error) => {
            notificationController.error({
              message: error.message,
            });
            this.props.setLoading(false);
          });
      }
    }, []);

    Promise.all([promise1]);
  }

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  render() {
    const { recordSet, totalElements } = this.state;
    //const showTotal = (totalElements) => `Total ${totalElements} items`;
    //const vendorViews = [];
    const data = [];
    recordSet.forEach((record, recordIndex) => {
      data.push({
        key: recordIndex,
        agentId: record.agentId,
        agentName: record.agentName,
        licenseNo: record.licenseNo,
        contactName: record.contactName,
        email: record.email,
        mobileNumber: record.mobileNumber,
        district: record.district,
        parentAgentId: record.parentAgentId,
        agentTypeId: record.agentTypeId,
      });
    });

    const columns = [
      /* {
          title: 'ID',
          dataIndex: 'agentId',
          key: 'agentId',
      }, */
      {
        title: 'License No',
        dataIndex: 'licenseNo',
        key: 'licenseNo',
        /* filteredValue:[this.state.searchedText],
            onFilter: (value, record) => {
                return (
                    String(record.licenseNo)
                    .toLowerCase()
                    .includes(value.toLowerCase()) ||

                    String(record.agentName)
                    .toLowerCase()
                    .includes(value.toLowerCase()) ||

                    String(record.contactName)
                    .toLowerCase()
                    .includes(value.toLowerCase()) ||

                    String(record.email)
                    .toLowerCase()
                    .includes(value.toLowerCase()) ||

                    String(record.mobileNumber)
                    .toLowerCase()
                    .includes(value.toLowerCase()) ||

                    String(record.agentTypeId)
                    .toLowerCase()
                    .includes(value.toLowerCase())

                );
            } */
      },
      {
        title: 'Agent Name',
        dataIndex: 'agentName',
        key: 'agentName;',
      },

      {
        title: 'Contact Name',
        dataIndex: 'contactName',
        key: 'contactName',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Mobile',
        dataIndex: 'mobileNumber',
        key: 'mobileNumber',
      },
      {
        title: 'Agent Type',
        dataIndex: 'agentTypeId',
        key: 'agentTypeId',
        render: (_text, record) => {
          if (record.agentTypeId === 1) {
            return 'Lead Agent';
          } else if (record.agentTypeId === 2) {
            return 'Agent';
          } else if (record.agentTypeId === 3) {
            return 'Sub-agent';
          } else {
            return 'Individual';
          }
        },
      },
      /* {
                title: 'District',
                dataIndex: 'district',
                key: 'district',
            }, */
      {
        title: 'Action',
        key: 'action',
        width: '10%',
        render: (_, record) => {
          return (
            <BaseSpace>
              <BaseButton size="small" type="primary" onClick={() => this.view(record.agentId)}>
                View
              </BaseButton>

              <BaseButton severity="info" size="small" onClick={() => this.edit(record.agentId)}>
                Edit
              </BaseButton>
              {/* Hidden Delete Button */}
              {/* <BaseButton type="primary" danger size="small" onClick={() => this.delete(record.agentId)}>
                Delete
              </BaseButton> */}
            </BaseSpace>
          );
        },
      },
    ];

    return (
      <div className="page-Container">
        <S.Card
          id="basic-table"
          title="Agent List"
          padding="1.25rem 1.25rem 0"
          extra={
            <BaseButton
              type="info"
              className="btn btn-dark"
              onClick={this.save}
              size="small"
              icon={<PlusCircleOutlined />}
            >
              Add
            </BaseButton>
          }
        >
          <div className="search-box">
            <ST.InputsWrapper>
              <SearchInput
                placeholder="Search..."
                /* onSearch={(value) => {
                  this.setState({
                    searchedText: value,
                  });
                }} */
                onChange={(e) => {
                  this.setState(
                    {
                      searchedText: e.target.value,
                    },
                    () => {
                      console.log('New state in ASYNC callback:', this.state.searchedText);
                      this.loadRecords(this.state.searchedText);
                    },
                  );
                }}
                style={{
                  width: 230,
                  marginBottom: 10,
                }}
                allowClear
                size="small"
              />
            </ST.InputsWrapper>
          </div>
          <BaseTable
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: 10,
              total: totalElements,
              //showTotal: showTotal,
              showTotal: (showTotal) => (
                <span style={{ left: 0, position: 'absolute', fontSize: 15 }}>Total {showTotal} records</span>
              ),
              onChange: (page) => this.loadRecords(this.state.searchedText, page - 1),
            }}
            rowKey={(record) => record.key}
            loading={this.props.loading}
            bordered
            scroll={{ x: true }}
            rowClassName={(_record, index) => (index % 2 === 0 ? 'table-row-odd' : 'table-row-even')}
          />
        </S.Card>

        <div>
          {this.state.viewRecord ? (
            <Modal
              open={this.state.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              okButtonProps={{ style: { display: 'none' } }}
              cancelButtonProps={{ style: { display: 'none' } }}
              width={1000}
              style={{ top: 20 }}
              footer={null}
            >
              <Row>
                <Descriptions title="Agent Info" layout="horizontal" bordered size="middle">
                  <Descriptions.Item label="Agent ID">{this.state.viewRecord.id}</Descriptions.Item>
                  <Descriptions.Item label="Agent Type">
                    {this.state.viewRecord.AgentType === 1
                      ? 'Lead Agent'
                      : this.state.viewRecord.AgentType === 2
                      ? 'Agent'
                      : this.state.viewRecord.AgentType === 3
                      ? 'Sub-agent'
                      : 'Individual'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Agent Name">{this.state.viewRecord.AgentName}</Descriptions.Item>
                  <Descriptions.Item label="License No">{this.state.viewRecord.LicenseNo}</Descriptions.Item>
                  <Descriptions.Item label="Email">{this.state.viewRecord.Email}</Descriptions.Item>
                  <Descriptions.Item label="Phone">{this.state.viewRecord.PhoneNo}</Descriptions.Item>
                  <Descriptions.Item label="Mobile">{this.state.viewRecord.MobileNo}</Descriptions.Item>
                  <Descriptions.Item label="Contact Name">{this.state.viewRecord.ContactName}</Descriptions.Item>
                  <Descriptions.Item label="District">{this.state.district}</Descriptions.Item>
                  <Descriptions.Item label="Thana">{this.state.thana}</Descriptions.Item>
                  <Descriptions.Item label="Address">{this.state.viewRecord.Address1}</Descriptions.Item>
                  <Descriptions.Item label="Parent Agent">{this.state.parentAgentName}</Descriptions.Item>
                  <Descriptions.Item label="Status">
                    {this.state.viewRecord.status === 1
                      ? 'Approved'
                      : this.state.viewRecord.status === 2
                      ? 'Rejected'
                      : this.state.viewRecord.status === 3
                      ? 'Requested'
                      : 'Suspended'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Activated">
                    <Badge status="success" text={this.state.viewRecord.isActive === 1 ? 'Active' : 'Inactive'} />
                  </Descriptions.Item>
                  <Descriptions.Item label="Created Date" time>
                    {moment(this.state.viewRecord.createdAt).format('YYYY-MM-DD')}
                  </Descriptions.Item>
                  <Descriptions.Item label="Updated Date">
                    {moment(this.state.viewRecord.updatedAt).format('YYYY-MM-DD')}
                  </Descriptions.Item>
                  <Descriptions.Item label="Created By">{this.state.viewRecord.createdBy}</Descriptions.Item>
                  <Descriptions.Item label="Modified By">{this.state.viewRecord.updatedBy}</Descriptions.Item>
                  {/* <Descriptions.Item label="Version">
                                            {this.state.viewRecord.version}
                                        </Descriptions.Item> */}
                </Descriptions>
              </Row>
            </Modal>
          ) : null}
        </div>
      </div>
    );
  }
}

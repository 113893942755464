import React, { Component, useState } from 'react';
import { saveRecord, getRecords } from '@app/utils/APIUtils';
import { Input, Select } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
//import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { handleEnter } from '@app/helper/EnterIndexHelper';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';

const FormItem = Form.Item;

const onSearch = (value) => {
  console.log('search:', value);
};

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  return <AddHotel {...props} navigation={navigate} isLoading={isLoading} setLoading={setLoading} />;
}

class AddHotel extends Component {
  render() {
    const AntWrappedOfficeForm = Form.create()(AddRoomForm);
    return (
      <div>
        <AntWrappedOfficeForm
          navigate={this.props.navigation}
          isLoading={this.props.isLoading}
          setLoading={this.props.setLoading}
        />
      </div>
    );
  }
}

class AddRoomForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hotelName: {
        value: '',
      },
      hotelType: {
        value: '',
      },
      locationId: {
        value: '',
      },
      address: {
        value: '',
      },
      remarks: {
        value: '',
      },
      pkgId: {
        value: '',
      },
      packageList: [],
      locationList: [],
      distanceList: [],
      isRequired: false,
      isReadonly: false,
      isCascade: true,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isFormInvalid = this.isFormInvalid.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  handleChangeIsCascade = (e) => {
    const { checked } = e.target;
    this.setState({
      isCascade: checked,
    });
  };

  componentDidMount() {
    let promise1, promise2, promise3;

    this.setState({
      isLoading: true,
    });

    promise1 = getRecords('/setup/v1/packages/list/all');
    promise1
      .then((response) => {
        this.setState({
          packageList: response.map((item) => ({ value: item.id, label: item.PackageName })),
        });
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
        this.setState({
          isLoading: false,
        });
      });

    promise2 = getRecords('/domain/data/locations/v1/all');
    promise2
      .then((response) => {
        this.setState({
          locationList: response.map((item) => ({ value: item.id, label: item.LocName })),
        });
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
        this.setState({
          isLoading: false,
        });
      });

    promise3 = getRecords('/domain/data/distance/v1/all');
    promise3
      .then((response) => {
        this.setState({
          distanceList: response.map((item) => ({ value: item.id, label: item.Distance })),
        });
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
        this.setState({
          isLoading: false,
        });
      });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const saveRequest = Object.assign({}, values);
        //saveRequest.referenceStatusId  = 111;
        console.log('saveRequest => ' + JSON.stringify(saveRequest));

        saveRecord(saveRequest, '/setup/v1/hotels')
          .then((response) => {
            if (response)
              notificationController.success({
                message: 'Hotel created.',
              });
            this.props.navigate('/hotel/list');
          })
          .catch((error) => {
            notificationController.error({
              message: 'Failed! ' + error.message,
            });
          });
      }
    });
  }

  onCancelForm(event) {
    event.preventDefault();
    this.props.navigate('/hotel/list');
  }

  isFormInvalid() {
    return !(this.state.agentName.validateStatus === 'success');
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <BaseCol xs={24} sm={24} xl={24}>
        <BaseCard id="validation form" title="Add Hotel" padding="1.25rem">
          <Form
            onSubmit={this.handleSubmit}
            className="hotel-form"
            name="basic"
            labelCol={{ span: 7 }}
            //wrapperCol={{ span: 16 }}
            style={{ maxWidth: 500 }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <FormItem label="Hotel Name">
              {getFieldDecorator('hotelName', {
                rules: [{ required: true, message: 'Hotel name is required!' }],
              })(
                <Input
                  name="hotelName"
                  size="small"
                  placeholder="Enter hotel name"
                  value={this.state.hotelName.value}
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>

            <FormItem label="Hotel Type">
              {getFieldDecorator('hotelType', {
                rules: [{ required: true, message: 'Hotel type is required!' }],
              })(
                <Input
                  name="hotelType"
                  size="small"
                  placeholder="Enter hotel type"
                  value={this.state.hotelType.value}
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>

            <FormItem label="Location">
              {getFieldDecorator('locationId', {
                rules: [{ required: true, message: 'Location is required!' }],
              })(
                <Select
                  showSearch
                  placeholder="Select location"
                  size="small"
                  name="locationId"
                  onKeyDown={(e) => handleEnter(e)}
                  optionFilterProp="children"
                  onSearch={onSearch}
                >
                  {this.state.locationList.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </FormItem>

            <FormItem label="Distance">
              {getFieldDecorator('distanceId', {
                rules: [{ required: true, message: 'Distance is required!' }],
              })(
                <Select
                  showSearch
                  placeholder="Select distance"
                  size="small"
                  name="distanceId"
                  onKeyDown={(e) => handleEnter(e)}
                  optionFilterProp="children"
                  onSearch={onSearch}
                >
                  {this.state.distanceList.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </FormItem>

            <FormItem label="Package">
              {getFieldDecorator('pkgId', {
                rules: [{ required: true, message: 'Package is required!' }],
              })(
                <Select
                  showSearch
                  placeholder="Select package"
                  size="small"
                  name="pkgId"
                  onKeyDown={(e) => handleEnter(e)}
                  optionFilterProp="children"
                  onSearch={onSearch}
                >
                  {this.state.packageList.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </FormItem>

            <FormItem label="Address">
              {getFieldDecorator('address', {
                rules: [{ required: false, message: 'Address is required!' }],
              })(
                <Input
                  name="address"
                  placeholder="Enter address"
                  size="small"
                  value={this.state.address.value}
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>

            <FormItem label="Remarks">
              {getFieldDecorator('remarks', {
                rules: [{ required: false, message: 'Remarks is required!' }],
              })(
                <Input
                  name="remarks"
                  placeholder="Enter remarks"
                  size="small"
                  value={this.state.remarks.value}
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>

            <FormItem label="Cascade">
              {getFieldDecorator('isCascade', {
                valuePropName: 'checked',
                initialValue: this.state.isCascade,
                rules: [{ required: false, message: 'Cascade is required!' }],
              })(
                <BaseCheckbox
                  onChange={(e) => this.handleChangeIsCascade(e)}
                  defaultChecked={this.state.isCascade}
                  value={this.state.isCascade}
                  onKeyDown={(e) => handleEnter(e)}
                ></BaseCheckbox>,
              )}
            </FormItem>

            <FormItem
              wrapperCol={{
                offset: 7,
                span: 9,
              }}
            >
              <BaseButtonsGroup onCancel={this.onCancelForm} />
            </FormItem>
          </Form>
        </BaseCard>
      </BaseCol>
    );
  }
}

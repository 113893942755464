import React, { Component, useState } from 'react';
import { saveFormRecord, getRecords, searchRecord } from '@app/utils/APIUtils';
import { MAX_FILE_SIZE } from '@app/constants';
import { Form, Descriptions, Input, Upload, Button } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { handleEnter } from '@app/helper/EnterIndexHelper';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import SearchCustomer from '@app/components/search-customer/SearchCustomer';
import { UploadOutlined } from '@ant-design/icons';

const FormItem = Form.Item;
const { TextArea } = Input;

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  return <AddDocument {...props} navigation={navigate} isLoading={isLoading} setLoading={setLoading} form={form} />;
}

class AddDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: {
        value: '',
      },
      descriptions: {
        value: '',
      },
      nidDoc: null,
      passportDoc: null,
      healthDoc: null,
      vaccineDoc: null,
      recordSet: [],
      attachment: [],
      customerId: {
        value: '',
      },
      mobileNumber: {
        value: '',
      },
      docId: [],
      docList: [],
      docFileNameList: [],
      customerInfo: {},
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  //Handle Search
  handleSearch(values) {
    const searchRequest = {
      mobileNumber: values.mobileNumber === '' ? null : values.mobileNumber,
      groupNumber: values.groupNumber === '' ? null : values.groupNumber,
    };
    if (values.mobileNumber === undefined && values.groupNumber === undefined) {
      this.setState({
        recordSet: [],
        customerInfo: '',
      });
      notificationController.warning({
        message: 'Please enter any filtering criteria!',
      });
    } else {
      searchRecord(searchRequest, '/public/v1/customers/search')
        .then((response) => {
          if (response.length !== 0) {
            this.setState({
              recordSet: response,
            });
          } else {
            this.setState({
              recordSet: [],
              customerInfo: '',
            });
            notificationController.warning({
              message: 'No record found',
            });
          }
        })
        .catch((error) => {
          this.setState({
            recordSet: [],
            customerInfo: '',
          });
          notificationController.error({
            message: 'Failed! ' + error.message,
          });
        });
    }
  }

  handleRowClick = (record) => {
    this.setState({
      customerInfo: record,
    });

    this.props.form.setFieldsValue({
      ['customerId']: record.customerId,
    });
  };

  //File change handler
  onFileChangeHandler = (index, docId) => (event) => {
    console.log('index: ' + index);
    console.log('doc id: ' + docId);
    const file = event.file; // Access the file

    console.log('Selected file:', file);

    let newSelectedFile = [...this.state.attachment]; // copying the old attachment array
    let newDocId = [...this.state.docId]; // copying the old docId array

    const uploadedFile = file;

    newSelectedFile[index] = uploadedFile; // replace e.target.value with whatever you want to change it to
    newDocId[index] = docId; // replace id with whatever you want to change it to

    if (!uploadedFile) {
      this.setState({
        attachment: [],
        docId: [],
      });
      return '';
    }

    this.setState({
      attachment: newSelectedFile,
      docId: newDocId,
    });

    const fileSizeKiloBytes = uploadedFile.size / 1024;

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      //event.target.value = null;
      this.setState({
        attachment: [],
        docId: [],
      });
      notificationController.warning({
        message: 'File size is greater than maximum limit (2MB)',
      });
    }
  };

  //File name change handler
  onFileNameChangeHandler = (index, docId) => (event) => {
    console.log('index: ' + index);
    console.log('doc id: ' + docId);
    console.log('File name: ' + event.target.value);

    let newFileName = [...this.state.docFileNameList]; // copying the old attachment array
    let newDocId = [...this.state.docId]; // copying the old docId array

    const docFileName = event.target.value;

    newFileName[index] = docFileName; // replace e.target.value with whatever you want to change it to
    newDocId[index] = docId; // replace id with whatever you want to change it to

    this.setState({
      docFileNameList: newFileName,
      docId: newDocId,
    });
  };

  componentDidMount() {
    this.props.setLoading(true);

    let promise;

    promise = getRecords('/domain/data/doctypes/v1/all');

    promise
      .then((response) => {
        this.setState({
          docList: response,
        });
        console.log(this.state.docList);
      })
      .catch((error) => {
        if (error) this.props.setLoading(false);
      });
  }

  /* handleKeyDown(event) {
    event.preventDefault();

    this.props.form.validateFields((_err, values) => {
      const searchRequest = {
        mobileNumber: values.mobileNumber,
      };

      console.log('searchRequest => ' + JSON.stringify(searchRequest));

      searchRecord(searchRequest, '/public/v1/customers/cif')
        .then((response) => {
          if (response.statusCode === 204) {
            notificationController.info({
              message: 'Customer not found!',
            });
            this.props.form.resetFields();
            this.setState({
              customerInfo: '',
            });
          } else {
            this.setState({
              customerInfo: response,
            });
            this.props.form.setFieldsValue({
              customerId: response.id,
            });

            //Get customer required document
            let promise1 = getRecords(`/customer/v1/documents/required/${response.id}/list`);
            promise1.then((res) => {
              if (res != null) {
                //const recordSet = this.state.docRecSet.slice();
                this.setState({
                  docList: res,
                });
              }
            });
          }

          console.log(this.state.customerInfo);
        })
        .catch((error) => {
          notificationController.error({
            message: 'Failed! ' + error.message,
          });
        });
    });
  } */

  handleSubmit(values) {
    if (this.state.attachment.length !== 0) {
      //const saveRequest = Object.assign({}, values);
      //saveRequest.referenceStatusId  = 111;
      let formData = new FormData();
      formData.append('customerId', values.customerId);
      //formData.append('fileName', values.fileName);
      formData.append('descriptions', values.descriptions === undefined ? '' : values.descriptions);
      formData.append('docId', this.state.docId);
      formData.append('fileName', this.state.docFileNameList);
      this.state.attachment.forEach((item) => formData.append('attachment', item));

      saveFormRecord(formData, '/customer/v1/documents').then((response) => {
        if (response.statusCode === 101) {
          notificationController.warning({
            message: 'Document already exist!',
          });
        } else {
          notificationController.success({
            message: 'Document uploaded',
          });
          this.props.navigation('/customer/doc/list');
        }
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
      });
    } else {
      notificationController.warning({
        message: 'Please select file to upload!',
      });
    }
  }

  onCancelForm(event) {
    event.preventDefault();
    this.props.navigation('/customer/doc/list');
  }

  render() {
    const { recordSet, docList, customerInfo } = this.state;
    const docData = [];
    const customerData = [];

    //Customer record set
    recordSet.forEach((record, recordIndex) => {
      customerData.push({
        key: recordIndex,
        customerId: record.customerId,
        customerName: record.customerName,
        mobileNumber: record.mobileNumber,
        groupNumber: record.groupNumber,
        email: record.email,
        address: record.address,
        district: record.district,
        thana: record.thana,
        reference: record.reference,
      });
    });

    const customerColumns = [
      {
        title: 'Name',
        dataIndex: 'customerName',
        key: 'customerName;',
      },
      {
        title: 'Mobile',
        dataIndex: 'mobileNumber',
        key: 'mobileNumber',
      },
      {
        title: 'Group Number',
        dataIndex: 'groupNumber',
        key: 'groupNumber',
      },
    ];

    //Document type record set
    docList.forEach((record, recordIndex) => {
      docData.push({
        key: recordIndex,
        id: record.id,
        docName: record.DocName,
        docType: record.DocType,
      });
    });

    const columns = [
      {
        title: 'Document Type',
        dataIndex: 'docName',
        key: 'docName;',
        width: '30%',
      },
      {
        title: 'Browse Document',
        key: 'action',
        width: '30%',
        render: (_, record) => {
          return (
            <BaseSpace>
              {/* <Input
                suffix={<UploadOutlined />}
                type="file"
                name={record.docType}
                size="small"
                accept="application/pdf"
                onKeyDown={(e) => handleEnter(e)}
                onChange={this.onFileChangeHandler(record.key, record.id)}
              /> */}
              <Upload
                accept=".jpg,.jpeg,.png" 
                type="file"
                name={record.docType}
                size="small"
                beforeUpload={() => false} // Prevent automatic upload
                onKeyDown={(e) => handleEnter(e)}
                onChange={this.onFileChangeHandler(record.key, record.id)}
              >
                <Button icon={<UploadOutlined />} size="small" type="dashed">
                  Upload
                </Button>
              </Upload>
            </BaseSpace>
          );
        },
      },
      {
        title: 'File Name',
        key: 'action',
        width: '40%',
        render: (_, record) => {
          return (
            <BaseSpace>
              <Input
                name={record.docType}
                size="small"
                onKeyDown={(e) => handleEnter(e)}
                onChange={this.onFileNameChangeHandler(record.key, record.id)}
              />
            </BaseSpace>
          );
        },
      },
    ];

    return (
      <BaseCard id="doc-card" title="Add Customer Document" padding="1.25rem">
        <BaseRow gutter={[30, 30]}>
          <BaseCol xs={24} sm={24} xl={12}>
            <SearchCustomer
              searchForm={this.props.form}
              onFinish={this.handleSearch}
              mobileNumber="mobileNumber"
              groupNumber="groupNumber"
              onKeyDown={(e) => handleEnter(e)}
            />
          </BaseCol>
          <BaseCol xs={24} sm={20} xl={12}>
            <BaseCard id="search" padding="1.25rem">
              <BaseRow gutter={[0, 0]}>
                <BaseCol xs={24} sm={24} xl={24}>
                  <BaseTable
                    onRow={(record) => ({
                      onClick: () => this.handleRowClick(record), // Handle row click
                    })}
                    columns={customerColumns}
                    dataSource={customerData}
                    pagination={{ pageSize: 5 }}
                    scroll={{ x: true }}
                    rowKey={(record) => record.customerId}
                    bordered
                  />
                </BaseCol>
              </BaseRow>
            </BaseCard>
          </BaseCol>
        </BaseRow>

        <BaseRow gutter={[30, 30]}>
          <BaseCol xs={24} sm={12} xl={12}>
            <Descriptions title="Customer Details" bordered size="middle" column={2}>
              <Descriptions.Item label="Customer Name" span={2}>
                {customerInfo.customerName}
              </Descriptions.Item>
              <Descriptions.Item label="Email" span={2}>
                {customerInfo.email}
              </Descriptions.Item>
              <Descriptions.Item label="Mobile No" span={1}>
                {customerInfo.mobileNumber}
              </Descriptions.Item>
            </Descriptions>
          </BaseCol>
          <BaseCol xs={24} sm={12} xl={12}>
            <Form
              form={this.props.form}
              onFinish={this.handleSubmit}
              className="doc-form"
              name="basic"
              layout="vertical"
              style={{ maxWidth: 500, marginTop: '20px' }}
              initialValues={{ remember: true }}
              autoComplete="off"
            >
              <FormItem
                label="Customer Id"
                name="customerId"
                rules={[
                  {
                    required: true,
                    message: 'Customer id is required!',
                  },
                ]}
                className="ant-latest-form-item"
                hidden={true}
              >
                {/* <-- Pass hidden customerId --> */}
                <Input
                  size="small"
                  readOnly={true}
                  onKeyDown={(e) => handleEnter(e)}
                  style={{ backgroundColor: '#c5d3e0' }}
                />
              </FormItem>

              <FormItem label="Upload Document" required={true} className="ant-latest-form-item">
                <BaseTable
                  columns={columns}
                  dataSource={docData}
                  pagination={false}
                  scroll={{ x: true }}
                  rowKey={(record) => record.id}
                  bordered
                  style={{ marginBottom: 20 }}
                />
              </FormItem>

              {/* <FormItem
                label="File Name"
                name="fileName"
                rules={[
                  {
                    required: false,
                    message: 'Please input file name!',
                  },
                ]}
                className="ant-latest-form-item"
              >
                <Input
                  size="small"
                  autoComplete="off"
                  placeholder="Enter file name"
                  onKeyDown={(e) => handleEnter(e)}
                />
              </FormItem> */}

              <FormItem
                label="Descriptions"
                name="descriptions"
                rules={[
                  {
                    required: false,
                    message: 'Please input descriptions!',
                  },
                ]}
                className="ant-latest-form-item"
              >
                <TextArea
                  autoComplete="off"
                  size="small"
                  placeholder="Enter descriptions"
                  onKeyDown={(e) => handleEnter(e)}
                />
              </FormItem>

              <FormItem
                wrapperCol={{
                  offset: 0,
                  span: 9,
                }}
              >
                <BaseButtonsGroup onCancel={this.onCancelForm} />
              </FormItem>
            </Form>
          </BaseCol>
        </BaseRow>
      </BaseCard>
    );
  }
}

import React, { Component, useState } from 'react';
import { getRecordById, updateRecord, getRecords, checkRecordAvailability } from '@app/utils/APIUtils';
import {
  AGENTNAME_MIN_LENGTH,
  AGENTNAME_MAX_LENGTH,
  EMAIL_MAX_LENGTH,
  PHONE_MAX_LENGTH,
  MOBILE_MAX_LENGTH,
} from '@app/constants';
import { Input, Select } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { handleEnter } from '@app/helper/EnterIndexHelper';

const FormItem = Form.Item;

const onSearch = (value) => {
  console.log('search:', value);
};

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  return <EditAgent {...props} navigation={navigate} isLoading={isLoading} setLoading={setLoading} params={params} />;
}

class EditAgent extends Component {
  render() {
    const AntWrappedOfficeForm = Form.create()(EditAgentForm);
    return (
      <div>
        <AntWrappedOfficeForm
          dataId={this.props.params}
          navigate={this.props.navigation}
          isLoading={this.props.isLoading}
          setLoading={this.props.setLoading}
        />
      </div>
    );
  }
}

class EditAgentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      agentId: this.props.dataId.id,
      agentTypeItems: [
        { value: 1, label: 'Lead Agent' },
        { value: 2, label: 'Agent' },
        { value: 3, label: 'Sub-agent' },
        { value: 4, label: 'Individual' },
      ],
      parentAgentList: [],
      districtList: [],
      thanaList: [],
      parentAgentAll: [],
      referenceStatusItems: [
        { value: 1, label: 'Approved' },
        { value: 2, label: 'Rejected' },
        { value: 3, label: 'Requested' },
        { value: 4, label: 'Suspended' },
      ],
      isRequired: false,
      isReadonly: false,
      agentName: {
        value: '',
      },
      email: {
        value: '',
      },
      phoneNumber: {
        value: '',
      },
      mobileNumber: {
        value: '',
      },
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);
    this.handleDistrictChange = this.handleDistrictChange.bind(this);
    this.handleAgentTypeChange = this.handleAgentTypeChange.bind(this);
    this.handleAgentIdChange = this.handleAgentIdChange.bind(this);
    this.validateAgentnameAvailability = this.validateAgentnameAvailability.bind(this);
    this.validateEmailAvailability = this.validateEmailAvailability.bind(this);
    this.validateAgentPhoneNumberAvailability = this.validateAgentPhoneNumberAvailability.bind(this);
    this.validateAgentMobileNumberAvailability = this.validateAgentMobileNumberAvailability.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  componentDidMount() {
    let promise, promise2, promise3, promise4;

    promise = getRecords('/admin/v1/agents/parent/all');
    promise4 = getRecords('/admin/v1/agents/all');
    promise2 = getRecords('/domain/data/districts/v1/all');

    this.setState({
      isLoading: true,
    });

    let promise1 = getRecordById('/admin/v1/agents/', this.state.agentId);
    promise1.then((res) => {
      this.props.form.setFieldsValue({
        agentTypeId: res.AgentType,
        agentName: res.AgentName,
        licenseNo: res.LicenseNo,
        contactName: res.ContactName,
        email: res.Email,
        phoneNumber: res.PhoneNo,
        mobileNumber: res.MobileNo,
        district: res.DistrictBean.id,
        thana: res.ThanBean.id,
        addressLineOne: res.Address1,
        addressLineTwo: res.Address2,
        parentAgentId: res.ParentID,
        recordStat: res.status,
      });

      if (res.AgentType === 2) {
        promise
          .then((response) => {
            this.setState({
              parentAgentList: response.map((item) => ({ value: item.id, label: item.AgentName })),
              parentAgentAll: response.content,
              isRequired: true,
              isReadonly: true,
            });

            console.log(this.state.parentAgentList);
          })
          .catch((error) => {
            if (error)
              this.setState({
                isLoading: false,
              });
          });
      } else if (res.AgentType === 3 || res.AgentType === 4) {
        promise4
          .then((response) => {
            this.setState({
              parentAgentList: response.map((item) => ({ value: item.id, label: item.AgentName })),
              parentAgentAll: response.content,
              isRequired: true,
              isReadonly: true,
            });

            console.log(this.state.parentAgentList);
          })
          .catch((error) => {
            if (error)
              this.setState({
                isLoading: false,
              });
          });
      }

      promise2
        .then((response) => {
          this.setState({
            districtList: response.map((item) => ({
              value: item.id,
              label: item.districtNameEn + ' - ' + item.districtNameBn,
            })),
          });
          console.log(this.state.districtList);
        })
        .catch((error) => {
          if (error)
            this.setState({
              isLoading: false,
            });
        });

      promise3 = getRecords(`/domain/data/thanas/v1/${res.DistrictBean.id}/district`);

      promise3
        .then((response) => {
          this.setState({
            thanaList: response.map((item) => ({ value: item.id, label: item.thanaNameEn + ' - ' + item.thanaNameBn })),
          });
          console.log(this.state.thanaList);
        })
        .catch((error) => {
          if (error)
            this.setState({
              isLoading: false,
            });
        });
    });

    Promise.all([promise, promise1, promise2, promise3]);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const updateRequest = Object.assign({}, values);
        //updateRequest.referenceStatusId  = 111;
        console.log('updateRequest => ' + JSON.stringify(updateRequest));

        updateRecord(this.state.agentId, updateRequest, '/admin/v1/agents/')
          .then((response) => {
            if (response.statusCode === 200) {
              notificationController.success({
                message: response.message,
              });
              this.props.navigate('/agent/list');
            } else if (response.statusCode === 102) {
              notificationController.error({
                message: response.message,
              });
            }
          })
          .catch((error) => {
            notificationController.error({
              message: 'Failed! ' + error.message,
            });
          });
      }
    });
  }

  handleDistrictChange = (value) => {
    try {
      getRecords(`/domain/data/thanas/v1/${value}/district`)
        .then((response) => {
          this.props.form.resetFields(['thana']);
          this.setState({
            thanaList: response.map((item) => ({ value: item.id, label: item.thanaNameEn + ' - ' + item.thanaNameBn })),
          });
          console.log(this.state.thanaList);
        })
        .catch((error) => {
          if (error) this.setState({ thanaList: [] });
          this.setState({
            isLoading: false,
          });
        });
    } catch (error) {}
  };

  handleAgentTypeChange = (value) => {
    try {
      if (value === 2) {
        getRecords('/admin/v1/agents/parent/all')
          .then((response) => {
            this.props.form.resetFields(['parentAgentId']);
            this.setState({
              parentAgentList: response.map((item) => ({ value: item.id, label: item.AgentName })),

              isRequired: true,
            });
            console.log(this.state.parentAgentList);
          })
          .catch((error) => {
            if (error) this.setState({ parentAgentList: [] });
            this.setState({
              isLoading: false,
            });
          });
      } else if (value === 3 || value === 4) {
        getRecords('/admin/v1/agents/all')
          .then((response) => {
            this.props.form.resetFields(['parentAgentId']);
            this.setState({
              parentAgentList: response.map((item) => ({ value: item.id, label: item.AgentName })),
              isRequired: true,
            });
          })
          .catch((error) => {
            if (error) this.setState({ parentAgentList: [] });
            this.setState({
              isLoading: false,
            });
          });
      } else {
        this.props.form.resetFields(['parentAgentId']);
        this.props.form.resetFields(['licenseNo']);
        this.setState({
          parentAgentList: [],
          isRequired: false,
          isReadonly: false,
        });
      }
    } catch (error) {}
  };

  handleAgentIdChange = (value) => {
    try {
      let promise3 = getRecordById('/admin/v1/agents/', value);
      promise3.then((res) => {
        this.props.form.setFieldsValue({
          licenseNo: res.LicenseNo,
        });
      });
      this.setState({
        isReadonly: true,
      });
    } catch (error) {}
  };

  onCancelForm(event) {
    event.preventDefault();
    this.props.navigate('/agent/list');
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <BaseCol xs={24} sm={24} xl={24}>
        <BaseCard id="validation form" title="Edit Agent" padding="1.25rem">
          <Form
            onSubmit={this.handleSubmit}
            className="agent-form"
            name="basic"
            labelCol={{ span: 10 }}
            //wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <FormItem label="Agent Type">
              {getFieldDecorator('agentTypeId', {
                rules: [{ required: true, message: 'Agent type is required!' }],
              })(
                <Select
                  showSearch
                  size="small"
                  placeholder="Select"
                  name="agentTypeId"
                  onChange={this.handleAgentTypeChange}
                  onKeyDown={(e) => handleEnter(e)}
                  disabled={true}
                >
                  {this.state.agentTypeItems.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </FormItem>

            <FormItem label="Parent Agent">
              {getFieldDecorator('parentAgentId', {
                rules: [{ required: this.state.isRequired, message: 'Parent agent is required!' }],
              })(
                <Select
                  showSearch
                  placeholder="Select"
                  size="small"
                  name="parentAgentId"
                  onChange={this.handleAgentIdChange}
                  onKeyDown={(e) => handleEnter(e)}
                  optionFilterProp="children"
                  onSearch={onSearch}
                  disabled={true}
                >
                  {this.state.parentAgentList.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </FormItem>

            <FormItem
              label="Agent/ Sub-agent/ Individual Name"
              hasFeedback
              validateStatus={this.state.agentName.validateStatus}
              help={this.state.agentName.errorMsg}
            >
              {getFieldDecorator('agentName', {
                rules: [{ required: true, message: 'Agent name is required!' }],
              })(
                <Input
                  name="agentName"
                  size="small"
                  placeholder="Enter agent name"
                  onBlur={this.validateAgentnameAvailability}
                  onChange={(event) => this.handleInputChange(event, this.validateAgentname)}
                  disabled={true}
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>
            <FormItem label="License No">
              {getFieldDecorator('licenseNo', {
                rules: [{ required: true, message: 'License no is required!' }],
              })(
                <Input
                  name="licenseNo"
                  size="small"
                  placeholder="Enter license no"
                  disabled={this.state.isReadonly}
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>
            <FormItem label="Contact Name">
              {getFieldDecorator('contactName', {
                rules: [{ required: true, message: 'Contact name is required!' }],
              })(<Input name="contactName" size="small" placeholder="Enter contact name" disabled={true} />)}
            </FormItem>
            <FormItem
              label="Email"
              hasFeedback
              validateStatus={this.state.email.validateStatus}
              help={this.state.email.errorMsg}
            >
              {getFieldDecorator('email', {
                rules: [{ required: true, message: 'Email address is required!' }],
              })(
                <Input
                  addonBefore={<MailOutlined />}
                  name="email"
                  size="small"
                  placeholder="Enter valid email address"
                  onBlur={this.validateEmailAvailability}
                  onChange={(event) => this.handleInputChange(event, this.validateEmail)}
                  disabled={true}
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>
            <FormItem
              label="Phone Number"
              hasFeedback
              validateStatus={this.state.phoneNumber.validateStatus}
              help={this.state.phoneNumber.errorMsg}
            >
              {getFieldDecorator('phoneNumber', {
                rules: [{ required: true, message: 'Contact number is required!' }],
              })(
                <Input
                  addonBefore={<PhoneOutlined />}
                  name="phoneNumber"
                  size="small"
                  placeholder="Enter phone number"
                  maxLength={11}
                  onBlur={this.validateAgentPhoneNumberAvailability}
                  onChange={(event) => this.handleInputChange(event, this.validatePhone)}
                  disabled={true}
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>
            <FormItem
              label="Mobile Number"
              hasFeedback
              validateStatus={this.state.mobileNumber.validateStatus}
              help={this.state.mobileNumber.errorMsg}
            >
              {getFieldDecorator('mobileNumber', {
                rules: [{ required: true, message: 'Mobile no is required!' }],
              })(
                <Input
                  addonBefore="+88"
                  size="small"
                  name="mobileNumber"
                  placeholder="Enter mobile number"
                  maxLength={11}
                  onBlur={this.validateAgentMobileNumberAvailability}
                  onChange={(event) => this.handleInputChange(event, this.validateMobile)}
                  disabled={true}
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>
            <FormItem label="District">
              {getFieldDecorator('district', {
                rules: [{ required: true, message: 'District is required!' }],
              })(
                <Select
                  showSearch
                  placeholder="Select"
                  size="small"
                  name="district"
                  onChange={this.handleDistrictChange}
                  optionFilterProp="children"
                  onSearch={onSearch}
                  onKeyDown={(e) => handleEnter(e)}
                >
                  {this.state.districtList.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </FormItem>
            <FormItem label="Thana">
              {getFieldDecorator('thana', {
                rules: [{ required: true, message: 'Thana is required!' }],
              })(
                <Select
                  showSearch
                  size="small"
                  placeholder="Select"
                  name="thana"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  onKeyDown={(e) => handleEnter(e)}
                >
                  {this.state.thanaList.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </FormItem>
            <FormItem label="Address Line 1">
              {getFieldDecorator('addressLineOne', {
                rules: [{ required: true, message: 'Address is required!' }],
              })(
                <Input
                  name="addressLineOne"
                  size="small"
                  placeholder="Enter address Line One"
                  onKeyDown={(e) => handleEnter(e)}
                  //value={this.state.addressLineOne.value}
                />,
              )}
            </FormItem>
            <FormItem label="Address Line 2">
              {getFieldDecorator('addressLineTwo', {
                rules: [{ required: false, message: 'Please input address line two!' }],
              })(
                <Input
                  name="addressLineTwo"
                  size="small"
                  placeholder="Enter address Line two"
                  onKeyDown={(e) => handleEnter(e)}
                  //value={this.state.addressLineTwo.value}
                />,
              )}
            </FormItem>
            <FormItem label="Status">
              {getFieldDecorator('recordStat', {
                rules: [{ required: true, message: 'Please select status!' }],
              })(
                <Select
                  showSearch
                  placeholder="Select"
                  size="small"
                  name="recordStat"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  onKeyDown={(e) => handleEnter(e)}
                  //value={this.state.referenceStatusId.value}
                >
                  {this.state.referenceStatusItems.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </FormItem>
            <FormItem
              wrapperCol={{
                offset: 10,
                span: 9,
              }}
            >
              <BaseButtonsGroup onCancel={this.onCancelForm} />
            </FormItem>
          </Form>
        </BaseCard>
      </BaseCol>
    );
  }

  // Validation Functions
  validateAgentname = (agentName) => {
    if (agentName.length < AGENTNAME_MIN_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `Agent name is too short (Minimum ${AGENTNAME_MIN_LENGTH} characters needed.)`,
      };
    } else if (agentName.length > AGENTNAME_MAX_LENGTH) {
      return {
        validationStatus: 'error',
        errorMsg: `Agent name is too long (Maximum ${AGENTNAME_MAX_LENGTH} characters allowed.)`,
      };
    } else {
      return {
        validateStatus: null,
        errorMsg: null,
      };
    }
  };

  validateEmail = (email) => {
    if (!email) {
      return {
        validateStatus: 'error',
        errorMsg: 'Email may not be empty',
      };
    }

    const EMAIL_REGEX = RegExp('[^@ ]+@[^@ ]+\\.[^@ ]+');
    if (!EMAIL_REGEX.test(email)) {
      return {
        validateStatus: 'error',
        errorMsg: 'Email not valid',
      };
    }

    if (email.length > EMAIL_MAX_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `Email is too long (Maximum ${EMAIL_MAX_LENGTH} characters allowed)`,
      };
    }

    return {
      validateStatus: null,
      errorMsg: null,
    };
  };

  validatePhone = (phoneNumber) => {
    if (!phoneNumber) {
      return {
        validateStatus: 'error',
        errorMsg: 'Phone number may not be empty',
      };
    }

    const NUMBER_REGEX = RegExp('^[0-9]');
    if (!NUMBER_REGEX.test(phoneNumber)) {
      return {
        validateStatus: 'error',
        errorMsg: 'Phone number not valid',
      };
    }

    if (phoneNumber.length > PHONE_MAX_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `Phone number is too long (Maximum ${PHONE_MAX_LENGTH} characters allowed)`,
      };
    }

    return {
      validateStatus: null,
      errorMsg: null,
    };
  };

  validateMobile = (mobileNumber) => {
    if (!mobileNumber) {
      return {
        validateStatus: 'error',
        errorMsg: 'Mobile number may not be empty',
      };
    }

    const NUMBER_REGEX = RegExp('^[0-9]');
    if (!NUMBER_REGEX.test(mobileNumber)) {
      return {
        validateStatus: 'error',
        errorMsg: 'Mobile number not valid',
      };
    }

    if (mobileNumber.length > MOBILE_MAX_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `Mobile number is too long (Maximum ${MOBILE_MAX_LENGTH} characters allowed)`,
      };
    }

    return {
      validateStatus: null,
      errorMsg: null,
    };
  };

  validateAgentnameAvailability() {
    // First check for client side errors in username
    const agentnameValue = this.state.agentName.value;
    const agentnameValidation = this.validateAgentname(agentnameValue);

    if (agentnameValidation.validateStatus === 'error') {
      this.setState({
        agentName: {
          value: agentnameValue,
          ...agentnameValidation,
        },
      });
      return;
    }

    this.setState({
      agentName: {
        value: agentnameValue,
        validateStatus: 'validating',
        errorMsg: null,
      },
    });

    checkRecordAvailability('/admin/v1/agents/check/name?agentName=', agentnameValue)
      .then((response) => {
        if (response.available) {
          this.setState({
            agentName: {
              value: agentnameValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
        } else {
          this.setState({
            agentName: {
              value: agentnameValue,
              validateStatus: 'error',
              errorMsg: 'This Agent name is already exists',
            },
          });
        }
      })
      .catch((error) => {
        if (error)
          // Marking validateStatus as success, Form will be recchecked at server
          this.setState({
            agentName: {
              value: agentnameValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
      });
  }

  validateEmailAvailability() {
    // First check for client side errors in email
    const emailValue = this.state.email.value;
    const emailValidation = this.validateEmail(emailValue);

    if (emailValidation.validateStatus === 'error') {
      this.setState({
        email: {
          value: emailValue,
          ...emailValidation,
        },
      });
      return;
    }

    this.setState({
      email: {
        value: emailValue,
        validateStatus: 'validating',
        errorMsg: null,
      },
    });

    checkRecordAvailability('/admin/v1/agents/check/email?email=', emailValue)
      .then((response) => {
        if (response.available) {
          this.setState({
            email: {
              value: emailValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
        } else {
          this.setState({
            email: {
              value: emailValue,
              validateStatus: 'error',
              errorMsg: 'This Email is already exists',
            },
          });
        }
      })
      .catch((error) => {
        if (error)
          // Marking validateStatus as success, Form will be recchecked at server
          this.setState({
            email: {
              value: emailValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
      });
  }

  validateAgentPhoneNumberAvailability() {
    // First check for client side errors in username
    const agentphoneValue = this.state.phoneNumber.value;
    const agentphoneValidation = this.validatePhone(agentphoneValue);

    if (agentphoneValidation.validateStatus === 'error') {
      this.setState({
        phoneNumber: {
          value: agentphoneValue,
          ...agentphoneValidation,
        },
      });
      return;
    }

    this.setState({
      phoneNumber: {
        value: agentphoneValue,
        validateStatus: 'validating',
        errorMsg: null,
      },
    });

    checkRecordAvailability('/admin/v1/agents/check/phone?phoneNumber=', agentphoneValue)
      .then((response) => {
        if (response.available) {
          this.setState({
            phoneNumber: {
              value: agentphoneValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
        } else {
          this.setState({
            phoneNumber: {
              value: agentphoneValue,
              validateStatus: 'error',
              errorMsg: 'Agent phone number is already exists',
            },
          });
        }
      })
      .catch((error) => {
        if (error)
          // Marking validateStatus as success, Form will be recchecked at server
          this.setState({
            phoneNumber: {
              value: agentphoneValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
      });
  }

  validateAgentMobileNumberAvailability() {
    // First check for client side errors in username
    const agentmobileValue = this.state.mobileNumber.value;
    const agentmobileValidation = this.validateMobile(agentmobileValue);

    if (agentmobileValidation.validateStatus === 'error') {
      this.setState({
        mobileNumber: {
          value: agentmobileValue,
          ...agentmobileValidation,
        },
      });
      return;
    }

    this.setState({
      mobileNumber: {
        value: agentmobileValue,
        validateStatus: 'validating',
        errorMsg: null,
      },
    });

    checkRecordAvailability('/admin/v1/agents/check/mobile?mobileNumber=', agentmobileValue)
      .then((response) => {
        if (response.available) {
          this.setState({
            mobileNumber: {
              value: agentmobileValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
        } else {
          this.setState({
            mobileNumber: {
              value: agentmobileValue,
              validateStatus: 'error',
              errorMsg: 'Agent mobile number is already exists',
            },
          });
        }
      })
      .catch((error) => {
        if (error)
          // Marking validateStatus as success, Form will be recchecked at server
          this.setState({
            mobileNumber: {
              value: agentmobileValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
      });
  }
}

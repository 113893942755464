import React, { useEffect, useState } from 'react';
//import { useTranslation } from 'react-i18next';
import { useResponsive } from 'hooks/useResponsive';
import * as S from '@app/components/medical-dashboard/bloodScreeningCard/PilgrimsByPackageTable/PilgrimsByPackageTable.styles';
import { BloodTestResult, /* flags, results */ } from '@app/constants/bloodTestResults';
import { ColumnsType } from 'antd/es/table';
import { getRecords } from '@app/utils/APIUtils';

export interface CustomerAgentResult {
  key: number;
  name: string;
  customer: number;
  precustomer: number;
  regcustomer: number;
}

export const PilgrimsByAllainceTable: React.FC = () => {
  const { isTablet, isDesktop } = useResponsive();
  //const { t } = useTranslation();
  const [dataSource, setDataSource] = useState<CustomerAgentResult[]>();
  
  useEffect(() => {
    const promise = getRecords('/admin/v1/dashboard/pilgrimsbyallaince/list');
    promise.then((res : any) => setDataSource(res));
  }, []);

  const columns: ColumnsType<BloodTestResult> = [
    {
      title: "Agent Name",
      dataIndex: 'name',
      width: '50%',
    },
    // {
    //   title: "Customers",
    //   dataIndex: 'customer',
    // },
    {
      title: "Pre-registered",
      dataIndex: 'precustomer',
    },
    {
      title: "Registered",
      dataIndex: 'regcustomer',
    },
  ];

  return (
    <S.Table
      size={'small'}
      pagination={false}
      columns={columns}
      dataSource={dataSource}
      rowKey={(record) => record.name}
      scroll={{ y: isDesktop ? 300 : isTablet ? 620 : 400 }}
      rowClassName={(_record, index) => (index % 2 === 0 ? 'table-row-odd' : 'table-row-even')}
    />
  );
};

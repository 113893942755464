import { FC } from 'react';
import { ReactComponent as AgentIcon } from '@app/assets/icons/agent.svg';
import { ReactComponent as CustomerIcon } from '@app/assets/icons/customer.svg';
import { ReactComponent as PregistrationIcon } from '@app/assets/icons/pregistration.svg';
import { ReactComponent as RegistrationIcon } from '@app/assets/icons/registration.svg';

export type StatisticColor = 'primary' | 'error' | 'secondary' | 'success';

interface ConfigStatistic {
  id: number;
  name: string;
  title: string;
  color: StatisticColor;
  Icon: FC;
  cardId: string;
}

export const statistics: ConfigStatistic[] = [
  {
    id: 1,
    name: 'My Alliance (Pilgrims)',
    title: 'My Alliance (Pilgrims)',
    color: 'success',
    Icon: AgentIcon,
    cardId: 'AgentCard',
  },
  {
    id: 2,
    name: 'Customers',
    title: 'Customers',
    color: 'error',
    Icon: CustomerIcon,
    cardId: 'CustomerCard',
  },
  {
    id: 3,
    name: 'Pre-Registered',
    title: 'Pre-Registered',
    color: 'primary',
    Icon: PregistrationIcon,
    cardId: 'PregisteredCard',
  },
  {
    id: 4,
    name: 'Registered',
    title: 'Registered',
    color: 'secondary',
    Icon: RegistrationIcon,
    cardId: 'RegisteredCard',
  },
];

import React from 'react';
import ProfileOverlay from '../ProfileOverlay/ProfileOverlay';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './ProfileDropdown.styles';
import { BasePopover } from '@app/components/common/BasePopover/BasePopover';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';
import { getCurrentUser } from 'utils/APIUtils';
import { notificationController } from '@app/controllers/notificationController';
import UserImage from '@app/components/common/icons/user.png';

class ProfileDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      isAuthenticated: false,
      isLoading: true,
      name: '',
      thumbnail: [],
    };
    this.loadCurrentUser = this.loadCurrentUser.bind(this);
  }

  componentDidMount() {
    this.loadCurrentUser();
  }

  loadCurrentUser() {
    getCurrentUser()
      .then((response) => {
        this.setState({
          currentUser: response,
          isAuthenticated: true,
          isLoading: false,
          name: response.name,
          thumbnail: response.thumbnail,
        });
      })
      .catch((error) => {
        if (error)
          notificationController.error({
            message: 'Failed! ' + ' User not found.',
          });
        this.setState({
          isLoading: false,
        });
      });
  }

  // const { isTablet } = useResponsive();

  // const user = useAppSelector((state) => state.user.user);

  render() {
    return this.state ? (
      <BasePopover content={<ProfileOverlay />} trigger="click">
        <S.ProfileDropdownHeader as={BaseRow} gutter={[10, 10]} align="middle">
          <BaseCol>
            <BaseAvatar
              src={this.state?.thumbnail ? `data:image/png;base64,${this.state?.thumbnail}` : UserImage}
              alt="User"
              shape="circle"
              size={40}
            />
          </BaseCol>
          {this.props.isTablet && (
            <BaseCol>
              <span className="authusername">{this.state?.name}</span>
            </BaseCol>
          )}
        </S.ProfileDropdownHeader>
      </BasePopover>
    ) : null;
  }
}

// Wrap and export
export default function (props) {
  //const user = useAppSelector((state) => state.user.user);
  const { isTablet } = useResponsive();
  return <ProfileDropdown {...props} isTablet={isTablet} />;
}

import React, { Component, useState } from 'react';
import { getRecordById, getRecords } from '@app/utils/APIUtils';
import { Descriptions } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useNavigate, useParams } from 'react-router-dom';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import moment from 'moment';

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  return (
    <Customer360View {...props} navigation={navigate} isLoading={isLoading} setLoading={setLoading} params={params} />
  );
}

class Customer360View extends Component {
  render() {
    const AntWrappedOfficeForm = Form.create()(Customer360ViewForm);
    return (
      <div>
        <AntWrappedOfficeForm
          dataId={this.props.params}
          navigate={this.props.navigation}
          isLoading={this.props.isLoading}
          setLoading={this.props.setLoading}
        />
      </div>
    );
  }
}

class Customer360ViewForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      customerId: this.props.dataId.id,
      customerInfo: {},
      pregistartionInfo: {},
      registartionInfo: {},
      pkgPreferencesInfo: {},
      hajYear: '',
      pkgDuration: '',
      makkahHotel: '',
      madinahHotel: '',
      isRequired: false,
      isReadonly: false,
      recordSet: [],
      roomRecSet: [],
      payRecSet: [],
      docRecSet: [],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.editCustomerInfo = this.editCustomerInfo.bind(this);
    this.editCustomerMoreInfo = this.editCustomerMoreInfo.bind(this);
    this.addPreRegistrationInfo = this.addPreRegistrationInfo.bind(this);
    this.editPreRegistrationInfo = this.editPreRegistrationInfo.bind(this);
    this.addRegistrationInfo = this.addRegistrationInfo.bind(this);
    this.editRegistrationInfo = this.editRegistrationInfo.bind(this);
  }

  handlePageChange = (newpage) => {
    this.props.setLoading(true);

    setTimeout(() => {
      this.props.setLoading(false);
    }, 1000);

    this.props.setPage(newpage);
  };

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    });

    let promise1 = getRecordById('/public/v1/customers/', this.state.customerId);
    promise1.then((res) => {
      this.setState({
        customerInfo: res,
      });
    });

    let promise2 = getRecords(`/public/customer/v1/pregistered/${this.state.customerId}/cif`);
    promise2.then((res) => {
      if (res != null) {
        this.setState({
          pregistartionInfo: res,
        });
      }
    });

    let promise3 = getRecords(`/public/customer/v1/registered/${this.state.customerId}/cif`);
    promise3.then((res) => {
      if (res != null) {
        this.setState({
          registartionInfo: res,
          hajYear: res.HajYearBean.ceYear,
        });
      }
    });

    let promise4 = getRecords(`/customer/v1/packages/${this.state.customerId}/list`);
    promise4.then((res) => {
      if (res != null) {
        const recordSet = this.state.recordSet.slice();
        this.setState({
          recordSet: recordSet.concat(res),
        });
      }
    });

    let promise5 = getRecords(`/customer/v1/rooms/${this.state.customerId}/list`);
    promise5.then((res) => {
      if (res != null) {
        const recordSet = this.state.roomRecSet.slice();
        this.setState({
          roomRecSet: recordSet.concat(res),
        });
      }
    });

    let promise6 = getRecords(`/customer/package/v1/preferences/${this.state.customerId}/details`);
    promise6.then((res) => {
      if (res != null) {
        this.setState({
          pkgPreferencesInfo: res,
          pkgDuration: res.Duration.noOfDays,
          makkahHotel: res.MakkahHotel.HotelType,
          madinahHotel: res.MadinaHotel.HotelType,
        });
      }
    });

    let promise7 = getRecords(`/customer/v1/payment/${this.state.customerId}/list`);
    promise7.then((res) => {
      if (res != null) {
        const recordSet = this.state.payRecSet.slice();
        this.setState({
          payRecSet: recordSet.concat(res),
        });
      }
    });

    let promise8 = getRecords(`/customer/v1/documents/${this.state.customerId}/list`);
    promise8.then((res) => {
      if (res != null) {
        const recordSet = this.state.docRecSet.slice();
        this.setState({
          docRecSet: recordSet.concat(res),
        });
      }
    });

    Promise.all([promise1, promise2, promise3, promise4, promise5, promise6, promise7, promise8]);
  }

  editCustomerInfo(id) {
    this.props.navigate(`/customer/edit/${id}`);
  }

  editCustomerMoreInfo(id) {
    this.props.navigate(`/customer/profile/new/${id}`);
  }

  addPreRegistrationInfo() {
    this.props.navigate(`/pre_registration/new`);
  }

  editPreRegistrationInfo(id) {
    this.props.navigate(`/pre_registration/edit/${id}`);
  }

  addRegistrationInfo() {
    this.props.navigate(`/registration/new`);
  }

  editRegistrationInfo(id) {
    this.props.navigate(`/registration/edit/${id}`);
  }

  render() {
    const {
      customerInfo,
      pregistartionInfo,
      registartionInfo,
      //pkgPreferencesInfo,
      recordSet,
      roomRecSet,
      payRecSet,
      docRecSet,
    } = this.state;

    const data = [];
    const roomData = [];
    const payData = [];
    const docData = [];

    const numberFormat = (value) => new Intl.NumberFormat('en-IN').format(value);

    recordSet.forEach((record, recordIndex) => {
      data.push({
        key: recordIndex,
        packageId: record.packageId,
        packageName: record.packageName,
        serviceName: record.serviceName,
        startDate: record.startDate === null ? '' : moment(record.startDate).format('YYYY-MM-DD'),
      });
    });

    roomRecSet.forEach((record, recordIndex) => {
      roomData.push({
        key: recordIndex,
        roomId: record.roomId,
        roomNo: record.roomNo,
        hotelName: record.hotelName,
      });
    });

    payRecSet.forEach((record, recordIndex) => {
      payData.push({
        key: recordIndex,
        packageName: record.pkgName,
        serviceName: record.serviceName,
        amounts: '৳'.concat(' ').concat(numberFormat(record.amount)),
        paidAmount: '৳'.concat(' ').concat(numberFormat(record.paidAmount)),
        dueAmount: '৳'.concat(' ').concat(numberFormat(record.dueAmount)),
      });
    });

    docRecSet.forEach((record, recordIndex) => {
      docData.push({
        key: recordIndex,
        fileId: record.fileId,
        fileType: record.fileType,
        fileName: record.fileName,
      });
    });

    const columns = [
      {
        title: 'ID',
        dataIndex: 'packageId',
        key: 'packageId',
      },
      {
        title: 'Package Name',
        dataIndex: 'packageName',
        key: 'packageName',
      },
      {
        title: 'Service',
        dataIndex: 'serviceName',
        key: 'serviceName',
      },
      {
        title: 'Date',
        dataIndex: 'startDate',
        key: 'startDate',
      },
    ];

    const roomColumns = [
      {
        title: 'Hotel Name',
        dataIndex: 'hotelName',
        key: 'hotelName;',
      },
      {
        title: 'Room ID',
        dataIndex: 'roomId',
        key: 'roomId',
      },
      {
        title: 'Room No',
        dataIndex: 'roomNo',
        key: 'roomNo',
      },
    ];

    const payColumns = [
      {
        title: 'Service',
        dataIndex: 'serviceName',
        key: 'serviceName;',
      },
      {
        title: 'Package',
        dataIndex: 'packageName',
        key: 'packageName',
      },
      {
        title: 'Amount',
        dataIndex: 'amounts',
        key: 'amounts',
      },
      {
        title: 'Paid',
        dataIndex: 'paidAmount',
        key: 'paidAmount',
      },
      {
        title: 'Due',
        dataIndex: 'dueAmount',
        key: 'dueAmount',
      },
    ];

    const docColumns = [
      {
        title: 'File Id',
        dataIndex: 'fileId',
        key: 'fileId',
      },
      {
        title: 'File Name',
        dataIndex: 'fileName',
        key: 'fileName;',
      },
      {
        title: 'File Type',
        dataIndex: 'fileType',
        key: 'fileType',
      },
    ];

    return (
      <BaseCard id="validation form" title="Customer / 360° View" padding="1.25rem">
        <BaseRow gutter={[30, 30]}>
          <BaseCol xs={24} sm={12} xl={12}>
            <BaseCard title="Customer Information" bordered={true} padding="1.25rem">
              <Descriptions bordered size="middle">
                <Descriptions.Item label="Customer Name" span={3}>
                  {customerInfo.customerName}
                </Descriptions.Item>
                <Descriptions.Item label="Profession" span={3}>
                  {customerInfo.profession}
                </Descriptions.Item>
                <Descriptions.Item label="Mobile Number" span={3}>
                  {customerInfo.mobileNumber}
                </Descriptions.Item>
                <Descriptions.Item label="WhatsApp Number" span={3}>
                  {customerInfo.whatsAppNumber}
                </Descriptions.Item>
                <Descriptions.Item label="Email" span={3}>
                  {customerInfo.email}
                </Descriptions.Item>
                <Descriptions.Item label="Date of Birth" span={3}>
                  {customerInfo.dob}
                </Descriptions.Item>
                <Descriptions.Item label="Gender" span={3}>
                  {customerInfo.gender === 1 ? 'Male' : customerInfo.gender === 2 ? 'Female' : ''}
                </Descriptions.Item>
                <Descriptions.Item label="District" span={3}>
                  {customerInfo.district}
                </Descriptions.Item>
                <Descriptions.Item label="Thana" span={3}>
                  {customerInfo.thana}
                </Descriptions.Item>
                <Descriptions.Item label="Tags" span={3}>
                  {customerInfo.tags && customerInfo.tags.join(', ')}
                </Descriptions.Item>
                <Descriptions.Item label="Address" span={3}>
                  {customerInfo.addressLineOne}
                </Descriptions.Item>
              </Descriptions>
              <BaseRow gutter={[10, 10]} wrap={false} style={{ marginTop: 15 }}>
                <BaseCol span={8} xs={12} sm={12} xl={6}>
                  <BaseButton block severity="info" size="small" onClick={() => this.editCustomerInfo(customerInfo.customerId)}>
                    Edit
                  </BaseButton>
                </BaseCol>
              </BaseRow>
            </BaseCard>
          </BaseCol>

          <BaseCol xs={24} sm={12} xl={12}>
            <BaseCard title="Personal Info" bordered={true} padding="1.25rem">
              <Descriptions bordered size="middle">
                <Descriptions.Item label="National Id" span={3}>
                  {customerInfo.nid}
                </Descriptions.Item>
                <Descriptions.Item label="Passport Number" span={3}>
                  {customerInfo.passportNo}
                </Descriptions.Item>
                <Descriptions.Item label="Passport Expiry Date" span={3}>
                  {customerInfo.passportExpiryDate}
                </Descriptions.Item>
                <Descriptions.Item label="Father's Name" span={3}>
                  {customerInfo.fatherName}
                </Descriptions.Item>
                <Descriptions.Item label="Mother's Name" span={3}>
                  {customerInfo.motherName}
                </Descriptions.Item>
                <Descriptions.Item label="Spouse Name" span={3}>
                  {customerInfo.spouseName}
                </Descriptions.Item>
                <Descriptions.Item label="Group Number" span={3}>
                  {customerInfo.groupNumber}
                </Descriptions.Item>
                <Descriptions.Item label="Group Name" span={3}>
                  {customerInfo.groupName}
                </Descriptions.Item>
                <Descriptions.Item label="Mahram Passport Number" span={3}>
                  {customerInfo.mahramPassportNo}
                </Descriptions.Item>
                <Descriptions.Item label="Mahram Mobile Number" span={3}>
                  {customerInfo.mahramMobileNo}
                </Descriptions.Item>
              </Descriptions>
              {customerInfo.nid != null ? (
                <BaseRow gutter={[10, 10]} wrap={false} style={{ marginTop: 15 }}>
                  <BaseCol span={8} xs={12} sm={12} xl={6}>
                    <BaseButton
                      block
                      severity="info"
                      size="small"
                      onClick={() => this.editCustomerMoreInfo(customerInfo.customerId)}
                    >
                      Edit
                    </BaseButton>
                  </BaseCol>
                </BaseRow>
              ) : (
                <BaseRow gutter={[10, 10]} wrap={false} style={{ marginTop: 15 }}>
                  <BaseCol span={8} xs={12} sm={12} xl={6}>
                    <BaseButton
                      block
                      type="primary"
                      size="small"
                      onClick={() => this.editCustomerMoreInfo(customerInfo.customerId)}
                    >
                      Add
                    </BaseButton>
                  </BaseCol>
                </BaseRow>
              )}
            </BaseCard>
          </BaseCol>
        </BaseRow>

        <BaseRow gutter={[30, 30]}>
          <BaseCol xs={24} sm={12} xl={12}>
            <BaseCard title="Pre-Registration Information" bordered={true} style={{ marginTop: 20 }} padding="1.25rem">
              <Descriptions bordered size="middle">
                <Descriptions.Item label="Pre-Registration Date" span={3}>
                  {pregistartionInfo.PreRegDate}
                </Descriptions.Item>
                <Descriptions.Item label="Serial No" span={3}>
                  {pregistartionInfo.PreSLNo}
                </Descriptions.Item>
                <Descriptions.Item label="Tracking No" span={3}>
                  {pregistartionInfo.PreTrackinNo}
                </Descriptions.Item>
                <Descriptions.Item label="Bank Info" span={3}>
                  {pregistartionInfo.BankInfo}
                </Descriptions.Item>
              </Descriptions>
              {pregistartionInfo.PreRegDate != null ? (
                <BaseRow gutter={[10, 10]} wrap={false} style={{ marginTop: 15 }}>
                  <BaseCol span={8} xs={12} sm={12} xl={6}>
                    <BaseButton
                      block
                      severity="info"
                      size="small"
                      onClick={() => this.editPreRegistrationInfo(pregistartionInfo.id)}
                    >
                      {' '}
                      Edit
                    </BaseButton>
                  </BaseCol>
                </BaseRow>
              ) : (
                <BaseRow gutter={[10, 10]} wrap={false} style={{ marginTop: 15 }}>
                  <BaseCol span={8} xs={12} sm={12} xl={6}>
                    <BaseButton
                      block
                      type="primary"
                      size="small"
                      onClick={() => this.addPreRegistrationInfo(pregistartionInfo.id)}
                    >
                      {' '}
                      Add
                    </BaseButton>
                  </BaseCol>
                </BaseRow>
              )}
            </BaseCard>
          </BaseCol>

          <BaseCol xs={24} sm={12} xl={12}>
            <BaseCard title="Registration Information" bordered={true} style={{ marginTop: 20 }} padding="1.25rem">
              <Descriptions bordered size="middle">
                <Descriptions.Item label="Haj Year" span={3}>
                  {this.state.hajYear}
                </Descriptions.Item>
                <Descriptions.Item label="Registration Date" span={3}>
                  {registartionInfo.RegDate}
                </Descriptions.Item>
                <Descriptions.Item label="Pilgrim ID" span={3}>
                  {registartionInfo.PID}
                </Descriptions.Item>
              </Descriptions>
              {registartionInfo.RegDate != null ? (
                <BaseRow gutter={[10, 10]} wrap={false} style={{ marginTop: 15 }}>
                  <BaseCol span={8} xs={12} sm={12} xl={6}>
                    <BaseButton
                      block
                      severity="info"
                      size="small"
                      onClick={() => this.editRegistrationInfo(registartionInfo.id)}
                    >
                      {' '}
                      Edit
                    </BaseButton>
                  </BaseCol>
                </BaseRow>
              ) : (
                <BaseRow gutter={[10, 10]} wrap={false} style={{ marginTop: 15 }}>
                  <BaseCol span={8} xs={12} sm={12} xl={6}>
                    <BaseButton block type="primary" size="small" onClick={() => this.addRegistrationInfo()}>
                      {' '}
                      Add
                    </BaseButton>
                  </BaseCol>
                </BaseRow>
              )}
            </BaseCard>
          </BaseCol>

          <BaseCol xs={24} sm={12} xl={12}>
            <BaseCard title="Package Information" bordered={true} style={{ marginTop: 20 }} padding="1.25rem">
              <BaseTable
                columns={columns}
                dataSource={data}
                pagination={{ pageSize: 5, onChange: (page) => this.handlePageChange(page) }}
                loading={this.props.isLoading}
                scroll={{ x: true }}
                bordered
              />
            </BaseCard>
          </BaseCol>

          <BaseCol xs={24} sm={12} xl={12}>
            <BaseCard title="Room Information" bordered={true} style={{ marginTop: 20 }} padding="1.25rem">
              <BaseTable
                columns={roomColumns}
                dataSource={roomData}
                pagination={{ pageSize: 5, onChange: (page) => this.handlePageChange(page) }}
                loading={this.props.isLoading}
                scroll={{ x: true }}
                bordered
              />
            </BaseCard>
          </BaseCol>

          <BaseCol xs={24} sm={12} xl={12}>
            <BaseCard title="Payment Information" bordered={true} style={{ marginTop: 20 }} padding="1.25rem">
              <BaseTable
                columns={payColumns}
                dataSource={payData}
                pagination={{ pageSize: 5, onChange: (page) => this.handlePageChange(page) }}
                loading={this.props.isLoading}
                scroll={{ x: true }}
                bordered
              />
            </BaseCard>
          </BaseCol>

          <BaseCol xs={24} sm={12} xl={12}>
            <BaseCard title="Document Information" bordered={true} style={{ marginTop: 20 }} padding="1.25rem">
              <BaseTable
                columns={docColumns}
                dataSource={docData}
                pagination={{ pageSize: 5, onChange: (page) => this.handlePageChange(page) }}
                loading={this.props.isLoading}
                scroll={{ x: true }}
                bordered
              />
            </BaseCard>
          </BaseCol>

          {/* <BaseCol xs={24} sm={12} xl={12}>
            <BaseCard title="Package Preferences" bordered={true} padding="1.25rem">
              <Descriptions bordered size="middle">
                <Descriptions.Item label="Total Person" span={3}>
                  {pkgPreferencesInfo.TotalPax}
                </Descriptions.Item>
                <Descriptions.Item label="Package Duration" span={3}>
                  {this.state.pkgDuration}
                </Descriptions.Item>
                <Descriptions.Item label="Hotel in Makkah" span={3}>
                  {this.state.makkahHotel}
                </Descriptions.Item>
                <Descriptions.Item label="Hotel in Madinah" span={3}>
                  {this.state.madinahHotel}
                </Descriptions.Item>
                <Descriptions.Item label="Room Preferences Type" span={3}>
                  {pkgPreferencesInfo.RoomPrefID === 1
                    ? 'Private Room'
                    : pkgPreferencesInfo.RoomPrefID === 2
                    ? 'Shared Room'
                    : pkgPreferencesInfo.RoomPrefID === 3
                    ? 'Family Room'
                    : ''}
                </Descriptions.Item>
              </Descriptions>
            </BaseCard>
          </BaseCol> */}
        </BaseRow>
      </BaseCard>
    );
  }
}

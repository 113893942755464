import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
//import { useTranslation } from 'react-i18next';
//import * as S from './ProfileOverlay.styles';
import { Menu } from 'antd';
import { ACCESS_TOKEN } from 'constants/index';
import { notificationController } from '@app/controllers/notificationController';
import { deleteItemByParam, getCurrentUser } from '@app/utils/APIUtils';

class ProfileOverlay extends React.Component {
  // const { t } = useTranslation();

  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      isAuthenticated: false,
      isLoading: true,
      username: '',
    };
    this.handleLogout = this.handleLogout.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.loadCurrentUser = this.loadCurrentUser.bind(this);
  }

  loadCurrentUser() {
    getCurrentUser()
    .then((response) => {
      this.setState({
        currentUser: response,
        isAuthenticated: true,
        isLoading: false,
        username: response.username,
      });
    }).catch((error) => {
      if(error)
        notificationController.error({
          message: 'Failed! ' + 'User not found',
        });
        this.setState({
          isLoading: false,
        });
    });
  }

  componentDidMount() {
    this.loadCurrentUser();
  }

  handleMenuClick({ key }) {
    if (key === 'logout') {
      // this.props.onLogout();
      this.handleLogout();
    }
  }

  handleLogout(redirectTo = '/auth/login', notificationType = 'success', message = 'Logged out.') {
    //Delete token by username on logout
    deleteItemByParam('/auth/logout?username=' + this.state.username)
    .then((res) => {
      if(res.statusCode === 200){

        localStorage.removeItem(ACCESS_TOKEN);
        this.setState({
          currentUser: null,
          isAuthenticated: false,
        });
        this.props.navigate(redirectTo);
        notificationController[notificationType]({
          message: message,
        });
      }
    }).catch((error) => {
      notificationController.error({
        message: error.message
      })
    })
  }

  render() {
    return (
      <div>
        <Menu selectedKeys={[]}>
          <Menu.Item key="profile">
            <Link to={`/profile/personal-info/${this.state.username}`}>Profile</Link>
          </Menu.Item>
          <Menu.Item key="logout" onClick={this.handleMenuClick}>
            Logout
          </Menu.Item>
        </Menu>
      </div>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  return <ProfileOverlay {...props} navigate={navigate} />;
}

import React, { useState } from 'react';
//import { useTranslation } from 'react-i18next';
//import { BloodScreeningChart } from '../BloodScreeningChart/BloodScreeningChart';
import { PilgrimsByPackageTable } from '../PilgrimsByPackageTable/PilgrimsByPackageTable';
//import { useResponsive } from '@app/hooks/useResponsive';
import { BloodTestResult, results } from '@app/constants/bloodTestResults';
import * as S from './PilgrimsByPackageCard.styles';

export const PilgrimsByPackageCard: React.FC = () => {
  //const { t } = useTranslation();

  const [activeItem, setActiveItem] = useState<BloodTestResult>(results[0]);

  //const { mobileOnly } = useResponsive();

  return (
    <S.PkgScreeningCard
      title={
          <S.TitleWrapper>
            <div>Pilgrims By Package {'(' + new Date().getFullYear() +')'}</div>
            {/* {!!activeItem?.test && <S.ActiveItem>{activeItem.test}</S.ActiveItem>} */}
          </S.TitleWrapper>
      }
      padding={10}
    >
      {/* <BloodScreeningChart data={activeItem.yearData} /> */}
      <PilgrimsByPackageTable activeItem={activeItem} setActiveItem={setActiveItem} />
    </S.PkgScreeningCard>
  );
};

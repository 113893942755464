import React, { Component, useState } from 'react';
import { Space, Radio } from 'antd';
import { ACCESS_TOKEN, API_BASE_URL } from '@app/constants';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';

const FormItem = Form.Item;

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  return <CustomerDocReport {...props} navigation={navigate} isLoading={isLoading} setLoading={setLoading} />;
}

class CustomerDocReport extends Component {
  render() {
    const AntWrappedOfficeForm = Form.create()(CustomerDocReportForm);
    return (
      <div>
        <AntWrappedOfficeForm
          navigate={this.props.navigation}
          isLoading={this.props.isLoading}
          setLoading={this.props.setLoading}
        />
      </div>
    );
  }
}

class CustomerDocReportForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: false,
      reportValue: {
        value: '',
      },
      fileFormat: {
        value: '',
      },
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.clearForm = this.clearForm.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const paramRequest = Object.assign({}, values);
        console.log('paramRequest => ' + JSON.stringify(paramRequest));

        fetch(API_BASE_URL + '/customer/v1/documents/preview', {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
          },
          body: JSON.stringify(paramRequest),
        })
          .then(async function (response) {
            const filename = response.headers
              .get('content-disposition')
              .split(';')
              .find((n) => n.includes('filename='))
              .replace('filename=', '')
              .replace('"', '')
              .replace('"', '')
              .trim();
            console.log(filename);
            if (response.ok) {
              let blob;
              var fileExt = filename.split('.').pop();

              const reportBytes = await response.arrayBuffer();

              if (fileExt === 'pdf') {
                blob = new File([new Uint8Array(reportBytes)], 'CustomerDocument.pdf', { type: 'application/pdf' });
              } else if (fileExt === 'xls') {
                blob = new File([new Uint8Array(reportBytes)], 'CustomerDocument.xls', {
                  type: 'application/vnd.ms-excel',
                });
              }
              const reportUrl = URL.createObjectURL(blob);
              const win = window.open(reportUrl, 'test');
              
              win.history.pushState(null, null, null);

              notificationController.success({
                message: 'Report generated successfully',
              });
            }
          })
          .catch(function (error) {
            console.log('Request failed', error);
          });
      }
    });
  }

  clearForm(event) {
    event.preventDefault();
    this.props.form.resetFields();
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <BaseCol xs={24} sm={24} xl={24}>
        <BaseCard id="validation form" title="Reports > List of Customers By Document" padding="1.25rem">
          <Form
            onSubmit={this.handleSubmit}
            className="report-form"
            name="basic"
            labelCol={{ span: 6 }}
            style={{ maxWidth: 500 }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <FormItem label="Filtering Criteria"></FormItem>

            <FormItem label="Report">
              {getFieldDecorator('reportValue', {
                rules: [{ required: true, message: 'Please select report!' }],
              })(
                <Radio.Group name="reportValue">
                  <Space direction="vertical">
                    <Radio value={1}>Customers with missing documents</Radio>
                    <Radio value={2}>Customers with submitted documents</Radio>
                  </Space>
                </Radio.Group>,
              )}
            </FormItem>

            <FormItem label="Export Option">
              {getFieldDecorator('fileFormat', {
                rules: [{ required: true, message: 'Please select report format!' }],
              })(
                <Radio.Group name="fileFormat">
                  <Radio value="pdf">PDF</Radio>
                  <Radio value="xls">XLS</Radio>
                </Radio.Group>,
              )}
            </FormItem>

            <FormItem
              wrapperCol={{
                offset: 6,
                span: 9,
              }}
            >
              <BaseRow gutter={[10, 10]} wrap={false}>
                <BaseCol span={12} xs={17} sm={12} xl={12}>
                  <BaseButton block type="primary" htmlType="submit" size="small">
                    Submit
                  </BaseButton>
                </BaseCol>
                <BaseCol span={12} xs={17} sm={12} xl={12}>
                  <BaseButton block type="ghost" onClick={this.clearForm} size="small">
                    Reset
                  </BaseButton>
                </BaseCol>
              </BaseRow>
            </FormItem>
          </Form>
        </BaseCard>
      </BaseCol>
    );
  }
}

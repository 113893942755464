import React, { Component, useState } from 'react';
import { getRecordById, updateRecord, getRecords } from '@app/utils/APIUtils';
import { Descriptions, Input, Select } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { handleEnter } from '@app/helper/EnterIndexHelper';

const FormItem = Form.Item;

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  return <EditRoom {...props} navigation={navigate} isLoading={isLoading} setLoading={setLoading} params={params} />;
}

class EditRoom extends Component {
  render() {
    const AntWrappedOfficeForm = Form.create()(EditRoomForm);
    return (
      <div>
        <AntWrappedOfficeForm
          dataId={this.props.params}
          navigate={this.props.navigation}
          isLoading={this.props.isLoading}
          setLoading={this.props.setLoading}
        />
      </div>
    );
  }
}

class EditRoomForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      roomId: this.props.dataId.id,
      roomTypeList: [],
      isRequired: false,
      isReadonly: false,
      hotelInfo: {},
      location: '',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  componentDidMount() {
    let promise2, promise3;

    promise2 = getRecords('/domain/data/roomtypes/v1/all');

    promise2
      .then((response) => {
        this.setState({
          roomTypeList: response.map((item) => ({ value: item.id, label: item.RomTypName })),
        });
        console.log(this.state.packageList);
      })
      .catch((error) => {
        if (error)
          this.setState({
            isLoading: false,
          });
      });

    let promise1 = getRecordById('/setup/v1/rooms/', this.state.roomId);
    promise1.then((response) => {
      this.props.form.setFieldsValue({
        roomId: response.id,
        roomTypeId: response.RoomTypeBean.id,
        roomNo: response.RoomNo,
        totalBeds: response.TotalBed,
        remarks: response.Remarks,
      });

      promise3 = getRecordById('/setup/v1/hotels/', response.HotelBean.id);
      promise3.then((response) => {
        this.setState({
          hotelInfo: response,
          location: response.LocationBean.LocName,
        });
      });
    });

    Promise.all([promise1, promise2, promise3]);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const updateRequest = Object.assign({}, values);
        //updateRequest.referenceStatusId  = 111;
        console.log('updateRequest => ' + JSON.stringify(updateRequest));

        updateRecord(this.state.roomId, updateRequest, '/setup/v1/rooms/')
          .then((response) => {
            if (response)
              notificationController.success({
                message: 'Room updated.',
              });
            this.props.navigate('/room/list');
          })
          .catch((error) => {
            notificationController.error({
              message: 'Failed! ' + error.message,
            });
          });
      }
    });
  }

  onCancelForm(event) {
    event.preventDefault();
    this.props.navigate('/room/list');
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { hotelInfo, location } = this.state;
    return (
      <BaseCard id="validation form" title="Edit Room" padding="1.25rem">
        <BaseRow gutter={[30, 30]}>
          <BaseCol xs={24} sm={20} xl={12}>
            <Form
              onSubmit={this.handleSubmit}
              className="room-form"
              name="basic"
              labelCol={{ span: 6 }}
              style={{ maxWidth: 500 }}
              initialValues={{ remember: true }}
              autoComplete="off"
            >
              <FormItem label="Room Id">
                {getFieldDecorator('roomId', {
                  rules: [{ required: true, message: 'Room id is required!' }],
                })(<Input name="roomId" size="small" disabled={true} />)}
              </FormItem>

              <FormItem label="Room Type">
                {getFieldDecorator('roomTypeId', {
                  rules: [{ required: true, message: 'Room type is required!' }],
                })(
                  <Select
                    showSearch
                    placeholder="Select room type"
                    size="small"
                    name="roomTypeId"
                    onKeyDown={(e) => handleEnter(e)}
                  >
                    {this.state.roomTypeList.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </FormItem>

              <FormItem label="Room No">
                {getFieldDecorator('roomNo', {
                  rules: [{ required: false, message: 'Room no is required!' }],
                })(<Input name="roomNo" placeholder="Enter room no" size="small" onKeyDown={(e) => handleEnter(e)} />)}
              </FormItem>

              <FormItem label="Beds / Room">
                {getFieldDecorator('totalBeds', {
                  rules: [{ required: true, message: 'Beds / room is required!' }],
                })(
                  <Input
                    name="totalBeds"
                    placeholder="Enter beds/room"
                    size="small"
                    onKeyDown={(e) => handleEnter(e)}
                  />,
                )}
              </FormItem>

              <FormItem label="Remarks">
                {getFieldDecorator('remarks', {
                  rules: [{ required: false, message: 'Remarks is required!' }],
                })(<Input name="remarks" placeholder="Enter remarks" size="small" onKeyDown={(e) => handleEnter(e)} />)}
              </FormItem>

              <FormItem
                wrapperCol={{
                  offset: 6,
                  span: 9,
                }}
              >
                <BaseButtonsGroup onCancel={this.onCancelForm} />
              </FormItem>
            </Form>
          </BaseCol>

          <BaseCol xs={24} sm={12} xl={12}>
            <Descriptions title="Hotel Info" bordered size="middle">
              <Descriptions.Item label="Hotel Id" span={3}>
                {hotelInfo.id}
              </Descriptions.Item>
              <Descriptions.Item label="Hotel Name" span={3}>
                {hotelInfo.HotelName}
              </Descriptions.Item>
              <Descriptions.Item label="Hotel Type" span={3}>
                {hotelInfo.HotelType}
              </Descriptions.Item>
              <Descriptions.Item label="Location" span={3}>
                {location}
              </Descriptions.Item>
              <Descriptions.Item label="Address" span={3}>
                {hotelInfo.Address}
              </Descriptions.Item>
            </Descriptions>
          </BaseCol>
        </BaseRow>
      </BaseCard>
    );
  }
}

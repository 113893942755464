import React, { useEffect, useState } from 'react';
//import { useTranslation } from 'react-i18next';
import { useResponsive } from 'hooks/useResponsive';
import * as S from './PilgrimsByPackageTable.styles';
import { BloodTestResult, /* flags, results */ } from '@app/constants/bloodTestResults';
import { ColumnsType } from 'antd/es/table';
import { getRecords } from '@app/utils/APIUtils';


interface PkgScreeningTableProps {
  activeItem: BloodTestResult;
  setActiveItem: (item: BloodTestResult) => void;
}

export const PilgrimsByPackageTable: React.FC<PkgScreeningTableProps> = ({}) => {
  const { isTablet, isDesktop } = useResponsive();
  //const { t } = useTranslation();
  const [dataSource, setDataSource] = useState<BloodTestResult[]>();
  
  useEffect(() => {
    const promise = getRecords('/admin/v1/dashboard/pilgrimsbypackage/list');
    promise.then((res : any) => setDataSource(res));
  }, []);

  const columns: ColumnsType<BloodTestResult> = [
    {
      title: "Package Name",
      dataIndex: 'name',
      width: '75%',
    },
    {
      title: "Total",
      dataIndex: 'result',
      width: '25%',
    }
    /* {
      title: t('hajlink-dashboard.bloodScreening.units'),
      dataIndex: 'units',
      render: (units, { key }) => <S.Text $isActive={activeItem.key === key}>{units}</S.Text>,
    },
    {
      title: t('hajlink-dashboard.bloodScreening.flag'),
      dataIndex: 'flag',
      render: (flag, { key }) => (
        <S.Flag $isNorm={flag === 0} $isActive={activeItem.key === key}>
          {t(flags[flag])}
        </S.Flag>
      ),
    }, */
  ];

  return (
    <S.Table
      size={'small'}
      pagination={false}
      columns={columns}
      dataSource={dataSource}
      rowKey={(record) => record.name}
      scroll={{ y: isDesktop ? 300 : isTablet ? 620 : 400 }}
      rowClassName={(_record, index) => (index % 2 === 0 ? 'table-row-odd' : 'table-row-even')}
    />
  );
};

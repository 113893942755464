import React, { Component, useState } from 'react';
import { saveRecord, checkRecordAvailability } from '@app/utils/APIUtils';
import { FLIGHTNUMBER_MAX_LENGTH } from '@app/constants';
import { Input, /* Select, */ DatePicker, TimePicker, Radio } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import moment from 'moment';
import { handleEnter } from '@app/helper/EnterIndexHelper';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';

const FormItem = Form.Item;

/* const onSearch = (value) => {
  console.log('search:', value);
}; */

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [time, setTime] = useState('12:08');
  return (
    <AddFlight
      {...props}
      navigation={navigate}
      isLoading={isLoading}
      setLoading={setLoading}
      time={time}
      setTime={setTime}
    />
  );
}

class AddFlight extends Component {
  render() {
    const AntWrappedOfficeForm = Form.create()(AddFlightForm);
    return (
      <div>
        <AntWrappedOfficeForm
          navigate={this.props.navigation}
          isLoading={this.props.isLoading}
          setLoading={this.props.setLoading}
          time={this.props.time}
          setTime={this.props.setTime}
        />
      </div>
    );
  }
}

class AddFlightForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flightId: {
        value: '',
      },
      flightNumber: {
        value: '',
      },
      flightDate: {
        value: '',
      },
      flightTime: {
        value: '',
      },
      arrivalDate: {
        value: '',
      },
      arrivalTime: {
        value: '',
      },
      leaderName: {
        value: '',
      },
      groupName: {
        value: '',
      },
      agentId: {
        value: '',
      },
      depFrom: {
        value: '',
      },
      arrivalTo: {
        value: '',
      },
      agentList: [],
      statusItems: [
        { value: 0, label: 'Inactive' },
        { value: 1, label: 'Active' },
      ],
      isRequired: false,
      optionalRequired: false,
      flightDateOpen: false,
      flightTimeOpen: false,
      arrivalDateOpen: false,
      arrivalTimeOpen: false,
      isCascade: true,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isFormInvalid = this.isFormInvalid.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);
    this.validateFlightNumberAvailability = this.validateFlightNumberAvailability.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.handleFlightTypeChange = this.handleFlightTypeChange.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  handleChangeIsCascade = (e) => {
    const { checked } = e.target;
    this.setState({
      isCascade: checked,
    });
  };

  handleSubmit(event) {
    event.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        //const saveRequest = Object.assign({}, values);
        console.log(values.flightType);
        const saveRequest = {
          flightNumber: values.flightNumber,
          flightType: values.flightType,
          depFrom: values.depFrom,
          arrivalTo: values.arrivalTo,
          flightDate: moment(values.flightDate).format('YYYY-MM-DD'),
          flightTime: values.flightTime === undefined ? '' : this.state.flightTime,
          arrivalDate: moment(values.arrivalDate).format('YYYY-MM-DD'),
          arrivalTime: values.arrivalTime === undefined ? '' : this.state.arrivalTime,
          leaderName: values.leaderName,
          groupName: values.groupName,
          agentId: values.agentId,
          isCascade: values.isCascade,
        };
        console.log('saveRequest => ' + JSON.stringify(saveRequest));

        saveRecord(saveRequest, '/setup/v1/flights')
          .then((response) => {
            if (response) {
              notificationController.success({
                message: 'Flight created.',
              });
              this.props.navigate('/flight/list');
            }
          })
          .catch((error) => {
            notificationController.error({
              message: 'Failed! ' + error.message,
            });
          });
      }
    });
  }

  onCancelForm(event) {
    event.preventDefault();
    this.props.navigate('/flight/list');
  }

  isFormInvalid() {
    return !(this.state.flightNumber.validateStatus === 'success');
  }

  onChangeDate = (date, dateString) => {
    console.log(date, dateString);
  };

  handleFlightTypeChange = (e) => {
    try {
      //? 'R' means -> Round Trip
      if (e.target.value === 'R') {
        this.setState({
          isRequired: true,
          optionalRequired: true,
        });
      } else {
        this.setState({
          isRequired: true,
          optionalRequired: true,
        });
      }
    } catch (error) {}
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const format = 'HH:mm';
    return (
      <BaseCol xs={24} sm={24} xl={24}>
        <BaseCard id="validation form" title="Add Flight" padding="1.25rem">
          <Form
            onSubmit={this.handleSubmit}
            className="bus-form"
            name="basic"
            labelCol={{ span: 6 }}
            style={{ maxWidth: 500 }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <FormItem label="Flight Type">
              {getFieldDecorator('flightType', {
                rules: [{ required: true, message: 'Flight type required!' }],
              })(
                <Radio.Group
                  name="flightType"
                  onChange={(e) => this.handleFlightTypeChange(e)}
                  onKeyDown={(e) => handleEnter(e)}
                >
                  <Radio value="D"> Departure</Radio>
                  <Radio value="R"> Return</Radio>
                </Radio.Group>,
              )}
            </FormItem>

            <FormItem
              label="Flight Number"
              hasFeedback
              validateStatus={this.state.flightNumber.validateStatus}
              help={this.state.flightNumber.errorMsg}
            >
              {getFieldDecorator('flightNumber', {
                rules: [{ required: true, message: 'Flight number is required!' }],
              })(
                <Input
                  size="small"
                  name="flightNumber"
                  autoComplete="off"
                  placeholder="Enter flight number"
                  value={this.state.flightNumber.value}
                  onKeyUpCapture={this.validateFlightNumberAvailability}
                  onChange={(event) => this.handleInputChange(event, this.validateFlightNumber)}
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>

            <FormItem label="From">
              {getFieldDecorator('depFrom', {
                rules: [{ required: this.state.isRequired, message: 'From is required!' }],
              })(
                <Input
                  size="small"
                  name="depFrom"
                  autoComplete="off"
                  placeholder="Enter destination from"
                  onKeyDown={(e) => handleEnter(e)}
                  value={this.state.depFrom.value}
                />,
              )}
            </FormItem>

            <FormItem label="To">
              {getFieldDecorator('arrivalTo', {
                rules: [{ required: this.state.isRequired, message: 'To is required!' }],
              })(
                <Input
                  size="small"
                  name="arrivalTo"
                  autoComplete="off"
                  placeholder="Enter destination to"
                  onKeyDown={(e) => handleEnter(e)}
                  value={this.state.arrivalTo.value}
                />,
              )}
            </FormItem>

            <FormItem label="Flight Date">
              {getFieldDecorator('flightDate', {
                rules: [{ required: this.state.isRequired, message: 'Flight date is required!' }],
              })(
                <DatePicker
                  size="small"
                  defaultValue={null}
                  name="flightDate"
                  placeholder="Enter flight date"
                  value={this.state.flightDate.value}
                  onKeyDown={(e) => handleEnter(e)}
                  onChange={this.onChangeDate}
                  format={this.state.flightDateOpen ? 'DDMMYYYY' : 'YYYY-MM-DD'}
                  onOpenChange={(status) => {
                    this.setState({ flightDateOpen: status });
                  }}
                />,
              )}
            </FormItem>

            <FormItem label="Flight Time">
              {getFieldDecorator('flightTime', {
                rules: [{ required: this.state.isRequired, message: 'Flight time is required!' }],
              })(
                <TimePicker
                  size="small"
                  name="flightTime"
                  placeholder="Enter flight time"
                  value={moment(this.state.flightTime.value, format)}
                  onSelect={(value) => {
                    const timeString = moment(value).format('HH:mm');
                    this.setState({ flightTime: timeString });
                    console.log(this.state.flightTime);
                  }}
                  onKeyDown={(e) => handleEnter(e)}
                  format={this.state.flightTimeOpen ? 'HHmm' : format}
                  onOpenChange={(status) => {
                    this.setState({ flightTimeOpen: status });
                  }}
                  onChange={(value) => {
                    const timeString = moment(value).format('HH:mm');
                    this.setState({ flightTime: timeString });
                    console.log(this.state.flightTime);
                  }}
                />,
              )}
            </FormItem>

            <FormItem label="Arrival Date">
              {getFieldDecorator('arrivalDate', {
                rules: [{ required: this.state.optionalRequired, message: 'Arrival date is required!' }],
              })(
                <DatePicker
                  size="small"
                  name="arrivalDate"
                  placeholder="Enter arrival date"
                  value={this.state.arrivalDate.value}
                  onKeyDown={(e) => handleEnter(e)}
                  onChange={this.onChangeDate}
                  format={this.state.arrivalDateOpen ? 'DDMMYYYY' : 'YYYY-MM-DD'}
                  onOpenChange={(status) => {
                    this.setState({ arrivalDateOpen: status });
                  }}
                />,
              )}
            </FormItem>

            <FormItem label="Arrival Time">
              {getFieldDecorator('arrivalTime', {
                rules: [{ required: this.state.optionalRequired, message: 'Arrival time is required!' }],
              })(
                <TimePicker
                  size="small"
                  name="arrivalTime"
                  placeholder="Arrival time"
                  value={moment(this.state.arrivalTime.value, format)}
                  onSelect={(value) => {
                    const timeString = moment(value).format('HH:mm');
                    this.setState({ arrivalTime: timeString });
                    console.log(this.state.arrivalTime);
                  }}
                  onKeyDown={(e) => handleEnter(e)}
                  format={this.state.arrivalTimeOpen ? 'HHmm' : format}
                  onOpenChange={(status) => {
                    this.setState({ arrivalTimeOpen: status });
                  }}
                  onChange={(value) => {
                    const timeString = moment(value).format('HH:mm');
                    this.setState({ arrivalTime: timeString });
                    console.log(this.state.arrivalTime);
                  }}
                />,
              )}
            </FormItem>

            <FormItem label="Leader Name">
              {getFieldDecorator('leaderName', {
                rules: [{ required: false, message: 'Please input leader name!' }],
              })(
                <Input
                  size="small"
                  name="leaderName"
                  autoComplete="off"
                  placeholder="Enter leader name"
                  onKeyDown={(e) => handleEnter(e)}
                  value={this.state.leaderName.value}
                />,
              )}
            </FormItem>

            <FormItem label="Group Name">
              {getFieldDecorator('groupName', {
                rules: [{ required: false, message: 'Please input group name!' }],
              })(
                <Input
                  size="small"
                  name="groupName"
                  autoComplete="off"
                  placeholder="Enter group name"
                  onKeyDown={(e) => handleEnter(e)}
                  value={this.state.groupName.value}
                />,
              )}
            </FormItem>

            <FormItem label="Cascade">
              {getFieldDecorator('isCascade', {
                valuePropName: 'checked',
                initialValue: this.state.isCascade,
                rules: [{ required: false, message: 'Cascade is required!' }],
              })(
                <BaseCheckbox
                  onChange={(e) => this.handleChangeIsCascade(e)}
                  defaultChecked={this.state.isCascade}
                  value={this.state.isCascade}
                  onKeyDown={(e) => handleEnter(e)}
                ></BaseCheckbox>,
              )}
            </FormItem>

            {/* <FormItem label="Agent">
              {getFieldDecorator('agentId', {
                rules: [{ required: true, message: 'Please select agent!' }],
              })(
                <Select
                  showSearch
                  size="small"
                  placeholder="Please select"
                  name="agentId"
                  value={this.state.agentId.value}
                  optionFilterProp="children"
                  onSearch={onSearch}
                  onKeyDown={(e) => handleEnter(e)}
                >
                  {this.state.agentList.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </FormItem> */}

            <FormItem
              wrapperCol={{
                offset: 6,
                span: 9,
              }}
            >
              <BaseButtonsGroup onCancel={this.onCancelForm} />
            </FormItem>
          </Form>
        </BaseCard>
      </BaseCol>
    );
  }

  // Validation Functions
  validateFlightNumber = (flightNumber) => {
    if (!flightNumber) {
      return {
        validateStatus: 'error',
        errorMsg: 'Flight number may not be empty',
      };
    }

    if (flightNumber.length > FLIGHTNUMBER_MAX_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `Flight number is too long (Maximum ${FLIGHTNUMBER_MAX_LENGTH} characters allowed)`,
      };
    }

    return {
      validateStatus: null,
      errorMsg: null,
    };
  };

  validateFlightNumberAvailability() {
    // First check for client side errors in flightNumber
    const flightNumberValue = this.state.flightNumber.value;
    const flightNumberValidation = this.validateFlightNumber(flightNumberValue);

    if (flightNumberValidation.validateStatus === 'error') {
      this.setState({
        flightNumber: {
          value: flightNumberValue,
          ...flightNumberValidation,
        },
      });
      return;
    }

    this.setState({
      flightNumber: {
        value: flightNumberValue,
        validateStatus: 'validating',
        errorMsg: null,
      },
    });

    checkRecordAvailability('/setup/v1/flights/check/flightnumber?flightNumber=', flightNumberValue)
      .then((response) => {
        if (response.available) {
          this.setState({
            flightNumber: {
              value: flightNumberValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
        } else {
          this.setState({
            flightNumber: {
              value: flightNumberValue,
              validateStatus: 'error',
              errorMsg: 'Flight number already exists',
            },
          });
        }
      })
      .catch((error) => {
        if (error)
          // Marking validateStatus as success, Form will be recchecked at server
          this.setState({
            flightNumber: {
              value: flightNumberValue,
              validateStatus: 'success',
              errorMsg: null,
            },
          });
      });
  }
}

import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
//import { useAppSelector } from '@app/hooks/reduxHooks';
import { WithChildrenProps } from '@app/types/generalTypes';
import { ACCESS_TOKEN, /* REFRESH_TOKEN */ } from '@app/constants';
import { getCurrentUser, /* tokenRefresh */ } from '@app/utils/APIUtils';
//import { notificationController } from '@app/controllers/notificationController';
//import { notificationController } from '@app/controllers/notificationController';

type User = {
  enabled?: number;
  username?: string;
};

const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  const [data, setData] = useState<User>();

  const token = localStorage.getItem(ACCESS_TOKEN);
  //const refreshToken  = localStorage.getItem(REFRESH_TOKEN);

  useEffect(() => {
    getCurrentUser().then((res) => 
      setData(res)
    )

    /* const refreshTokenRequest = Object.assign({
      'refreshToken': refreshToken
    });

    console.log("refreshTokenRequest", refreshTokenRequest)
    tokenRefresh(refreshTokenRequest).then((res) => {

      if(res.statusCode === 200){
        //alert("H!")
        return <>{children}</>;
      }

    }).catch((error) => {
      //alert("H2")
      notificationController.error({
        message: error.message,
      });
      localStorage.clear();
        return <Navigate to="/auth/login" replace />;
    }); */
  }, []);

  const url = '/auth/new-password/' + data?.username;

  if (data?.enabled === 0) {
    return <Navigate to={url} replace />;
  }

  return token ? <>{children}</> : <Navigate to="/auth/login" replace />;
};

export default RequireAuth;

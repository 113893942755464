import React, { Component, useState } from 'react';
import { getRecordById, updateRecord, getRecords, searchRecord } from '@app/utils/APIUtils';
import { Input, Select } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';

const FormItem = Form.Item;

const onSearch = (value) => {
  console.log('search:', value);
};

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  return (
    <CustomerPackageEdit
      {...props}
      navigation={navigate}
      isLoading={isLoading}
      setLoading={setLoading}
      params={params}
    />
  );
}

class CustomerPackageEdit extends Component {
  render() {
    const AntWrappedOfficeForm = Form.create()(CustomerPackageEditForm);
    return (
      <div>
        <AntWrappedOfficeForm
          dataId={this.props.params}
          navigate={this.props.navigation}
          isLoading={this.props.isLoading}
          setLoading={this.props.setLoading}
        />
      </div>
    );
  }
}

class CustomerPackageEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      mPackageCustomerId: this.props.dataId.id,
      packageList: [],
      mobileNumber: {
        value: '',
      },
      id: {
        value: '',
      },
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  componentDidMount() {
    let promise1;

    promise1 = getRecords('/setup/packages/list/all');

    promise1
      .then((response) => {
        this.setState({
          packageList: response.map((item) => ({ value: item.id, label: item.packageName })),
        });
        console.log(this.state.packageList);
      })
      .catch((error) => {
        if (error)
          this.setState({
            isLoading: false,
          });
      });

    this.setState({
      isLoading: true,
    });

    let promise2 = getRecordById('/setup/mPackageCustomer/', this.state.mPackageCustomerId);
    promise2.then((res) => {
      this.props.form.setFieldsValue({
        customerId: res.customer.id,
        customerName: res.customer.customerName,
        packageId: res._package.id,
      });

      let promise3 = getRecords(`/customer/pregistered/${res.customer.id}/cif`);
      promise3.then((res) => {
        this.props.form.setFieldsValue({
          groupName: res.groupName,
          groupId: res.groupNumber,
        });
      });
    });

    Promise.all([promise1, promise2]);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const updateRequest = Object.assign({}, values);
        //updateRequest.referenceStatusId  = 111;
        console.log('updateRequest => ' + JSON.stringify(updateRequest));

        updateRecord(this.state.mPackageCustomerId, updateRequest, '/setup/mPackageCustomer/')
          .then((response) => {
            if (response)
              notificationController.success({
                message: 'Customer package updated.',
              });
            this.props.navigate('/m_package_customer/list');
          })
          .catch((error) => {
            notificationController.error({
              message: 'Failed! ' + error.message,
            });
          });
      }
    });
  }

  handleKeyDown(event) {
    if (event.keyCode === 13) {
      console.log('Enter key pressed');
      event.preventDefault();

      this.props.form.validateFields((err, values) => {
        const searchRequest = {
          mobileNumber: values.mobileNumber,
          customerId: values.id,
        };

        console.log('searchRequest => ' + JSON.stringify(searchRequest));

        searchRecord(searchRequest, '/customer/pregistered/info')
          .then((response) => {
            if (response.statusCode === 204) {
              notificationController.info({
                message: 'Customer not found!',
              });
            } else {
              this.props.form.setFieldsValue({
                customerId: response.customer.id,
                customerName: response.customer.customerName,
                groupName: response.groupName,
                groupId: response.groupNumber,
              });
            }

            console.log(response.statusCode);
          })
          .catch((error) => {
            notificationController.error({
              message: 'Failed! ' + error.message,
            });
          });
      });
    }
  }

  onCancelForm(event) {
    event.preventDefault();
    this.props.form.resetFields();
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <BaseCard id="validation form" title="Edit Map Customer Packages" padding="1.25rem">
        <BaseRow gutter={[30, 30]}>
          <BaseCol xs={24} sm={20} xl={12}>
            <Form
              className="payment-form"
              name="basic"
              labelCol={{ span: 7 }}
              style={{ maxWidth: 500 }}
              initialValues={{ remember: true }}
              autoComplete="off"
            >
              <div className="label" style={{ marginBottom: 10 }}>
                <span style={{ fontSize: 18, fontWeight: 600 }}>Enter mobile number or customer Id</span>
              </div>

              <FormItem label="Mobile Number">
                {getFieldDecorator('mobileNumber', {
                  rules: [{ required: false, message: 'Mobile number is required!' }],
                })(
                  <Input
                    name="mobileNumber"
                    placeholder="Enter mobile number"
                    value={this.state.mobileNumber.value}
                    onKeyDown={this.handleKeyDown}
                    readOnly
                  />,
                )}
              </FormItem>

              <FormItem label="Customer ID">
                {getFieldDecorator('id', {
                  rules: [{ required: false, message: 'Customer id is required!' }],
                })(
                  <Input
                    name="id"
                    placeholder="Enter customer id"
                    value={this.state.id.value}
                    onKeyDown={this.handleKeyDown}
                    readOnly
                  />,
                )}
              </FormItem>
            </Form>
          </BaseCol>
          <BaseCol xs={24} sm={20} xl={12}>
            <Form
              onSubmit={this.handleSubmit}
              className="pre-registration-form"
              name="basic"
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 15 }}
              style={{ maxWidth: 800 }}
              initialValues={{ remember: true }}
              autoComplete="off"
            >
              <FormItem label="Customer Id">
                {getFieldDecorator('customerId', {
                  rules: [{ required: true, message: 'Customer id is required!' }],
                })(<Input name="customerId" placeholder="Enter customer id" readOnly />)}
              </FormItem>

              <FormItem label="Customer Name">
                {getFieldDecorator('customerName', {
                  rules: [{ required: true, message: 'Customer name is required!' }],
                })(<Input name="customerName" placeholder="Enter customer name" readOnly />)}
              </FormItem>

              <FormItem label="Group Name">
                {getFieldDecorator('groupName', {
                  rules: [{ required: true, message: 'Group name is required!' }],
                })(<Input name="groupName" placeholder="Enter group name" readOnly />)}
              </FormItem>

              <FormItem label="Group Id">
                {getFieldDecorator('groupId', {
                  rules: [{ required: true, message: 'Group id is required!' }],
                })(<Input name="groupId" placeholder="Enter group id" readOnly />)}
              </FormItem>

              <FormItem label="Package">
                {getFieldDecorator('packageId', {
                  rules: [{ required: true, message: 'Package is required!' }],
                })(
                  <Select
                    showSearch
                    placeholder="Select package"
                    name="packageId"
                    optionFilterProp="children"
                    onSearch={onSearch}
                  >
                    {this.state.packageList.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </FormItem>

              <FormItem
                wrapperCol={{
                  offset: 7,
                  span: 9,
                }}
              >
                <BaseButtonsGroup onCancel={this.onCancelForm} />
              </FormItem>
            </Form>
          </BaseCol>
        </BaseRow>
      </BaseCard>
    );
  }
}

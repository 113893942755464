import React /* useState, */ /* useEffect */ from 'react';
//import { useTranslation } from 'react-i18next';
import { User } from '@app/domain/User';
import * as S from './ProfileInfo.styles';
import { useTranslation } from 'react-i18next';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';
import UserImage from '@app/components/common/icons/user.png';
import { notificationController } from '@app/controllers/notificationController';
import { Button } from 'antd';
import { updateFormData } from '@app/utils/APIUtils';
import { useParams } from 'react-router-dom';
import UploadOutlined from '@ant-design/icons/lib/icons/UploadOutlined';

interface ProfileInfoProps {
  profileData: User | null;
}

export const ProfileInfo: React.FC<ProfileInfoProps> = ({ profileData }) => {
  //const [fullness] = useState(90);

  //const { t } = useTranslation();
  const [isLoading, setLoading] = React.useState(false);
  const params = useParams();
  const { t } = useTranslation();
  const [prevThumbnail, setPrevThumbnail] = React.useState(String);
  const [thumbnail, setThumbnail] = React.useState(String);
  const [showBtn, setShowBtn] = React.useState(true);

  const onThumbChangeHandler = (event: any) => {
    const uploadedFile = event.target.files[0];
    const fileUrl = URL.createObjectURL(uploadedFile);

    setPrevThumbnail(fileUrl);
    setShowBtn(false);

    if (!uploadedFile) {
      return '';
    }
    setThumbnail(uploadedFile);
    const fileSizeKiloBytes = uploadedFile.size / 1024;
    if (fileSizeKiloBytes > 2048) {
      event.target.value = null;
      notificationController.warning({
        message: 'File size is greater than maximum limit (2MB)',
      });
      setPrevThumbnail('');
      setShowBtn(true);
    }
  };

  /*  useEffect(() => {
    setPrevThumbnail(UserImage);
  }, []); */

  const handleUpload = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('thumbnail', thumbnail);
    updateFormData(formData, `/sa/users/thumbnail/${params.username}`)
      .then((response) => {
        if (response.statusCode === 200) {
          notificationController.success({
            message: t('User thumbnail updated'),
          });
        }
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
      });

    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      //navinate("/profile/personal-info/" + `${params.username}`);
      window.location.reload();
    }, 1000);
  };

  return profileData ? (
    <S.Wrapper>
      <label htmlFor="upload-button">
        <S.ImgWrapper>
          <BaseAvatar
            shape="circle"
            src={prevThumbnail != '' ? prevThumbnail : `data:image/png;base64,${profileData?.thumbnail}`}
            alt="Profile"
            icon={<img src={UserImage} alt="default profile img" />}
            style={{ height: 164, width: 164 }}
          />
          <input
            type="file"
            name="thumbnail"
            id="upload-button"
            onChange={onThumbChangeHandler}
            style={{ display: 'none' }}
            accept='image/png, image/jpeg'
          />
        </S.ImgWrapper>
        <Button
          hidden={showBtn}
          size="small"
          type="primary"
          onClick={handleUpload}
          loading={isLoading}
          icon={<UploadOutlined />}
        >
          Upload
        </Button>
      </label>
      <S.Title>{profileData?.name}</S.Title>
      <S.Subtitle>{profileData?.agentName}</S.Subtitle>
      <S.Subtitle>
        {
          profileData?.agentType === 1 ? 'LEAD_AGENT' :
          profileData?.agentType === 2 ? 'AGENT' :
          profileData?.agentType === 3 ? 'SUB_AGENT' :
          profileData?.agentType === 4 ? 'INDIVIDUAL' : profileData?.rolename
        }
      </S.Subtitle>
      {/* <S.FullnessWrapper>
        <S.FullnessLine width={fullness}>{fullness}%</S.FullnessLine>
      </S.FullnessWrapper>
      <S.Text>{t('profile.fullness')}</S.Text> */}
    </S.Wrapper>
  ) : null;
};

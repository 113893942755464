import React  from 'react';
import * as S from '@app/components/medical-dashboard/bloodScreeningCard/PilgrimsByPackageCard/PilgrimsByPackageCard.styles';
import { PilgrimsByAllainceTable } from './PilgrimsByAllainceTable';

export const PilgrimsByAllainceCard: React.FC = () => {

  return (
    <S.PkgScreeningCard
      title={
        
          <S.TitleWrapper>
            <div>Pilgrims By Allaince {'(' + new Date().getFullYear() +')'}</div>
            {/* {!!activeItem?.test && <S.ActiveItem>{activeItem.test}</S.ActiveItem>} */}
          </S.TitleWrapper>
      }
      padding={10}
    >
      {/* <BloodScreeningChart data={activeItem.yearData} /> */}
      <PilgrimsByAllainceTable />
    </S.PkgScreeningCard>
  );
};

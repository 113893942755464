import React, { Component, useState } from 'react';
import { getRecordById, getRecords, updateFormRecord } from '@app/utils/APIUtils';
import { Input, Select, DatePicker, Descriptions, Form } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import Moment from 'moment';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { handleEnter } from '../../helper/EnterIndexHelper';
import { MAX_FILE_SIZE } from '@app/constants';

const FormItem = Form.Item;

const onSearch = (value) => {
  console.log('search:', value);
};

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();
  return (
    <AddCustomerMoreInfo
      {...props}
      navigation={navigate}
      isLoading={isLoading}
      setLoading={setLoading}
      params={params}
      form={form}
    />
  );
}

class AddCustomerMoreInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      customerId: this.props.params.id,
      genderTypeItems: [
        { value: 1, label: 'Male' },
        { value: 2, label: 'Female' },
      ],
      isFeaturd: false,
      customerInfo: {},
      isRequired: false,
      image: null,
      previewImage: null,
      dobOpen: false,
      ppExpDateOpen: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);
    this.handleGenderChange = this.handleGenderChange.bind(this);
    this.onImageChangeHandler = this.onImageChangeHandler.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  onImageChangeHandler = (event) => {
    const uploadedFile = event.target.files[0];

    const fileUrl = URL.createObjectURL(uploadedFile);

    this.setState({
      previewImage: fileUrl,
    });

    if (!uploadedFile) {
      return '';
    }

    this.setState({
      image: uploadedFile,
    });

    const fileSizeKiloBytes = uploadedFile.size / 1024;

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      event.target.value = null;

      notification.warning({
        message: 'File Size',
        description: 'File size is greater than maximum limit (2MB)',
      });
    }
  };

  componentDidMount() {
    let promise1;

    this.setState({
      isLoading: true,
    });

    promise1 = getRecordById('/public/v1/customers/', this.state.customerId);
    promise1.then((res) => {
      this.props.form.setFieldsValue({
        customerId: res.customerId,
        nationalId: res.nid,
        dateOfBirth: res.dob == null ? '' : Moment(res.dob),
        ageCategory: res.ageCategory === null ? '' : res.ageCategory,
        fatherName: res.fatherName === null ? '' : res.fatherName,
        motherName: res.motherName === null ? '' : res.motherName,
        spouseName: res.spouseName === null ? '' : res.spouseName,
        gender: res.gender,
        permanentAddress: res.permanentAddress,
        passportNo: res.passportNo,
        passportExpiryDate: res.passportExpiryDate == null ? '' : Moment(res.passportExpiryDate),
        birthCertificate: res.birthCertificate === null ? '' : res.birthCertificate,
        smartCard: res.smartCard === null ? '' : res.smartCard,
        mahramPassportNo: res.mahramPassportNo === null ? '' : res.mahramPassportNo,
        mahramMobileNo: res.mahramMobileNo === null ? '' : res.mahramMobileNo,
      });

      this.setState({
        customerInfo: res,
        image: res.image,
      });

      if (res.gender === 2) {
        this.setState({
          isRequired: true,
        });
      } else {
        this.setState({
          isRequired: false,
        });
      }
    });

    Promise.all([promise1]);
  }

  handleSubmit(values) {
    let formData = new FormData();
    formData.append('nationalId', values.nationalId);
    formData.append('dateOfBirth', Moment(values.dateOfBirth).format('YYYY-MM-DD'));
    formData.append('fatherName', values.fatherName);
    formData.append('ageCategory', values.ageCategory);
    formData.append('motherName', values.motherName);
    formData.append('spouseName', values.spouseName);
    formData.append('gender', values.gender);
    formData.append('permanentAddress', values.permanentAddress);
    formData.append('passportNo', values.passportNo);
    formData.append('passportExpiryDate', Moment(values.passportExpiryDate).format('YYYY-MM-DD'));
    formData.append('birthCertificate', values.birthCertificate);
    formData.append('smartCard', values.smartCard);
    formData.append('mahramPassportNo', values.mahramPassportNo);
    formData.append('mahramMobileNo', values.mahramMobileNo);
    formData.append('image', this.state.image);

    updateFormRecord(this.state.customerId, formData, '/public/v1/customers/')
      .then((response) => {
        if (response)
          notificationController.success({
            message: 'Profile updated.',
          });
        this.props.navigation('/customer/list');
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
      });
  }

  handleGenderChange = (value) => {
    try {
      if (value !== 1) {
        this.setState({
          isRequired: true,
        });
      } else {
        this.setState({
          isRequired: false,
        });
      }
    } catch (error) {}
  };

  onCancelForm(event) {
    event.preventDefault();
    this.props.navigation('/customer/list');
  }

  onChangeDateOfBirth = (date, dateString) => {
    const dateOfBirth = dateString;
    if (dateOfBirth !== '') {
      let promise1 = getRecords(`/public/v1/customers/age/${dateOfBirth}`);
      promise1
        .then((response) => {
          this.props.form.setFieldsValue({
            ['ageCategory']: response,
          });
        })
        .catch((error) => {
          this.props.form.resetFields(['ageCategory']);
          notificationController.error({
            message: error.message,
          });
          this.props.setLoading(false);
        });

      console.log(date, dateString);
    } else {
      this.props.form.resetFields(['ageCategory']);
    }
  };

  onChangePassportExpiryDate = (date, dateString) => {
    console.log(date, dateString);
  };

  render() {
    const { customerInfo } = this.state;

    return (
      <BaseCard id="validation form" title="Add Customer Profile Info" padding="1.25rem">
        <BaseRow gutter={[30, 30]}>
          <BaseCol xs={24} sm={12} xl={12}>
            <Form
              onFinish={this.handleSubmit}
              className="customer-form"
              name="customerProfile"
              labelCol={{ span: 8 }}
              style={{ maxWidth: 500 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              form={this.props.form}
            >
              <FormItem
                name="customerId"
                label="Customer ID"
                rules={[{ required: true, message: 'Customer id is required!' }]}
                className="ant-latest-form-item"
              >
                <Input size="small" disabled={true} />
              </FormItem>

              <FormItem
                name="nationalId"
                label="National Id"
                className="ant-latest-form-item"
                rules={[
                  {
                    required: true,
                    message: 'NID is required!',
                  },
                  {
                    pattern: /^(?:\d*)$/,
                    message: 'Only number allowed',
                  },
                ]}
              >
                <Input size="small" placeholder="Enter NID number" onKeyDown={(e) => handleEnter(e)} />
              </FormItem>

              <FormItem
                name="birthCertificate"
                label="Birth Certificate"
                rules={[{ required: false, message: 'Birth certificate is required!' }]}
                className="ant-latest-form-item"
              >
                <Input placeholder="Enter birth certificate number" onKeyDown={(e) => handleEnter(e)} />
              </FormItem>

             {/*  <FormItem
                name="smartCard"
                label="Smart Card"
                rules={[{ required: false, message: 'Smart card no is required!' }]}
                className="ant-latest-form-item"
              >
                <Input size="small" placeholder="Enter smart card number" onKeyDown={(e) => handleEnter(e)} />
              </FormItem> */}

              <Form.Item label="Date of Birth" className="ant-latest-form-item" required>
                <Input.Group compact>
                  <Form.Item
                    noStyle
                    name="dateOfBirth"
                    label="Date Of Birth"
                    rules={[{ required: true, message: 'Date Of Birth is required!' }]}
                    className="ant-latest-form-item"
                  >
                    <DatePicker
                      size="small"
                      style={{ width: '45%', marginRight: '15px' }}
                      placeholder="Select DOB"
                      onChange={this.onChangeDateOfBirth}
                      onKeyDown={(e) => handleEnter(e)}
                      format={this.state.dobOpen ? 'DDMMYYYY' : 'YYYY-MM-DD'}
                      onOpenChange={(status) => {
                        this.setState({ dobOpen: status });
                      }}
                    />
                  </Form.Item>
                  <Form.Item name="ageCategory" label="Age Category" className="ant-latest-form-item" noStyle>
                    <Input
                      style={{ width: '50%' }}
                      size="small"
                      placeholder="Age category"
                      onKeyDown={(e) => handleEnter(e)}
                      readOnly={true}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>

              <FormItem
                name="image"
                label="Upload Image"
                rules={[{ required: false, message: 'Please Choose a image!' }]}
                className="ant-latest-form-item"
              >
                <Input
                  type="file"
                  size="small"
                  onChange={this.onImageChangeHandler}
                  onKeyDown={(e) => handleEnter(e)}
                />

                {this.state.previewImage ? (
                  <div>
                    <img
                      src={this.state.previewImage}
                      alt="Preview image"
                      style={{
                        height: 120,
                        width: 120,
                        marginTop: 10,
                      }}
                    />
                  </div>
                ) : null}
                {this.state.previewImage === null && this.state.image != null ? (
                  <div>
                    <img
                      src={`data:image/png;base64,${this.state.image}`}
                      alt="Uploaded image"
                      style={{
                        height: 120,
                        width: 120,
                        marginTop: 10,
                      }}
                    />
                  </div>
                ) : null}
              </FormItem>

              <FormItem
                name="fatherName"
                label="Father's Name"
                rules={[{ required: true, message: "Father's name is required!" }]}
                className="ant-latest-form-item"
              >
                <Input size="small" placeholder="Enter father's name" onKeyDown={(e) => handleEnter(e)} />
              </FormItem>

              <FormItem
                name="motherName"
                label="Mother's Name"
                rules={[{ required: true, message: 'Mother name is required!' }]}
                className="ant-latest-form-item"
              >
                <Input
                  size="small"
                  placeholder="Enter mother name"
                  onKeyDown={(e) => handleEnter(e)}
                  className="ant-latest-form-item"
                />
              </FormItem>

              <FormItem
                name="spouseName"
                label="Spouse Name"
                rules={[{ required: false, message: 'Spouse name is required!' }]}
                className="ant-latest-form-item"
              >
                <Input size="small" placeholder="Enter spouse name" onKeyDown={(e) => handleEnter(e)} />
              </FormItem>

              <FormItem
                name="gender"
                label="Gender"
                rules={[{ required: true, message: 'Gender is required!' }]}
                className="ant-latest-form-item"
              >
                <Select
                  showSearch
                  placeholder="Select Gender"
                  size="small"
                  onChange={this.handleGenderChange}
                  onKeyDown={(e) => handleEnter(e)}
                  optionFilterProp="children"
                  onSearch={onSearch}
                >
                  {this.state.genderTypeItems.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>

              <FormItem
                name="permanentAddress"
                label="Permanent Address"
                rules={[{ required: true, message: 'Permanent Address is required!' }]}
                className="ant-latest-form-item"
              >
                <Input size="small" placeholder="Enter permanent address" onKeyDown={(e) => handleEnter(e)} />
              </FormItem>

              <FormItem
                name="passportNo"
                label="Passport No"
                rules={[{ required: true, message: 'Passport no is required!' }]}
                className="ant-latest-form-item"
              >
                <Input size="small" placeholder="Enter passport no" onKeyDown={(e) => handleEnter(e)} />
              </FormItem>

              <FormItem
                name="passportExpiryDate"
                label="PP Expiry Date"
                className="ant-latest-form-item"
                rules={[
                  {
                    required: true,
                    message: 'Passport expiry date is required!',
                  }, // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  /* ({ getFieldValue }) => ({
                    async validator(_, value) {
                      const ppExpiryDt = Moment(value).format('YYYY-MM-DD');
                      await validatePassportExpiryDate(ppExpiryDt);
                    },
                  }), */
                ]}
              >
                <DatePicker
                  size="small"
                  placeholder="Select Date"
                  onChange={this.onChangePassportExpiryDate}
                  onKeyDown={(e) => handleEnter(e)}
                  format={this.state.ppExpDateOpen ? 'DDMMYYYY' : 'YYYY-MM-DD'}
                  onOpenChange={(status) => {
                    this.setState({ ppExpDateOpen: status });
                  }}
                />
              </FormItem>

              <FormItem
                name="mahramPassportNo"
                label="Mahram Reference"
                rules={[{ required: this.state.isRequired, message: 'Mahram reference no is required!' }]}
                className="ant-latest-form-item"
              >
                <Input size="small" placeholder="Enter mahram reference info" onKeyDown={(e) => handleEnter(e)} />
              </FormItem>

              <FormItem
                name="mahramMobileNo"
                label="Emergency Mobile No"
                rules={[{ required: this.state.isRequired, message: 'Mahram mobile no is required!' }]}
                className="ant-latest-form-item"
              >
                <Input
                  size="small"
                  placeholder="Enter mahram mobile no"
                  maxLength={11}
                  onKeyDown={(e) => handleEnter(e)}
                />
              </FormItem>

              <FormItem
                wrapperCol={{
                  offset: 8,
                  span: 9,
                }}
              >
                <BaseButtonsGroup onCancel={this.onCancelForm} />
              </FormItem>
            </Form>
          </BaseCol>

          <BaseCol xs={24} sm={12} xl={12}>
            <Descriptions title="Customer Details" bordered size="middle">
              <Descriptions.Item label="Customer Name" span={3}>
                {customerInfo.customerName}
              </Descriptions.Item>
              <Descriptions.Item label="Mobile Number" span={3}>
                {customerInfo.mobileNumber}
              </Descriptions.Item>
              <Descriptions.Item label="Email" span={3}>
                {customerInfo.email}
              </Descriptions.Item>
              <Descriptions.Item label="Address" span={3}>
                {customerInfo.addressLineOne}
              </Descriptions.Item>
              <Descriptions.Item label="District" span={3}>
                {customerInfo.district}
              </Descriptions.Item>
              <Descriptions.Item label="Thana" span={3}>
                {customerInfo.thana}
              </Descriptions.Item>
            </Descriptions>
          </BaseCol>
        </BaseRow>
      </BaseCard>
    );
  }
}

/* const validatePassportExpiryDate = (ppExpiryDt) => {
  // Check for server side validation for ppExpiryDt
  return new Promise(async (resolve, reject) => {
    await checkRecordAvailability('/public/v1/customers/checkPassportExpiryDate?passportExpiryDate=', ppExpiryDt).then(
      (response) => {
        if (response.available) {
          return reject('Passport expiry date is less then hajj year date!');
        } else {
          return resolve(response);
        }
      },
    );
  });
}; */

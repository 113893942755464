import React from 'react';
//import { useTranslation } from 'react-i18next';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from '@app/components/medical-dashboard/statisticsCards/statisticsCard/StatisticsCard/StatisticsCard.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import * as SS from '@app/components/medical-dashboard/statisticsCards/statisticsCard/StatisticsProgress/StatisticsProgress.styles';
import * as ST from '@app/components/medical-dashboard/statisticsCards/statisticsCard/StatisticsInfo/StatisticsInfo.styles';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { Divider } from 'antd';

interface StatisticsCardProps {
  id: string;
  name: string;
  total: number;
  byToday: number;
  thisMonth: number;
  thisYear: number;
}

export const PaymentStatCard: React.FC<StatisticsCardProps> = ({ id, name, total, byToday, thisMonth, thisYear }) => {
  //const theme = useAppSelector((state) => state.theme.theme);
  const { isTablet: isTabletOrHigher } = useResponsive();

  const numberFormat = (value: number | bigint) => new Intl.NumberFormat('en-IN').format(value);

  /*.concat(' ').concat('৳');*/

  //const { t } = useTranslation();

  return (
    <S.StatisticCard /* padding="0.5rem" */ $color={'primary'} id={id} title={name}>
      <BaseRow wrap={false} gutter={[isTabletOrHigher ? 10 : 1, 0]}>
        <BaseCol flex={1}>
          <BaseRow justify="space-between" wrap={false}>
            <BaseCol>
              <BaseSpace direction="vertical" size={19}>
                <BaseRow>
                  <ST.Text style={{ color: '#000000' }}>Total</ST.Text>
                </BaseRow>

                <BaseRow>
                  <SS.ValueText>{'৳'.concat(' ').concat(numberFormat(total))}</SS.ValueText>
                </BaseRow>
              </BaseSpace>
            </BaseCol>
            <Divider type="vertical" style={{ height: 100, borderLeft: '2px solid white' }} />
            <BaseCol>
              <BaseSpace direction="vertical" size={19}>
                <BaseRow justify="space-between" wrap={false}>
                  <BaseCol>
                    <BaseRow>
                      <BaseCol className="rightContent" style={{ marginRight: 100 }}>
                        <BaseSpace direction="vertical" size={19}>
                          <ST.Text style={{ color: '#000000' }}>Today</ST.Text>

                          <ST.Text style={{ color: '#000000' }}>This Month</ST.Text>

                          <ST.Text style={{ color: '#000000' }}>This Year</ST.Text>
                        </BaseSpace>
                      </BaseCol>
                      <BaseCol>
                        <BaseSpace direction="vertical" size={19}>
                          <ST.Text style={{ fontWeight: 600 }}>{'৳'.concat(' ').concat(numberFormat(byToday))}</ST.Text>

                          <ST.Text style={{ fontWeight: 600 }}>
                            {'৳'.concat(' ').concat(numberFormat(thisMonth))}
                          </ST.Text>

                          <ST.Text style={{ fontWeight: 600 }}>
                            {'৳'.concat(' ').concat(numberFormat(thisYear))}
                          </ST.Text>
                        </BaseSpace>
                      </BaseCol>
                    </BaseRow>
                  </BaseCol>
                </BaseRow>
              </BaseSpace>
            </BaseCol>
          </BaseRow>
        </BaseCol>
      </BaseRow>
    </S.StatisticCard>
  );
};

import { ImportOutlined, UploadOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput.styles';
import { Form, Input } from 'antd';
import React, { useState } from 'react';
import { saveRecord } from '@app/utils/APIUtils';
import * as ST from '@app/pages/uiComponentsPages/UIComponentsPage.styles';
import * as S from '@app/components/tables/Tables/Tables.styles';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { notificationController } from '@app/controllers/notificationController';
import Papa from 'papaparse';
import { useNavigate } from 'react-router-dom';

function ImportPackageRecord() {
  const [file, setFile] = useState();
  const [recordSet, setRecordSet] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [searchedText, setsearchedText] = useState('');
  const navigate = useNavigate();
  const data = [];

  const handleOnChange = (e) => {
    e.preventDefault();
    setFile(e.target.files[0]);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          console.log(results.data);

          results.data.forEach((record, recordIndex) => {
            data.push({
              serial: recordIndex + 1,
              serviceId: record.serviceId,
              serviceName: record.serviceName,
              packageName: record.packageName,
              packageTypeId: record.packageTypeId,
              packageType: record.packageType,
              durationId: record.durationId,
              duration: record.duration,
              shortDescription: record.shortDescription,
              packageDetails: record.packageDetails,
              recordStat: record.recordStat,
            });
          });

          setRecordSet(data);
        },
      });

      setDisabled(false);
    } else if (file === undefined) {
      notificationController.warning({
        message: 'Please choose a file',
      });
      setRecordSet([]);
      setDisabled(true);
    }
  };

  const columns = [
    {
      title: 'Index',
      dataIndex: 'serial',
      key: 'serial',
      width: '10%',
    },
    {
      title: 'Service Name',
      dataIndex: 'serviceName',
      key: 'serviceName',
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          String(record.serviceName).toLowerCase().includes(value.toLowerCase()) ||
          String(record.packageName).toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      title: 'Package Name',
      dataIndex: 'packageName',
      key: 'packageName;',
    },
    {
      title: 'Package Type',
      dataIndex: 'packageType',
      key: 'packageType;',
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration;',
    },
    {
      title: 'Short Description',
      dataIndex: 'shortDescription',
      key: 'shortDescription',
    },
    {
      title: 'Package Details',
      dataIndex: 'packageDetails',
      key: 'packageDetails',
    },
  ];

  const handleUpload = (event) => {
    event.preventDefault();

    const savePackageRequest = recordSet;

    console.log('saveRequest => ' + JSON.stringify(savePackageRequest));

    saveRecord(savePackageRequest, '/setup/v1/packages/import/csv')
      .then((response) => {
        if (response) {
          notificationController.success({
            message: 'Package created.',
          });
          navigate('/package/list');
        }
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
      });
  };

  const onCancelForm = (event) => {
    event.preventDefault();
    navigate('/package/list');
  };

  return (
    <div className="page-Container">
      <S.Card id="basic-table" title="Import Package CSV" padding="1.25rem 1.25rem 0">
        <Form id="fileUploadForm" style={{ position: 'absolute', left: '35%' }}>
          <Form.Item>
            <Input type={'file'} id={'csvFileInput'} accept={'.csv'} onChange={handleOnChange} allowClear />
          </Form.Item>

          <BaseSpace style={{ position: 'absolute', left: '15%' }}>
            <BaseButton
              type="primary"
              onClick={(e) => {
                handleOnSubmit(e);
              }}
              size="small"
              icon={<ImportOutlined />}
            >
              Import CSV
            </BaseButton>

            <BaseButton
              type="info"
              severity="info"
              onClick={(e) => {
                onCancelForm(e);
              }}
              size="small"
            >
              Cancel
            </BaseButton>
          </BaseSpace>
        </Form>

        <br />
        <br />
        <br />
        <br />
        <br />

        <div className="search-box" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ST.InputsWrapper>
            <SearchInput
              placeholder="Search..."
              onSearch={(value) => {
                setsearchedText(value);
              }}
              onChange={(e) => {
                setsearchedText(e.target.value);
              }}
              style={{
                width: 230,
                marginBottom: 10,
              }}
              allowClear
              size="small"
            />
          </ST.InputsWrapper>
          <div className="uploadBtn">
            <BaseButton
              htmlType="submit"
              type="primary"
              size="small"
              icon={<UploadOutlined />}
              disabled={disabled}
              onClick={handleUpload}
            >
              Upload
            </BaseButton>
          </div>
        </div>

        <BaseTable
          columns={columns}
          dataSource={recordSet}
          bordered
          key={(record) => record.key}
          scroll={{ x: true }}
          rowClassName={(_record, index) => (index % 2 === 0 ? 'table-row-odd' : 'table-row-even')}
        />
      </S.Card>
    </div>
  );
}

export default ImportPackageRecord;

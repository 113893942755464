import React, { Component, useState } from 'react';
import { getRecordById, updateRecord } from '@app/utils/APIUtils';
import { Input, Descriptions, DatePicker } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import Moment from 'moment';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { handleEnter } from '@app/helper/EnterIndexHelper';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';

const FormItem = Form.Item;

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  return (
    <EditPreRegistration
      {...props}
      navigation={navigate}
      isLoading={isLoading}
      setLoading={setLoading}
      params={params}
    />
  );
}

class EditPreRegistration extends Component {
  render() {
    const AntWrappedOfficeForm = Form.create()(EditPreRegistrationForm);
    return (
      <div>
        <AntWrappedOfficeForm
          dataId={this.props.params}
          navigate={this.props.navigation}
          isLoading={this.props.isLoading}
          setLoading={this.props.setLoading}
        />
      </div>
    );
  }
}

class EditPreRegistrationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      preRegId: this.props.dataId.id,
      isTransferredIn: false,
      isTransferredOut: false,
      customerInfo: {},
      disabledTransferredIn: false,
      disabledTransferredOut: false,
      preRegDateOpen: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  /* handleChangeTransferredIn = (e) => {
    const { checked } = e.target;
    this.setState({
      isTransferredIn: checked,
    });

    if(checked === true){
      this.setState({
        disabledTransferredOut: true,
        isTransferredOut: false,
      });
    }else{
      this.setState({
        disabledTransferredOut: false,
      });
    }
  }; */

  handleChangeTransferredOut = (e) => {
    const { checked } = e.target;
    this.setState({
      isTransferredOut: checked,
    });
  };

  componentDidMount() {
    this.setState({
      isLoading: true,
    });

    let promise = getRecordById('/public/customer/v1/pregistered/', this.state.preRegId);
    promise.then((res) => {
      this.props.form.setFieldsValue({
        customerId: res.customerId,
        customerName: res.customerName,
        preRegistrationDate: Moment(res.preRegistrationDate),
        serialNo: res.serialNo,
        trackingNo: res.trackingNo,
        groupName: res.groupName,
        groupNumber: res.groupNumber,
        bankInfo: res.bankInfo,
        //isTransferredIn: res.IsTransferredIn,
        isTransferredOut: res.isTransferredOut,
        licenseNo: res.licenseNo,
        agencyName: res.agencyName,
      });

      if (res.isTransferredOut === true) {
        this.setState({
          isTransferredOut: true,
        });
      }
      getRecordById('/public/v1/customers/', res.customerId)
        .then((response) => {
          if (response.statusCode === 204) {
            notificationController.info({
              message: 'Customer not found!',
            });
          } else {
            this.setState({
              customerInfo: response,
            });
          }
        })
        .catch((error) => {
          notificationController.error({
            message: 'Failed! ' + error.message,
          });
        });
    });

    Promise.all([promise]);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //const updateRequest = Object.assign({}, values);
        const updateRequest = {
          customerId: values.customerId,
          preRegistrationDate: Moment(values.preRegistrationDate).format('YYYY-MM-DD'),
          serialNo: values.serialNo,
          trackingNo: values.trackingNo,
          groupName: values.groupName,
          groupNumber: values.groupNumber,
          bankInfo: values.bankInfo !== null ? values.bankInfo : '',
          isTransferredIn: this.state.isTransferredIn,
          isTransferredOut: this.state.isTransferredOut,
          licenseNo: values.licenseNo !== null ? values.licenseNo : '',
          agencyName: values.agencyName !== null ? values.agencyName : '',
        };
        //updateRequest.referenceStatusId  = 111;
        console.log('updateRequest => ' + JSON.stringify(updateRequest));

        updateRecord(this.state.preRegId, updateRequest, '/public/customer/v1/pregistered/')
          .then((response) => {
            if (response)
              notificationController.success({
                message: 'Pre-Reg info updated.',
              });
            this.props.navigate('/pre_registration/list');
          })
          .catch((error) => {
            notificationController.error({
              message: 'Failed! ' + error.message,
            });
          });
      }
    });
  }

  onCancelForm(event) {
    event.preventDefault();
    this.props.navigate('/pre_registration/list');
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { customerInfo } = this.state;

    const onChangeDate = (date, dateString) => {
      console.log(date, dateString);
    };

    return (
      <BaseCard id="validation form" title="Edit Pre-Registration" padding="1.25rem">
        {/* <BaseCard id="mobileNumber" padding="1.25rem" style={{marginBottom: 20}}>
              <BaseRow gutter={[30, 30]} >
                  <BaseCol xs={24} sm={12} xl={12}>
                      <FormItem label="Search by mobile number">
                          {getFieldDecorator('mobileNumber', {
                              rules: [{ required: false, message: 'Mobile number is required!' }],
                          })(
                              <Input
                                  name="mobileNumber"
                                  placeholder="Enter mobile number"
                                  disabled
                              />
                          )}
                      </FormItem>
                  </BaseCol>
              </BaseRow>
          </BaseCard> */}

        <BaseRow gutter={[30, 30]}>
          <BaseCol xs={24} sm={20} xl={12}>
            <Form
              onSubmit={this.handleSubmit}
              className="pregistration-form"
              name="basic"
              labelCol={{ span: 8 }}
              style={{ maxWidth: 1000 }}
              initialValues={{ remember: true }}
              autoComplete="off"
            >
              <FormItem label="Customer ID">
                {getFieldDecorator('customerId', {
                  rules: [{ required: true, message: 'Customer id is required!' }],
                })(<Input name="customerId" size="small" disabled={true} />)}
              </FormItem>

              <FormItem label="Pre-Registration Date">
                {getFieldDecorator('preRegistrationDate', {
                  rules: [{ required: true, message: 'Pre-registration date is required!' }],
                })(
                  <DatePicker
                    name="preRegistrationDate"
                    size="small"
                    placeholder="Select Date"
                    onChange={onChangeDate}
                    onKeyDown={(e) => handleEnter(e)}
                    format={this.state.preRegDateOpen ? 'DDMMYYYY' : 'YYYY-MM-DD'}
                    onOpenChange={(status) => {
                      this.setState({ preRegDateOpen: status });
                    }}
                  />,
                )}
              </FormItem>

              <FormItem label="Serial No">
                {getFieldDecorator('serialNo', {
                  rules: [{ required: true, message: 'Serial no is required!' }],
                })(
                  <Input
                    name="serialNo"
                    placeholder="Enter serial no"
                    size="small"
                    onKeyDown={(e) => handleEnter(e)}
                  />,
                )}
              </FormItem>

              <FormItem label="Tracking No">
                {getFieldDecorator('trackingNo', {
                  rules: [{ required: true, message: 'Tracking no is required!' }],
                })(
                  <Input
                    name="trackingNo"
                    size="small"
                    placeholder="Enter tracking no"
                    onKeyDown={(e) => handleEnter(e)}
                  />,
                )}
              </FormItem>

              <FormItem label="Group Name">
                {getFieldDecorator('groupName', {
                  rules: [{ required: false, message: 'Group name is required!' }],
                })(
                  <Input
                    name="groupName"
                    size="small"
                    placeholder="Enter group name"
                    onKeyDown={(e) => handleEnter(e)}
                  />,
                )}
              </FormItem>

              <FormItem label="Group Number">
                {getFieldDecorator('groupNumber', {
                  rules: [{ required: false, message: 'Group number is required!' }],
                })(
                  <Input
                    name="groupNumber"
                    size="small"
                    placeholder="Enter group number"
                    onKeyDown={(e) => handleEnter(e)}
                  />,
                )}
              </FormItem>

              <FormItem label="Bank Info">
                {getFieldDecorator('bankInfo', {
                  rules: [{ required: false, message: 'Bank Info is required!' }],
                })(
                  <Input
                    name="bankInfo"
                    placeholder="Enter bank info"
                    size="small"
                    onKeyDown={(e) => handleEnter(e)}
                  />,
                )}
              </FormItem>

              {/*  <FormItem label="Transferred In">
                {getFieldDecorator('isTransferredIn', {
                  rules: [{ required: false, message: 'Transferred in is required!' }],
                })(
                  <BaseCheckbox
                    onChange={(e) => this.handleChangeTransferredIn(e)}
                    checked={this.state.isTransferredIn}
                    onKeyDown={(e) => handleEnter(e)}
                    disabled={this.state.disabledTransferredIn}
                  ></BaseCheckbox>,
                )}
              </FormItem> */}

              <FormItem label="Transferred Out">
                {getFieldDecorator('isTransferredOut', {
                  rules: [{ required: false, message: 'Transferred out is required!' }],
                })(
                  <BaseCheckbox
                    onChange={(e) => this.handleChangeTransferredOut(e)}
                    checked={this.state.isTransferredOut}
                    onKeyDown={(e) => handleEnter(e)}
                    disabled={this.state.disabledTransferredOut}
                  ></BaseCheckbox>,
                )}
              </FormItem>

              {this.state.isTransferredOut === true ? (
                <>
                  <FormItem label="Agency License No">
                    {getFieldDecorator('licenseNo', {
                      rules: [{ required: true, message: 'Agency License is required!' }],
                    })(
                      <Input
                        name="licenseNo"
                        placeholder="Enter license no"
                        size="small"
                        onKeyDown={(e) => handleEnter(e)}
                      />,
                    )}
                  </FormItem>

                  <FormItem label="Agency Name">
                    {getFieldDecorator('agencyName', {
                      rules: [{ required: true, message: 'Agency name is required!' }],
                    })(
                      <Input
                        name="agencyName"
                        placeholder="Enter agency name"
                        size="small"
                        onKeyDown={(e) => handleEnter(e)}
                      />,
                    )}
                  </FormItem>
                </>
              ) : null}

              <FormItem
                wrapperCol={{
                  offset: 8,
                  span: 9,
                }}
              >
                <BaseButtonsGroup onCancel={this.onCancelForm} />
              </FormItem>
            </Form>
          </BaseCol>

          <BaseCol xs={24} sm={12} xl={12}>
            <Descriptions title="Customer Info" bordered size="middle">
              <Descriptions.Item label="Customer Name" span={3}>
                {customerInfo.customerName}
              </Descriptions.Item>
              <Descriptions.Item label="Customer Email" span={3}>
                {customerInfo.email}
              </Descriptions.Item>
              <Descriptions.Item label="Mobile No" span={3}>
                {customerInfo.mobileNumber}
              </Descriptions.Item>
              <Descriptions.Item label="Address" span={3}>
                {customerInfo.addressLineOne}
              </Descriptions.Item>
              <Descriptions.Item label="District" span={3}>
                {customerInfo.district}
              </Descriptions.Item>
              <Descriptions.Item label="Thana" span={3}>
                {customerInfo.thana}
              </Descriptions.Item>
              <Descriptions.Item label="Reference" span={3}>
                {customerInfo.reference}
              </Descriptions.Item>
            </Descriptions>
          </BaseCol>
        </BaseRow>
      </BaseCard>
    );
  }
}

import React from 'react';
import { SearchFilter } from '../SearchFilter/SearchFilter';
import { SearchResults } from '../SearchResults/SearchResults';
import { NotFound } from '@app/components/common/NotFound/NotFound';
import * as S from './SearchOverlay.styles';
import { Component } from '@app/constants/config/components';

interface SearchOverlayProps {
  data: Component[] | null;
  isFilterOpen: boolean;
}

export const SearchOverlay: React.FC<SearchOverlayProps> = ({ data, isFilterOpen }) => {

  return (
    <S.Menu>
      <SearchFilter data={data} isOpen={isFilterOpen}>
        {(filteredResults) => {
          return (filteredResults.length > 0 ? <SearchResults results={filteredResults} /> : <NotFound />)}
        }
      </SearchFilter>
    </S.Menu>
  );
};

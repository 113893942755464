import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatisticsInfo } from '../StatisticsInfo/StatisticsInfo';
//import { StatisticsProgress } from '../StatisticsProgress/StatisticsProgress';
import { useResponsive } from '@app/hooks/useResponsive';
import { StatisticColor } from '@app/constants/config/statistics';
import * as S from './StatisticsCard.styles';
//import { themeObject } from '@app/styles/themes/themeVariables';
//import { useAppSelector } from '@app/hooks/reduxHooks';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
//import * as SS from '@app/components/medical-dashboard/statisticsCards/statisticsCard/StatisticsProgress/StatisticsProgress.styles';
import * as ST from '@app/components/medical-dashboard/statisticsCards/statisticsCard/StatisticsInfo/StatisticsInfo.styles';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';

interface StatisticsCardProps {
  id: string;
  name: string;
  value: number;
  prevValue: number;
  color: StatisticColor;
  unit: 'kg';
  Icon: React.FC;
}

export const StatisticsCard: React.FC<StatisticsCardProps> = ({ id, name, value, prevValue, color /* Icon */ }) => {
  //const theme = useAppSelector((state) => state.theme.theme);
  const { isTablet: isTabletOrHigher } = useResponsive();

  const { t } = useTranslation();

  return (
    <S.StatisticCard /* padding="0.5rem" */ $color={color} id={id} title={name}>
      <BaseRow wrap={false} gutter={[isTabletOrHigher ? 10 : 5, 0]}>
        {/*  <BaseCol>
          <S.IconWrapper>
            <S.Icon component={Icon} />
          </S.IconWrapper>
        </BaseCol> */}

        <BaseCol flex={1}>
          <BaseRow justify="space-between" align="middle" wrap={false}>
            <BaseCol>
              <BaseSpace direction="vertical" size={26}>
                <BaseRow>
                  <ST.Text style={{ fontWeight: 600, color: '#000000' }}>This Year:</ST.Text>
                </BaseRow>

                <BaseRow>
                  <ST.Text style={{ fontWeight: 600, color: '#000000' }}>Total:</ST.Text>
                </BaseRow>
              </BaseSpace>
            </BaseCol>
            <BaseCol>
              {/* <StatisticsProgress color={themeObject[theme][color]} unit={unit} value={value} /> */}
              <BaseSpace direction="vertical" size={20}>
                <BaseRow>
                  <StatisticsInfo name={t(name)} value={value} prevValue={prevValue} />
                </BaseRow>
                
                <BaseRow>
                  <ST.Text style={{ fontWeight: 600, color: '#000000', fontSize: 17 }}>{value}</ST.Text>
                </BaseRow>  
              </BaseSpace>
            </BaseCol>
          </BaseRow>
        </BaseCol>
      </BaseRow>
    </S.StatisticCard>
  );
};

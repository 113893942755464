import React, { Component, useState } from 'react';
import { saveRecord, getRecords, searchRecord } from '@app/utils/APIUtils';
import { Form, Input, Select, DatePicker, Descriptions } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { handleEnter } from '@app/helper/EnterIndexHelper';
import moment from 'moment';
import SearchCustomer from '@app/components/search-customer/SearchCustomer';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';

const FormItem = Form.Item;

const onSearch = (value) => {
  console.log('search:', value);
};

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  return (
    <AddRegistration {...props} 
      navigation={navigate} 
      isLoading={isLoading}
      setLoading={setLoading} 
      form={form} 
    />
  )
}

class AddRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      hajYearList: [],
      recordSet: [],
      customerInfo: {},
      isReadonly: false,
      isRequired: false,
      regDateOpen: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  componentDidMount() {
    let promise;

    promise = getRecords('/domain/data/hajyears/v1/active/all');

    this.setState({
      isLoading: true,
    });

    promise
      .then((response) => {
        this.setState({
          hajYearList: response.map((item) => ({ value: item.id, label: item.ceYear + ' - (' + item.hijriYear + ')' })),
        });
      })
      .catch((error) => {
        if (error)
          this.setState({
            isLoading: false,
          });
      });

    Promise.all([promise]);
  }

  //Handle Search
  handleSearch(values) {
    const searchRequest = {
      customerName: values.customerName === '' ? null : values.customerName,
      mobileNumber: values.mobileNumber === '' ? null : values.mobileNumber,
      groupNumber: values.groupNumber === '' ? null : values.groupNumber,
    };
    if (values.mobileNumber === undefined && values.groupNumber === undefined && values.customerName === undefined) {
      this.setState({
        recordSet: [],
        customerInfo: '',
      });
      notificationController.warning({
        message: 'Please enter any filtering criteria!',
      });
    } else {
      searchRecord(searchRequest, '/public/v1/customers/search')
        .then((response) => {
          if (response.length !== 0) {
            this.setState({
              recordSet: response,
            });
          } else {
            this.setState({
              recordSet: [],
              customerInfo: '',
            });
            notificationController.warning({
              message: 'No record found',
            });
          }
        })
        .catch((error) => {
          this.setState({
            recordSet: [],
            customerInfo: '',
          });
          notificationController.error({
            message: 'Failed! ' + error.message,
          });
        });
    }
  }

  //Handle Row Click
  handleRowClick = (record) => {
    this.props.form.setFieldsValue({
      ['customerId']: record.customerId,
    });

    const searchRequest = {
      customerId: record.customerId,
    };

    searchRecord(searchRequest, '/public/customer/v1/pregistered/info')
      .then((res) => {
        if (res.statusCode === 404) {
          notificationController.warning({
            message: res.message,
          });
          this.props.form.resetFields([
            'customerId',
            'hajYearId',
            'hajSerialNo',
            'registrationDate',
            'pilgrimId',
            'groupName',
            'groupNumber',
            'ehajTag',
          ]);
          this.setState({
            customerInfo: '',
          });
        } else if (res.statusCode === 302) {
          notificationController.warning({
            message: res.message,
          });
          //Get pregistration info by customerId
          getRecords(`/public/customer/v1/pregistered/${res.data.customerId}/cif`)
            .then((res) => {
              this.setState({
                customerInfo: res,
              });
            })
            .catch((error) => {
              notificationController.error({
                message: 'Failed! ' + error.message,
              });
            });
          //Get registration info by customerId
          getRecords(`/public/customer/v1/registered/${res.data.customerId}/cif`)
            .then((res) => {
              this.props.form.resetFields(['customerId', 'hajSerialNo', 'registrationDate']);
              this.props.form.setFieldsValue({
                customerId: res.CustomerBean.id,
                hajYearId: res.HajYearBean.id,
                hajSerialNo: res.HajSerialNo,
                registrationDate: moment(res.RegDate),
                regVoucherNo: res.RegVoucherNo,
                pilgrimId: res.PID,
                groupName: res.GroupName,
                groupNumber: res.GroupNo,
                ehajTag: res.EHajTag,
                isReplaced: res.IsReplaced,
              });
            })
            .catch((error) => {
              notificationController.error({
                message: 'Failed! ' + error.message,
              });
            });
        } else {
          this.props.form.resetFields(['customerId', 'hajYearId', 'hajSerialNo', 'registrationDate', 'regVoucherNo']);
          this.setState({
            customerInfo: res.data,
          });
          this.props.form.setFieldsValue({
            customerId: res.data.customerId,
            hajSerialNo: res.data.serialNo,
          });
        }
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
      });
  };

  //Handle Submit
  handleSubmit(values) {
    const saveRequest = {
      customerId: values.customerId,
      hajYearId: values.hajYearId,
      hajSerialNo: values.hajSerialNo,
      registrationDate: moment(values.registrationDate).format('YYYY-MM-DD'),
      regVoucherNo: values.regVoucherNo,
    };
    console.log('saveRequest => ' + JSON.stringify(saveRequest));

    saveRecord(saveRequest, '/public/customer/v1/registered')
      .then((response) => {
        if (response.statusCode === 101) {
          notificationController.warning({
            message: 'Customer already registered!',
          });
        } else if (response.statusCode === 110) {
          notificationController.info({
            message: 'Haj year must be current year!',
          });
        } else {
          notificationController.success({
            message: 'Registration info created.',
          });
          this.props.navigation('/registration/list');
        }
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
      });
  }

  onCancelForm(event) {
    event.preventDefault();
    this.props.navigation('/registration/list');
  }

  onChangeDate = (date, dateString) => {
    console.log(date, dateString);
  };

  render() {
    const { customerInfo, recordSet } = this.state;

    const data = [];

    //Customer record set
    recordSet.forEach((record, recordIndex) => {
      data.push({
        key: recordIndex,
        customerId: record.customerId,
        customerName: record.customerName,
        mobileNumber: record.mobileNumber,
        groupNumber: record.groupNumber,
        email: record.email,
        address: record.address,
        district: record.district,
        thana: record.thana,
        reference: record.reference,
      });
    });

    const columns = [
      {
        title: 'Name',
        dataIndex: 'customerName',
        key: 'customerName;',
      },
      {
        title: 'Mobile',
        dataIndex: 'mobileNumber',
        key: 'mobileNumber',
      },
      {
        title: 'Group Number',
        dataIndex: 'groupNumber',
        key: 'groupNumber',
      },
    ];

    return (
      <BaseCard id="reg-card" title="Add Registration" padding="1.25rem">
        <BaseRow gutter={[30, 30]}>
          <BaseCol xs={24} sm={20} xl={12}>
            <SearchCustomer
              searchForm={this.props.form}
              onFinish={this.handleSearch}
              customerName="customerName"
              mobileNumber="mobileNumber"
              groupNumber="groupNumber"
              onKeyDown={(e) => handleEnter(e)}
            />
          </BaseCol>
          <BaseCol xs={24} sm={20} xl={12}>
            <BaseCard id="search" padding="1.25rem">
              <BaseRow gutter={[0, 0]}>
                <BaseCol xs={24} sm={24} xl={24}>
                  <BaseTable
                    onRow={(record) => ({
                      onClick: () => this.handleRowClick(record), // Handle row click
                    })}
                    columns={columns}
                    dataSource={data}
                    pagination={{ pageSize: 5 }}
                    scroll={{ x: true }}
                    rowKey={(record) => record.customerId}
                    bordered
                  />
                </BaseCol>
              </BaseRow>
            </BaseCard>
          </BaseCol>
        </BaseRow>
        <BaseRow gutter={[30, 30]}>
          <BaseCol xs={24} sm={20} xl={12}>
            <BaseCard id="customer-info" padding="1.25rem">
              <Descriptions title="Pre-registartion Details" bordered size="middle" column={2}>
                <Descriptions.Item label="Customer Name" span={2}>
                  {customerInfo.customerName}
                </Descriptions.Item>
                <Descriptions.Item label="Mobile No" span={2}>
                  {customerInfo.mobileNumber}
                </Descriptions.Item>
                <Descriptions.Item label="Pre-Registration Date" span={2}>
                  {customerInfo.preRegistrationDate}
                </Descriptions.Item>
                <Descriptions.Item label="Serial No" span={1}>
                  {customerInfo.serialNo}
                </Descriptions.Item>
                <Descriptions.Item label="Tracking No" span={1}>
                  {customerInfo.trackingNo}
                </Descriptions.Item>
                <Descriptions.Item label="Bank Info" span={2}>
                  {customerInfo.bankInfo}
                </Descriptions.Item>
              </Descriptions>
            </BaseCard>
          </BaseCol>
          <BaseCol xs={24} sm={20} xl={12}>
            <Form
              form={this.props.form}
              onFinish={this.handleSubmit}
              className="registration-form"
              name="basic"
              labelCol={{ span: 7 }}
              style={{ maxWidth: 500, marginTop: '20px' }}
              initialValues={{ remember: true }}
              autoComplete="off"
            >
              <FormItem
                label="Customer Id"
                name="customerId"
                rules={[
                  {
                    required: true,
                    message: 'Customer id is required!',
                  },
                ]}
                className="ant-latest-form-item"
                hidden={true}
              >
                {/* <-- Pass hidden customerId --> */}
                <Input
                  size="small"
                  readOnly={true}
                  onKeyDown={(e) => handleEnter(e)}
                  style={{ backgroundColor: '#c5d3e0' }}
                />
              </FormItem>

              <FormItem
                label="Haj Year"
                name="hajYearId"
                rules={[
                  {
                    required: true,
                    message: 'Haj Year is required!',
                  },
                ]}
                className="ant-latest-form-item"
              >
                <Select
                  showSearch
                  placeholder="Select Haj Year"
                  size="small"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  readOnly={true}
                  onKeyDown={(e) => handleEnter(e)}
                >
                  {this.state.hajYearList.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>

              <FormItem
                label="Haj Serial No"
                name="hajSerialNo"
                rules={[
                  {
                    required: true,
                    message: 'Haj serial no is required!',
                  },
                ]}
                className="ant-latest-form-item"
              >
                <Input placeholder="Enter haj serial no" size="small" onKeyDown={(e) => handleEnter(e)} />
              </FormItem>

              <FormItem
                label="Registration Date"
                name="registrationDate"
                rules={[
                  {
                    required: true,
                    message: 'Registration date is required!',
                  },
                ]}
                className="ant-latest-form-item"
              >
                <DatePicker
                  size="small"
                  placeholder="Select Date"
                  onChange={this.onChangeDate}
                  onKeyDown={(e) => handleEnter(e)}
                  format={this.state.regDateOpen ? 'DDMMYYYY' : 'YYYY-MM-DD'}
                  onOpenChange={(status) => {
                    this.setState({ regDateOpen: status });
                  }}
                />
              </FormItem>

              <FormItem
                label="Reg Voucher No"
                name="regVoucherNo"
                rules={[
                  {
                    required: false,
                    message: 'Reg voucher no is required!',
                  },
                ]}
                className="ant-latest-form-item"
              >
                <Input placeholder="Enter registration voucher no" size="small" onKeyDown={(e) => handleEnter(e)} />
              </FormItem>

              <FormItem
                wrapperCol={{
                  offset: 8,
                  span: 9,
                }}
              >
                <BaseButtonsGroup onCancel={this.onCancelForm} />
              </FormItem>
            </Form>
          </BaseCol>
        </BaseRow>
      </BaseCard>
    );
  }
}

import React, { Component, useState } from 'react';
import { Form, Select, Spin } from 'antd';
import { ACCESS_TOKEN, API_BASE_URL } from '@app/constants';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { getRecords } from '@app/utils/APIUtils';

const FormItem = Form.Item;

const onSearch = (value) => {
  console.log('search:', value);
};

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const [spinning, setSpinning] = React.useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  return (
    <CustomerUploadedDocReport
      {...props}
      navigation={navigate}
      isLoading={isLoading}
      setLoading={setLoading}
      spinning={spinning}
      setSpinning={setSpinning}
      form={form}
    />
  );
}

class CustomerUploadedDocReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: false,
      flightList: [],
      documentList: [],
      fileFormat: {
        value: '',
      },
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.clearForm = this.clearForm.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  componentDidMount() {
    let promise, promise1;

    promise = getRecords('/domain/data/doctypes/v1/all');
    promise1 = getRecords('/setup/v1/flights/all');

    this.props.setLoading(true);

    promise
      .then((response) => {
        this.setState({
          documentList: response.map((item) => ({ value: item.id, label: item.DocName })),
        });
      })
      .catch((error) => {
        notificationController.error({
          message: error.message,
        });
        this.props.setLoading(false);
      });

    promise1
      .then((response) => {
        this.setState({
          flightList: response.map((item) => ({ value: item.id, label: item.FlightNumber })),
        });
      })
      .catch((error) => {
        notificationController.error({
          message: error.message,
        });
        this.props.setLoading(false);
      });

    Promise.all([promise, promise1]);
  }

  handleSubmit(values) {
    this.props.setSpinning(true);

    const paramRequest = Object.assign({}, values);
    console.log('paramRequest => ' + JSON.stringify(paramRequest));

    fetch(API_BASE_URL + `/customer/v1/documents/view/${values.flightId}/${values.docId}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
      },
    }).then(async (response) => {
      if (response.ok) {
        let blob;
        const reportBytes = await response.arrayBuffer();
        blob = new File([new Uint8Array(reportBytes)], 'CustomerDocument.pdf', { type: 'application/pdf' });

        const reportUrl = URL.createObjectURL(blob);
        window.open(reportUrl, '_test');

        notificationController.success({
          message: 'Report generated successfully',
        });
      }
      this.props.setSpinning(false);
    })
    .catch(function (error) {
      this.props.setSpinning(false);
      notificationController.error({
        message: error.message,
      });
    });
  }

  clearForm(event) {
    event.preventDefault();
    this.props.form.resetFields();
  }

  render() {
    return (
      <Spin spinning={this.props.spinning} fullscreen="true" size="large" tip="Loading...">
        <BaseCol xs={24} sm={24} xl={24}>
          <BaseCard id="validation form" title="Reports > Customers Uploaded Document By Flight" padding="1.25rem">
            <Form
              form={this.props.form}
              onFinish={this.handleSubmit}
              className="report-form"
              name="basic"
              labelCol={{ span: 6 }}
              style={{ maxWidth: 500 }}
              initialValues={{ remember: true }}
              autoComplete="off"
            >
              <FormItem label="Filtering Criteria" className="ant-latest-form-item"></FormItem>

              <FormItem
                label="Flight"
                name="flightId"
                rules={[
                  {
                    required: true,
                    message: 'Flight is required!',
                  },
                ]}
                className="ant-latest-form-item"
              >
                <Select
                  showSearch
                  placeholder="Select flight"
                  size="small"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  //defaultValue={0}
                >
                  {/* <Select.Option value={0}>ALL</Select.Option> */}
                  {this.state.flightList.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>

              <FormItem
                label="Document"
                name="docId"
                rules={[
                  {
                    required: true,
                    message: 'Document type is required!',
                  },
                ]}
                className="ant-latest-form-item"
              >
                <Select
                  showSearch
                  placeholder="Select document"
                  size="small"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  //defaultValue={0}
                >
                  {/* <Select.Option value={0}>ALL</Select.Option> */}
                  {this.state.documentList.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>

              <FormItem
                wrapperCol={{
                  offset: 6,
                  span: 9,
                }}
              >
                <BaseRow gutter={[10, 10]} wrap={false}>
                  <BaseCol span={12} xs={17} sm={12} xl={12}>
                    <BaseButton block type="primary" htmlType="submit" size="small">
                      Submit
                    </BaseButton>
                  </BaseCol>
                  <BaseCol span={12} xs={17} sm={12} xl={12}>
                    <BaseButton block type="ghost" onClick={this.clearForm} size="small">
                      Reset
                    </BaseButton>
                  </BaseCol>
                </BaseRow>
              </FormItem>
            </Form>
          </BaseCard>
        </BaseCol>
      </Spin>
    );
  }
}

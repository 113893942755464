import React, { Component, useState } from 'react';
import { searchRecord, getRecords, getRecordById } from '@app/utils/APIUtils';
import { ACCESS_TOKEN, API_BASE_URL } from '@app/constants';
import { Form, Input, Select, Descriptions, Tag, Spin } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { handleEnter } from '@app/helper/EnterIndexHelper';
import SearchCustomer from '@app/components/search-customer/SearchCustomer';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';

const FormItem = Form.Item;

const onSearch = (value) => {
  console.log('search:', value);
};

export default function (props) {
  const [loading, setLoading] = useState(false);
  const [spinning, setSpinning] = React.useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  return (
    <AddCollection
      {...props}
      navigation={navigate}
      loading={loading}
      setLoading={setLoading}
      spinning={spinning}
      setSpinning={setSpinning}
      form={form}
    />
  );
}

class AddCollection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      customerName: '',
      collectionAmount: '',
      payCategoryList: [],
      paymentModeList: [],
      recordSet: [],
      isReadonly: false,
      isRequired: false,
      customerInfo: {},
      amountInfo: '',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  componentDidMount() {
    let promise1;

    promise1 = getRecords('/domain/data/paymentmode/v1/all');

    promise1
      .then((response) => {
        this.setState({
          paymentModeList: response.map((item) => ({ value: item.id, label: item.paymentModeName })),
        });
      })
      .catch((error) => {
        notificationController.error({
          message: error.message,
        });
      });

    Promise.all([promise1]);
  }

  handleSubmit(values) {
    this.props.setSpinning(true);
    const saveRequest = Object.assign({}, values);
    console.log('saveRequest => ' + JSON.stringify(saveRequest));

    fetch(API_BASE_URL + '/customer/v1/collections', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
      },
      body: JSON.stringify(saveRequest),
    })
      .then(async (response) => {
        if (response.ok) {
          const reportBytes = await response.arrayBuffer();
          const blob = new File([new Uint8Array(reportBytes)], 'filename.pdf', { type: 'application/pdf' });
          const reportUrl = URL.createObjectURL(blob);
          window.open(reportUrl, 'test');
          notificationController.success({
            message: 'Collection successful.',
          });
        }
        this.props.setSpinning(false);
      })
      .catch((error) => {
        this.props.setSpinning(false);
        notificationController.error({
          message: error.message,
        });
      });
  }

  onCancelForm(event) {
    event.preventDefault();
    this.props.navigation('/customer/payment/collection/list');
  }

  //Handle Search
  handleSearch(values) {
    if (values.mobileNumber === undefined && values.groupNumber === undefined && values.customerName === undefined) {
      notificationController.warning({
        message: 'Please enter one filtering criteria!',
      });
    }
    const searchRequest = {
      customerName: values.customerName === '' ? null : values.customerName,
      mobileNumber: values.mobileNumber === '' ? null : values.mobileNumber,
      groupNumber: values.groupNumber === '' ? null : values.groupNumber,
    };

    searchRecord(searchRequest, '/public/v1/customers/search')
      .then((response) => {
        this.setState({
          recordSet: response,
        });
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
      });
  }

  //Handle Row Click
  handleRowClick = (record) => {
    this.setState({
      customerInfo: record,
    });

    this.props.form.setFieldsValue({
      ['customerId']: record.customerId,
    });

    let promise = getRecordById('/customer/v1/collections/amount/', record.customerId);
    promise
      .then((res) => {
        this.setState({
          amountInfo: res,
        });
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
      });
  };

  render() {
    const { customerInfo, recordSet, amountInfo } = this.state;
    const numberFormat = (value) => new Intl.NumberFormat('en-IN').format(value);
    const data = [];

    //Customer record set
    recordSet.forEach((record, recordIndex) => {
      data.push({
        key: recordIndex,
        customerId: record.customerId,
        customerName: record.customerName,
        mobileNumber: record.mobileNumber,
        groupNumber: record.groupNumber,
        packageId: record.packageId,
        packageName: record.packageName,
        email: record.email,
        serviceId: record.serviceId,
        serviceName: record.serviceName,
        hotelName: record.hotelName,
        roomNumber: record.roomNumber,
        busNo: record.busNo,
        flightNo: record.flightNo,
        address: record.address,
      });
    });

    const columns = [
      {
        title: 'Name',
        dataIndex: 'customerName',
        key: 'customerName;',
      },
      {
        title: 'Mobile',
        dataIndex: 'mobileNumber',
        key: 'mobileNumber',
      },
      {
        title: 'Group Number',
        dataIndex: 'groupNumber',
        key: 'groupNumber',
      },
    ];
    
    return (
      <Spin spinning={this.props.spinning} fullscreen="true" size="large" tip="Loading...">
        <BaseCard id="collection" title="Add Collection" padding="1.25rem">
          <BaseRow gutter={[30, 30]}>
            <BaseCol xs={24} sm={20} xl={12}>
              <SearchCustomer
                searchForm={this.props.form}
                onFinish={this.handleSearch}
                customerName="customerName"
                mobileNumber="mobileNumber"
                groupNumber="groupNumber"
                onKeyDown={(e) => handleEnter(e)}
              />
            </BaseCol>
            <BaseCol xs={24} sm={20} xl={12}>
              <BaseCard id="search" padding="1.25rem">
                <BaseRow gutter={[0, 0]}>
                  <BaseCol xs={24} sm={24} xl={24}>
                    <BaseTable
                      onRow={(record) => ({
                        onClick: () => this.handleRowClick(record), // Handle row click
                      })}
                      columns={columns}
                      dataSource={data}
                      pagination={{ pageSize: 5 }}
                      scroll={{ x: true }}
                      rowKey={(record) => record.customerId}
                      bordered
                    />
                  </BaseCol>
                </BaseRow>
              </BaseCard>
            </BaseCol>
          </BaseRow>
          <BaseRow gutter={[30, 30]}>
            <BaseCol xs={24} sm={20} xl={12}>
              <BaseCard id="search" padding="1.25rem">
                <Descriptions title="Customer Collection Info" bordered size="middle">
                  <Descriptions.Item label="Customer Name" span={3}>
                    {customerInfo.customerName}
                  </Descriptions.Item>
                  <Descriptions.Item label="Mobile Number" span={3}>
                    {customerInfo.mobileNumber}
                  </Descriptions.Item>
                  <Descriptions.Item label="Email" span={3}>
                    {customerInfo.email}
                  </Descriptions.Item>
                  <Descriptions.Item label="Address" span={3}>
                    {customerInfo.address}
                  </Descriptions.Item>
                  <Descriptions.Item label="Total Collection Amount" span={3}>
                    <Tag color="green" key={amountInfo} style={{ fontSize: 15, fontWeight: 600 }}>
                      {amountInfo === '' ? '৳'.concat(' ').concat(0) : '৳'.concat(' ').concat(numberFormat(amountInfo))}
                    </Tag>
                  </Descriptions.Item>
                </Descriptions>
              </BaseCard>
            </BaseCol>
            <BaseCol xs={24} sm={20} xl={12}>
              <Form
                form={this.props.form}
                onFinish={this.handleSubmit}
                className="collect-form"
                name="basic"
                labelCol={{ span: 7 }}
                style={{ maxWidth: 500, marginTop: '20px' }}
                initialValues={{ remember: true }}
                autoComplete="off"
              >
                <FormItem
                  label="Customer Id"
                  name="customerId"
                  rules={[
                    {
                      required: true,
                      message: 'Customer id is required!',
                    },
                  ]}
                  className="ant-latest-form-item"
                  hidden={true}
                >
                  {/* <-- Pass hidden customerId --> */}
                  <Input
                    size="small"
                    readOnly={true}
                    onKeyDown={(e) => handleEnter(e)}
                    style={{ backgroundColor: '#c5d3e0' }}
                  />
                </FormItem>

                <FormItem
                  label="Voucher No"
                  name="voucherNo"
                  rules={[
                    {
                      required: true,
                      message: 'Voucher no is required!',
                    },
                  ]}
                  className="ant-latest-form-item"
                >
                  <Input size="small" placeholder="Enter voucher no" onKeyDown={(e) => handleEnter(e)} />
                </FormItem>

                <FormItem
                  label="Payment Category"
                  name="paymentCategory"
                  rules={[
                    {
                      required: true,
                      message: 'Payment category is required!',
                    },
                  ]}
                  className="ant-latest-form-item"
                >
                  <Input size="small" placeholder="Enter payment category" onKeyDown={(e) => handleEnter(e)} />
                </FormItem>

                <FormItem
                  label="Mode of Payment"
                  name="paymentMode"
                  rules={[
                    {
                      required: true,
                      message: 'Mode of payment is required!',
                    },
                  ]}
                  className="ant-latest-form-item"
                >
                  <Select
                    showSearch
                    placeholder="Select payment mode"
                    size="small"
                    optionFilterProp="children"
                    onSearch={onSearch}
                    onKeyDown={(e) => handleEnter(e)}
                  >
                    {this.state.paymentModeList.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>

                <FormItem
                  label="Amount"
                  name="amount"
                  rules={[
                    {
                      required: true,
                      message: 'Amount is required!',
                    },
                    {
                      pattern: /^(?:\d*)$/,
                      message: 'Only number allowed',
                    },
                  ]}
                  className="ant-latest-form-item"
                >
                  <Input
                    addonAfter="BDT"
                    size="small"
                    placeholder="Enter collection amount"
                    onKeyDown={(e) => handleEnter(e)}
                  />
                </FormItem>

                <FormItem
                  label="Others"
                  name="others"
                  rules={[
                    {
                      required: false,
                      message: 'Others is required!',
                    },
                  ]}
                  className="ant-latest-form-item"
                >
                  <Input name="others" placeholder="Enter others" size="small" onKeyDown={(e) => handleEnter(e)} />
                </FormItem>

                <FormItem
                  label="Remarks"
                  name="remarks"
                  rules={[
                    {
                      required: false,
                      message: 'Remarks is required!',
                    },
                  ]}
                  className="ant-latest-form-item"
                >
                  <Input size="small" placeholder="Enter remarks" onKeyDown={(e) => handleEnter(e)} />
                </FormItem>

                <FormItem
                  wrapperCol={{
                    offset: 7,
                    span: 9,
                  }}
                >
                  <BaseButtonsGroup onCancel={this.onCancelForm} />
                </FormItem>
              </Form>
            </BaseCol>
          </BaseRow>
        </BaseCard>
      </Spin>
    );
  }
}

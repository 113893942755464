import React, { Component, useState } from 'react';
import { deleteItemByParam, getRecords, getRecordSet, updateGridRecord } from '@app/utils/APIUtils';
import { Modal, Row, Descriptions, Form, Popconfirm, Select, Tag } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import * as S from '@app/components/tables/Tables/Tables.styles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput';
import * as ST from '@app/pages/uiComponentsPages/UIComponentsPage.styles';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import { LIST_SIZE } from '@app/constants';
import moment from 'moment';

const onSearch = (value) => {
  console.log('search:', value);
};

export default function (props) {
  const [loading, setLoading] = useState(false);
  const [editingRow, setEditingRow] = useState('');
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  return (
    <CustomerFlightList
      {...props}
      loading={loading}
      setLoading={setLoading}
      page={page}
      setPage={setPage}
      navigate={navigate}
      editingRow={editingRow}
      setEditingRow={setEditingRow}
      form={form}
    />
  );
}

class CustomerFlightList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      recordSet: [],
      flightList: [],
      visible: false,
      viewRecord: [],
      isLoading: false,
      response: {},
      searchedText: '',
      page: 0,
      size: 10,
      totalElements: 0,
      totalPages: 0,
      last: true,
      searchedText: '',
    };
    this.save = this.save.bind(this);
    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);
    this.loadRecords = this.loadRecords.bind(this);
  }

  loadRecords(searchedText, page, size = LIST_SIZE) {
    this.setState({
      recordSet: [],
      page: 0,
      size: 10,
      totalElements: 0,
      totalPages: 0,
      last: true,
      isLoading: false,
    });

    this.props.setLoading(true);

    let promise = getRecordSet(page, size, '/customer/v1/flights?page=', searchedText);

    if (!promise) {
      return;
    }

    promise
      .then((response) => {
        const recordSet = this.state.recordSet.slice();

        if (!(searchedText === '' || searchedText === undefined)) {
          this.setState({
            recordSet: recordSet.concat(response.content),
            page: response.page,
            size: response.size,
            totalElements: response.totalElements,
            totalPages: response.totalPages,
            last: response.last,
          });

          this.props.setLoading(false);
        } else {
          setTimeout(() => {
            this.setState({
              recordSet: recordSet.concat(response.content),
              page: response.page,
              size: response.size,
              totalElements: response.totalElements,
              totalPages: response.totalPages,
              last: response.last,
            });

            this.props.setLoading(false);
          }, 1000);
        }
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
        this.props.setLoading(false);
      });
    console.log('Component mounted.');
  }

  componentDidMount() {
    let promise;

    promise = getRecords('/setup/v1/flights/all');

    promise
      .then((response) => {
        this.setState({
          flightList: response.map((item) => ({
            value: item.id,
            label: item.DepFrom + ' - ' + item.ArrivalTo + ' (' + item.FlightNumber + ')',
          })),
        });
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
        this.setState({
          isLoading: false,
        });
      });

    //Load Records
    this.loadRecords();
  }

  save() {
    this.props.navigate('/assign_customer_flight/new');
  }

  edit(id) {
    this.props.navigate(`/assign_customer_flight/edit/${id}`);
  }

  delete(customerId, flightId) {
    const { confirm } = Modal;
    return new Promise((resolve, reject) => {
      confirm({
        title: 'Are you sure you want to Delete ?',
        onOk: () => {
          resolve(true);

          //onRemoveFunctionality here
          deleteItemByParam(`/customer/v1/flights/${customerId}/${flightId}`).then((res) => {
            if (res)
              this.setState({
                recordSet: this.state.recordSet.filter((record) => record.customerId !== customerId),
              });
            notificationController.success({
              message: 'Customer flight deleted.',
            });
          });
        },
        onCancel: () => {
          reject(true);
        },
      });
    });
  }

  view(customerId, flightId) {
    this.setState({
      visible: true,
    });

    let promise1 = getRecords(`/customer/v1/flights/${customerId}/${flightId}`);
    promise1.then((res) => {
      this.setState({
        viewRecord: res,
      });
    }, []);

    Promise.all([promise1]);
  }

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  render() {
    const { recordSet, totalElements, viewRecord } = this.state;

    //const vendorViews = [];
    const data = [];
    recordSet.forEach((record, recordIndex) => {
      data.push({
        key: recordIndex,
        customerId: record.customerId,
        dob: record.dob === null ? '' : moment(record.dob).format('YYYY-MM-DD'),
        hajSerialNo: record.hajSerialNo,
        groupName: record.groupName,
        groupNumber: record.groupNumber === null ? '' : record.groupNumber,
        customerName: record.customerName,
        flightId: record.flightId,
        flightType: record.flightType === 'D' ? 'Departure' : 'Return',
        flightNumber: record.depFrom + ' - ' + record.arrivalTo + ' (' + record.flightNumber + ')',
        flightDate: record.flightDate,
        returnDate: record.returnDate,
      });
    });

    const isEditing = (record) => record.key === this.props.editingRow;
    const edit = (record) => {
      this.props.form.setFieldsValue({
        customerId: '',
        flightId: '',
        ...record,
      });
      this.props.setEditingRow(record.key);
    };

    const cancel = () => {
      this.props.setEditingRow('');
    };

    const columns = [
      {
        title: 'CIF ID',
        dataIndex: 'customerId',
        key: 'customerId;',
        width: '6%',
        /* filteredValue: [this.state.searchedText],
        onFilter: (value, record) => {
          return (
            String(record.customerId).toLowerCase().includes(value.toLowerCase()) ||
            String(record.customerName).toLowerCase().includes(value.toLowerCase()) ||
            String(record.flightId).toLowerCase().includes(value.toLowerCase()) ||
            String(record.flightNumber).toLowerCase().includes(value.toLowerCase()) ||
            String(record.flightDate).toLowerCase().includes(value.toLowerCase()) ||
            String(record.returnDate).toLowerCase().includes(value.toLowerCase())
          );
        }, */
      },
      {
        title: 'Group No',
        dataIndex: 'groupNumber',
        key: 'groupNumber;',
        width: '7%',
        render: (_, { groupNumber }) => {
          let color = groupNumber.length >= 1 ? '#108ee9' : '';
          return (
            groupNumber && (
              <React.Fragment>
                <Tag color={color} key={groupNumber}>
                  {groupNumber}
                </Tag>
              </React.Fragment>
            )
          );
        },
      },
      {
        title: 'Customer Name',
        dataIndex: 'customerName',
        key: 'customerName;',
        width: '12%',
      },
      {
        title: 'DOB',
        dataIndex: 'dob',
        key: 'dob;',
        width: '8%',
      },
      {
        title: 'Haj Serial',
        dataIndex: 'hajSerialNo',
        key: 'hajSerialNo;',
        width: '8%',
      },
      {
        title: 'Flight Type',
        dataIndex: 'flightType',
        key: 'flightType;',
        width: '8%',
      },
      {
        title: 'Flight',
        dataIndex: 'flightNumber',
        key: 'flightNumber',
        width: '15%',
        editable: true,
        render: (_text, record) => {
          if (this.props.editingRow === record.key) {
            return (
              <Form.Item name="flightId">
                <Select
                  showSearch
                  placeholder="Select flight"
                  size="small"
                  optionFilterProp="children"
                  onSearch={onSearch}
                >
                  {this.state.flightList.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            );
          } else {
            return <td>{_text}</td>;
          }
        },
      },
      {
        title: 'Flight Date',
        dataIndex: 'flightDate',
        key: 'flightDate',
        width: '8%',
      },
      {
        title: 'Action',
        key: 'action',
        width: '10%',
        render: (_, record) => {
          const editable = isEditing(record);

          return editable ? (
            <BaseSpace>
              <BaseButton size="small" type="primary" onClick={() => save(record.key)}>
                Save
              </BaseButton>
              <Popconfirm title="Are you ure to cancel?" onConfirm={cancel} placement="topLeft">
                <BaseButton severity="info" size="small">
                  Cancel
                </BaseButton>
              </Popconfirm>
            </BaseSpace>
          ) : (
            <BaseSpace>
              <BaseButton size="small" type="primary" onClick={() => this.view(record.customerId, record.flightId)}>
                View
              </BaseButton>
              <BaseButton
                severity="info"
                size="small"
                disabled={this.props.editingRow !== ''}
                onClick={() => edit(record)}
              >
                Edit
              </BaseButton>
              {/* <BaseButton
                type="primary"
                danger
                size="small"
                onClick={() => this.delete(record.customerId, record.flightId)}
              >
                Delete
              </BaseButton> */}
            </BaseSpace>
          );
        },
      },
    ];

    const mergedColumns = columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === 'age' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    });

    const save = async (key) => {
      try {
        const row = await this.props.form.validateFields();
        const newData = [...data];
        const index = newData.findIndex((item) => key === item.key);

        if (index > -1) {
          const item = newData[index];

          newData.splice(index, 1, {
            ...item,
            ...row,
          });
          const values = newData[index];

          const updatedData = {
            newFlightId: values.flightId,
          };

          updateGridRecord(updatedData, `/customer/v1/flights/${item.customerId}/${item.flightId}`)
            .then((response) => {
              if (response.statusCode === 200) {
                this.setState({
                  recordSet: newData,
                });
                this.props.setEditingRow('');

                notificationController.success({
                  message: 'Customer flight updated.',
                });

                this.loadRecords();
              }
            })
            .catch((error) => {
              notificationController.error({
                message: 'Failed! ' + error.message,
              });
            });
          //this.props.setData(newData);
        } else {
          newData.push(row);
          //setData(newData);
          this.setState({
            recordSet: newData,
          });
          this.props.setEditingRow('');
        }
      } catch (errInfo) {
        console.log('Validate Failed:', errInfo);
      }
    };

    return (
      <div className="page-Container">
        <S.Card
          id="basic-table"
          title="Customer Flights / List"
          padding="1.25rem 1.25rem 0"
          extra={
            <BaseButton
              type="info"
              className="btn btn-dark"
              onClick={this.save}
              size="small"
              icon={<PlusCircleOutlined />}
              style={{}}
            >
              Add
            </BaseButton>
          }
        >
          <div className="search-box">
            <ST.InputsWrapper>
              <SearchInput
                placeholder="Search..."
                onChange={(e) => {
                  this.setState(
                    {
                      searchedText: e.target.value,
                    },
                    () => {
                      console.log('New state in ASYNC callback:', this.state.searchedText);
                      this.loadRecords(this.state.searchedText);
                    },
                  );
                }}
                style={{
                  width: 230,
                  marginBottom: 10,
                }}
                allowClear
                size="small"
              />
            </ST.InputsWrapper>
          </div>

          <Form form={this.props.form}>
            <BaseTable
              columns={mergedColumns}
              dataSource={data}
              pagination={{
                pageSize: 10,
                total: totalElements,
                showTotal: (showTotal) => (
                  <span style={{ left: 0, position: 'absolute', fontSize: 15 }}>Total {showTotal} records</span>
                ),
                onChange: (page) => this.loadRecords(this.state.searchedText, page - 1),
              }}
              key={(record) => record.key}
              loading={this.props.loading}
              bordered
              scroll={{ x: true }}
              rowClassName={(_record, index) => (index % 2 === 0 ? 'table-row-odd' : 'table-row-even')}
            />
          </Form>
        </S.Card>
        <Modal
          open={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
          width={1000}
          style={{ top: 20 }}
          footer={null}
        >
          <Row>
            <Descriptions title="Customer Flight / Info" layout="horizontal" bordered size="middle">
              <Descriptions.Item label="Customer ID">{viewRecord.customerId}</Descriptions.Item>
              <Descriptions.Item label="Customer Name">{viewRecord.customerName}</Descriptions.Item>
              <Descriptions.Item label="Date of Birth">{viewRecord.dob}</Descriptions.Item>
              <Descriptions.Item label="Passport No">{viewRecord.passportNo}</Descriptions.Item>
              <Descriptions.Item label="Group Name">{viewRecord.groupName}</Descriptions.Item>
              <Descriptions.Item label="Group Number">{viewRecord.groupNumber}</Descriptions.Item>
              <Descriptions.Item label="Haj Serial No">{viewRecord.hajSerialNo}</Descriptions.Item>
              <Descriptions.Item label="Package Name">{viewRecord.packageName}</Descriptions.Item>
              <Descriptions.Item label="Duration">{viewRecord.duration}</Descriptions.Item>
              <Descriptions.Item label="Flight Number">{viewRecord.flightNumber}</Descriptions.Item>
              <Descriptions.Item label="Dep From">{viewRecord.depFrom}</Descriptions.Item>
              <Descriptions.Item label="Arrival To">{viewRecord.arrivalTo}</Descriptions.Item>
              <Descriptions.Item label="Flight Date">{viewRecord.flightDate}</Descriptions.Item>
              <Descriptions.Item label="Arrival Date">{viewRecord.arrivalDate}</Descriptions.Item>
              <Descriptions.Item label="Agent ID">{viewRecord.agentId}</Descriptions.Item>
              <Descriptions.Item label="Agent Name">{viewRecord.agentName}</Descriptions.Item>
              {/*  <Descriptions.Item label="Activated">
                <Badge status="success" text={viewRecord.isActive} />
              </Descriptions.Item> */}
              <Descriptions.Item label="Created At" time>
                {moment(viewRecord.createdAt).format('YYYY-MM-DD')}
              </Descriptions.Item>
              {/* <Descriptions.Item label="Updated Date" span={2}>
                {moment(viewRecord.updatedData).format('YYYY-MM-DD')}
              </Descriptions.Item>
              <Descriptions.Item label="Created By">{viewRecord.createdBy}</Descriptions.Item>
              <Descriptions.Item label="Modified By">{viewRecord.modifiedBy}</Descriptions.Item>
              <Descriptions.Item label="Version">{viewRecord.version}</Descriptions.Item> */}
            </Descriptions>
          </Row>
        </Modal>
      </div>
    );
  }
}

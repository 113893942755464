interface ConfigPayment {
  id: number;
  name: string;
  title: string;
  cardId: string;
}

export const payment: ConfigPayment[] = [
  {
    id: 1,
    name: 'Hajj',
    title: 'Payment (BDT) | Hajj',
    cardId: 'HajjCard',
  },
  {
    id: 2,
    name: 'Umrah',
    title: 'Payment (BDT) | Umrah',
    cardId: 'UmrahCard',
  },
];

import React, { Component, useState } from 'react';
import { searchRecord } from '@app/utils/APIUtils';
import { Tag, Input } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';

const FormItem = Form.Item;

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  return <CustomerSearch {...props} navigation={navigate} isLoading={isLoading} setLoading={setLoading} />;
}

class CustomerSearch extends Component {
  render() {
    const AntWrappedOfficeForm = Form.create()(CustomerSearchForm);
    return (
      <div>
        <AntWrappedOfficeForm
          navigate={this.props.navigation}
          isLoading={this.props.isLoading}
          setLoading={this.props.setLoading}
        />
      </div>
    );
  }
}

class CustomerSearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      recordSet: [],
      isLoading: false,
      response: {},
      customerName: {
        value: '',
      },
      mobileNumber: {
        value: '',
      },
      groupNumber: {
        value: '',
      },
      nationalId: {
        value: '',
      },
      passportNo: {
        value: '',
      },
      tags: {
        value: '',
      },
    };
    this.view = this.view.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.clearForm = this.clearForm.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  view(id) {
    this.props.navigate(`/customer/360/view/${id}`);
  }

  handleSubmit(event) {
    event.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (
          values.customerName === undefined &&
          values.mobileNumber === undefined &&
          values.groupNumber === undefined &&
          values.nationalId === undefined &&
          values.passportNo === undefined &&
          values.tags === undefined
        ) {
          notificationController.warning({
            message: 'Please enter any of the filtering criteria!',
          });
        }

        const searchRequest = {
          customerName: values.customerName === '' ? null : values.customerName,
          mobileNumber: values.mobileNumber === '' ? null : values.mobileNumber,
          groupNumber: values.groupNumber === '' ? null : values.groupNumber,
          nationalId: values.nationalId === '' ? null : values.nationalId,
          passportNo: values.passportNo === '' ? null : values.passportNo,
          tags: values.tags,
        };
        console.log(values);
        //const searchRequest = Object.assign({}, values);
        console.log('searchRequest => ' + JSON.stringify(searchRequest));

        searchRecord(searchRequest, '/public/v1/customers/search')
          .then((response) => {
            this.setState({
              recordSet: response,
            });
            setTimeout(() => {
              this.props.setLoading(false);
            }, 1000);
          })
          .catch((error) => {
            notificationController.error({
              message: 'Failed! ' + error.message,
            });
          });
      }
    });
  }

  clearForm(event) {
    event.preventDefault();
    this.props.form.resetFields();
  }

  render() {
    const { recordSet } = this.state;
    const { getFieldDecorator } = this.props.form;
    //const vendorViews = [];
    const data = [];
    recordSet.forEach((record) => {
      data.push({
        customerId: record.customerId,
        customerName: record.customerName,
        professionName: record.profession,
        mobileNumber: record.mobileNumber,
        email: record.email,
        tags: record.tags,
      });
    });

    const columns = [
      {
        title: 'Customer Name',
        dataIndex: 'customerName',
        key: 'customerName;',
      },
      {
        title: 'Profession',
        dataIndex: 'professionName',
        key: 'professionName',
      },
      {
        title: 'Mobile Number',
        dataIndex: 'mobileNumber',
        key: 'mobileNumber',
      },
      {
        title: 'Tag',
        dataIndex: 'tags',
        key: 'tags',
        render: (_, { tags }) => (
          <React.Fragment>
            {tags.map((tag) => {
              let color = tag.length > 4 ? 'geekblue' : 'green';
              return (
                <Tag color={color} key={tag}>
                  {tag.toUpperCase()}
                </Tag>
              );
            })}
          </React.Fragment>
        ),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Action',
        key: 'action',
        width: '10%',
        render: (_, record) => {
          return (
            <BaseSpace>
              <BaseButton size="small" type="primary" onClick={() => this.view(record.customerId)}>
                360{'\u00b0'} View
              </BaseButton>
            </BaseSpace>
          );
        },
      },
    ];

    return (
      <BaseCol xs={24} sm={24} xl={24}>
        <BaseCard id="validation form" title="Search Customer" padding="1.25rem">
          <Form
            onSubmit={this.handleSubmit}
            className="service-form"
            name="basic"
            labelCol={{ span: 7 }}
            style={{ maxWidth: 1100 }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <BaseRow gutter={[30, 30]}>
              <BaseCol xs={24} sm={20} xl={8}>
                <FormItem label="Cutomer Name">
                  {getFieldDecorator('customerName', {
                    rules: [{ required: false, message: 'Cutomer name is required!' }],
                  })(
                    <Input
                      name="customerName"
                      size="small"
                      placeholder="Enter customer name"
                      value={this.state.customerName.value}
                    />,
                  )}
                </FormItem>

                <FormItem label="Mobile No">
                  {getFieldDecorator('mobileNumber', {
                    rules: [{ required: false, message: 'Mobile number is required!' }],
                  })(
                    <Input
                      addonBefore="+88"
                      size="small"
                      name="mobileNumber"
                      placeholder="Enter mobile number"
                      maxLength={11}
                      value={this.state.mobileNumber.value}
                    />,
                  )}
                </FormItem>
              </BaseCol>
              <BaseCol xs={24} sm={20} xl={8}>
                <FormItem label="Group No">
                  {getFieldDecorator('groupNumber', {
                    rules: [{ required: false, message: 'Group number is required!' }],
                  })(
                    <Input
                      name="groupNumber"
                      size="small"
                      placeholder="Enter group number"
                      value={this.state.groupNumber.value}
                    />,
                  )}
                </FormItem>

                <FormItem label="NID">
                  {getFieldDecorator('nationalId', {
                    rules: [{ required: false, message: 'NID is required!' }],
                  })(
                    <Input
                      name="nationalId"
                      size="small"
                      placeholder="Enter national id number"
                      value={this.state.nationalId.value}
                    />,
                  )}
                </FormItem>
              </BaseCol>
              <BaseCol xs={24} sm={20} xl={8}>
                <FormItem label="Passport No">
                  {getFieldDecorator('passportNo', {
                    rules: [{ required: false, message: 'Passport number is required!' }],
                  })(
                    <Input
                      name="passportNo"
                      size="small"
                      placeholder="Enter passport number"
                      value={this.state.passportNo.value}
                    />,
                  )}
                </FormItem>
                <FormItem label="Tags">
                  {getFieldDecorator('tags', {
                    rules: [{ required: false, message: 'Tags is required!' }],
                  })(<Input name="tags" size="small" placeholder="Enter tags" value={this.state.tags.value} />)}
                </FormItem>
              </BaseCol>
            </BaseRow>

            <FormItem
              wrapperCol={{
                offset: 7,
                span: 9,
              }}
              style={{
                marginTop: 20,
              }}
            >
              <BaseRow gutter={[10, 10]} wrap={false}>
                <BaseCol span={12} xs={17} sm={12} xl={12}>
                  <BaseButton block type="primary" htmlType="submit" size="small">
                    Search
                  </BaseButton>
                </BaseCol>
                <BaseCol span={12} xs={17} sm={12} xl={12}>
                  <BaseButton block type="ghost" onClick={this.clearForm} size="small">
                    Reset
                  </BaseButton>
                </BaseCol>
              </BaseRow>
            </FormItem>
          </Form>

          <div className="page-Container" style={{ marginTop: 20 }}>
            <BaseTable
              columns={columns}
              dataSource={data}
              pagination={{ 
                pageSize: 5, 
                showTotal: (showTotal) => (
                  <span style={{ left: 0, position: "absolute", fontSize: 15 }}>
                    Total {showTotal} records
                  </span>
                ),
                onChange: (page) => this.handlePageChange(page) 
              }}
              loading={this.props.loading}
              scroll={{ x: true }}
              bordered
            />
          </div>
        </BaseCard>
      </BaseCol>
    );
  }
}

import React, { Component, useState } from 'react';
import { /* deleteItemByID, */ getRecordSet } from '@app/utils/APIUtils';
import {
  //Modal,
  Tag,
  // Row,
  // Descriptions,
  // Badge
} from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import * as S from '@app/components/tables/Tables/Tables.styles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput';
import * as ST from '@app/pages/uiComponentsPages/UIComponentsPage.styles';
//import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import { LIST_SIZE } from '@app/constants';
import { notificationController } from '@app/controllers/notificationController';

export default function (props) {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  return (
    <PaymentList
      {...props}
      loading={loading}
      setLoading={setLoading}
      page={page}
      setPage={setPage}
      navigate={navigate}
    />
  );
}

class PaymentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      recordSet: [],
      isLoading: false,
      response: {},
      searchedText: '',
      page: 0,
      size: 10,
      totalElements: 0,
      totalPages: 0,
      last: true,
    };
    this.save = this.save.bind(this);
    this.edit = this.edit.bind(this);
    //this.delete = this.delete.bind(this);
    this.loadRecords = this.loadRecords.bind(this);
  }

  async loadRecords(searchedText, page, size = LIST_SIZE) {
    this.setState({
      recordSet: [],
      page: 0,
      size: 10,
      totalElements: 0,
      totalPages: 0,
      last: true,
      isLoading: false,
    });

    this.props.setLoading(true);

    let promise = getRecordSet(page, size, '/customer/v1/payment?page=', searchedText);

    if (!promise) {
      return;
    }

    await promise
      .then((response) => {
        const recordSet = this.state.recordSet.slice();

        if (!(searchedText === '' || searchedText === undefined)) {
          this.setState({
            recordSet: recordSet.concat(response.content),
            page: response.page,
            size: response.size,
            totalElements: response.totalElements,
            totalPages: response.totalPages,
            last: response.last,
          });

          this.props.setLoading(false);
        } else {
          setTimeout(() => {
            this.setState({
              recordSet: recordSet.concat(response.content),
              page: response.page,
              size: response.size,
              totalElements: response.totalElements,
              totalPages: response.totalPages,
              last: response.last,
            });

            this.props.setLoading(false);
          }, 500);
        }
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
        this.props.setLoading(false);
      });
    console.log('Component mounted.');
  }

  componentDidMount() {
    this.loadRecords();
  }

  save() {
    this.props.navigate('/customer/payment/new');
  }

  edit(id) {
    this.props.navigate(`/customer/payment/edit/${id}`);
  }

  //? Hide delete option
  /* delete(id) {
    const { confirm } = Modal;
    return new Promise((resolve, reject) => {
      confirm({
        title: 'Are you sure you want to Delete ?',
        onOk: () => {
          resolve(true);

          //onRemoveFunctionality here
          deleteItemByID(id, '/customer/v1/payment/').then((res) => {
            if (res) this.setState({ recordSet: this.state.recordSet.filter((record) => record.paymentId !== id) });
            notificationController.success({
              message: 'Payment deleted successfully.',
            });
          });
        },
        onCancel: () => {
          reject(true);
        },
      });
    });
  } */

  view(id) {
    this.props.navigate(`/customer/payment/edit/${id}`);
  }

  render() {
    const { recordSet, totalElements } = this.state;

    const numberFormat = (value) => new Intl.NumberFormat('en-IN').format(value);

    //const vendorViews = [];
    const data = [];
    recordSet.forEach((record, recordIndex) => {
      data.push({
        key: recordIndex,
        paymentId: record.paymentId,
        customerName: record.customerName,
        mobileNo: record.mobileNo,
        packageName: record.packageName,
        serviceName: record.serviceName,
        amounts: '৳'.concat(' ').concat(numberFormat(record.amount)),
        paidAmount: record.amount,
        paymentModeName: record.paymentModeName,
        voucherNo: record.voucherNo,
        remarks: record.remarks,
        paymentDate: record.paymentDate,
      });
    });

    const columns = [
      {
        title: 'Customer Name',
        dataIndex: 'customerName',
        key: 'customerName;',
        width: '20%',
      },
      {
        title: 'Mobile No',
        dataIndex: 'mobileNo',
        key: 'mobileNo',
      },
      /* {
        title: 'Service Name',
        dataIndex: 'serviceName',
        key: 'serviceName',
      },
      {
        title: 'Package Name',
        dataIndex: 'packageName',
        key: 'packageName',
      }, */
      {
        title: 'Voucher No',
        dataIndex: 'voucherNo',
        key: 'voucherNo',
      },
      {
        title: 'Payment Mode',
        dataIndex: 'paymentModeName',
        key: 'paymentModeName',
      },
      {
        title: 'Payment Date',
        dataIndex: 'paymentDate',
        key: 'paymentDate',
      },
      {
        title: 'Amount',
        dataIndex: 'amounts',
        key: 'amounts',
        render: (_, { amounts, paidAmount }) => {
          let color = paidAmount > 0 ? 'green' : 'blue';
          return (
            <React.Fragment>
              <Tag color={color} key={amounts} style={{ fontSize: 16, fontWeight: 600 }}>
                {amounts}
              </Tag>
            </React.Fragment>
          );
        },
      },
      {
        title: 'Status',
        dataIndex: 'paidAmount',
        key: 'paidAmount',
        render: (_, { paidAmount }) => (
          <React.Fragment>
            <Tag color={paidAmount > 0 ? 'green' : 'blue'} key={paidAmount} style={{ fontSize: 16, fontWeight: 600 }}>
              {paidAmount > 0 ? 'PAID' : 'REFUND'}
            </Tag>
          </React.Fragment>
        ),
      },
      {
        title: 'Action',
        key: 'action',
        render: (_, record) => {
          return (
            <BaseSpace>
              <BaseButton size="small" type="primary" onClick={() => this.view(record.paymentId)}>
                View
              </BaseButton>

              <BaseButton severity="info" size="small" onClick={() => this.edit(record.paymentId)}>
                Edit
              </BaseButton>

              {/* <BaseButton type="primary" danger size="small" onClick={() => this.delete(record.paymentId)}>
                Delete
              </BaseButton> */}
            </BaseSpace>
          );
        },
      },
    ];

    return (
      <div className="page-Container">
        <S.Card
          id="basic-table"
          title="Payment List"
          padding="1.25rem 1.25rem 0"
          extra={
            <BaseButton
              type="info"
              className="btn btn-dark"
              onClick={this.save}
              size="small"
              icon={<PlusCircleOutlined />}
              style={{}}
            >
              Add
            </BaseButton>
          }
        >
          <div className="search-box">
            <ST.InputsWrapper>
              <SearchInput
                placeholder="Search..."
                onChange={(e) => {
                  this.setState(
                    {
                      searchedText: e.target.value,
                    },
                    () => {
                      console.log('New state in ASYNC callback:', this.state.searchedText);
                      this.loadRecords(this.state.searchedText);
                    },
                  );
                }}
                style={{
                  width: 230,
                  marginBottom: 10,
                }}
                allowClear
                size="small"
              />
            </ST.InputsWrapper>
          </div>
          <BaseTable
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: 10,
              total: totalElements,
              showTotal: (showTotal) => (
                <span style={{ left: 0, position: 'absolute', fontSize: 15 }}>Total {showTotal} records</span>
              ),
              onChange: (page) => this.loadRecords(page - 1),
            }}
            rowKey={(record) => record.key}
            loading={this.props.loading}
            scroll={{ x: true }}
            bordered
          />
        </S.Card>
        {/* <Modal
                        open={this.state.open}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        okButtonProps={{ disabled: true }}
                        cancelButtonProps={{ disabled: true }}
                        width={1000}
                        style={{ top: 20 }}
                    >
                        <Row>
                            <Descriptions title="Service Info" layout="vertical" bordered >
                                <Descriptions.Item label="Service Id">
                                    {this.state.viewRecord.id}
                                </Descriptions.Item>
                                <Descriptions.Item  label="Service Name">
                                    {this.state.viewRecord.serviceName}
                                </Descriptions.Item>
                                <Descriptions.Item label="Descriptions">
                                    {this.state.viewRecord.descriptions}
                                </Descriptions.Item>
                                <Descriptions.Item label="Parent Service">
                                    {this.state.parentServiceName.serviceName}
                                </Descriptions.Item>
                                <Descriptions.Item label="Record Status">
                                    {this.state.viewRecord.status}
                                </Descriptions.Item>
                                <Descriptions.Item label="Activated">
                                    <Badge status="success" text={this.state.viewRecord.isActive} />
                                </Descriptions.Item>
                                <Descriptions.Item label="Created Date" time>
                                    {moment(this.state.viewRecord.createdDate).format('YYYY-MM-DD')}
                                </Descriptions.Item>
                                <Descriptions.Item label="Updated Date" span={2}>
                                    {moment(this.state.viewRecord.updatedData).format('YYYY-MM-DD')}
                                </Descriptions.Item>
                                <Descriptions.Item label="Created By">
                                    {this.state.viewRecord.createdBy}
                                </Descriptions.Item>
                                <Descriptions.Item label="Modified By">
                                    {this.state.viewRecord.modifiedBy}
                                </Descriptions.Item>
                                <Descriptions.Item label="Version">
                                    {this.state.viewRecord.version}
                                </Descriptions.Item>
                            </Descriptions>
                        </Row>
                    </Modal> */}
      </div>
    );
  }
}

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PieChartCustomLegend } from '../../common/charts/PieChartCustomLegend';
import { HealthFactor /* healthChartData */ } from 'constants/healthChartData';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { getRecords } from '@app/utils/APIUtils';

export const ExpensesPaymentCard: React.FC = () => {
  const [chartValue, setChartValue] = useState<HealthFactor[]>([]);
  const { t } = useTranslation();
  const numberFormat = (value: number) => new Intl.NumberFormat('en-IN').format(value);
  
  useEffect(() => {
    const promise = getRecords('/admin/v1/dashboard/expense_payment/total');
    promise.then((res) => setChartValue(res));
  }, []);

  const chartData = chartValue.map((item) => ({
    ...item,
    name: t(item.name),
    description: t(item.description),
  }));

  const legendData = chartData.map((item) => ({ ...item, value: '৳ ' + `${numberFormat(item.value)}` }));

  return (
    <BaseCard title="Expenses & Payments | Current year" padding={'0 2rem 2.5rem'}>
      <PieChartCustomLegend name="HAJLINK" chartData={chartData} legendData={legendData} height="250px" />
    </BaseCard>
  );
};

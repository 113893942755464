import React, { Component, useState } from 'react';
import { getRecords, saveRecord } from '@app/utils/APIUtils';
//import './AddService.css';
import { NAME_MIN_LENGTH, NAME_MAX_LENGTH, DESCRIPTION_MAX_LENGTH } from '@app/constants';
import { Input, Select } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { handleEnter } from '@app/helper/EnterIndexHelper';

const FormItem = Form.Item;
const { TextArea } = Input;

/* const onSearch = (value) => {
    console.log('search:', value);
}; */

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  return <AddService {...props} navigation={navigate} isLoading={isLoading} setLoading={setLoading} />;
}

class AddService extends Component {
  render() {
    const AntWrappedOfficeForm = Form.create()(AddServiceForm);
    return (
      <div>
        <AntWrappedOfficeForm
          navigate={this.props.navigation}
          isLoading={this.props.isLoading}
          setLoading={this.props.setLoading}
        />
      </div>
    );
  }
}

class AddServiceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceId: {
        value: '',
      },
      docId: [],
      serviceName: {
        value: '',
      },
      descriptions: {
        value: '',
      },
      parentServiceId: {
        value: '',
      },
      parentServiceList: [],
      docList: [],
      recordStat: {
        value: '',
      },
      referenceStatusItems: [
        { value: 0, label: 'Inactive' },
        { value: 1, label: 'Active' },
      ],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isFormInvalid = this.isFormInvalid.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  componentDidMount() {
    this.props.setLoading(true);

    let promise, promise1;

    promise = getRecords('/setup/v1/services/parent/all');

    promise
      .then((response) => {
        this.setState({
          parentServiceList: response.map((item) => ({ value: item.id, label: item.ServiceName })),
        });
      })
      .catch((error) => {
        if (error) this.props.setLoading(false);
      });

    promise1 = getRecords('/domain/data/doctypes/v1/all');

    promise1
      .then((response) => {
        this.setState({
          docList: response,
        });
      })
      .catch((error) => {
        if (error) this.props.setLoading(false);
      });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        //const saveRequest = Object.assign({}, values);
        const saveRequest = {
          serviceName: values.serviceName,
          parentServiceId: values.parentServiceId,
          descriptions: values.descriptions,
          docId: this.state.docId,
        };
        //saveRequest.referenceStatusId  = 111;
        console.log('saveRequest => ' + JSON.stringify(saveRequest));

        if (Object.keys(saveRequest.docId).length === 0) {
          notificationController.warning({
            message: 'Please select document type!',
          });
        } else {
          saveRecord(saveRequest, '/setup/v1/services')
            .then((response) => {
              if (response) {
                notificationController.success({
                  message: 'Service created.',
                });
                this.props.navigate('/service/list');
              }
            })
            .catch((error) => {
              notificationController.error({
                message: 'Failed! ' + error.message,
              });
            });
        }
      }
    });
  }

  onCancelForm(event) {
    event.preventDefault();
    this.props.navigate('/service/list');
  }

  isFormInvalid() {
    return !(this.state.serviceName.validateStatus === 'success');
  }

  render() {
    const { docList } = this.state;
    const { getFieldDecorator } = this.props.form;

    const data = [];
    docList.forEach((record, recordIndex) => {
      data.push({
        key: recordIndex,
        docId: record.id,
        docName: record.DocName,
      });
    });

    const columns = [
      {
        title: 'ID',
        dataIndex: 'docId',
        key: 'docId',
      },
      {
        title: 'Document Name',
        dataIndex: 'docName',
        key: 'docName;',
      },
    ];

    const docSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          docId: selectedRows.map((item) => item.docId),
        });

        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      },
    };

    return (
      <BaseCol xs={24} sm={24} xl={24}>
        <BaseCard id="validation form" title="Add Service" padding="1.25rem">
          <Form
            onSubmit={this.handleSubmit}
            className="service-form"
            name="basic"
            labelCol={{ span: 6 }}
            //wrapperCol={{ span: 16 }}
            style={{ maxWidth: 500 }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <FormItem label="Service Name">
              {getFieldDecorator('serviceName', {
                rules: [{ required: true, message: 'Please input service name!' }],
              })(
                <Input
                  size="small"
                  name="serviceName"
                  autoComplete="off"
                  placeholder="Enter service name"
                  onKeyDown={(e) => handleEnter(e)}
                  value={this.state.serviceName.value}
                />,
              )}
            </FormItem>

            <FormItem label="Parent Service">
              {getFieldDecorator('parentServiceId', {
                rules: [{ required: false, message: 'Please select parent service!' }],
              })(
                <Select
                  showSearch
                  size="small"
                  placeholder="Please Select"
                  name="parentServiceId"
                  onKeyDown={(e) => handleEnter(e)}
                  value={this.state.parentServiceId.value}
                >
                  {this.state.parentServiceList.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </FormItem>

            <FormItem label="Descriptions">
              {getFieldDecorator('descriptions', {
                rules: [{ required: true, message: 'Please input descriptions!' }],
              })(
                <TextArea
                  size="small"
                  name="descriptions"
                  autoComplete="off"
                  placeholder="Enter descriptions"
                  onKeyDown={(e) => handleEnter(e)}
                  value={this.state.descriptions.value}
                />,
              )}
            </FormItem>

            {/* <FormItem label="Status">
                    {getFieldDecorator('recordStat', {
                        rules: [{ required: true, message: 'Please select status!' }],
                    })(
                        <Select  showSearch
                            placeholder="Select status"
                            name="recordStat"
                            value={this.state.recordStat.value}
                            optionFilterProp="children"
                            onSearch={onSearch}
                        >
                            {this.state.referenceStatusItems.map((item, index) => <Select.Option value={item.value} key={index}>{item.label}</Select.Option>)}
                        </Select>
                    )}
                </FormItem> */}
            <FormItem label="Document Type" required={true}>
              <BaseTable
                rowSelection={{
                  type: 'checkbox',
                  ...docSelection,
                }}
                columns={columns}
                dataSource={data}
                pagination={{ pageSize: 5, onChange: (page) => this.handlePageChange(page) }}
                loading={this.props.loading}
                bordered
                onKeyDown={(e) => handleEnter(e)}
              />
            </FormItem>

            <FormItem
              wrapperCol={{
                offset: 6,
                span: 9,
              }}
            >
              <BaseButtonsGroup onCancel={this.onCancelForm} />
            </FormItem>
          </Form>
        </BaseCard>
      </BaseCol>
    );
  }

  // Validation Functions

  validateServiceName = (serviceName) => {
    if (serviceName.length < NAME_MIN_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `Activity Name is too short (Minimum ${NAME_MIN_LENGTH} characters needed.)`,
      };
    } else if (serviceName.length > NAME_MAX_LENGTH) {
      return {
        validationStatus: 'error',
        errorMsg: `Activity Name is too long (Maximum ${NAME_MAX_LENGTH} characters allowed.)`,
      };
    } else {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }
  };

  validateDescriptions = (descriptions) => {
    if (!descriptions) {
      return {
        validateStatus: 'error',
        errorMsg: 'Descriptions may not be empty',
      };
    }

    if (descriptions.length > DESCRIPTION_MAX_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `Descriptions is too long (Maximum ${DESCRIPTION_MAX_LENGTH} characters allowed)`,
      };
    }

    return {
      validateStatus: null,
      errorMsg: null,
    };
  };
}

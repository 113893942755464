import React, { Component, useState } from 'react';
import { saveRecord, getRecords, getRecordById } from '@app/utils/APIUtils';
import { Descriptions, Input, Select, Tag } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
//import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { handleEnter } from '@app/helper/EnterIndexHelper';

const FormItem = Form.Item;

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  return <AddRoom {...props} navigation={navigate} isLoading={isLoading} setLoading={setLoading} params={params} />;
}

class AddRoom extends Component {
  render() {
    const AntWrappedOfficeForm = Form.create()(AddRoomForm);
    return (
      <div>
        <AntWrappedOfficeForm
          dataId={this.props.params}
          navigate={this.props.navigation}
          isLoading={this.props.isLoading}
          setLoading={this.props.setLoading}
        />
      </div>
    );
  }
}

class AddRoomForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hotelId: this.props.dataId.id,
      roomTypeId: {
        value: '',
      },
      roomNo: {
        value: '',
      },
      totalBeds: {
        value: '',
      },
      remarks: {
        value: '',
      },
      totalRooms: {
        value: '',
      },
      roomTypeList: [],
      hotelList: [],
      hotelInfo: {},
      location: '',
      isRequired: false,
      isReadonly: false,
      disabled: false,
      occupied: '',
      vacant: '',
      total: '',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isFormInvalid = this.isFormInvalid.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);
    this.handleHotelChange = this.handleHotelChange.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  componentDidMount() {
    let promise1, promise2, promise3, promise4;

    promise1 = getRecords('/domain/data/roomtypes/v1/all');

    //? If hotelId is not null or undefined
    if (this.state.hotelId != undefined || this.state.hotelId != null) {
      this.props.form.setFieldsValue({
        hotelId: this.state.hotelId,
      });
      promise2 = getRecordById('/setup/v1/hotels/', this.state.hotelId);
      promise4 = getRecords(`/setup/v1/rooms/summary/${this.state.hotelId}/hotel`);
    }

    promise3 = getRecords('/setup/v1/hotels/list/all');

    this.setState({
      isLoading: true,
    });

    //? To Get room type drop-down list
    promise1
      .then((response) => {
        this.setState({
          roomTypeList: response.map((item) => ({ value: item.id, label: item.RomTypName })),
        });
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed !' + error.message,
        });
        this.setState({
          isLoading: false,
        });
      });

    //? To Get hotel info from hotel list UI
    //? If hotelId is not null or undefined
    if (this.state.hotelId != undefined || this.state.hotelId != null) {
      promise2
        .then((response) => {
          this.setState({
            hotelInfo: response,
            location: response.LocationBean.LocName,
            disabled: true,
          });

          this.props.form.setFieldsValue({
            hotel: response.id,
          });
        })
        .catch((error) => {
          notificationController.error({
            message: 'Failed !' + error.message,
          });
          this.setState({
            isLoading: false,
          });
        });

      //? To Get room symmary info from hotel list UI
      promise4
        .then((response) => {
          if (response != null || response != '') {
            this.setState({
              occupied: response.occupied,
              vacant: response.vacant,
              total: response.total,
            });
          }
        })
        .catch((error) => {
          notificationController.error({
            message: 'Failed !' + error.message,
          });
          this.setState({
            isLoading: false,
          });
        });
    }
    //? To Get hotel drop-down list
    promise3
      .then((response) => {
        this.setState({
          hotelList: response.map((item) => ({
            value: item.id,
            label: item.HotelName + ' - (' + item.LocationBean.LocName + ')',
          })),
        });
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed !' + error.message,
        });
        this.setState({
          isLoading: false,
        });
      });
  }

  //? On chnage hotel drop-down
  handleHotelChange = (value) => {
    try {
      //? To Get hotel info
      getRecordById('/setup/v1/hotels/', value)
        .then((response) => {
          this.setState({
            hotelInfo: response,
            location: response.LocationBean.LocName,
          });
        })
        .catch((error) => {
          notificationController.error({
            message: 'Failed !' + error.message,
          });
          this.setState({
            isLoading: false,
          });
        });

      //? To Get room summary info
      getRecords(`/setup/v1/rooms/summary/${value}/hotel`)
        .then((response) => {
          //this.props.form.resetFields(['hotel']);
          if (response != null || response != '') {
            this.setState({
              occupied: response.occupied,
              vacant: response.vacant,
              total: response.total,
            });
          }
        })
        .catch((error) => {
          if (error)
            this.setState({
              occupied: '',
              vacant: '',
              total: '',
            });
          this.setState({
            isLoading: false,
          });
        });
    } catch (error) {}
  };

  //? To save data
  handleSubmit(event) {
    event.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const saveRequest = Object.assign({}, values);
        //saveRequest.referenceStatusId  = 111;
        console.log('saveRequest => ' + JSON.stringify(saveRequest));

        saveRecord(saveRequest, '/setup/v1/rooms')
          .then((response) => {
            if (response)
              notificationController.success({
                message: 'Room created.',
              });
            this.props.navigate('/room/list');
          })
          .catch((error) => {
            notificationController.error({
              message: 'Failed! ' + error.message,
            });
          });
      }
    });
  }

  onCancelForm(event) {
    event.preventDefault();
    if (this.state.hotelId != undefined) {
      this.props.navigate('/hotel/list');
    } else {
      this.props.navigate('/room/list');
    }
  }

  isFormInvalid() {
    return !(this.state.agentName.validateStatus === 'success');
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { hotelInfo, occupied, vacant, total } = this.state;

    return (
      <BaseCard id="validation form" title="Add Room" padding="1.25rem">
        <BaseRow gutter={[30, 30]}>
          <BaseCol xs={24} sm={20} xl={12}>
            <Form
              onSubmit={this.handleSubmit}
              className="room-form"
              name="basic"
              labelCol={{ span: 5 }}
              style={{ maxWidth: 500 }}
              initialValues={{ remember: true }}
              autoComplete="off"
            >
              {/* <FormItem label="Hotel Id">
                {getFieldDecorator('hotelId', {
                  rules: [{ required: true, message: 'Hotel id is required!' }],
                })(
                  <Input
                    name="hotelId"
                    size="small"
                    placeholder="Enter hotel id"
                    value={this.state.hotelId}
                    disabled={true}
                  />,
                )}
              </FormItem> */}

              <FormItem label="Hotel">
                {getFieldDecorator('hotel', {
                  rules: [{ required: true, message: 'Hotel is required!' }],
                })(
                  <Select
                    showSearch
                    placeholder="Select hotel"
                    size="small"
                    name="hotel"
                    onKeyDown={(e) => handleEnter(e)}
                    disabled={this.state.disabled}
                    onChange={this.handleHotelChange}
                  >
                    {this.state.hotelList.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </FormItem>

              <FormItem label="Room Type">
                {getFieldDecorator('roomTypeId', {
                  rules: [{ required: true, message: 'Room type is required!' }],
                })(
                  <Select
                    showSearch
                    placeholder="Select room type"
                    size="small"
                    name="roomTypeId"
                    onKeyDown={(e) => handleEnter(e)}
                  >
                    {this.state.roomTypeList.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </FormItem>

              <FormItem label="Total Rooms">
                {getFieldDecorator('totalRooms', {
                  rules: [
                    { required: false, message: 'Total rooms is required!' },
                    {
                      pattern: /^(?:\d*)$/,
                      message: 'Only number allowed',
                    },
                  ],
                })(
                  <Input
                    name="totalRooms"
                    placeholder="Enter total rooms"
                    size="small"
                    value={this.state.totalRooms.value}
                    onKeyDown={(e) => handleEnter(e)}
                  />,
                )}
              </FormItem>

              <FormItem label="Beds / Room">
                {getFieldDecorator('totalBeds', {
                  rules: [
                    { required: true, message: 'Total beds / room is required!' },
                    {
                      pattern: /^(?:\d*)$/,
                      message: 'Only number allowed',
                    },
                  ],
                })(
                  <Input
                    name="totalBeds"
                    placeholder="Enter beds / room"
                    size="small"
                    value={this.state.totalBeds.value}
                    onKeyDown={(e) => handleEnter(e)}
                  />,
                )}
              </FormItem>

              {this.state.hotelId === undefined ? (
                <FormItem label="Room No">
                  {getFieldDecorator('roomNo', {
                    rules: [{ required: false, message: 'Room no is required!' }],
                  })(
                    <Input
                      name="roomNo"
                      placeholder="Enter room no"
                      size="small"
                      value={this.state.roomNo.value}
                      onKeyDown={(e) => handleEnter(e)}
                    />,
                  )}
                </FormItem>
              ) : null}

              <FormItem label="Remarks">
                {getFieldDecorator('remarks', {
                  rules: [{ required: false, message: 'Remarks is required!' }],
                })(
                  <Input
                    name="remarks"
                    placeholder="Enter remarks"
                    size="small"
                    value={this.state.remarks.value}
                    onKeyDown={(e) => handleEnter(e)}
                  />,
                )}
              </FormItem>

              <FormItem
                wrapperCol={{
                  offset: 5,
                  span: 9,
                }}
              >
                <BaseButtonsGroup onCancel={this.onCancelForm} />
              </FormItem>
            </Form>
          </BaseCol>

          <BaseCol xs={24} sm={12} xl={12}>
            <Descriptions title="Hotel Info" bordered size="middle">
              <Descriptions.Item label="Hotel Name" span={3}>
                {hotelInfo.HotelName}
              </Descriptions.Item>
              <Descriptions.Item label="Hotel Type" span={3}>
                {hotelInfo.HotelType}
              </Descriptions.Item>
              <Descriptions.Item label="Location" span={3}>
                {this.state.location}
              </Descriptions.Item>
              <Descriptions.Item label="Room Summary :" span={3}></Descriptions.Item>
              <Descriptions.Item label="Total Occupied" span={3}>
                <Tag color="blue" key={occupied} style={{ fontSize: 15, fontWeight: 600 }}>
                  {occupied}
                </Tag>
              </Descriptions.Item>
              <Descriptions.Item label="Total Vacant" span={3}>
                <Tag color={vacant > 0 ? 'green' : 'red'} key={vacant} style={{ fontSize: 15, fontWeight: 600 }}>
                  {vacant}
                </Tag>
              </Descriptions.Item>
              <Descriptions.Item label="Total" span={3}>
                <Tag color="gold" key={total} style={{ fontSize: 15, fontWeight: 600 }}>
                  {total}
                </Tag>
              </Descriptions.Item>
            </Descriptions>
          </BaseCol>
        </BaseRow>
      </BaseCard>
    );
  }
}

import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { Form, Input } from 'antd';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';

const FormItem = Form.Item;
const SearchCustomer = ({ searchForm, onFinish, customerName, mobileNumber, groupNumber, onKeyDown }) => {
  const [form] = Form.useForm();

  const clearForm = (event) => {
    event.preventDefault();
    form.resetFields();
    searchForm.resetFields();
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      className="search-form"
      name="search-form"
      style={{ maxWidth: 520 }}
      initialvalues={{ remember: true }}
      autoComplete="off"
    >
      <BaseCard id="search" padding="1.25rem" style={{ marginBottom: 20 }}>
        <BaseRow gutter={[0, 0]}>
          <BaseCol xs={24} sm={24} xl={24}>
            <div style={{ marginBottom: 10 }}>
              <span style={{ color: '#01509A', fontWeight: 600 }}>Search Customer By (any one field)</span>
            </div>
            <FormItem className="ant-latest-form-item">
              <Input.Group compact>
                <FormItem label="Name" name={customerName} style={{ marginRight: '15px', width: '48%' }} className="ant-latest-form-item">
                  <Input placeholder="Enter customer name" onKeyDown={onKeyDown} />
                </FormItem>
                <FormItem label="Mobile" name={mobileNumber} style={{ width: '48%' }} className="ant-latest-form-item">
                  <Input placeholder="Enter mobile number" onKeyDown={onKeyDown} />
                </FormItem>
              </Input.Group>
            </FormItem>

            <FormItem label="Group Number" name={groupNumber} className="ant-latest-form-item">
              <Input placeholder="Enter group number" onKeyDown={onKeyDown} />
            </FormItem>
          </BaseCol>
          <BaseCol xs={24} sm={12} xl={12}>
            <FormItem style={{ margin: '0 0 0 0px' }}>
              <BaseRow gutter={[10, 10]} wrap={false} style={{ justifyContent: 'space-between' }}>
                <BaseCol span={12} xs={12} sm={12} xl={12}>
                  <BaseButton block type="primary" htmlType="submit" size="small">
                    Search
                  </BaseButton>
                </BaseCol>
                <BaseCol span={12} xs={12} sm={12} xl={12}>
                  <BaseButton block type="ghost" size="small" onClick={clearForm}>
                    Reset
                  </BaseButton>
                </BaseCol>
              </BaseRow>
            </FormItem>
          </BaseCol>
        </BaseRow>
      </BaseCard>
    </Form>
  );
};

export default SearchCustomer;

import React, { useState, useEffect, useCallback } from 'react';
import { getRecordById, getRecordSet } from '@app/utils/APIUtils';
import { Modal, Tag, Row, Descriptions, Badge, Button } from 'antd';
import { ImportOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import * as S from '@app/components/tables/Tables/Tables.styles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput';
import * as ST from '@app/pages/uiComponentsPages/UIComponentsPage.styles';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import { LIST_SIZE } from '@app/constants';
import moment from 'moment';
import debounce from 'lodash.debounce';

const CustomerList = () => {
  const [loading, setLoading] = useState(false);
  const [recordSet, setRecordSet] = useState([]);
  const [searchedText, setsearchedText] = useState('');
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [visible, setVisible] = useState(false);
  const [viewRecord, setViewRecord] = useState({});
  const [tags, setTags] = useState([]);
  const navigate = useNavigate();

  const loadRecords = useCallback(
    debounce((searchText, page, size = LIST_SIZE) => {
      setLoading(true);
      setRecordSet([]);
      getRecordSet(page, size, '/public/v1/customers?page=', searchText)
        .then((response) => {
          setRecordSet(response.content);
          setPage(response.page);
          setTotalElements(response.totalElements);
        })
        .catch((error) => {
          notificationController.error({ message: 'Failed! ' + error.message });
        })
        .finally(() => setLoading(false));
    }, 500),
    [],
  );

  useEffect(() => {
    loadRecords(searchedText, page);
  }, [searchedText, page, loadRecords]);

  const handleSearch = (e) => {
    setsearchedText(e.target.value);
  };

  const save = () => {
    navigate('/customer/new');
  };

  const edit = (id) => {
    navigate(`/customer/edit/${id}`);
  };

  const moreInfo = (id) => {
    navigate(`/customer/profile/new/${id}`);
  };

  const view = (customerId) => {
    setVisible(true);
    getRecordById('/public/v1/customers/', customerId).then((res) => {
      setViewRecord(res);
      setTags(res.tags);
    });
  };

  const importCsvFile = () => {
    navigate('/customer/import/csv');
  }

  const handleOk = () => setVisible(false);
  const handleCancel = () => setVisible(false);

  const columns = [
    { title: 'Customer Name', dataIndex: 'customerName', key: 'customerName' },
    { title: 'Mobile Number', dataIndex: 'mobileNumber', key: 'mobileNumber' },
    /* { title: 'Email', dataIndex: 'email', key: 'email' }, */
    {
      title: 'Tag',
      dataIndex: 'tags',
      key: 'tags',
      render: (_, { tags }) => (
        <>
          {tags.map((tag, index) => (
            <Tag color={tag.length > 4 ? 'geekblue' : 'green'} key={index}>
              {tag.toUpperCase()}
            </Tag>
          ))}
        </>
      ),
    },
    { title: 'Reference', dataIndex: 'reference', key: 'reference' },
    { title: 'Remarks', dataIndex: 'remarks', key: 'remarks' },
    { title: 'Status', dataIndex: 'customerStatus', key: 'customerStatus' },
    {
      title: 'Action',
      key: 'action',
      width: '15%',
      render: (_, record) => (
        <BaseSpace>
          <BaseButton size="small" type="primary" onClick={() => view(record.customerId)}>
            View
          </BaseButton>
          <BaseButton severity="info" size="small" onClick={() => edit(record.customerId)}>
            Edit
          </BaseButton>
          <BaseButton type="primary" size="small" onClick={() => moreInfo(record.customerId)}>
            More (Info)
          </BaseButton>
        </BaseSpace>
      ),
    },
  ];

  return (
    <div className="page-Container">
      <S.Card
        id="basic-table"
        title="Customer List"
        padding="1.25rem 1.25rem 0"
        extra={
          <BaseButton type="info" className="btn btn-dark" onClick={save} size="small" icon={<PlusCircleOutlined />}>
            Add
          </BaseButton>
        }
      >
        <div className="search-box" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ST.InputsWrapper>
            <SearchInput
              placeholder="Search..."
              onChange={handleSearch}
              value={searchedText}
              allowClear
              size="small"
              style={{ width: 230, marginBottom: 10 }}
            />
          </ST.InputsWrapper>
          <div className="importBtn">
            <Button
              type="primary"
              className="btn btnImport"
              onClick={importCsvFile}
              size="small"
              icon={<ImportOutlined />}
            >
              Import
            </Button>
          </div>
        </div>
        <BaseTable
          columns={columns}
          dataSource={recordSet}
          pagination={{
            pageSize: 10,
            total: totalElements,
            showTotal: (total) => (
              <span style={{ left: 0, position: 'absolute', fontSize: 15 }}>Total {total} records</span>
            ),
            onChange: (newPage) => setPage(newPage - 1),
          }}
          rowKey={(record) => record.customerId}
          loading={loading}
          bordered
          scroll={{ x: true }}
          rowClassName={(_, index) => (index % 2 === 0 ? 'table-row-odd' : 'table-row-even')}
        />
      </S.Card>
      <Modal open={visible} onOk={handleOk} onCancel={handleCancel} footer={null} width={1000} style={{ top: 20 }}>
        <Row>
          <Descriptions title="Customer Info" layout="horizontal" bordered size="middle">
            <Descriptions.Item label="CID">{viewRecord.customerId}</Descriptions.Item>
            <Descriptions.Item label="Name">{viewRecord.customerName}</Descriptions.Item>
            <Descriptions.Item label="Profession">{viewRecord.profession}</Descriptions.Item>
            <Descriptions.Item label="Mobile">{viewRecord.mobileNumber}</Descriptions.Item>
            <Descriptions.Item label="WhatsApp">{viewRecord.whatsAppNumber}</Descriptions.Item>
            <Descriptions.Item label="Email">{viewRecord.email}</Descriptions.Item>
            <Descriptions.Item label="District">{viewRecord.district}</Descriptions.Item>
            <Descriptions.Item label="Thana">{viewRecord.thana}</Descriptions.Item>
            <Descriptions.Item label="Address">{viewRecord.addressLineOne}</Descriptions.Item>
            <Descriptions.Item label="Tags">
              {tags.map((tag) => (
                <Tag color={tag.length > 4 ? 'geekblue' : 'green'} key={tag}>
                  {tag.toUpperCase()}
                </Tag>
              ))}
            </Descriptions.Item>
            <Descriptions.Item label="NID">{viewRecord.nid}</Descriptions.Item>
            <Descriptions.Item label="DOB">{viewRecord.dob}</Descriptions.Item>
            <Descriptions.Item label="Father's Name">{viewRecord.fatherName}</Descriptions.Item>
            <Descriptions.Item label="Mother's Name">{viewRecord.motherName}</Descriptions.Item>
            <Descriptions.Item label="Spouse Name">{viewRecord.spouseName}</Descriptions.Item>
            <Descriptions.Item label="Gender">{viewRecord.gender === 1 ? 'Male' : 'Female'}</Descriptions.Item>
            <Descriptions.Item label="Group Name">{viewRecord.groupName}</Descriptions.Item>
            <Descriptions.Item label="Group No">{viewRecord.groupNumber}</Descriptions.Item>
            <Descriptions.Item label="Remarks">{viewRecord.remarks}</Descriptions.Item>
            <Descriptions.Item label="Reference">{viewRecord.reference}</Descriptions.Item>
            <Descriptions.Item label="Status Change Notes">{viewRecord.statusChangeNotes}</Descriptions.Item>
            <Descriptions.Item label="Status">{viewRecord.customerStatus}</Descriptions.Item>
            <Descriptions.Item label="Created By">{viewRecord.createdBy}</Descriptions.Item>
            <Descriptions.Item label="Created At">
              {moment(viewRecord.createdDate).format('YYYY-MM-DD')}
            </Descriptions.Item>
            <Descriptions.Item label="Updated By">{viewRecord.updatedBy}</Descriptions.Item>
            <Descriptions.Item label="Updated At">
              {moment(viewRecord.updatedDate).format('YYYY-MM-DD')}
            </Descriptions.Item>
            <Descriptions.Item label="Activated">
              <Badge
                status={viewRecord.isActive === 1 ? 'success' : 'error'}
                text={viewRecord.isActive === 1 ? 'Yes' : 'No'}
              />
            </Descriptions.Item>
          </Descriptions>
        </Row>
      </Modal>
    </div>
  );
};

export default CustomerList;

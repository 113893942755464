import React, { Component, useState } from 'react';
import { saveFormRecord, getRecords } from '@app/utils/APIUtils';
import { Input, Select /* DatePicker */ } from 'antd';
import { MAX_FILE_SIZE } from '@app/constants';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { handleEnter } from '@app/helper/EnterIndexHelper';

const FormItem = Form.Item;
const { TextArea } = Input;

const onSearch = (value) => {
  console.log('search:', value);
};

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  return <AddPackage {...props} navigation={navigate} isLoading={isLoading} setLoading={setLoading} />;
}

class AddPackage extends Component {
  render() {
    const AntWrappedOfficeForm = Form.create()(AddPackageForm);
    return (
      <div>
        <AntWrappedOfficeForm
          navigate={this.props.navigation}
          isLoading={this.props.isLoading}
          setLoading={this.props.setLoading}
        />
      </div>
    );
  }
}

class AddPackageForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      packageId: {
        value: '',
      },
      serviceId: {
        value: '',
      },
      packageName: {
        value: '',
      },
      packageTypeId: {
        value: '',
      },
      durationId: {
        value: '',
      },
      packageTypeItems: [
        { value: 1, label: 'Shifting' },
        { value: 2, label: 'Non-Shifting' },
        { value: 3, label: 'Not Applicable' },
      ],
      shortDescription: {
        value: '',
      },
      packageDetails: {
        value: '',
      },
      startDate: {
        value: '',
      },
      endDate: {
        value: '',
      },
      price: 0,
      priceRange: {
        value: '',
      },
      thumbnail: null,
      banner: null,
      remarks: '',
      packageDoc: null,
      serviceList: [],
      durationList: [],
      agentId: {
        value: '',
      },
      isCascade: true,
      isPublished: false,
      recordStat: {
        value: '',
      },
      referenceStatusItems: [
        { value: 1, label: 'Active' },
        { value: 0, label: 'Inactive' },
      ],
      isRequired: false,
      isReadonly: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isFormInvalid = this.isFormInvalid.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);
    this.onThumbChangeHandler = this.onThumbChangeHandler.bind(this);
    this.onBannerChangeHandler = this.onBannerChangeHandler.bind(this);
    this.onDocChangeHandler = this.onDocChangeHandler.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangePrice = this.onChangePrice.bind(this);
    this.onChangeRemarks = this.onChangeRemarks.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  handleChangeIsPublished = (e) => {
    const { checked } = e.target;
    this.setState({
      isPublished: checked,
    });
  };

  handleChangeIsCascade = (e) => {
    const { checked } = e.target;
    this.setState({
      isCascade: checked,
    });
  };

  onThumbChangeHandler = (event) => {
    const uploadedFile = event.target.files[0];

    if (!uploadedFile) {
      return '';
    }

    this.setState({
      thumbnail: uploadedFile,
    });

    const fileSizeKiloBytes = uploadedFile.size / 1024;

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      event.target.value = null;

      notificationController.warning({
        message: 'File size is greater than maximum limit (2MB)',
      });
    }
  };

  onBannerChangeHandler = (event) => {
    const uploadedFile = event.target.files[0];

    if (!uploadedFile) {
      return '';
    }

    this.setState({
      banner: uploadedFile,
    });

    const fileSizeKiloBytes = uploadedFile.size / 1024;

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      event.target.value = null;

      notificationController.warning({
        message: 'File size is greater than maximum limit (2MB)',
      });
    }
  };

  onDocChangeHandler = (event) => {
    const uploadedFile = event.target.files[0];

    if (!uploadedFile) {
      return '';
    }

    this.setState({
      packageDoc: uploadedFile,
    });

    const fileSizeKiloBytes = uploadedFile.size / 1024;

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      event.target.value = null;

      notificationController.warning({
        message: 'File size is greater than maximum limit (2MB)',
      });
    }
  };

  onChangePrice = (event) => {
    this.setState({
      price: event.target.value,
    });
  };

  onChangeRemarks = (event) => {
    this.setState({
      remarks: event.target.value,
    });
  };

  componentDidMount() {
    let promise, promise1;

    promise = getRecords('/domain/data/durations/v1/all');
    promise1 = getRecords('/setup/v1/services/all');

    this.setState({
      isLoading: true,
    });

    promise
      .then((response) => {
        this.setState({
          durationList: response.map((item) => ({ value: item.id, label: item.NoOfDays })),
        });
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
        this.setState({
          isLoading: false,
        });
      });

    promise1
      .then((response) => {
        this.setState({
          serviceList: response.map((item) => ({ value: item.id, label: item.ServiceName })),
        });
        console.log(this.state.serviceList);
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
        this.setState({
          isLoading: false,
        });
      });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        //const saveRequest = Object.assign({}, values);
        //saveRequest.referenceStatusId  = 111;
        //console.log('saveRequest => ' + JSON.stringify(saveRequest));

        let formData = new FormData();

        formData.append('serviceId', values.serviceId);
        formData.append('packageName', values.packageName);
        formData.append('packageTypeId', values.packageTypeId);
        formData.append('durationId', values.durationId);
        formData.append('shortDescription', values.shortDescription);
        formData.append('packageDetails', values.packageDetails);
        formData.append('priceRange', values.priceRange === undefined ? '' : values.priceRange);
        // formData.append('startDate', values.startDate === undefined ? '' : values.startDate);
        // formData.append('endDate', values.endDate  === undefined ? '' : values.endDate);
        //formData.append('agentId', values.agentId)
        formData.append('isPublished', this.state.isPublished);
        formData.append('recordStat', values.recordStat);
        formData.append('isCascade', this.state.isCascade);

        // formData.append('price', this.state.price);
        // formData.append('remarks', this.state.remarks);
        // formData.append('thumbnail', this.state.thumbnail);
        // formData.append('banner', this.state.banner);
        // formData.append('packageDoc', this.state.packageDoc);

        console.log('values', values);
        console.log('formdata =>', formData);
        console.log('thumbnail', this.state.thumbnail);
        console.log('banner', this.state.banner);
        console.log('packageDoc', this.state.packageDoc);

        saveFormRecord(formData, '/setup/v1/packages')
          .then((response) => {
            if (response.statusCode === 200) {
              notificationController.success({
                message: 'Package created.',
              });
              this.props.navigate('/package/list');
            }
          })
          .catch((error) => {
            notificationController.error({
              message: 'Failed! ' + error.message,
            });
          });
      }
    });
  }

  onCancelForm(event) {
    event.preventDefault();
    this.props.navigate('/package/list');
  }

  isFormInvalid() {
    return !(this.state.agentName.validateStatus === 'success');
  }

  onChangeDate = (date, dateString) => {
    console.log(date, dateString);
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    //const rate = (this.state.agentTypeId.value == 2) ? false : true;
    return (
      <BaseCol xs={24} sm={24} xl={24}>
        <BaseCard id="validation form" title="Add Package" padding="1.25rem">
          <Form
            onSubmit={this.handleSubmit}
            className="package-form"
            name="basic"
            labelCol={{ span: 7 }}
            //wrapperCol={{ span: 16 }}
            style={{ maxWidth: 500 }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <FormItem label="Service">
              {getFieldDecorator('serviceId', {
                rules: [{ required: true, message: 'Service is required!' }],
              })(
                <Select
                  showSearch
                  size="small"
                  placeholder="Select service"
                  name="serviceId"
                  onKeyDown={(e) => handleEnter(e)}
                  optionFilterProp="children"
                  onSearch={onSearch}
                >
                  {this.state.serviceList.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </FormItem>

            <FormItem label="Package Name">
              {getFieldDecorator('packageName', {
                rules: [
                  {
                    required: true,
                    message: 'Package name is required!',
                  },
                  {
                    min: 4,
                    message: 'Package name should be minimum 4 character',
                  },
                ],
              })(
                <Input
                  name="packageName"
                  size="small"
                  placeholder="Enter package name"
                  value={this.state.packageName.value}
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>

            <FormItem label="Package Type">
              {getFieldDecorator('packageTypeId', {
                rules: [{ required: true, message: 'Package type is required!' }],
              })(
                <Select
                  showSearch
                  placeholder="Select type"
                  size="small"
                  name="packageTypeId"
                  value={this.state.packageTypeId.value}
                  onKeyDown={(e) => handleEnter(e)}
                  optionFilterProp="children"
                  onSearch={onSearch}
                >
                  {this.state.packageTypeItems.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </FormItem>

            <FormItem label="Package Duration">
              {getFieldDecorator('durationId', {
                rules: [{ required: true, message: 'Duration is required!' }],
              })(
                <Select
                  showSearch
                  placeholder="Select duration"
                  size="small"
                  name="durationId"
                  value={this.state.durationId.value}
                  onKeyDown={(e) => handleEnter(e)}
                  optionFilterProp="children"
                  onSearch={onSearch}
                >
                  {this.state.durationList.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </FormItem>

            <FormItem label="Short Description">
              {getFieldDecorator('shortDescription', {
                rules: [{ required: true, message: 'Short description is required!' }],
              })(
                <TextArea
                  name="shortDescription"
                  size="small"
                  rows={3}
                  placeholder="Enter short description"
                  value={this.state.shortDescription.value}
                  onKeyDownCapture={(e) => {
                    //Press ALT + N to go to new line
                    if (e.keyCode === 78 && e.altKey) {
                      e.preventDefault();
                      packageDetails.value = packageDetails.value + '\r\n';
                    }
                  }}
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>

            <FormItem label="Package Details">
              {getFieldDecorator('packageDetails', {
                rules: [{ required: true, message: 'Package details is required!' }],
              })(
                <TextArea
                  name="packageDetails"
                  size="small"
                  rows={3}
                  placeholder="Enter package details"
                  onKeyDownCapture={(e) => {
                    //Press ALT + N to go to new line
                    if (e.keyCode === 78 && e.altKey) {
                      e.preventDefault();
                      packageDetails.value = packageDetails.value + '\r\n';
                    }
                  }}
                  value={this.state.packageDetails.value}
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>

            <FormItem label="Price Range">
              {getFieldDecorator('priceRange', {
                rules: [{ required: false, message: 'Price range is required!' }],
              })(
                <Input
                  name="priceRange"
                  size="small"
                  placeholder="Enter price range"
                  value={this.state.priceRange.value}
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>

            {/* <FormItem label="Start Date">
              {getFieldDecorator('startDate', {
                rules: [{ required: false, message: 'Start date is required!' }],
              })(
                <DatePicker
                  name="startDate"
                  size="small"
                  placeholder="Select Start Date"
                  value={this.state.startDate.value}
                  onChange={this.onChangeDate}
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>

            <FormItem label="End Date">
              {getFieldDecorator('endDate', {
                rules: [{ required: false, message: 'End date is required!' }],
              })(
                <DatePicker
                  name="endDate"
                  size="small"
                  placeholder="Select End Date"
                  value={this.state.endDate.value}
                  onChange={this.onChangeDate}
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>

            <FormItem label="Price">
              {getFieldDecorator('price', {
                rules: [
                  {
                    required: false,
                    message: 'Price is required!',
                  },
                  {
                    pattern: /^(?:\d*)$/,
                    message: 'Price should contain just number',
                  },
                ],
              })(
                <Input
                  name="price"
                  size="small"
                  placeholder="Enter price"
                  onChange={this.onChangePrice}
                  addonBefore="BDT"
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>

            <FormItem label="Thumbnail">
              {getFieldDecorator('thumbnail', {
                rules: [{ required: false, message: 'Please Choose thumbnail!' }],
              })(
                <Input
                  type="file"
                  size="small"
                  name="thumbnail"
                  onChange={this.onThumbChangeHandler}
                  onKeyDown={(e) => handleEnter(e)}
                  accept="image/*"
                />,
              )}
            </FormItem>

            <FormItem label="Banner">
              {getFieldDecorator('banner', {
                rules: [{ required: false, message: 'Please Choose banner!' }],
              })(
                <Input
                  type="file"
                  name="banner"
                  size="small"
                  onChange={this.onBannerChangeHandler}
                  onKeyDown={(e) => handleEnter(e)}
                  accept="image/*"
                />,
              )}
            </FormItem>

            <FormItem label="Remarks">
              {getFieldDecorator('remarks', {
                rules: [{ required: false, message: 'Remarks is required!' }],
              })(
                <Input
                  name="remarks"
                  size="small"
                  placeholder="Enter remarks"
                  onChange={this.onChangeRemarks}
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>

            <FormItem label="Document">
              {getFieldDecorator('packageDoc', {
                rules: [{ required: false, message: 'Please Choose document!' }],
              })(
                <Input
                  type="file"
                  size="small"
                  name="packageDoc"
                  onChange={this.onDocChangeHandler}
                  onKeyDown={(e) => handleEnter(e)}
                  accept=".doc, .docx, .xlsx, .xls, .pdf"
                />,
              )}
            </FormItem> */}

            {/* <FormItem label="Agent">
                {getFieldDecorator('agentId', {
                    rules: [{ required: true, message: 'Agent is required!' }],
                })(
                    <Select  showSearch
                            placeholder="Select agent"
                            size='small'
                            name="agentId"
                            optionFilterProp="children"
                            onSearch={onSearch}
                    >
                        {this.state.agentList.map((item, index) => <Select.Option value={item.value} key={index}>{item.label}</Select.Option>)}
                    </Select>
                )}
            </FormItem> */}

            <FormItem label="Cascade">
              {getFieldDecorator('isCascade', {
                rules: [{ required: false, message: 'Cascade is required!' }],
              })(
                <BaseCheckbox
                  onChange={(e) => this.handleChangeIsCascade(e)}
                  defaultChecked={this.state.isCascade}
                  onKeyDown={(e) => handleEnter(e)}
                ></BaseCheckbox>,
              )}

              {/* {this.state.isPublished.toString()} */}
            </FormItem>

            <FormItem label="Published">
              {getFieldDecorator('isPublished', {
                rules: [{ required: false, message: 'Published is required!' }],
              })(
                <BaseCheckbox
                  onChange={(e) => this.handleChangeIsPublished(e)}
                  defaultChecked={this.state.isPublished}
                  onKeyDown={(e) => handleEnter(e)}
                ></BaseCheckbox>,
              )}

              {/* {this.state.isPublished.toString()} */}
            </FormItem>

            <FormItem label="Status">
              {getFieldDecorator('recordStat', {
                initialValue: 1,
                rules: [{ required: true, message: 'Please select status!' }],
              })(
                <Select
                  showSearch
                  placeholder="Select status"
                  name="recordStat"
                  size="small"
                  value={this.state.recordStat.value}
                  optionFilterProp="children"
                  onSearch={onSearch}
                  onKeyDown={(e) => handleEnter(e)}
                >
                  {this.state.referenceStatusItems.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </FormItem>

            <FormItem
              wrapperCol={{
                offset: 7,
                span: 9,
              }}
            >
              <BaseButtonsGroup onCancel={this.onCancelForm} />
            </FormItem>
          </Form>
        </BaseCard>
      </BaseCol>
    );
  }
}

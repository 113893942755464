import React, { Component, useState } from 'react';
import { getRecords, saveRecord, searchRecord } from '@app/utils/APIUtils';
import { Form, Input, DatePicker, Descriptions } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import moment from 'moment';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { handleEnter } from '@app/helper/EnterIndexHelper';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import SearchCustomer from '@app/components/search-customer/SearchCustomer';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';

const FormItem = Form.Item;

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  return (
    <AddPreRegistration {...props} navigation={navigate} isLoading={isLoading} setLoading={setLoading} form={form} />
  );
}

class AddPreRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recordSet: [],
      customerInfo: {},
      isLoading: false,
      isReadonly: false,
      isRequired: false,
      isTransferredIn: false,
      preRegDateOpen: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  handleChangeCheckbox = (e) => {
    const { checked } = e.target;
    this.setState({
      isTransferredIn: checked,
    });
  };

  //Handle Search
  handleSearch(values) {
    const searchRequest = {
      customerName: values.customerName === '' ? null : values.customerName,
      mobileNumber: values.mobileNumber === '' ? null : values.mobileNumber,
      groupNumber: values.groupNumber === '' ? null : values.groupNumber,
    };
    if (values.mobileNumber === undefined && values.groupNumber === undefined && values.customerName === undefined) {
      this.setState({
        recordSet: [],
        customerInfo: '',
      });
      notificationController.warning({
        message: 'Please enter any filtering criteria!',
      });
    } else {
      searchRecord(searchRequest, '/public/v1/customers/search')
        .then((response) => {
          if (response.length !== 0) {
            this.setState({
              recordSet: response,
            });
          } else {
            this.setState({
              recordSet: [],
              customerInfo: '',
            });
            notificationController.warning({
              message: 'No record found',
            });
          }
        })
        .catch((error) => {
          this.setState({
            recordSet: [],
            customerInfo: '',
          });
          notificationController.error({
            message: 'Failed! ' + error.message,
          });
        });
    }
  }

  //Handle Row Click
  handleRowClick = (record) => {
    this.props.form.setFieldsValue({
      ['customerId']: record.customerId,
    });

    const searchRequest = {
      customerId: record.customerId,
    };

    searchRecord(searchRequest, '/public/v1/customers/info')
      .then((res) => {
        if (res.statusCode === 302) {
          //If record found in pre_customers table
          notificationController.warning({
            message: res.message,
          });
          //Get pregistration info by customerId
          getRecords(`/public/customer/v1/pregistered/${res.data.customerId}/cif`)
            .then((res) => {
              this.setState({
                customerInfo: record,
              });
              this.props.form.setFieldsValue({
                customerId: res.customerId,
                preRegistrationDate: moment(res.preRegistrationDate),
                serialNo: res.serialNo,
                trackingNo: res.trackingNo,
                groupNumber: res.groupNumber,
                bankInfo: res.bankInfo,
                isTransferredIn: res.isTransferredIn,
                licenseNo: res.licenseNo,
                agencyName: res.agencyName,
              });
              if (res.isTransferredIn === true) {
                this.setState({
                  isTransferredIn: true,
                });
              } else {
                this.setState({
                  isTransferredIn: false,
                });
              }
            })
            .catch((error) => {
              notificationController.error({
                message: 'Failed! ' + error.message,
              });
            });
        } else {
          //If record found in customer table only
          this.props.form.setFieldsValue({
            customerId: res.data.customerId,
          });
          this.setState({
            customerInfo: record,
          });
        }
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
      });
  };

  //Handle Submit
  handleSubmit(values) {
    //const saveRequest = Object.assign({}, values);
    //console.log('saveRequest => ' + JSON.stringify(saveRequest));
    const saveRequest = {
      customerId: values.customerId,
      preRegistrationDate: moment(values.preRegistrationDate).format('YYYY-MM-DD'),
      serialNo: values.serialNo,
      trackingNo: values.trackingNo,
      groupNumber: values.groupNumber,
      bankInfo: values.bankInfo !== null ? values.bankInfo : '',
      isTransferredIn: this.state.isTransferredIn,
      licenseNo: values.licenseNo !== null ? values.licenseNo : '',
      agencyName: values.agencyName !== null ? values.agencyName : '',
    };

    saveRecord(saveRequest, '/public/customer/v1/pregistered')
      .then((response) => {
        if (response.statusCode === 101) {
          notificationController.warning({
            message: 'Already Pre-registered!',
          });
        } else {
          notificationController.success({
            message: 'Pre-reg info created.',
          });
          this.props.navigation('/pre_registration/list');
        }
        console.log(response);
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
      });
  }

  onCancelForm(event) {
    event.preventDefault();
    this.props.navigation('/pre_registration/list');
  }

  render() {
    const { customerInfo, recordSet } = this.state;

    const onChangeDate = (date, dateString) => {
      console.log(date, dateString);
    };

    const data = [];

    //Customer record set
    recordSet.forEach((record, recordIndex) => {
      data.push({
        key: recordIndex,
        customerId: record.customerId,
        customerName: record.customerName,
        mobileNumber: record.mobileNumber,
        groupNumber: record.groupNumber,
        /* packageId: record.packageId,
        packageName: record.packageName, */
        email: record.email,
        /* serviceId: record.serviceId,
        serviceName: record.serviceName,
        hotelName: record.hotelName,
        roomNumber: record.roomNumber,
        busNo: record.busNo,
        flightNo: record.flightNo, */
        address: record.address,
        district: record.district,
        thana: record.thana,
        reference: record.reference,
      });
    });

    const columns = [
      {
        title: 'Name',
        dataIndex: 'customerName',
        key: 'customerName;',
      },
      {
        title: 'Mobile',
        dataIndex: 'mobileNumber',
        key: 'mobileNumber',
      },
      {
        title: 'Group Number',
        dataIndex: 'groupNumber',
        key: 'groupNumber',
      },
    ];

    return (
      <BaseCard id="pre-reg-card" title="Add Pre-Registration" padding="1.25rem">
        <BaseRow gutter={[30, 30]}>
          <BaseCol xs={24} sm={20} xl={12}>
            <SearchCustomer
              searchForm={this.props.form}
              onFinish={this.handleSearch}
              customerName="customerName"
              mobileNumber="mobileNumber"
              groupNumber="groupNumber"
              onKeyDown={(e) => handleEnter(e)}
            />
          </BaseCol>
          <BaseCol xs={24} sm={20} xl={12}>
            <BaseCard id="search" padding="1.25rem">
              <BaseRow gutter={[0, 0]}>
                <BaseCol xs={24} sm={24} xl={24}>
                  <BaseTable
                    onRow={(record) => ({
                      onClick: () => this.handleRowClick(record), // Handle row click
                    })}
                    columns={columns}
                    dataSource={data}
                    pagination={{ pageSize: 5 }}
                    scroll={{ x: true }}
                    rowKey={(record) => record.customerId}
                    bordered
                  />
                </BaseCol>
              </BaseRow>
            </BaseCard>
          </BaseCol>
        </BaseRow>
        <BaseRow gutter={[30, 30]}>
          <BaseCol xs={24} sm={20} xl={12}>
            <BaseCard id="customer-info" padding="1.25rem">
              <Descriptions title="Customer Details" bordered size="middle" column={2}>
                <Descriptions.Item label="Customer Name" span={2}>
                  {customerInfo.customerName}
                </Descriptions.Item>
                <Descriptions.Item label="Mobile" span={2}>
                  {customerInfo.mobileNumber}
                </Descriptions.Item>
                <Descriptions.Item label="Email" span={2}>
                  {customerInfo.email}
                </Descriptions.Item>
                <Descriptions.Item label="Address" span={2}>
                  {customerInfo.address}
                </Descriptions.Item>
                <Descriptions.Item label="District" span={1}>
                  {customerInfo.district}
                </Descriptions.Item>
                <Descriptions.Item label="Thana" span={1}>
                  {customerInfo.thana}
                </Descriptions.Item>
                <Descriptions.Item label="Reference" span={1}>
                  {customerInfo.reference === null ? '' : customerInfo.reference}
                </Descriptions.Item>
              </Descriptions>
            </BaseCard>
          </BaseCol>
          <BaseCol xs={24} sm={20} xl={12}>
            <Form
              form={this.props.form}
              onFinish={this.handleSubmit}
              className="pre-reg-form"
              name="basic"
              labelCol={{ span: 8 }}
              style={{ maxWidth: 500, marginTop: '20px' }}
              initialValues={{ remember: true }}
              autoComplete="off"
            >
              <FormItem
                label="Customer Id"
                name="customerId"
                rules={[
                  {
                    required: true,
                    message: 'Customer id is required!',
                  },
                ]}
                className="ant-latest-form-item"
                hidden={true}
              >
                {/* <-- Pass hidden customerId --> */}
                <Input
                  size="small"
                  readOnly={true}
                  onKeyDown={(e) => handleEnter(e)}
                  style={{ backgroundColor: '#c5d3e0' }}
                />
              </FormItem>

              <FormItem
                label="Pre-Registration Date"
                name="preRegistrationDate"
                rules={[
                  {
                    required: true,
                    message: 'Pre registration date is required!',
                  },
                ]}
                className="ant-latest-form-item"
              >
                <DatePicker
                  size="small"
                  placeholder="Select Date"
                  onChange={onChangeDate}
                  onKeyDown={(e) => handleEnter(e)}
                  format={this.state.preRegDateOpen ? 'DDMMYYYY' : 'YYYY-MM-DD'}
                  onOpenChange={(status) => {
                    this.setState({ preRegDateOpen: status });
                  }}
                />
              </FormItem>

              <FormItem
                label="Serial No"
                name="serialNo"
                rules={[
                  {
                    required: true,
                    message: 'Serial no is required!',
                  },
                ]}
                className="ant-latest-form-item"
              >
                <Input placeholder="Enter serial no" size="small" onKeyDown={(e) => handleEnter(e)} />
              </FormItem>

              <FormItem
                label="Tracking No"
                name="trackingNo"
                rules={[
                  {
                    required: true,
                    message: 'Tracking no is required!',
                  },
                ]}
                className="ant-latest-form-item"
              >
                <Input size="small" placeholder="Enter tracking no" onKeyDown={(e) => handleEnter(e)} />
              </FormItem>

              <FormItem
                label="Group Number"
                name="groupNumber"
                rules={[
                  {
                    required: false,
                    message: 'Group number is required!',
                  },
                ]}
                className="ant-latest-form-item"
              >
                <Input
                  size="small"
                  placeholder="Enter group number"
                  onKeyDown={(e) => handleEnter(e)}
                />
              </FormItem>

              <FormItem
                label="Bank Info"
                name="bankInfo"
                rules={[
                  {
                    required: false,
                    message: 'Bank Info is required!',
                  },
                ]}
                className="ant-latest-form-item"
              >
                <Input placeholder="Enter bank info" size="small" onKeyDown={(e) => handleEnter(e)} />
              </FormItem>

              <FormItem
                label="Transferred In"
                name="isTransferredIn"
                rules={[
                  {
                    required: false,
                    message: 'Transferred in is required!',
                  },
                ]}
                className="ant-latest-form-item"
              >
                <BaseCheckbox
                  onChange={(e) => this.handleChangeCheckbox(e)}
                  checked={this.state.isTransferredIn}
                  onKeyDown={(e) => handleEnter(e)}
                />
              </FormItem>

              {this.state.isTransferredIn === true ? (
                <>
                  <FormItem
                    label="Agency License No"
                    name="licenseNo"
                    rules={[
                      {
                        required: true,
                        message: 'Agency license is required!',
                      },
                    ]}
                    className="ant-latest-form-item"
                  >
                    <Input placeholder="Enter license no" size="small" onKeyDown={(e) => handleEnter(e)} />
                  </FormItem>
                  <FormItem
                    label="Agency Name"
                    name="agencyName"
                    rules={[
                      {
                        required: true,
                        message: 'Agency name is required!',
                      },
                    ]}
                    className="ant-latest-form-item"
                  >
                    <Input placeholder="Enter agency name" size="small" onKeyDown={(e) => handleEnter(e)} />
                  </FormItem>
                </>
              ) : null}

              <FormItem
                wrapperCol={{
                  offset: 8,
                  span: 9,
                }}
              >
                <BaseButtonsGroup onCancel={this.onCancelForm} />
              </FormItem>
            </Form>
          </BaseCol>
        </BaseRow>
      </BaseCard>
    );
  }
}

import React, { Component, useState } from 'react';
import { /* deleteItemByID, */ getRecordSet, getRecordById, saveAsRecord } from '@app/utils/APIUtils';
import { Modal, Row, Descriptions, Badge, Tag } from 'antd';
import { PlusCircleOutlined, ImportOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import moment from 'moment';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import * as S from '@app/components/tables/Tables/Tables.styles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput';
import * as ST from '@app/pages/uiComponentsPages/UIComponentsPage.styles';
//import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import { LIST_SIZE } from '@app/constants';
import { notificationController } from '@app/controllers/notificationController';
import { Button } from '@app/components/common/BaseButton/BaseButton.styles';

export default function (props) {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  return (
    <PackageList
      {...props}
      loading={loading}
      setLoading={setLoading}
      page={page}
      setPage={setPage}
      navigate={navigate}
    />
  );
}

class PackageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      recordSet: [],
      isLoading: false,
      response: {},
      packageTypeItems: [
        { value: 1, label: 'Shifting' },
        { value: 2, label: 'Non-Shifting' },
      ],
      visible: false,
      viewRecord: [],
      searchedText: '',
      page: 0,
      size: 10,
      totalElements: 0,
      totalPages: 0,
      last: true,
    };
    this.save = this.save.bind(this);
    this.edit = this.edit.bind(this);
    //this.delete = this.delete.bind(this);
    this.loadRecords = this.loadRecords.bind(this);
    this.handleSaveAs = this.handleSaveAs.bind(this);
    this.importCsvFile = this.importCsvFile.bind(this);
  }

  loadRecords(searchedText = '', page, size = LIST_SIZE) {
    this.setState({
      recordSet: [],
      page: 0,
      size: 10,
      totalElements: 0,
      totalPages: 0,
      last: true,
      isLoading: false,
    });

    this.props.setLoading(true);

    let promise = getRecordSet(page, size, '/setup/v1/packages?page=', searchedText);

    if (!promise) {
      return;
    }

    promise
      .then((response) => {
        const recordSet = this.state.recordSet.slice();

        if (!(searchedText === '')) {
          this.setState({
            recordSet: recordSet.concat(response.content),
            page: response.page,
            size: response.size,
            totalElements: response.totalElements,
            totalPages: response.totalPages,
            last: response.last,
          });

          this.props.setLoading(false);
        } else {
          setTimeout(() => {
            this.setState({
              recordSet: recordSet.concat(response.content),
              page: response.page,
              size: response.size,
              totalElements: response.totalElements,
              totalPages: response.totalPages,
              last: response.last,
            });

            this.props.setLoading(false);
          }, 1000);
        }
      })
      .catch((error) => {
        notificationController.error({
          message: error.message,
        });
        this.props.setLoading(false);
      });
    console.log('Component mounted.');
  }

  componentDidMount() {
    this.loadRecords();
  }

  save() {
    this.props.navigate('/package/new');
  }

  handleSaveAs(id, event) {
    event.preventDefault();

    saveAsRecord('/setup/v1/packages/', id)
      .then((response) => {
        if (response.statusCode === 200) {
          notificationController.success({
            message: 'Package created.',
          });
          this.setState({
            visible: false,
          });
          this.loadRecords();
          this.props.navigate('/package/list');
          //window.location.reload();
        }
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
      });
  }

  edit(id) {
    this.props.navigate(`/package/edit/${id}`);
  }

  importCsvFile() {
    this.props.navigate('/package/import/csv');
  }

  //? Hide delete option
  /* delete(id) {
    const { confirm } = Modal;
    return new Promise((resolve, reject) => {
      confirm({
        title: 'Are you sure you want to Delete ?',
        onOk: () => {
          resolve(true);

          //onRemoveFunctionality here
          deleteItemByID(id, '/setup/v1/packages/').then((res) => {
            if (res) this.setState({ recordSet: this.state.recordSet.filter((record) => record.packageId !== id) });
            notificationController.success({
              message: 'Package deleted successfully.',
            });
          });
        },
        onCancel: () => {
          reject(true);
        },
      });
    });
  } */

  view(id) {
    this.setState({
      visible: true,
    });

    let promise1 = getRecordById('/setup/v1/packages/', id);
    promise1.then((res) => {
      this.setState({
        viewRecord: res,
        serviceName: res.ServiceBean.ServiceName,
        agentName: res.AgentBean.AgentName,
        duration: res.DurationBean.NoOfDays,
      });
    }, []);

    Promise.all([promise1]);
  }

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  render() {
    const { recordSet, totalElements, viewRecord, serviceName, agentName, duration } = this.state;
    //const showTotal = totalElements;
    const numberFormat = (value) => new Intl.NumberFormat('en-IN').format(value);
    //const vendorViews = [];
    const data = [];

    recordSet.forEach((record, recordIndex) => {
      data.push({
        key: recordIndex,
        packageId: record.packageId,
        serviceId: record.serviceId,
        serviceName: record.serviceName,
        packageName: record.packageName + ' - (' + record.duration + ')',
        packageTypeId: record.packageTypeId,
        startDate: record.startDate === null ? '' : moment(record.startDate).format('YYYY-MM-DD'),
        agentId: record.agentId,
        agentName: record.agentName,
        isPublished: record.isPublished === true ? 'YES' : 'NO',
        duration: record.duration,
      });
    });

    const columns = [
      /* {
          title: 'ID',
          dataIndex: 'agentId',
          key: 'agentId',
        }, */
      {
        title: 'Service Name',
        dataIndex: 'serviceName',
        key: 'serviceName;',
        width: '15%',
        // filteredValue: [this.state.searchedText],
        // onFilter: (value, record) => {
        //   return (
        //     String(record.serviceName).toLowerCase().includes(value.toLowerCase()) ||
        //     String(record.packageName).toLowerCase().includes(value.toLowerCase()) ||
        //     String(record.startDate).toLowerCase().includes(value.toLowerCase()) ||
        //     String(record.agentName).toLowerCase().includes(value.toLowerCase()) ||
        //     String(record.isPublished).toLowerCase().includes(value.toLowerCase())
        //   );
        // },
      },
      {
        title: 'Package Name',
        dataIndex: 'packageName',
        key: 'packageName;',
        width: '25%',
      },
      {
        title: 'Agent Name',
        dataIndex: 'agentName',
        key: 'agentName',
        width: '20%',
      },
      {
        title: 'Package Type',
        dataIndex: 'packageTypeId',
        key: 'packageTypeId',
        width: '14%',
        render: (_text, record) =>
          record.packageTypeId === 1 ? 'Shifting' : record.packageTypeId === 2 ? 'Non-Shifting' : 'Not Applicable',
      },
      {
        title: 'Start Date',
        dataIndex: 'startDate',
        key: 'startDate',
        width: '10%',
      },
      {
        title: 'Published',
        dataIndex: 'isPublished',
        key: 'isPublished',
        width: '6%',
      },
      {
        title: 'Action',
        key: 'action',
        width: '10%',
        render: (_, record) => {
          return (
            <BaseSpace>
              <BaseButton size="small" type="primary" onClick={() => this.view(record.packageId)}>
                View
              </BaseButton>

              <BaseButton severity="info" size="small" onClick={() => this.edit(record.packageId)}>
                Edit
              </BaseButton>

              {/* <BaseButton type="primary" danger size="small" onClick={() => this.delete(record.packageId)}>
                Delete
              </BaseButton> */}
            </BaseSpace>
          );
        },
      },
    ];

    return (
      <div className="page-Container">
        <S.Card
          id="basic-table"
          title="Package List"
          padding="1.25rem 1.25rem 0"
          extra={
            <BaseButton
              type="info"
              className="btn btn-dark"
              onClick={this.save}
              size="small"
              icon={<PlusCircleOutlined />}
              style={{}}
            >
              Add
            </BaseButton>
          }
        >
          <div className="search-box" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ST.InputsWrapper>
              <SearchInput
                placeholder="Search..."
                // onSearch={(value) => {
                //   this.setState({
                //     searchedText: value,
                //   });
                // }}
                onChange={(e) => {
                  this.setState(
                    {
                      searchedText: e.target.value,
                    },
                    () => {
                      console.log('New state in ASYNC callback:', this.state.searchedText);
                      this.loadRecords(this.state.searchedText);
                    },
                  );
                }}
                style={{
                  width: 230,
                  marginBottom: 10,
                }}
                allowClear
                size="small"
              />
            </ST.InputsWrapper>
            <div className="importBtn">
              <Button
                type="primary"
                className="btn btnImport"
                onClick={this.importCsvFile}
                size="small"
                icon={<ImportOutlined />}
              >
                Import
              </Button>
            </div>
          </div>
          <BaseTable
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: 10,
              total: totalElements,
              showTotal: (showTotal) => (
                <span style={{ left: 0, position: 'absolute', fontSize: 15 }}>Total {showTotal} records</span>
              ),
              onChange: (page) => this.loadRecords(this.state.searchedText, page - 1),
            }}
            rowKey={(record) => record.key}
            loading={this.props.loading}
            bordered
            scroll={{ x: true }}
            rowClassName={(_record, index) => (index % 2 === 0 ? 'table-row-odd' : 'table-row-even')}
          />
        </S.Card>

        <div>
          {viewRecord ? (
            <Modal
              open={this.state.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              okButtonProps={{ style: { display: 'none' } }}
              cancelButtonProps={{ style: { display: 'none' } }}
              width={1000}
              style={{ top: 20 }}
              footer={[
                <Button
                  key="saveAs"
                  size="small"
                  type="primary"
                  style={{ display: 'inline' }}
                  onClick={(e) => this.handleSaveAs(viewRecord.id, e)}
                >
                  Save As
                </Button>,
              ]}
            >
              <Row>
                <Descriptions title="Package Info" layout="horizontal" bordered size="middle">
                  <Descriptions.Item label="Package Id">{viewRecord.id}</Descriptions.Item>
                  <Descriptions.Item label="Service Name">{serviceName}</Descriptions.Item>
                  <Descriptions.Item label="Package Name">{viewRecord.PackageName}</Descriptions.Item>
                  <Descriptions.Item label="Package Type">
                    {viewRecord.PackageType === 1 ? 'Shifting' : 'Non-Shifting'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Duration">{duration}</Descriptions.Item>
                  <Descriptions.Item label="Short Descriptions">{viewRecord.ShortDesc}</Descriptions.Item>
                  <Descriptions.Item label="Package Details">{viewRecord.PackageDetails}</Descriptions.Item>
                  <Descriptions.Item label="Start Date" time>
                    {viewRecord.EndDate != null ? moment(viewRecord.StartDate).format('YYYY-MM-DD') : ''}
                  </Descriptions.Item>
                  <Descriptions.Item label="End Date">
                    {viewRecord.EndDate != null ? moment(viewRecord.EndDate).format('YYYY-MM-DD') : ''}
                  </Descriptions.Item>
                  <Descriptions.Item label="Price">
                    <Tag color="green" key={viewRecord.Price}>
                      {'৳'.concat(' ').concat(numberFormat(viewRecord.Price))}
                    </Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="Published">
                    {viewRecord.IsPublished === true ? 'YES' : 'NO'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Cascade">{viewRecord.IsCascade === true ? 'YES' : 'NO'}</Descriptions.Item>
                  <Descriptions.Item label="Record Status">
                    <Badge status="success" text={viewRecord.status === 1 ? 'Active' : 'Inactive'} />
                  </Descriptions.Item>
                  <Descriptions.Item label="Agent Name">{agentName}</Descriptions.Item>
                </Descriptions>
              </Row>
            </Modal>
          ) : null}
        </div>
      </div>
    );
  }
}

import React, { useEffect, useState, useCallback } from 'react';
import { getRecordById, getRecordSet } from '@app/utils/APIUtils';
import { Badge, Button, Descriptions, Modal, Row, Tag } from 'antd';
import '@ant-design/compatible/assets/index.css';
import moment from 'moment';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import * as S from '@app/components/tables/Tables/Tables.styles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput';
import * as ST from '@app/pages/uiComponentsPages/UIComponentsPage.styles';
import { useNavigate } from 'react-router-dom';
import { ImportOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { LIST_SIZE } from '@app/constants';
import { notificationController } from '@app/controllers/notificationController';
import debounce from 'lodash.debounce';

const PreRegistrationList = () => {
  const [recordSet, setRecordSet] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchedText, setsearchedText] = useState('');
  const [visible, setVisible] = useState(false);
  const [viewRecord, setViewRecord] = useState([]);
  const [tags, setTags] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const navigate = useNavigate();

  const loadRecords = useCallback(
    debounce((searchText, page, size = LIST_SIZE) => {
      setLoading(true);
      getRecordSet(page, size, '/public/customer/v1/pregistered?page=', searchText)
        .then((response) => {
          setRecordSet(response.content);
          setPage(response.page);
          setSize(response.size);
          setTotalElements(response.totalElements);
        })
        .catch((error) => {
          notificationController.error({ message: 'Failed! ' + error.message });
        })
        .finally(() => setLoading(false));
    }, 500),
    [],
  );

  useEffect(() => {
    loadRecords(searchedText, page);
  }, [searchedText, page, loadRecords]);

  const save = () => navigate('/pre_registration/new');

  const edit = (id) => navigate(`/pre_registration/edit/${id}`);

  const view = (id) => {
    setVisible(true);
    getRecordById('/public/customer/v1/pregistered/', id).then((res) => {
      setViewRecord(res);
      setTags(res.tags);
    });
  };

  const importCsvFile = () => {
    navigate('/pre_registration/import/csv');
  }

  const handleModalClose = () => setVisible(false);

  const columns = [
    {
      title: 'Group No',
      dataIndex: 'groupNumber',
      key: 'groupNumber',
      render: (_, { groupNumber }) => {
        let color = groupNumber ? '#108ee9' : '';
        return groupNumber ? (
          <Tag color={color} key={groupNumber}>
            {groupNumber}
          </Tag>
        ) : null;
      },
    },
    {
      title: 'Customer Name',
      dataIndex: 'custName',
      key: 'custName',
    },
    {
      title: 'Mobile No',
      dataIndex: 'mobileNo',
      key: 'mobileNo',
    },
    {
      title: 'Tracking No',
      dataIndex: 'trackingNo',
      key: 'trackingNo',
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
    },
    {
      title: 'Passport No',
      dataIndex: 'passportNumber',
      key: 'passportNumber',
    },
    {
      title: 'Date of Birth',
      dataIndex: 'dob',
      key: 'dob',
    },
    {
      title: 'Passport Expiry',
      dataIndex: 'passportExpiryDate',
      key: 'passportExpiryDate',
    },
    {
      title: 'Action',
      key: 'action',
      width: '10%',
      render: (_, record) => (
        <BaseSpace>
          <BaseButton size="small" type="primary" onClick={() => view(record.registrationId)}>
            View
          </BaseButton>
          <BaseButton severity="info" size="small" onClick={() => edit(record.registrationId)}>
            Edit
          </BaseButton>
        </BaseSpace>
      ),
    },
  ];

  return (
    <div className="page-Container">
      <S.Card
        id="basic-table"
        title="Pre-Registration List"
        padding="1.25rem 1.25rem 0"
        extra={
          <BaseButton type="info" className="btn btn-dark" onClick={save} size="small" icon={<PlusCircleOutlined />}>
            Add
          </BaseButton>
        }
      >
        <div className="search-box" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ST.InputsWrapper>
            <SearchInput
              placeholder="Search..."
              onChange={(e) => {
                setsearchedText(e.target.value);
                loadRecords(e.target.value);
              }}
              style={{
                width: 230,
                marginBottom: 10,
              }}
              allowClear
              size="small"
            />
          </ST.InputsWrapper>
          <div className="importBtn">
            <Button
              type="primary"
              className="btn btnImport"
              onClick={importCsvFile}
              size="small"
              icon={<ImportOutlined />}
            >
              Import
            </Button>
          </div>
        </div>
        <BaseTable
          columns={columns}
          dataSource={recordSet}
          rowKey={(record) => record.registrationId}
          pagination={{
            pageSize: size,
            total: totalElements,
            showTotal: (total) => (
              <span style={{ left: 0, position: 'absolute', fontSize: 15 }}>Total {total} records</span>
            ),
            onChange: (newPage) => setPage(newPage - 1),
          }}
          loading={loading}
          bordered
          scroll={{ x: true }}
          rowClassName={(_, index) => (index % 2 === 0 ? 'table-row-odd' : 'table-row-even')}
        />
      </S.Card>
      <Modal
        open={visible}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        width={1000}
        style={{ top: 20 }}
        footer={null}
      >
        <Row>
          <Descriptions title="Pre-Registration Info" layout="horizontal" bordered size="middle">
            <Descriptions.Item label="PRID">{viewRecord.preRegId}</Descriptions.Item>
            <Descriptions.Item label="Customer Name">{viewRecord.customerName}</Descriptions.Item>
            <Descriptions.Item label="Mobile Number">{viewRecord.mobileNumber}</Descriptions.Item>
            <Descriptions.Item label="Email">{viewRecord.email}</Descriptions.Item>
            <Descriptions.Item label="Tags">
              {tags.map((tag) => (
                <Tag color={tag.length > 4 ? 'geekblue' : 'green'} key={tag}>
                  {tag.toUpperCase()}
                </Tag>
              ))}
            </Descriptions.Item>
            <Descriptions.Item label="Gender">
              {viewRecord.gender === 1 ? 'MALE' : viewRecord.gender === 2 ? 'FEMALE' : ''}
            </Descriptions.Item>
            <Descriptions.Item label="Date of Birth">{viewRecord.dob}</Descriptions.Item>
            <Descriptions.Item label="Passport No">{viewRecord.passportNumber}</Descriptions.Item>
            <Descriptions.Item label="Passport Expiry">{viewRecord.passportExpiryDate}</Descriptions.Item>
            <Descriptions.Item label="Pre Reg Date">
              {moment(viewRecord.preRegistrationDate).format('YYYY-MM-DD')}
            </Descriptions.Item>
            <Descriptions.Item label="Serial No">{viewRecord.serialNo}</Descriptions.Item>
            <Descriptions.Item label="Tracking No">{viewRecord.trackingNo}</Descriptions.Item>
            <Descriptions.Item label="Group No">{viewRecord.groupNumber}</Descriptions.Item>
            <Descriptions.Item label="Created By">{viewRecord.createdBy}</Descriptions.Item>
            <Descriptions.Item label="Created At">
              {moment(viewRecord.createdDate).format('YYYY-MM-DD')}
            </Descriptions.Item>
            <Descriptions.Item label="Updated By">{viewRecord.updatedBy}</Descriptions.Item>
            <Descriptions.Item label="Updated At">
              {moment(viewRecord.updatedDate).format('YYYY-MM-DD')}
            </Descriptions.Item>
            <Descriptions.Item label="Activated">
              <Badge status={viewRecord.isActive === 1 ? 'success' : 'error'} 
                text={viewRecord.isActive === 1 ? 'Yes' : 'No'} 
              />
            </Descriptions.Item>
          </Descriptions>
        </Row>
      </Modal>
    </div>
  );
};

export default PreRegistrationList;

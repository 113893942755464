import React, { Component, useState } from 'react';
import { getRecordById, updateFormRecord, getRecords } from '@app/utils/APIUtils';
import { MAX_FILE_SIZE } from '@app/constants';
import { Input, Select } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { handleEnter } from '@app/helper/EnterIndexHelper';

const FormItem = Form.Item;
const { TextArea } = Input;

const onSearch = (value) => {
  console.log('search:', value);
};

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  return (
    <EditActivity {...props} navigation={navigate} isLoading={isLoading} setLoading={setLoading} params={params} />
  );
}

class EditActivity extends Component {
  render() {
    const AntWrappedOfficeForm = Form.create()(EditActivityForm);
    return (
      <div>
        <AntWrappedOfficeForm
          dataId={this.props.params}
          navigate={this.props.navigation}
          isLoading={this.props.isLoading}
          setLoading={this.props.setLoading}
        />
      </div>
    );
  }
}

class EditActivityForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      activityId: this.props.dataId.id,
      parentActivityList: [],
      file: null,
      previewImage: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);
    this.onFileChangeHandler = this.onFileChangeHandler.bind(this);
    //this.handleEnterIndex = this.handleEnterIndex.bind(this);
  }

  onFileChangeHandler = (event) => {
    const uploadedFile = event.target.files[0];

    const filetUrl = URL.createObjectURL(uploadedFile);

    this.setState({
      previewImage: filetUrl,
    });

    if (!uploadedFile) {
      return '';
    }

    this.setState({
      file: uploadedFile,
    });

    const fileSizeKiloBytes = uploadedFile.size / 1024;

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      event.target.value = null;

      notification.warning({
        message: 'File Size',
        description: 'File size is greater than maximum limit (2MB)',
      });
    }
  };

  componentDidMount() {
    let promise;

    promise = getRecords('/service/v1/activities/all');

    this.setState({
      isLoading: true,
    });

    promise
      .then((response) => {
        this.setState({
          parentActivityList: response.map((item) => ({ value: item.id, label: item.ActivityName })),
        });
      })
      .catch((error) => {
        if (error)
          this.setState({
            isLoading: false,
          });
      });

    let promise1 = getRecordById('/service/v1/activities/', this.state.activityId);
    promise1.then((res) => {
      this.props.form.setFieldsValue({
        activityName: res.ActivityName,
        descriptions: res.ActivityDesc,
        prerequisites: res.PreReq,
        notes: res.Notes,
        parentActivityId: res.ParentID,
        //"preFile": Buffer.from(res.attachment, 'binary').toString('base64')
      });

      const prevFile = res.File;

      this.setState({
        file: prevFile,
      });

      console.log('File=>' + this.state.file);
    });
    console.log(promise1);
    Promise.all([promise, promise1]);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //const updateRequest = Object.assign({}, values);
        //updateRequest.referenceStatusId  = 111;
        //console.log('updateRequest => ' + JSON.stringify(updateRequest));

        let formData = new FormData();

        formData.append('activityName', values.activityName);
        formData.append('parentActivityId', values.parentActivityId === null ? '' : values.parentActivityId);
        formData.append('descriptions', values.descriptions);
        formData.append('prerequisites', values.prerequisites);
        formData.append('notes', values.notes);
        formData.append('file', this.state.file);

        console.log('data', formData);
        console.log('file', this.state.file);

        updateFormRecord(this.state.activityId, formData, '/service/v1/activities/')
          .then((response) => {
            if (response)
              notificationController.success({
                message: 'Activity updated',
              });

            this.props.navigate('/activity/list');
          })
          .catch((error) => {
            notificationController.error({
              message: 'Failed! ' + error.message,
            });
          });
      }
    });
  }

  onCancelForm(event) {
    event.preventDefault();
    this.props.navigate('/activity/list');
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <BaseCol xs={24} sm={24} xl={24}>
        <BaseCard id="validation form" title="Activity Edit" padding="1.25rem">
          <Form
            onSubmit={this.handleSubmit}
            className="activity-form"
            name="basic"
            labelCol={{ span: 6 }}
            //wrapperCol={{ span: 16 }}
            style={{ maxWidth: 500 }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <FormItem label="Activity Name">
              {getFieldDecorator('activityName', {
                rules: [
                  {
                    required: true,
                    message: 'Activity name required!',
                  },
                  {
                    min: 4,
                    message: 'Activity name should be minimum 4 character',
                  },
                ],
              })(
                <Input
                  name="activityName"
                  size="small"
                  autoComplete="off"
                  placeholder="Enter activity name"
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>

            <FormItem label="Parent Activity">
              {getFieldDecorator('parentActivityId', {
                rules: [{ required: false, message: 'Activity required!' }],
              })(
                <Select
                  showSearch
                  placeholder="Select"
                  size="small"
                  name="parentActivityId"
                  onKeyDown={(e) => handleEnter(e)}
                  optionFilterProp="children"
                  onSearch={onSearch}
                >
                  {this.state.parentActivityList.map((item, index) => (
                    <Select.Option value={item.value} key={index}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </FormItem>

            <FormItem label="Descriptions">
              {getFieldDecorator('descriptions', {
                rules: [{ required: true, message: 'Descriptions required!' }],
              })(
                <TextArea
                  name="descriptions"
                  size="small"
                  autoComplete="off"
                  placeholder="Enter descriptions"
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>

            <FormItem label="Prerequisites">
              {getFieldDecorator('prerequisites', {
                rules: [{ required: false, message: 'Please input prerequisites!' }],
              })(
                <Input
                  name="prerequisites"
                  size="small"
                  autoComplete="off"
                  placeholder="Enter prerequisites"
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>

            <FormItem label="Attachment">
              {getFieldDecorator('file', {
                rules: [{ required: false, message: 'Please Choose a file!' }],
              })(
                <Input
                  type="file"
                  name="file"
                  size="small"
                  onChange={this.onFileChangeHandler}
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
              {this.state.file != null ? (
                <div>
                  <img
                    src={this.state.previewImage ? this.state.previewImage : `data:image/png;base64,${this.state.file}`}
                    alt="#"
                    style={{
                      height: 120,
                      width: 120,
                      marginTop: 10,
                    }}
                  />
                </div>
              ) : null}
            </FormItem>

            <FormItem label="Notes">
              {getFieldDecorator('notes', {
                rules: [{ required: false, message: 'Please input notes!' }],
              })(
                <Input
                  name="notes"
                  size="small"
                  autoComplete="off"
                  placeholder="Enter notes"
                  onKeyDown={(e) => handleEnter(e)}
                />,
              )}
            </FormItem>

            <FormItem
              wrapperCol={{
                offset: 6,
                span: 9,
              }}
            >
              <BaseButtonsGroup onCancel={this.onCancelForm} />
            </FormItem>
          </Form>
        </BaseCard>
      </BaseCol>
    );
  }
}

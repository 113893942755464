import React, { Component, useState } from 'react';
import { getRecordById, updateFormRecord, getRecords } from '@app/utils/APIUtils';
import { Input, notification, Select, DatePicker, Tag, Card, Radio } from 'antd';
import Moment from 'moment';
import { MAX_FILE_SIZE } from '@app/constants';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import { BaseButtonsGroup } from '@app/components/common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { handleEnter } from '@app/helper/EnterIndexHelper';

const FormItem = Form.Item;
const { TextArea } = Input;

const onSearch = (value) => {
  console.log('search:', value);
};

export default function (props) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  return <EditPackage {...props} navigation={navigate} isLoading={isLoading} setLoading={setLoading} params={params} />;
}

class EditPackage extends Component {
  render() {
    const AntWrappedOfficeForm = Form.create()(EditPackageForm);
    return (
      <div>
        <AntWrappedOfficeForm
          dataId={this.props.params}
          navigate={this.props.navigation}
          isLoading={this.props.isLoading}
          setLoading={this.props.setLoading}
        />
      </div>
    );
  }
}

class EditPackageForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      packageId: this.props.dataId.id,
      packageTypeItems: [
        { value: 1, label: 'Shifting' },
        { value: 2, label: 'Non-Shifting' },
        { value: 3, label: 'Not Applicable' },
      ],
      durationId: '',
      serviceList: [],
      durationList: [],
      makkahHoletList: [],
      madinahHoletList: [],
      referenceStatusItems: [
        { value: 1, label: 'Active' },
        { value: 0, label: 'Inactive' },
      ],
      isPublished: false,
      isCascade: true,
      endDate: {
        value: '',
      },
      price: 0,
      vipTentCharge: 0,
      busCharge: 0,
      mealsCharge: 0,
      thumbnail: null,
      banner: null,
      document: null,
      remarks: '',
      packageDoc: null,
      previewThumb: null,
      previewBanner: null,
      previewDoc: null,
      startDateOpen: false,
      endDateOpen: false,
      priceGroupList: [],
      categoryId: [],
      priceGroup: [],
      isSpecialBus: false,
      isMealsIncluded: true,
      hideMealCharge: true,
      hideBusCharge: true,
      isRequired: false,
      isBusChargeRequired: false,
      data: [],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCancelForm = this.onCancelForm.bind(this);
    //this.onChangeSpecialBus = this.onChangeSpecialBus.bind(this);
  }

  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue),
      },
    });
  }

  onChangeSpecialBus = (e) => {
    console.log('radio checked', e.target.value);
    this.setState({
      isSpecialBus: e.target.value,
    });

    if (e.target.value === true) {
      this.setState({
        hideBusCharge: false,
        isBusChargeRequired: true,
      });
    } else {
      this.setState({
        hideBusCharge: true,
        isBusChargeRequired: false,
      });
    }
  };

  handleChangeIsPublished = (e) => {
    const { checked } = e.target;
    this.setState({
      isPublished: checked,
    });
  };

  handleChangeIsCascade = (e) => {
    const { checked } = e.target;
    this.setState({
      isCascade: checked,
    });
  };

  handleChangeIsMealsInclude = (e) => {
    const { checked } = e.target;
    this.setState({
      isMealsIncluded: checked,
      hideMealCharge: checked,
    });

    if (checked === true) {
      this.setState({
        isRequired: false,
      });
    } else {
      this.setState({
        isRequired: true,
      });
    }
  };

  onThumbChangeHandler = (event) => {
    const uploadedFile = event.target.files[0];

    const filetUrl = URL.createObjectURL(uploadedFile);

    this.setState({
      previewThumb: filetUrl,
    });

    if (!uploadedFile) {
      return '';
    }

    this.setState({
      thumbnail: uploadedFile,
    });

    const fileSizeKiloBytes = uploadedFile.size / 1024;

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      event.target.value = null;

      notification.warning({
        message: 'File Size',
        description: 'File size is greater than maximum limit (2MB)',
      });
    }
  };

  onBannerChangeHandler = (event) => {
    const uploadedFile = event.target.files[0];

    const filetUrl = URL.createObjectURL(uploadedFile);

    this.setState({
      previewBanner: filetUrl,
    });

    if (!uploadedFile) {
      return '';
    }

    this.setState({
      banner: uploadedFile,
    });

    const fileSizeKiloBytes = uploadedFile.size / 1024;

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      event.target.value = null;

      notification.warning({
        message: 'File Size',
        description: 'File size is greater than maximum limit (2MB)',
      });
    }
  };

  onDocChangeHandler = (event) => {
    const uploadedFile = event.target.files[0];
    console.log('🚀 ~ EditPackageForm ~ uploadedFile:', uploadedFile.name);

    this.setState({
      previewDoc: uploadedFile.name,
    });

    if (!uploadedFile) {
      return '';
    }

    this.setState({
      packageDoc: uploadedFile,
    });

    const fileSizeKiloBytes = uploadedFile.size / 1024;

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      event.target.value = null;

      notification.warning({
        message: 'File Size',
        description: 'File size is greater than maximum limit (2MB)',
      });
    }
  };

  onChangePrice = (event) => {
    this.setState({
      price: event.target.value,
    });
  };

  onChangeRemarks = (event) => {
    this.setState({
      remarks: event.target.value,
    });
  };

  componentDidMount() {
    let promise2, promise3, promise4, promise5, promise6;

    promise2 = getRecords('/setup/v1/services/all');
    promise3 = getRecords('/domain/data/durations/v1/all');
    promise4 = getRecords('/domain/data/pricing/group/v1/list');
    promise5 = getRecords('/domain/data/makkah/hotel/v1/list');
    promise6 = getRecords('/domain/data/madinah/hotel/v1/list');

    this.setState({
      isLoading: true,
    });

    let promise1 = getRecordById('/setup/v1/packages/', this.state.packageId);
    promise1.then((res) => {
      //To check isPublished
      if (res.IsPublished === true) {
        this.setState({
          isPublished: true,
        });
      } else {
        this.setState({
          isPublished: false,
        });
      }

      //To check isCascade
      if (res.IsCascade === true) {
        this.setState({
          isCascade: true,
        });
      } else {
        this.setState({
          isCascade: false,
        });
      }

      //To check isSpecialBus
      if (res.IsSpecialBus === true) {
        this.setState({
          isSpecialBus: true,
          hideBusCharge: false,
        });
      } else {
        this.setState({
          isSpecialBus: false,
          hideBusCharge: true,
        });
      }

      //To check isMealsIncluded
      if (res.IsMealsIncluded === true) {
        this.setState({
          isMealsIncluded: true,
          hideMealCharge: true,
        });
      } else if (res.IsMealsIncluded === false) {
        this.setState({
          isMealsIncluded: false,
          hideMealCharge: false,
        });
      }

      this.props.form.setFieldsValue({
        serviceId: res.ServiceBean.id,
        packageName: res.PackageName,
        packageTypeId: res.PackageType,
        durationId: res.DurationBean.id,
        shortDescription: res.ShortDesc,
        packageDetails: res.PackageDetails,
        startDate: res.StartDate === null ? null : Moment(res.StartDate),
        endDate: res.EndDate === null ? null : Moment(res.EndDate),
        priceRange: res.PriceRange,
        price: res.Price === null ? 0.0 : res.Price,
        //"packageThumb": res.packageThumb,
        //"packageBanner": res.packageBanner,
        remarks: res.Remarks,
        //"packageDoc": res.packageDoc,
        agentId: res.AgentBean.id,
        isPublished: res.IsPublished,
        isCascade: res.IsCascade,
        recordStat: res.status,
        isMealsIncluded: res.IsMealsIncluded === null ? this.state.isMealsIncluded : res.IsMealsIncluded,
        makkahHotelId: res.MakkahBean === null ? null : res.MakkahBean.id,
        madinahHotelId: res.MadinahBean === null ? null : res.MadinahBean.id,
        vipTentCharge: res.VipTent === null ? '' : res.VipTent,
        busCharge: res.BusCharge === 0 ? '' : res.BusCharge,
        mealsCharge: res.MealsCharge === 0 ? '' : res.MealsCharge,
      });

      this.setState({
        data: res.PriceBean.map((item) => ({ key: item.CategoryBean.id, value: item.PackagePrice })),
      });

      const thumbnail = res.ThumbnailImage;
      const banner = res.BannerImage;
      const doc = res.DocName;

      this.setState({
        thumbnail: thumbnail,
      });

      this.setState({
        banner: banner,
      });

      this.setState({
        document: doc,
      });
    });

    promise2
      .then((response) => {
        this.setState({
          serviceList: response.map((item) => ({ value: item.id, label: item.ServiceName })),
        });
        console.log(this.state.serviceList);
      })
      .catch((error) => {
        if (error)
          this.setState({
            isLoading: false,
          });
      });

    promise3
      .then((response) => {
        this.setState({
          durationList: response.map((item) => ({ value: item.id, label: item.NoOfDays })),
        });
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
        this.setState({
          isLoading: false,
        });
      });

    promise4
      .then((response) => {
        this.setState({
          priceGroupList: response,
        });
      })
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
      });

    promise5
      .then((response) => {
        this.setState({
          makkahHoletList: response.map((item) => ({ value: item.id, label: item.CategoryName })),
        });
      })
      .catch((error) => {
        if (error)
          this.setState({
            isLoading: false,
          });
      });

    promise6
      .then((response) => {
        this.setState({
          madinahHoletList: response.map((item) => ({ value: item.id, label: item.CategoryName })),
        });
      })
      .catch((error) => {
        if (error)
          this.setState({
            isLoading: false,
          });
      });

    Promise.all([promise1, promise2, promise3, promise4, promise5, promise6]);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //const updateRequest = Object.assign({}, values);
        //updateRequest.referenceStatusId  = 111;
        //console.log('updateRequest => ' + JSON.stringify(updateRequest));
        let formData = new FormData();

        formData.append('serviceId', values.serviceId);
        formData.append('packageName', values.packageName);
        formData.append('packageTypeId', values.packageTypeId);
        formData.append('durationId', values.durationId);
        formData.append('shortDescription', values.shortDescription);
        formData.append('packageDetails', values.packageDetails);
        formData.append('startDate', values.startDate === null ? '' : Moment(values.startDate).format('YYYY-MM-DD'));
        formData.append('endDate', values.endDate === null ? '' : Moment(values.endDate).format('YYYY-MM-DD'));
        //formData.append('agentId', values.agentId)
        formData.append('isPublished', this.state.isPublished);
        formData.append('isCascade', this.state.isCascade);
        formData.append('recordStat', values.recordStat);
        formData.append('priceRange', values.priceRange == null ? '' : values.priceRange);
        formData.append('price', values.price !== 0.0 ? values.price : 0.0);
        formData.append('remarks', this.state.remarks);
        formData.append('thumbnail', this.state.thumbnail);
        formData.append('banner', this.state.banner);
        formData.append('packageDoc', this.state.packageDoc);
        formData.append('categoryId', this.state.categoryId);
        formData.append('priceGroup', this.state.priceGroup);
        formData.append('makkahHotelId', values.makkahHotelId == null ? '' : values.makkahHotelId);
        formData.append('madinahHotelId', values.madinahHotelId == null ? '' : values.madinahHotelId);
        formData.append('vipTentCharge', values.vipTentCharge !== 0.0 ? values.vipTentCharge : 0.0);
        formData.append('isSpecialBus', values.isSpecialBus);
        /* if(values.busCharge !== undefined){
          formData.append('busCharge', values.busCharge !== 0 ? values.busCharge : 0);
        } */
        if (values.isSpecialBus === false) {
          formData.append('busCharge', this.state.busCharge);
        } else {
          formData.append('busCharge', values.busCharge !== 0 ? values.busCharge : 0);
        }

        formData.append('isMealsIncluded', values.isMealsIncluded);
        //formData.append('mealsCharge', values.mealsCharge !== 0 ? values.mealsCharge : 0);

        if (values.isMealsIncluded === true) {
          formData.append('mealsCharge', this.state.mealsCharge);
        } else {
          formData.append('mealsCharge', values.mealsCharge !== 0 ? values.mealsCharge : 0);
        }

        /*  var categoryId = this.state.categoryId.filter(function (el) {
          return el != '';
        });
        formData.append('categoryId', categoryId);

        var priceGroup = this.state.priceGroup.filter(function (el) {
          return el != '';
        });
        formData.append('priceGroup', priceGroup); */

        updateFormRecord(this.state.packageId, formData, '/setup/v1/packages/').then((response) => {
          if (response){
            notificationController.success({
              message: 'Package updated.',
            });
            this.props.navigate('/package/list');
          }
        })
        .catch((error) => {
          notificationController.error({
            message: 'Failed! ' + error.message,
          });
        });
      }
    });
  }

  onCancelForm(event) {
    event.preventDefault();
    this.props.navigate('/package/list');
  }

  onChangeDate = (date, dateString) => {
    console.log(date, dateString);
  };

  onChangedPriceGroup = (index, id) => (e) => {
    console.log('index: ' + index);
    console.log('price: ' + e.target.value);
    console.log('Category id: ' + id);
    let newPrice = [...this.state.priceGroup]; // copying the old priceGroup array
    let newId = [...this.state.categoryId]; // copying the old categoryId array
    // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
    newPrice[index] = e.target.value; // replace e.target.value with whatever you want to change it to
    newId[index] = id; // replace id with whatever you want to change it to

    this.setState({
      priceGroup: newPrice,
      categoryId: newId,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const priceData = this.state.data.sort((a, b) => a.key - b.key);
    console.log('this.state.data', priceData);

    return (
      <BaseCol xs={24} sm={24} xl={24}>
        <BaseCard id="validation form" title="Edit Package" padding="1.25rem">
          <Form
            onSubmit={this.handleSubmit}
            className="service-form"
            name="basic"
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 20 }}
            style={{ maxWidth: 1200 }}
            //initialValues={{ remember: true }}
            autoComplete="off"
          >
            <BaseRow gutter={[30, 30]}>
              <BaseCol xs={24} sm={20} xl={12}>
                <FormItem label="Service">
                  {getFieldDecorator('serviceId', {
                    rules: [{ required: true, message: 'Service is required!' }],
                  })(
                    <Select
                      showSearch
                      placeholder="Select service"
                      size="small"
                      name="serviceId"
                      onKeyDown={(e) => handleEnter(e)}
                    >
                      {this.state.serviceList.map((item, index) => (
                        <Select.Option value={item.value} key={index}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </FormItem>

                <FormItem label="Package Name">
                  {getFieldDecorator('packageName', {
                    rules: [
                      {
                        required: true,
                        message: 'Package name is required!',
                      },
                      {
                        min: 4,
                        message: 'Package name should be minimum 4 character',
                      },
                    ],
                  })(
                    <Input
                      name="packageName"
                      size="small"
                      placeholder="Enter package name"
                      onKeyDown={(e) => handleEnter(e)}
                    />,
                  )}
                </FormItem>

                <FormItem label="Package Type">
                  {getFieldDecorator('packageTypeId', {
                    rules: [{ required: true, message: 'Package type is required!' }],
                  })(
                    <Select
                      showSearch
                      placeholder="Select"
                      size="small"
                      name="packageTypeId"
                      onKeyDown={(e) => handleEnter(e)}
                      optionFilterProp="children"
                      onSearch={onSearch}
                    >
                      {this.state.packageTypeItems.map((item, index) => (
                        <Select.Option value={item.value} key={index}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </FormItem>

                <FormItem label="Package Duration">
                  {getFieldDecorator('durationId', {
                    rules: [{ required: true, message: 'Duration is required!' }],
                  })(
                    <Select
                      showSearch
                      placeholder="Select duration"
                      size="small"
                      name="durationId"
                      onKeyDown={(e) => handleEnter(e)}
                      optionFilterProp="children"
                      onSearch={onSearch}
                    >
                      {this.state.durationList.map((item, index) => (
                        <Select.Option value={item.value} key={index}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </FormItem>

                <FormItem label="Short Description">
                  {getFieldDecorator('shortDescription', {
                    rules: [{ required: true, message: 'Short description is required!' }],
                  })(
                    <TextArea
                      name="shortDescription"
                      size="small"
                      rows={3}
                      placeholder="Enter short description"
                      onKeyDownCapture={(e) => {
                        //Press ALT + N to go to new line
                        if (e.keyCode === 78 && e.altKey) {
                          e.preventDefault();
                          shortDescription.value = shortDescription.value + '\r\n';
                        }
                      }}
                      onKeyDown={(e) => handleEnter(e)}
                    />,
                  )}
                </FormItem>

                <FormItem label="Package Details">
                  {getFieldDecorator('packageDetails', {
                    rules: [{ required: true, message: 'Package details is required!' }],
                  })(
                    <TextArea
                      name="packageDetails"
                      size="small"
                      rows={3}
                      placeholder="Enter package details"
                      onKeyDownCapture={(e) => {
                        //Press ALT + N to go to new line
                        if (e.keyCode === 78 && e.altKey) {
                          e.preventDefault();
                          packageDetails.value = packageDetails.value + '\r\n';
                        }
                      }}
                      onKeyDown={(e) => handleEnter(e)}
                    />,
                  )}
                </FormItem>

                <FormItem label="Start Date">
                  {getFieldDecorator('startDate', {
                    initialValue: null,
                    rules: [{ required: false, message: 'Start date is required!' }],
                  })(
                    <DatePicker
                      name="startDate"
                      size="small"
                      //initialValue={null}
                      format={this.state.startDateOpen ? 'DDMMYYYY' : 'YYYY-MM-DD'}
                      onOpenChange={(status) => {
                        this.setState({ startDateOpen: status });
                      }}
                      placeholder="Select Start Date"
                      onChange={this.onChangeDate}
                      onKeyDown={(e) => handleEnter(e)}
                    />,
                  )}
                </FormItem>

                <FormItem label="End Date">
                  {getFieldDecorator('endDate', {
                    initialValue: null,
                    rules: [{ required: false, message: 'End date is required!' }],
                  })(
                    <DatePicker
                      name="endDate"
                      size="small"
                      //initialValue={null}
                      format={this.state.endDateOpen ? 'DDMMYYYY' : 'YYYY-MM-DD'}
                      onOpenChange={(status) => {
                        this.setState({ endDateOpen: status });
                      }}
                      placeholder="Select End Date"
                      onChange={this.onChangeDate}
                      onKeyDown={(e) => handleEnter(e)}
                    />,
                  )}
                </FormItem>

                <FormItem label="Thumbnail">
                  {getFieldDecorator('thumbnail', {
                    rules: [{ required: false, message: 'Please Choose thumbnail!' }],
                  })(
                    <Input
                      type="file"
                      accept="image/*"
                      name="thumbnail"
                      size="small"
                      onKeyDown={(e) => handleEnter(e)}
                      onChange={this.onThumbChangeHandler}
                    />,
                  )}
                  {this.state.thumbnail != null ? (
                    <div>
                      <img
                        src={
                          this.state.previewThumb != null
                            ? this.state.previewThumb
                            : `data:image/png;base64,${this.state.thumbnail}`
                        }
                        alt=""
                        style={{
                          height: 120,
                          width: 120,
                          marginTop: 10,
                        }}
                      />
                    </div>
                  ) : null}
                </FormItem>

                <FormItem label="Banner">
                  {getFieldDecorator('banner', {
                    rules: [{ required: false, message: 'Please Choose banner!' }],
                  })(
                    <Input
                      type="file"
                      accept="image/*"
                      name="banner"
                      size="small"
                      onKeyDown={(e) => handleEnter(e)}
                      onChange={this.onBannerChangeHandler}
                    />,
                  )}
                  {this.state.banner != null ? (
                    <div>
                      <img
                        src={
                          this.state.previewBanner != null
                            ? this.state.previewBanner
                            : `data:image/png;base64,${this.state.banner}`
                        }
                        alt=""
                        style={{
                          height: 120,
                          width: 120,
                          marginTop: 10,
                        }}
                      />
                    </div>
                  ) : null}
                </FormItem>

                <FormItem label="Remarks">
                  {getFieldDecorator('remarks', {
                    rules: [{ required: false, message: 'Remarks is required!' }],
                  })(
                    <Input
                      name="remarks"
                      size="small"
                      placeholder="Enter remarks"
                      onChange={this.onChangeRemarks}
                      onKeyDown={(e) => handleEnter(e)}
                    />,
                  )}
                </FormItem>

                <FormItem label="Document">
                  {getFieldDecorator('packageDoc', {
                    rules: [{ required: false, message: 'Please Choose document!' }],
                  })(
                    <Input
                      type="file"
                      name="packageDoc"
                      accept=".doc, .docx, .xlsx, .xls, .pdf"
                      size="small"
                      onKeyDown={(e) => handleEnter(e)}
                      onChange={this.onDocChangeHandler}
                    />,
                  )}
                  {this.state.packageDoc != null ? (
                    <div>
                      <Tag color="success">
                        {this.state.previewDoc != null ? this.state.previewDoc : this.state.packageDoc}
                      </Tag>
                    </div>
                  ) : null}
                </FormItem>

                {/* <FormItem label="Agent">
                    {getFieldDecorator('agentId', {
                        rules: [{ required: true, message: 'Agent  is required!' }],
                    })(
                        <Select  showSearch
                                placeholder="Select agent"
                                size='small'
                                name="agentId"
                        >
                            {this.state.agentList.map((item, index) => <Select.Option value={item.value} key={index}>{item.label}</Select.Option>)}
                        </Select>
                    )}
                </FormItem> */}

                <FormItem label="Cascade">
                  {getFieldDecorator('isCascade', {
                    rules: [{ required: false, message: 'Cascade is required!' }],
                  })(
                    <BaseCheckbox
                      onChange={(e) => this.handleChangeIsCascade(e)}
                      checked={this.state.isCascade}
                      onKeyDown={(e) => handleEnter(e)}
                    ></BaseCheckbox>,
                  )}
                </FormItem>

                <FormItem label="Published">
                  {getFieldDecorator('isPublished', {
                    rules: [{ required: false, message: 'Published is required!' }],
                  })(
                    <BaseCheckbox
                      onChange={(e) => this.handleChangeIsPublished(e)}
                      checked={this.state.isPublished}
                      onKeyDown={(e) => handleEnter(e)}
                    ></BaseCheckbox>,
                  )}

                  {/* {this.state.isPublished.toString()} */}
                </FormItem>

                <FormItem label="Status">
                  {getFieldDecorator('recordStat', {
                    rules: [{ required: true, message: 'Please select status!' }],
                  })(
                    <Select
                      showSearch
                      placeholder="Select status"
                      name="recordStat"
                      size="small"
                      optionFilterProp="children"
                      onSearch={onSearch}
                      onKeyDown={(e) => handleEnter(e)}
                    >
                      {this.state.referenceStatusItems.map((item, index) => (
                        <Select.Option value={item.value} key={index}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </FormItem>
              </BaseCol>
              <BaseCol xs={24} sm={20} xl={12}>
                <FormItem label="Price Range">
                  {getFieldDecorator('priceRange', {
                    rules: [{ required: false, message: 'Price range is required!' }],
                  })(
                    <Input
                      name="priceRange"
                      size="small"
                      placeholder="Enter price range"
                      onKeyDown={(e) => handleEnter(e)}
                    />,
                  )}
                </FormItem>

                <FormItem label="Base Price">
                  {getFieldDecorator('price', {
                    rules: [
                      {
                        required: false,
                        message: 'Base price is required!',
                      },
                      {
                        pattern: /^(?:\d*)$/,
                        message: 'Price should contain only number',
                      },
                    ],
                  })(
                    <Input
                      addonBefore="BDT"
                      name="price"
                      size="small"
                      placeholder="Enter base price"
                      onChange={this.onChangePrice}
                      onKeyDown={(e) => handleEnter(e)}
                    />,
                  )}
                </FormItem>

                <FormItem label="Pricing Group">
                  <Card bordered={true} style={{ width: 370, borderColor: 'red', marginTop: 10 }}>
                    {this.state.priceGroupList.map((row, index) => (
                      <FormItem label={row.CategoryName} key={row.id} labelCol={{ span: 7 }} wrapperCol={{ span: 20 }}>
                        {priceData.length !== 0 ? (
                          <Input
                            required={true}
                            name="priceGroup"
                            size="small"
                            placeholder="Enter price"
                            defaultValue={
                              priceData[index].value !== 0.0 && priceData[index].key === row.id
                                ? priceData[index].value
                                : 0.0
                            }
                            onChange={this.onChangedPriceGroup(index, row.id)}
                            onKeyDown={(e) => handleEnter(e)}
                          />
                        ) : (
                          <Input
                            required={true}
                            name="priceGroup"
                            size="small"
                            placeholder="Enter price"
                            //defaultValue={priceData[index].value !== 0.00 && priceData[index].key === row.id ? priceData[index].value : 0.00}
                            onChange={this.onChangedPriceGroup(index, row.id)}
                            onKeyDown={(e) => handleEnter(e)}
                          />
                        )}
                      </FormItem>
                    ))}
                  </Card>
                </FormItem>

                <FormItem label="Makkah Hotel">
                  {getFieldDecorator('makkahHotelId', {
                    rules: [{ required: false, message: 'Makkah hotel is required!' }],
                  })(
                    <Select
                      showSearch
                      placeholder="Select makkah hotel"
                      size="small"
                      name="makkahHotelId"
                      optionFilterProp="children"
                      onSearch={onSearch}
                      onKeyDown={(e) => handleEnter(e)}
                    >
                      {this.state.makkahHoletList.map((item, index) => (
                        <Select.Option value={item.value} key={index}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </FormItem>

                <FormItem label="Madinah Hotel">
                  {getFieldDecorator('madinahHotelId', {
                    rules: [{ required: false, message: 'Madinah hotel is required!' }],
                  })(
                    <Select
                      showSearch
                      placeholder="Select madinah hotel"
                      size="small"
                      name="madinahHotelId"
                      optionFilterProp="children"
                      onSearch={onSearch}
                      onKeyDown={(e) => handleEnter(e)}
                      selectDefaultFirstOption={true}
                    >
                      {this.state.madinahHoletList.map((item, index) => (
                        <Select.Option value={item.value} key={index}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </FormItem>

                <FormItem label="VIP Tent (Add’l. Chrg.)">
                  {getFieldDecorator('vipTentCharge', {
                    rules: [
                      {
                        required: false,
                        message: 'Additional charge is required!',
                      },
                      {
                        pattern: /^(?:\d*)$/,
                        message: 'Charge should contain only number',
                      },
                    ],
                  })(
                    <Input
                      addonBefore="BDT"
                      name="vipTentCharge"
                      size="small"
                      placeholder="Enter vip additional charge"
                      onChange={this.onChangePrice}
                      onKeyDown={(e) => handleEnter(e)}
                    />,
                  )}
                </FormItem>

                <FormItem label="Special Bus (Incl.)">
                  {getFieldDecorator('isSpecialBus', {
                    initialValue: this.state.isSpecialBus,
                    rules: [{ required: true, message: 'Special bus is required!' }],
                  })(
                    <Radio.Group
                      name="isSpecialBus"
                      onChange={this.onChangeSpecialBus}
                      //initialValue={this.state.isSpecialBus}
                      onKeyDown={(e) => handleEnter(e)}
                    >
                      <Radio value={false}> NO </Radio>
                      <Radio value={true}> YES </Radio>
                    </Radio.Group>,
                  )}
                </FormItem>

                <FormItem label="Additional Charge" hidden={this.state.hideBusCharge}>
                  {getFieldDecorator('busCharge', {
                    rules: [
                      {
                        required: this.state.isBusChargeRequired,
                        message: 'Bus additional charge required!',
                      },
                      {
                        pattern: /^(?:\d*)$/,
                        message: 'Charge should contain only number',
                      },
                    ],
                  })(
                    <Input
                      addonBefore="BDT"
                      name="busCharge"
                      size="small"
                      placeholder="Enter bus additional charge"
                      onChange={this.onChangePrice}
                      onKeyDown={(e) => handleEnter(e)}
                    />,
                  )}
                </FormItem>

                <FormItem label="Meals (Included)">
                  {getFieldDecorator('isMealsIncluded', {
                    rules: [{ required: true, message: 'Meals (Included) is required!' }],
                  })(
                    <BaseCheckbox
                      onChange={(e) => this.handleChangeIsMealsInclude(e)}
                      defaultChecked={this.state.isMealsIncluded}
                      onKeyDown={(e) => handleEnter(e)}
                    ></BaseCheckbox>,
                  )}
                </FormItem>

                <FormItem label="Without Meals" hidden={this.state.hideMealCharge}>
                  {getFieldDecorator('mealsCharge', {
                    rules: [
                      {
                        required: this.state.isRequired,
                        message: 'Meals deducted charge required!',
                      },
                      {
                        pattern: /^(?:\d*)$/,
                        message: 'Charge should contain only number',
                      },
                    ],
                  })(
                    <Input
                      addonBefore="BDT"
                      name="mealsCharge"
                      size="small"
                      placeholder="Enter deducted meals charge"
                      onChange={this.onChangePrice}
                      onKeyDown={(e) => handleEnter(e)}
                    />,
                  )}
                </FormItem>

                <FormItem
                  wrapperCol={{
                    offset: 7,
                    span: 9,
                  }}
                >
                  <BaseButtonsGroup onCancel={this.onCancelForm} />
                </FormItem>
              </BaseCol>
            </BaseRow>
          </Form>
        </BaseCard>
      </BaseCol>
    );
  }
}

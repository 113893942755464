
export const API_BASE_URL = process.env.REACT_APP_BASE_URL; //API BASE URL catch from .env

export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';

export const POLL_LIST_SIZE = 30;
export const LIST_SIZE = 10;
export const MAX_CHOICES = 6;
export const POLL_QUESTION_MAX_LENGTH = 140;
export const POLL_CHOICE_MAX_LENGTH = 40;

export const NAME_MIN_LENGTH = 4;
export const NAME_MAX_LENGTH = 40;

export const USERNAME_MIN_LENGTH = 3;
export const USERNAME_MAX_LENGTH = 15;

export const EMAIL_MAX_LENGTH = 40;

export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 20;

//Activity Validation
export const ACTIVITY_NAME_MIN_LENGTH = 4;
export const ACTIVITY_NAME_MAX_LENGTH = 50;
export const DESCRIPTION_MAX_LENGTH = 250;

//Agent Validation
export const AGENTNAME_MIN_LENGTH = 4;
export const AGENTNAME_MAX_LENGTH = 15;
export const PHONE_MAX_LENGTH = 15;
export const MOBILE_MAX_LENGTH = 15;

//Customer Validation
export const CUSTOMERNAME_MIN_LENGTH = 4;
export const CUSTOMERNAME_MAX_LENGTH = 15;
//File Size Validation
export const MAX_FILE_SIZE = 2048; // 2MB

//BusLabel Max size
export const BUSNO_MAX_LENGTH = 12;
export const BUSLABEL_MAX_LENGTH = 25;

//Airline  name Max size
export const FLIGHTNUMBER_MAX_LENGTH = 40;
import React, { Component, useState } from 'react';
import { deleteItemByID, getRecordSet, getRecordById } from '@app/utils/APIUtils';
import { Modal, Row, Descriptions, Badge } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import moment from 'moment';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import * as S from '@app/components/tables/Tables/Tables.styles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput';
import * as ST from '@app/pages/uiComponentsPages//UIComponentsPage.styles';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import { LIST_SIZE } from '@app/constants';

export default function (props) {
  const [filteredInfo, setFilteredInfo] = useState(false);
  const [loading, setLoading] = useState({});
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  return (
    <ActivityList
      {...props}
      loading={loading}
      setLoading={setLoading}
      page={page}
      setPage={setPage}
      navigate={navigate}
      filteredInfo={filteredInfo}
      setFilteredInfo={setFilteredInfo}
    />
  );
}

class ActivityList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      recordSet: [],
      isLoading: false,
      response: {},
      searchedText: '',
      page: 0,
      size: 10,
      totalElements: 0,
      totalPages: 0,
      last: true,
      visible: false,
      viewRecord: [],
      value: '',
    };
    this.save = this.save.bind(this);
    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);
    this.loadRecords = this.loadRecords.bind(this);
  }

  loadRecords(searchedText, page, size = LIST_SIZE) {
    this.setState({
      recordSet: [],
      page: 0,
      size: 10,
      totalElements: 0,
      totalPages: 0,
      last: true,
      isLoading: false,
    });

    this.props.setLoading(true);

    let promise = getRecordSet(page, size, '/service/v1/activities?page=', searchedText);

    if (!promise) {
      return;
    }

    promise
      .then((response) => {
        const recordSet = this.state.recordSet.slice();

        if (!(searchedText === '' || searchedText === undefined)) {
          this.setState({
            recordSet: recordSet.concat(response.content),
            page: response.page,
            size: response.size,
            totalElements: response.totalElements,
            totalPages: response.totalPages,
            last: response.last,
          });

          this.props.setLoading(false);
        } else {
          setTimeout(() => {
            this.setState({
              recordSet: recordSet.concat(response.content),
              page: response.page,
              size: response.size,
              totalElements: response.totalElements,
              totalPages: response.totalPages,
              last: response.last,
            });

            this.props.setLoading(false);
          }, 1000);
        }
      })
      .catch((error) => {
        notificationController.error({
          message: error.message,
        });
        this.props.setLoading(false);
      });
    console.log('Component mounted.');
  }

  componentDidMount() {
    this.loadRecords();
  }

  save() {
    this.props.navigate('/activity/new');
  }

  edit(id) {
    this.props.navigate(`/activity/edit/${id}`);
  }

  delete(id) {
    const { confirm } = Modal;
    return new Promise((resolve, reject) => {
      confirm({
        title: 'Are you sure you want to Delete ?',
        onOk: () => {
          resolve(true);
          //onRemoveFunctionality here
          deleteItemByID(id, '/service/v1/activities/')
            .then((res) => {
              if (res.statusCode === 200) {
                this.setState({ recordSet: this.state.recordSet.filter((record) => record.activityId !== id) });
                notificationController.success({
                  message: 'Activity deleted.',
                });
              }
            })
            .catch((error) => {
              notificationController.error({
                message: 'Failed! ' + error.message,
              });
            });
        },
        onCancel: () => {
          reject(true);
        },
      });
    });
  }

  view(id) {
    this.setState({
      visible: true,
    });

    let promise1 = getRecordById('/service/v1/activities/', id);
    promise1
      .then((res) => {
        this.setState({
          viewRecord: res,
        });

        if (res.ParentID != null) {
          let promise2 = getRecordById('/service/v1/activities/', res.ParentID);
          promise2
            .then((response) => {
              this.setState({
                parentActivityName: response.ActivityName,
              });
            })
            .catch((error) => {
              notificationController.error({
                message: error.message,
              });
              this.props.setLoading(false);
            });
        }
      }, [])
      .catch((error) => {
        notificationController.error({
          message: 'Failed! ' + error.message,
        });
      });

    Promise.all([promise1]);
  }

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  render() {
    console.log('value', this.state.value);
    const { recordSet, totalElements, viewRecord, parentActivityName } = this.state;
    const handleChange = (pagination, filters, sorter) => {
      console.log('Various parameters', pagination, filters, sorter);
      this.props.setFilteredInfo(filters);
    };
    //const vendorViews = [];
    const data = [];
    recordSet.forEach((record, recordIndex) => {
      data.push({
        key: recordIndex,
        activityId: record.activityId,
        activityName: record.activityName,
        descriptions: record.descriptions.substring(0, 50) + '...',
        prerequisites: record.prerequisites,
        notes: record.notes,
      });
    });

    const columns = [
      {
        title: 'Activity Name',
        dataIndex: 'activityName',
        key: 'activityName;',
        filters: data.map((item) => ({
          text: item.activityName,
          value: item.activityName,
        })),
        filteredValue: this.props.filteredInfo.activityName || null,
        //filterMode: 'tree',
        filterSearch: true,
        onFilter: (value, record) => record.activityName.startsWith(value),
        ellipsis: true,
      },
      {
        title: 'Descriptions',
        dataIndex: 'descriptions',
        key: 'descriptions',
      },
      {
        title: 'Prerequisites',
        dataIndex: 'prerequisites',
        key: 'prerequisites',
      },
      {
        title: 'Notes',
        dataIndex: 'notes',
        key: 'notes',
      },
      {
        title: 'Action',
        key: 'action',
        width: '15%',
        render: (_, record) => {
          return (
            <BaseSpace>
              <BaseButton size="small" type="primary" onClick={() => this.view(record.activityId)}>
                View
              </BaseButton>

              <BaseButton severity="info" size="small" onClick={() => this.edit(record.activityId)}>
                Edit
              </BaseButton>

              <BaseButton type="primary" danger size="small" onClick={() => this.delete(record.activityId)}>
                Delete
              </BaseButton>
            </BaseSpace>
          );
        },
      },
    ];

    return (
      <div className="page-Container">
        <S.Card
          id="basic-table"
          title="Activity List"
          padding="1.25rem 1.25rem 0"
          extra={
            <BaseButton
              type="info"
              className="btn btn-dark"
              onClick={this.save}
              size="small"
              icon={<PlusCircleOutlined />}
              style={{}}
            >
              Add
            </BaseButton>
          }
        >
          <div className="search-box">
            <ST.InputsWrapper>
              <SearchInput
                placeholder="Search..."
                /* onSearch={(value) =>{
                    this.setState({
                        searchedText: value
                    })
                }} */
                onChange={(e) => {
                  this.setState(
                    {
                      searchedText: e.target.value,
                    },
                    () => {
                      console.log('New state in ASYNC callback:', this.state.searchedText);
                      this.loadRecords(this.state.searchedText);
                    },
                  );
                }}
                style={{
                  width: 230,
                  marginBottom: 10,
                }}
                allowClear
                size="small"
              />
            </ST.InputsWrapper>
          </div>
          <BaseTable
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: 10,
              total: totalElements,
              showTotal: (showTotal) => (
                <span style={{ left: 0, position: 'absolute', fontSize: 15 }}>Total {showTotal} records</span>
              ),
              onChange: (page) => this.loadRecords(this.state.searchedText, page - 1),
            }}
            loading={this.props.loading}
            rowKey={(record) => record.key}
            onChange={handleChange}
            bordered
            scroll={{ x: true }}
            rowClassName={(_record, index) => (index % 2 === 0 ? 'table-row-odd' : 'table-row-even')}
          />
        </S.Card>

        <div>
          {this.state.viewRecord ? (
            <Modal
              open={this.state.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              okButtonProps={{ style: { display: 'none' } }}
              cancelButtonProps={{ style: { display: 'none' } }}
              width={1000}
              style={{ top: 20 }}
            >
              <Row>
                <Descriptions title="Activity Info" layout="vertical" bordered size="middle">
                  <Descriptions.Item label="ID">{viewRecord.id}</Descriptions.Item>
                  <Descriptions.Item label="Activity Name">{viewRecord.ActivityName}</Descriptions.Item>
                  <Descriptions.Item label="Descriptions">{viewRecord.ActivityDesc}</Descriptions.Item>
                  <Descriptions.Item label="Pre-Requisites">{viewRecord.PreReq}</Descriptions.Item>
                  <Descriptions.Item label="Notes">{viewRecord.Notes}</Descriptions.Item>
                  <Descriptions.Item label="Parent Activity">{parentActivityName}</Descriptions.Item>
                  <Descriptions.Item label="Record Status">{viewRecord.status}</Descriptions.Item>
                  <Descriptions.Item label="Activated">
                    <Badge status="success" text={this.state.viewRecord.isActive === 1 ? 'Active' : 'Inactive'} />
                  </Descriptions.Item>
                  <Descriptions.Item label="Created Date" time>
                    {moment(viewRecord.createdAt).format('YYYY-MM-DD')}
                  </Descriptions.Item>
                  <Descriptions.Item label="Updated Date">
                    {moment(viewRecord.updatedAt).format('YYYY-MM-DD')}
                  </Descriptions.Item>
                  <Descriptions.Item label="Version">{viewRecord.version}</Descriptions.Item>
                </Descriptions>
              </Row>
            </Modal>
          ) : null}
        </div>
      </div>
    );
  }
}
